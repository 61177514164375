import React from "react";
import { Autocomplete, createTheme, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import PbDeviceAutocompleteItem from "../items/PbDevicesAutocompleteItem";
import PbDevicesAutocompleteViewProps from "./PbDevicesAutocompleteViewProps";

let themeMobile = createTheme({});

const PbDevicesAutocompleteMobileView = (
  props: PbDevicesAutocompleteViewProps
) => {
  const itemOnClick = props.itemOnClick;
  return (
    <>
      <ThemeProvider theme={themeMobile}>
        <Autocomplete
          id="devices-search"
          openOnFocus={props.openOnFocus}
          open={props.open}
          options={props.options}
          groupBy={(op) => op.group}
          getOptionLabel={(reading) => reading?.device?.name || ""}
          renderOption={(props, option, { selected }) => (
            <PbDeviceAutocompleteItem
              device={option.device}
              reading={option.reading}
              itemOnClick={itemOnClick}
            />
          )}
          filterOptions={props.filterOptions}
          fullWidth={true}
          style={props.styles}
          renderInput={(params) => (
            <TextField
              style={props.textFieldStyle}
              variant="standard"
              {...params}
              placeholder={`Searching out of ${props.options.length} devices`}
            />
          )}
        />
      </ThemeProvider>
    </>
  );
};

export default PbDevicesAutocompleteMobileView;
