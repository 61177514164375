import mockService from "../../../../Services/MockService";
import { plataformClient } from "../../../api";

interface getDevicesReadingsResponse {
  status: string;
  error?: {
    message: string;
    code: string;
  };
}

export async function delete_Geofence_Webhook(id: string) {
  try {
    if (mockService.getIsDemo()) {
      await mockService.deleteMockedGeofence(id);
      return { data: { status: "success" }, status: 200 };
    }
    const response = await plataformClient.delete<getDevicesReadingsResponse>(
      `/geofences/${id}`
    );
    return response;
  } catch (err) {
    return null;
  }
}
