import axios, { AxiosInstance } from "axios";
import { t } from "i18next";
import AuthService from "../Services/AuthService";
import messagesService from "../Services/MessagesService";

const addInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use((req) => {
    if (AuthService.isAuthenticated()) {
      req.headers.set("Authorization", `Bearer ${AuthService.getToken()}`);
    }
    return req;
  });

  instance.interceptors.response.use((res) => {
    if (res.status === 401) {
      //handle Unauthorizide
      AuthService.handleLogOut();
      window.location.reload();
    }
    return res;
  }, (error) => {
    if(error && error.response) {
      if(error.response.status === 429) {
        messagesService.notififyError("Throtelling", t("apiErrors.postLogin.toManyAttempts"))
      }
    }
  });
};

//Core Api Client
const v1Client = axios.create({
  baseURL: process.env.REACT_APP_V1_API_HOST,
  headers: {
    "Content-Type": "application/json",
    "PB-APP-ID": process.env.REACT_APP_PB_APP_ID || "",
    "x-app-ver": "a1",
  },
});

//Core Api Client
const httpClientCore = axios.create({
  baseURL: process.env.REACT_APP_CORE_API_HOST,
  headers: {
    "Content-Type": "application/json",
    "PB-APP-ID": process.env.REACT_APP_PB_APP_ID || "",
  },
  validateStatus: () => true
});

const plataformClient = axios.create({
  baseURL: process.env.REACT_APP_PLATAFORM_API_HOST,
  headers: {
    "Content-Type": "application/json",
    "PB-APP-ID": process.env.REACT_APP_PB_APP_ID || "",
  },
  validateStatus: () => true
});

const subscriptionsClient = axios.create({
  baseURL: process.env.REACT_APP_SUBSRIPTIONS_HOST,
  headers: {
    "Content-Type": "application/json",
    "PB-APP-ID": process.env.REACT_APP_PB_APP_ID || "",
  },
  validateStatus: () => true
});

const googleMapsApiClient = axios.create({
  baseURL: process.env.REACT_APP_GOOGLE_MAPS_API,
  headers: {
    "Content-Type": "application/json",
  }
});

addInterceptor(httpClientCore);
addInterceptor(v1Client);
addInterceptor(plataformClient);
addInterceptor(subscriptionsClient);

export { httpClientCore };
export { v1Client };
export { plataformClient };
export { subscriptionsClient };
export { googleMapsApiClient };
