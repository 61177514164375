import React from "react";
import { PbDimYellow, PbYellow } from "../../app/colors";
import { connect } from "react-redux";
import { RootState } from "../../app/store";
import AddDevicesStep from "./components/AddDevicesStep";
import GeofenceStepper from "./components/GeofenceStepper";
import NamingStep from "./components/NamingStep";
import PlacingStep from "./components/PlaceingStep";
import { setFenceError, setGeofence } from "./GeofenceHandlerSlice";
import { Reading } from "pebblebee-sdk-frontend";
import { ActionCreatorWithOptionalPayload } from "@reduxjs/toolkit";
import { t } from "i18next";
import { LoggedUser } from "../../Services/AuthService";
import { Mutex } from "async-mutex";

interface GeofencesCreateEditProps {
  geofenceId?: string;
  geofence?: { center: { lat: number; lng: number }; radius: number };
  createStep: "Placement" | "Customization" | "Devices";
  readings: Reading[];
  loggedUser?: LoggedUser;
  markerHover: boolean;
  setFenceError: ActionCreatorWithOptionalPayload<string | undefined>;
  setGeofence: ActionCreatorWithOptionalPayload<
    | {
        center: {
          lat: number;
          lng: number;
        };
        radius: number;
      }
    | undefined,
    "geofenceHandlerr/setGeofence"
  >;
}

interface GeofencesCreateEditState {
  circle?: google.maps.Circle;
  mapClickListener?: google.maps.MapsEventListener;
}

class GeofencesCreateEdit extends React.Component<
  GeofencesCreateEditProps,
  GeofencesCreateEditState
> {
  mutex = new Mutex();

  constructor(props: GeofencesCreateEditProps) {
    super(props);
    this.state = {};
    this.setCircle = this.setCircle.bind(this);
    this.setMapClickListener = this.setMapClickListener.bind(this);
    this.drawCircle = this.drawCircle.bind(this);
    this.getStepLayout = this.getStepLayout.bind(this);
  }

  componentDidMount() {
    if (window.gMap && !this.state.mapClickListener) {
      const hasClickListener = window.google.maps.event.hasListeners(
        window.gMap,
        "click"
      );
      if (hasClickListener) {
        window.google.maps.event.clearListeners(window.gMap, "click");
      }
      const listener = window.gMap.addListener(
        "click",
        (mapsMouseEvent: google.maps.MapMouseEvent) => {
          const cords = mapsMouseEvent.latLng;
          const radius = this.props.geofence ? this.props.geofence.radius : 300;
          if (!this.props.markerHover) {
            if (cords) {
              this.drawCircle({
                lat: cords.lat(),
                lng: cords.lng(),
                radius: radius,
                centerOnCircle: this.props.geofence ? false : true,
              });
            }
          }
        }
      );
      this.setMapClickListener(listener);
    }
    this.mutex.runExclusive(() => {
      if (this.props.geofence && this.props.geofenceId) {
        this.drawCircle({
          lat: this.props.geofence.center.lat,
          lng: this.props.geofence.center.lng,
          radius: this.props.geofence.radius,
          centerOnCircle: true,
        });
        /*const bounds = new window.google.maps.LatLngBounds(
          this.props.geofence.center
        );
        if (window.gMap) window.gMap.fitBounds(bounds);*/
      } else {
        if (this.props.readings) {
          const bounds = new window.google.maps.LatLngBounds();
          this.props.readings.forEach((reading) => {
            if(reading.coordinates)
            bounds.extend(reading.coordinates.value);
          });
          if (window.gMap) window.gMap.fitBounds(bounds);
        }
      }
    });
  }

  componentDidUpdate(
    prevProps: Readonly<GeofencesCreateEditProps>,
    prevState: Readonly<GeofencesCreateEditState>,
    snapshot?: any
  ): void {
    const circle = this.state.circle;
    if (circle) {
      const updateGeofenceShape = () => {
        this.props.setFenceError(undefined);
        const center = circle.getCenter();
        const radius = circle.getRadius();
        if (radius < 92) {
          //set error
          if (this.props.loggedUser && this.props.loggedUser.isMetric === 1) {
            this.props.setFenceError(
              t("geofences.errors.fenceToSmallErrorMts") as string
            );
          } else {
            this.props.setFenceError(
              t("geofences.errors.fenceToSmallError") as string
            );
          }
        }
        if (center) {
          this.props.setGeofence({
            center: {
              lat: center.lat(),
              lng: center.lng(),
            },
            radius: radius,
          });
        }
      };
      circle.addListener("center_changed", () => {
        updateGeofenceShape();
      });
      circle.addListener("radius_changed", () => {
        updateGeofenceShape();
      });
    }
  }

  async componentWillUnmount() {
    const { circle, mapClickListener } = this.state;

    if (circle) {
      circle.setMap(null);
      await this.setCircle(undefined);
    }

    if (mapClickListener) {
      mapClickListener?.remove();
      this.setMapClickListener(undefined);
    }
  }

  private setCircle(circle?: google.maps.Circle) {
    this.setState((state) => ({
      circle: circle,
    }));
  }

  private setMapClickListener(
    mapClickListener?: google.maps.MapsEventListener
  ) {
    this.setState((state) => ({
      mapClickListener: mapClickListener,
    }));
  }

  async drawCircle({
    lat,
    lng,
    radius,
    centerOnCircle,
  }: {
    lat: number;
    lng: number;
    radius: number;
    centerOnCircle?: boolean;
  }) {
    const circle = this.state.circle;
    if (circle) {
      circle.setMap(null);
      await this.setCircle(undefined);
    }
    const newCircle = new google.maps.Circle({
      strokeColor: PbYellow,
      strokeOpacity: 1,
      strokeWeight: 4,
      fillColor: PbDimYellow,
      fillOpacity: 0.4,
      center: {
        lat: lat,
        lng: lng,
      },
      radius: radius,
      map: window.gMap,
      editable: true,
      draggable: true,
    });
    if (window.gMap && centerOnCircle && newCircle.getBounds() !== null) {
      window.gMap.fitBounds(newCircle.getBounds() as google.maps.LatLngBounds);
    }
    await this.setCircle(newCircle);
    this.props.setGeofence({
      center: {
        lat: lat,
        lng: lng,
      },
      radius: radius,
    });
    return newCircle;
  }

  getStepLayout() {
    switch (this.props.createStep) {
      case "Placement":
        return <PlacingStep />;
      case "Customization":
        return <NamingStep />;
      case "Devices":
        return <AddDevicesStep />;
      default:
        return <PlacingStep />;
    }
  }

  render() {
    return (
      <div>
        <GeofenceStepper />
        {this.getStepLayout()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  geofenceId: state.geofenceHandler.geofenceId,
  geofence: state.geofenceHandler.geofence,
  createStep: state.geofenceHandler.createStep,
  readings: state.readings.readings,
  user: state.login.loggedUser,
  markerHover: state.map.markerHover,
});

const mapDispatchToProps = { setGeofence, setFenceError };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeofencesCreateEdit);
