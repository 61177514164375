class MessagesService {
  private errorCallback: ((caller: string, message: string) => void)[] = [];

  subscribeToErrors(cb: (caller: string, message: string) => void) {
    this.errorCallback.push(cb);
  }

  notififyError(caller: string, message: string) {
    this.errorCallback.forEach((cb) => {
      cb(caller, message);
    });
  }
}

const messagesService = new MessagesService();

export default messagesService;
