import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface deleteNotificationsResponse {
  data: {
    status: string;
  };
}

export async function delete_Notifications({
  mac_address,
  notificationType,
}: {
  mac_address: string;
  notificationType: string;
}) {
  try {
    if (mockService.getIsDemo()) {
      return { data: { data: { status: "Success" } }, status: 200 };
    }
    const response = await httpClientCore.delete<deleteNotificationsResponse>(
      `/v2/user/notifications`,
      {
        data: {
          deviceId: mac_address,
          type: notificationType,
        },
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
