import { Geofence } from "pebblebee-sdk-frontend";
import mockService from "../../../../Services/MockService";
import { plataformClient } from "../../../api";

interface getGeofencesResponse {
  status: string;
  geofences: Geofence[];
  error?: {
    message: string;
    code: string;
  };
}

export async function get_Geofences() {
  try {
    if (mockService.getIsDemo()) {
      return { data: { status: "success", geofences: await mockService.getMockedGeofences() }, status: 200 };
    }
    const response = await plataformClient.get<getGeofencesResponse>(
      `/geofences`
    );
    return response;
  } catch (err) {
    return null;
  }
}
