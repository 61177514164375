import styled from "@emotion/styled";
import React from "react";
import { fourthLevel } from "../../../app/levels";
import {
  HistoryPoint,
  MapOverlayData,
  MapReading,
} from "../../../Services/ReadingsService";
import ClusterInfoWindow from "./ClusterMarker/ClusterInfoWindow";
import HistoryInfoWindow from "./History/HistoryInfoWindow";



const MapInfoWindow = (props: MapOverlayData) => {

  const InfoWindowContainer = styled.div({
    position: "relative",
    width: "fit-content",
    height: "fit-content",
    zIndex: fourthLevel
  });

  const getInfoWindow = () => {
    if (props.readingsType === "location") {
      return <></>;
    } else if (props.readingsType === "cluster") {
      const mapReading = props.data as MapReading;
      if (mapReading.isCluster) {
        return (
          <ClusterInfoWindow
            clusterId={mapReading.id}
            shared={mapReading.isShared}
            mapReadings={mapReading.mapReadings}
          />
        );
      }
      return <></>;
    } else if (props.readingsType === "history") {
      const historyPoint = props.data as HistoryPoint;
      return <HistoryInfoWindow historyPoint={historyPoint} />;
    }
    return <></>;
  };

  return (
    <InfoWindowContainer id={`info-window-${props.id}`}>
      {getInfoWindow()}
    </InfoWindowContainer>
  );
};

export default MapInfoWindow;
