import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type TopMenu = "account" | "search" | "notifications" | "";
export type BottomRightMenu = "layers" | "features" | "contactSales" |"";

export interface MenuState {
  showLayersBox: boolean;
  showFeaturesBox: boolean;
  showContactSalesBox: boolean;
  showAccountData: boolean;
  showSearchInput: boolean;
  showNotificationsBox: boolean;
  notificationsVolume: number;
  topMenu: TopMenu;
  bottomRightMenu: BottomRightMenu;
  useInteracted: boolean;
}

const initialState: MenuState = {
  showFeaturesBox: false,
  showLayersBox: false,
  showContactSalesBox: false,
  showAccountData: false,
  showSearchInput: false,
  showNotificationsBox: false,
  notificationsVolume: 40,
  bottomRightMenu: "",
  topMenu: "",
  useInteracted: false,
};

export const MenusSlice = createSlice({
  name: "menus",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setShowLayersBox: (state, action: PayloadAction<boolean>) => {
      state.showLayersBox = action.payload;
      state.useInteracted = true;
    },
    setShowFeaturesBox: (state, action: PayloadAction<boolean>) => {
      state.showFeaturesBox = action.payload;
      state.useInteracted = true;
    },
    setShowAccountData: (state, action: PayloadAction<boolean>) => {
      state.showAccountData = action.payload;
      state.useInteracted = true;
    },
    setShowSearchInput: (state, action: PayloadAction<boolean>) => {
      state.showSearchInput = action.payload;
      state.useInteracted = true;
    },
    setShowNotificationsBox: (state, action: PayloadAction<boolean>) => {
      state.showNotificationsBox = action.payload;
      state.useInteracted = true;
    },
    setTopMenus: (state, action: PayloadAction<TopMenu>) => {
      state.topMenu = action.payload;
      state.useInteracted = true;
    },
    setBottomRightMenu: (state, action: PayloadAction<BottomRightMenu>) => {
      state.bottomRightMenu = action.payload;
      state.useInteracted = true;
    },
    setNotificationsVolume: (state, action: PayloadAction<number>) => {
      state.notificationsVolume = action.payload;
      state.useInteracted = true;
    },
    setShowContactSalesBox: (state, action: PayloadAction<boolean>) => {
      state.showContactSalesBox = action.payload;
      state.useInteracted = true;
    },
    resetMenusSlice: () => initialState
  },
  extraReducers: (builder) => {},
});

export const {
  setShowAccountData,
  setShowFeaturesBox,
  setShowLayersBox,
  setShowNotificationsBox,
  setShowSearchInput,
  setBottomRightMenu,
  setTopMenus,
  setNotificationsVolume,
  resetMenusSlice,
  setShowContactSalesBox
} = MenusSlice.actions;

export default MenusSlice.reducer;
