import styled from "@emotion/styled";
import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { boxShadows } from "../../../../app/boxShadows";
import { thirdLevel } from "../../../../app/levels";
import { PbDarkYellow, PbFontBlack, PbYellow } from "../../../../app/colors";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { OperatingSystems } from "../../../../app/CommonSlice";

const GetStartedBoxContianer = styled.div({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: "28vw",
  height: "14vw",
  background: "#FFFFFF",
  boxShadow: boxShadows.veryHard,
  borderRadius: "1cqi",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: thirdLevel,
  padding: "0.5cqi 0.2cqi"
});

const Message = styled.div({
  fontSize: "1.4cqi",
  fontWeight: 600,
  marginBottom: "3cqi",
  margin: "0 2cqi",
});

const GetTheApp = styled.div({
  fontSize: "1.1cqi",
  margin: "1cqi 0.5cqi",
  padding: "0px 1cqi"
});

const AppsBadgesRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "0.5cqi",
});

const GetTheAppButton = styled(Button)({
  width: "18vw",
  background: PbYellow,
  color: "#000",
  borderRadius: "0.5cqi",
  ":hover": {
    background: PbDarkYellow,
  },
});

const GotoLink = styled.a({
  textDecoration: "none",
  color: "#000",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  containerType: "inline-size",
  fontSize: "1.1cqi",
});

const GetStartedBox = () => {
  const { t } = useTranslation();

  const os = useAppSelector((state: RootState) => state.common.os)

  const getLink = () => {
    if(os === OperatingSystems.iOS) {
      return "https://apps.apple.com/us/app/pebblebee-app/id1052309602"
    }
    return "https://play.google.com/store/apps/details?id=com.pebblebee.app.hive3&hl=en&gl=US"
  }

  return (
    <GetStartedBoxContianer>
      <Message>{t("floatingMenu.getStarted.title")}</Message>
      <GetTheApp>{t("floatingMenu.getStarted.getTheApp")}</GetTheApp>
      <AppsBadgesRow>
        <GetTheAppButton variant="contained">
          <GotoLink href={getLink()}>{t("floatingMenu.getStarted.button")}</GotoLink>
        </GetTheAppButton>
      </AppsBadgesRow>
    </GetStartedBoxContianer>
  );
};

export default GetStartedBox;
