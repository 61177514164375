import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { get_Geocoding } from "../../app/apiRequests/googleApi/getGeocoding";
import i18n from "../../i18n/i18n";
import ReadingsService, {
  MapOverlayData,
} from "../../Services/ReadingsService";

export interface MapState {
  mapError?: string;
  zoom?: number;
  mapCenter?: { lat: number; lng: number };
  backgroundRefreshing: boolean;
  mapsEntries: {
    position: {
      lat: number;
      lng: number;
    };
    compProps: MapOverlayData;
    isFocus?: boolean;
  }[];
  mapLines: {
    lat: number;
    lng: number;
  }[][];
  mapCircles: {
    center: { lat: number; lng: number };
    radius: number;
    color?: string;
    strokeColor?: string;
  }[];
  selectedPosition?: {
    id: string;
    position: {
      lat: number;
      lng: number;
    };
  };
  loadingMapData: boolean;
  loadingAddressInfo: boolean;
  coordinatesAddresses: {
    coordinates: { lat: number; lng: number };
    status: google.maps.GeocoderStatus | "pending";
    address: string;
  }[];
  showZoomControls: boolean;
  markerHover: boolean;
}

const initialState: MapState = {
  mapLines: [],
  mapCircles: [],
  mapsEntries: [],
  backgroundRefreshing: false,
  loadingAddressInfo: false,
  loadingMapData: false,
  coordinatesAddresses: [],
  zoom: 15,
  showZoomControls: false,
  markerHover: false
};

export const getGeocoding = createAsyncThunk(
  "devices/addressGeocoding",
  async ({ lat, lng }: { lat: number; lng: number }, thunkApi) => {
    try {
      const response = await get_Geocoding(lat, lng);
      if (!response) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getGeocoding.default")
        );
      }
      return {
        coordinates: { lat: lat, lng: lng },
        address: ReadingsService.getReadingAddress(response),
      };
    } catch (err) {
      return thunkApi.rejectWithValue(i18n.t("apiErrors.getGeocoding.default"));
    }
  }
);

export const MapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    itemSelected: (state, payload: PayloadAction<string>) => {},
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setMapCenter: (
      state,
      action: PayloadAction<{ lat: number; lng: number } | undefined>
    ) => {
      state.mapCenter = action.payload;
    },
    setMapEntries: (
      state,
      action: PayloadAction<
        {
          position: {
            lat: number;
            lng: number;
          };
          compProps: MapOverlayData;
          isFocus?: boolean;
        }[]
      >
    ) => {
      state.mapsEntries = action.payload;
    },
    setMapLines: (
      state,
      action: PayloadAction<
        {
          lat: number;
          lng: number;
        }[][]
      >
    ) => {
      state.mapLines = action.payload;
    },
    setMapCircles: (
      state,
      action: PayloadAction<
        {
          center: { lat: number; lng: number };
          radius: number;
          color?: string;
          strokeColor?: string;
        }[]
      >
    ) => {
      state.mapCircles = action.payload;
    },
    setSelectedPosition: (
      state,
      action: PayloadAction<
        | {
            id: string;
            position: {
              lat: number;
              lng: number;
            };
          }
        | undefined
      >
    ) => {
      state.selectedPosition = action.payload;
    },
    setLoadingMapData: (state, action: PayloadAction<boolean>) => {
      state.loadingMapData = action.payload;
    },
    setCoordinateAddress: (
      state,
      action: PayloadAction<{
        coordinates: { lat: number; lng: number };
        status: google.maps.GeocoderStatus | "pending";
        address: string;
      }>
    ) => {
      //search if the element for those coordinates exits
      const el = state.coordinatesAddresses.find((el) => {
        return (
          el.coordinates.lat === action.payload.coordinates.lat &&
          el.coordinates.lng === action.payload.coordinates.lng
        );
      });
      if (!el) {
        const coordinatesAddress = {
          coordinates: action.payload.coordinates,
          status: action.payload.status,
          address: action.payload.address,
        };
        state.coordinatesAddresses.push(coordinatesAddress);
      } else {
        //check that element exists and dont repull
        if (el && el.status !== "OK") {
          const index = state.coordinatesAddresses.indexOf(el);
          if (index !== -1 && action.payload.address) {
            state.coordinatesAddresses[index].status = action.payload.status;
            state.coordinatesAddresses[index].address = action.payload.address;
          }
        }
      }
    },
    setShowZoomControls: (state, action: PayloadAction<boolean>) => {
      state.showZoomControls = action.payload;
    },
    setMarkerHover: (state, action: PayloadAction<boolean>) => {
      state.markerHover = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeocoding.pending, (state) => {
        state.loadingAddressInfo = true;
      })
      .addCase(getGeocoding.fulfilled, (state, action) => {
        state.loadingAddressInfo = false;
      })
      .addCase(getGeocoding.rejected, (state) => {
        state.mapError = i18n.t("apiErrors.getGeocoding.default") as string;
        state.loadingAddressInfo = false;
      });
  },
});

export const {
  setMapEntries,
  setMapLines,
  setMapCircles,
  setSelectedPosition,
  setLoadingMapData,
  itemSelected,
  setMapCenter,
  setZoom,
  setCoordinateAddress,
  setShowZoomControls,
  setMarkerHover
} = MapSlice.actions;

export default MapSlice.reducer;
