import styled from "@emotion/styled";
import { boxShadows } from "../../../../app/boxShadows";
import { PbFontBlack, PbFontGray } from "../../../../app/colors";
import { thirdLevel } from "../../../../app/levels";
import CancelIcon from "@mui/icons-material/Cancel";

export const MenuBox = styled.div({
  position: "fixed",
  right: "7vw",
  bottom: "1vw",
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  width: "30%",
  height: "35%",
  borderRadius: "20px",
  background: "#FFF",
  zIndex: thirdLevel,
  paddingBottom: "30px",
  boxShadow: boxShadows.veryHard,
});

export const MenuTitle = styled.div({
  color: PbFontGray,
  textAlign: "left",
  fontSize: "1.5cqi",
  marginTop: "20px",
  marginBottom: "10px",
  marginLeft: "4%",
});

export const MenuSubtitle = styled.div({
  color: PbFontBlack,
  textAlign: "left",
  fontSize: "0.8cqi",
  marginTop: "0.2cqi",
  marginBottom: "0.2cqi",
  margin: "0% 4%",
  fontWeight: 500
});

export const MenuMessage = styled.div({
  color: PbFontBlack,
  textAlign: "left",
  fontSize: "0.8cqi",
  marginTop: "0.2cqi",
  marginBottom: "0.2cqi",
  marginLeft: "4%",
});


export const MenuCloseButton = styled(CancelIcon)({
  position: "absolute",
  top: "10px",
  right: "10px",
  width: "20px",
  height: "20px",
  color: PbFontGray,
  ":hover": {
    cursor: "pointer",
  },
});
