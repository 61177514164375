import styled from "@emotion/styled";
import { PbYellow } from "../../../../app/colors";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { HistoryPoint } from "../../../../Services/ReadingsService";
import { setSelectedHistoryPoint } from "../../../historyLayout/historySlice";

interface HistoryMarkerProps {
  id: string;
  index: number;
  historyPoint: HistoryPoint;
}

const ClusterIcon = styled.div(() => ({
  width: "1.8vw",
  height: "1.8vw",
  background: PbYellow,
  color: "#000000",
  borderRadius: "5vw",
  border: "3px solid #FFFFFF",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
}));

const PassthroughPoint = styled.div({
  width: "1vw",
  height: "1vw",
  background: "#F6EA66 0% 0% no-repeat padding-box",
  border: "0.1vw solid #FFFFFF",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
  },
});

//Mobile

const ClusterIconMobile = styled.div(() => ({
  width: "6vw",
  height: "6vw",
  background: PbYellow,
  color: "#000000",
  borderRadius: "5vw",
  border: "0.5cqi solid #FFFFFF",
  zIndex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: 700,
  fontSize: "4cqi",
  ":hover": {
    cursor: "pointer",
  },
}));

const PassthroughPointMobile = styled.div({
  width: "2.5vw",
  height: "2.5vw",
  background: "#F6EA66 0% 0% no-repeat padding-box",
  border: "0.1vw solid #FFFFFF",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
  },
});

const HistoryMarker = ({ historyPoint, index, id }: HistoryMarkerProps) => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector((state: RootState) =>  state.common.isMobileScreen)

  const renderDesktop = () => {
    return <> <div
    id={`history-marker-${index}`}
    style={{ zIndex: 1 }}
    onClick={() => {
      dispatch(
        setSelectedHistoryPoint(
          historyPoint.deviceId + historyPoint.timestamp
        )
      );
    }}
  >
    {historyPoint.type === "PreciseCluster" ? (
      <ClusterIcon>{id}</ClusterIcon>
    ) : (
      <PassthroughPoint />
    )}
  </div></>
  }

  const renderMobile = () => {
    return <> <div
    id={`history-marker-${index}`}
    style={{ zIndex: 1 }}
    onClick={() => {
      dispatch(
        setSelectedHistoryPoint(
          historyPoint.deviceId + historyPoint.timestamp
        )
      );
    }}
  >
    {historyPoint.type === "PreciseCluster" ? (
      <ClusterIconMobile>{id}</ClusterIconMobile>
    ) : (
      <PassthroughPointMobile />
    )}
  </div></>
  }

  return isMobile ? renderMobile(): renderDesktop()
};

export default HistoryMarker;
