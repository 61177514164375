import mockService from "../../../../Services/MockService";
import { EnterpriseAccessLevel } from "../../../../models/enterprise/EnterpriseUser";
import { httpClientCore } from "../../../api";

interface PutEnterpriseUserPayload {
  deviceGroupIds: string[];
  accessLevel: EnterpriseAccessLevel;
}

export async function put_EnterpriseUser({
  id,
  accessLevel,
  groupsIds,
}: {
  id: number;
  accessLevel: EnterpriseAccessLevel;
  groupsIds: string[];
}) {
  try {
    if (mockService.getIsDemo()) {
      await mockService.editMockedEnterpriseUser(id, accessLevel, groupsIds)
      return { status: 200 };
    }

    const payload: PutEnterpriseUserPayload = {
      accessLevel: accessLevel,
      deviceGroupIds: groupsIds,
    };

    const response = await httpClientCore.put(
      `/v2/enterprise/users/${id}`,
      payload
    );
    return response;
  } catch (err) {
    return null;
  }
}
