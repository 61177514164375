import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { secondLevel } from "../../../app/levels";
import {
  PbFontBlack,
  PbFontGray,
  PbFontGrayDark,
  PbFontGrayLight,
  PbFontGrayWatermark,
  PbWhite,
} from "../../../app/colors";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PbSwitch from "../../mainLayout/components/menus/LayersManager/PbSwitch";
import {
  setShowCreationHandler,
  setShowDeleteFenceModal,
} from "../geofencesLayoutSlice";
import {
  setGeofence,
  setGeofenceDevices,
  setGeofenceId,
  setGeofenceName,
} from "../../GeofenceCreateEdit/GeofenceHandlerSlice";
import { Geofence } from "pebblebee-sdk-frontend";
import {
  deleteGeofencesWebhook,
  getGeofences,
  patchGeofences,
} from "../../../dataSlices/GeofencesSlice";
import { boxShadows } from "../../../app/boxShadows";
import { AccessList } from "../../../Services/AuthService";
import geofencesService from "../../../Services/GeofencesService";

const ProgressContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const Loader = styled(CircularProgress)((props: { isMobile: number }) => ({
  width: props.isMobile === 1 ? "10cqi" : "4cqi",
  height: props.isMobile === 1 ? "10cqi" : "4cqi",
}));

const ListContianer = styled.div((props: { isMobile: number }) => ({
  ...({
    position: props.isMobile === 1 ? "relative" : "fixed",
    top: props.isMobile === 1 ? undefined : "6vw",
    left: props.isMobile === 1 ? undefined : "1vw",
    width: props.isMobile === 1 ? "100%" : "22vw",
    height: props.isMobile === 1 ? "auto" : "40vh",
    minHeight: props.isMobile === 1 ? "40vh" : undefined,
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "0.2cqi",
      background: PbWhite,
    },
    "::-webkit-scrollbar-thumb": {
      background: PbFontGrayWatermark,
      height: "0.8cqi",
    },
    transition: "0.5s",
    boxShadow: boxShadows.veryHard,
    borderRadius: "0.7cqi",
    display: "flex",
    flexDirection: "column",
    zIndex: secondLevel,
    background: "#FFFFFF",
  } as React.CSSProperties),
}));

const EditButton = styled(EditIcon)(
  (props: { enabled: boolean; isMobile: number }) => ({
    width: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    height: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    "& svg": {
      width: props.isMobile === 1 ? "4cqi" : "1.3cqi",
      height: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    },
    margin: "0.2cqi 0.4cqi",
    color: PbFontGray,
    ":hover": {
      cursor: "pointer",
      color: PbFontGrayDark,
    },
  })
);

const DeleteButton = styled(DeleteIcon)(
  (props: { enabled: boolean; isMobile: number }) => ({
    width: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    height: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    margin: "0.2cqi 0.4cqi",
    "& svg": {
      width: props.isMobile === 1 ? "4cqi" : "1.3cqi",
      height: props.isMobile === 1 ? "4cqi" : "1.3cqi",
    },
    color: props.enabled ? PbFontGray : PbFontGrayWatermark,
    ":hover": {
      cursor: props.enabled ? "pointer" : undefined,
      color: props.enabled ? PbFontGrayDark : PbFontGrayWatermark,
    },
  })
);

const ButtonRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
});

const GeofenceName = styled(ListItemText)((props: { isMobile: number }) => ({
  fontSize: props.isMobile === 1 ? "3.5cqi" : "0.9cqi",
  color: PbFontGrayLight,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  "& span": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: props.isMobile === 1 ? "3.5cqi" : "0.9cqi",
  },
  ":hover": {
    cursor: "pointer",
    color: PbFontGray,
  },
  marginLeft: "0.4cqi",
}));

const EmptyListContainer = styled.div((props: { isMobile: number }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: props.isMobile === 1 ? "3.5cqi" : "1cqi",
}));

const BackButton = styled(Button)({
  flex: "0.3",
  color: "#FFF",
  background: PbFontBlack,
  borderRadius: "999px",
  ":hover": {
    background: PbFontGrayDark,
  },
});

const ListTitle = styled(ListSubheader)((props: { isMobile: number }) => ({
  ...({
    textAlign: "left",
    fontSize: props.isMobile === 1 ? "4.5cqi" : "1.2cqi",
    color: PbFontGray,
    marginTop: "0.5cqi",
    display: props.isMobile === 1 ? "none" : undefined,
  } as React.CSSProperties),
}));

const ListSubTitle = styled(ListSubheader)((props: { isMobile: number }) => ({
  ...({
    textAlign: "left",
    fontSize: props.isMobile === 1 ? "3.8cqi" : "1.3cqi",
    color: PbFontGray,
    marginTop: props.isMobile === 1 ? "1.6cqi" : "0.6cqi",
    marginBottom: props.isMobile === 1 ? undefined : "0.4cqi",
    lineHeight: "1.3cqi !important",
  } as React.CSSProperties),
}));

const GeofenceListItem = styled(ListItem)({
  paddingTop: "0px !important",
  paddingBottom: "0px !important",
});

//Mobile

const GeofencesList = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const geofences = useSelector((state: RootState) => {
    return state.geofences.geofences;
  });

  const loadingGeofences = useSelector((state: RootState) => {
    return state.geofences.loadingGeofences;
  });

  const showDeleteFenceModal = useSelector((state: RootState) => {
    return state.geofencesLayout.showDeleteFenceModal;
  });

  const geofenceId = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceId;
  });

  const geofenceName = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceName;
  });

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const [loadingGeofenceUpdate, setLoadingGeofenceUpdate] =
    useState<boolean>(false);

  const editGeofence = (fence: Geofence) => {
    if (fence.id) {
      dispatch(setGeofenceId(fence.id));
      dispatch(
        setGeofence({
          center: fence.geometry.coordinates,
          radius: fence.geometry.radius,
        })
      );
      dispatch(setGeofenceName(fence.name));
      dispatch(setGeofenceDevices(fence.deviceId));
      dispatch(setShowCreationHandler(true));
    }
  };

  const deleteGeofence = (fence: Geofence) => {
    if (fence.id) {
      dispatch(setGeofenceId(fence.id));
      dispatch(setGeofenceName(fence.name));
      dispatch(setShowDeleteFenceModal(true));
    }
  };

  const confirmDeleteGeofence = async () => {
    if (geofenceId) {
      //dispatch delete
      await dispatch(deleteGeofencesWebhook({ id: geofenceId }));
      dispatch(setGeofenceId(undefined));
      dispatch(setGeofenceName(""));
      dispatch(setShowDeleteFenceModal(false));
      setTimeout(() => {
        dispatch(getGeofences());
      }, 1000);
    }
  };

  const enbaleDisableGeofence = async (fence: Geofence, enable: boolean) => {
    if (fence.id) {
      setLoadingGeofenceUpdate(true);
      await dispatch(
        patchGeofences({
          id: fence.id,
          center: fence.geometry.coordinates,
          devices: fence.deviceId,
          name: fence.name,
          radius: fence.geometry.radius,
          enabled: enable,
        })
      );
      setTimeout(() => {
        dispatch(getGeofences());
        setLoadingGeofenceUpdate(false);
      }, 1000);
    }
  };

  const enabledFences = useMemo(() => {
    return geofencesService
      .getUserGeofences(geofences, loggedUser, undefined, true)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [geofences, loggedUser]);

  const disabledFences = useMemo(() => {
    return geofencesService
      .getUserGeofences(geofences, loggedUser, undefined, false)
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [geofences, loggedUser]);

  const userOwnedFences = useMemo(() => {
    const userFences = geofences.filter(
      (g) =>
        loggedUser?.isEnterprise &&
        (loggedUser.enterpriseData?.isChild || g.userId === loggedUser?.id)
    );
    return userFences;
  }, [geofences, loggedUser]);

  const handleFenceClick = (geofence: Geofence) => {
    if (geofence && geofence.geometry.coordinates && geofence.geometry.radius) {
      if (window.gMap) {
        const center = geofence.geometry.coordinates;
        const radius = geofence.geometry.radius;

        const circle = new google.maps.Circle({
          center: center,
          radius: radius * 2,
          map: window.gMap,
          visible: false,
        });

        window.gMap.fitBounds(circle.getBounds() as google.maps.LatLngBounds);

        circle.setMap(null);
      }
    }
  };

  return (
    <>
      <ListContianer isMobile={isMobile ? 1 : 0}>
        {loadingGeofences ? (
          <ProgressContainer>
            <Loader isMobile={isMobile ? 1 : 0} />
          </ProgressContainer>
        ) : (
          <>
            {userOwnedFences.length > 0 ? (
              <List aria-labelledby="nested-list-subheader">
                {enabledFences.length > 0 ? (
                  <>
                    <ListSubTitle
                      isMobile={isMobile ? 1 : 0}
                      id="nested-list-subheader"
                    >
                      {t("geofences.sections.active")}
                    </ListSubTitle>
                    {enabledFences.map((fence) => {
                      return (
                        <GeofenceListItem key={fence.name + "-fence-item"}>
                          <GeofenceName
                            id={fence.name + "-fence-item"}
                            isMobile={isMobile ? 1 : 0}
                            onClick={() => {
                              handleFenceClick(fence);
                            }}
                            primary={`${fence.name}`}
                          />
                          <>
                            {loggedUser?.isEnterprise &&
                            loggedUser.enterpriseData?.permissionList?.includes(
                              AccessList.GEOFENCES_UPDATE
                            ) ? (
                              <Tooltip
                                title={
                                  fence.id
                                    ? t("geofences.actionsTooltip.edit")
                                    : t("geofences.actionsTooltip.notAvailable")
                                }
                              >
                                <EditButton
                                  isMobile={isMobile ? 1 : 0}
                                  enabled={fence.id !== undefined}
                                  onClick={() => {
                                    editGeofence(fence);
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                            {loggedUser?.isEnterprise &&
                            loggedUser.enterpriseData?.permissionList?.includes(
                              AccessList.GEOFENCES_DELETE
                            ) ? (
                              <Tooltip
                                title={
                                  fence.id
                                    ? t("geofences.actionsTooltip.delete")
                                    : t("geofences.actionsTooltip.notAvailable")
                                }
                              >
                                <DeleteButton
                                  isMobile={isMobile ? 1 : 0}
                                  enabled={fence.id !== undefined}
                                  onClick={() => {
                                    deleteGeofence(fence);
                                  }}
                                />
                              </Tooltip>
                            ) : null}
                            {loggedUser?.isEnterprise &&
                            loggedUser.enterpriseData?.permissionList?.includes(
                              AccessList.GEOFENCES_UPDATE
                            ) ? (
                              <>
                                {loadingGeofenceUpdate ? (
                                  <CircularProgress
                                    style={{
                                      width: "1.5cqi",
                                      height: "1.5cqi",
                                      margin: "5px",
                                    }}
                                  />
                                ) : (
                                  <PbSwitch
                                    defaultChecked
                                    handleChange={(e, c) => {
                                      enbaleDisableGeofence(fence, c);
                                    }}
                                    name={fence.name}
                                    checked={fence.enabled}
                                    key={fence.name + "-fence-item-switch"}
                                  />
                                )}
                              </>
                            ) : null}
                          </>
                        </GeofenceListItem>
                      );
                    })}
                  </>
                ) : null}

                {disabledFences.length > 0 ? (
                  <>
                    <ListSubTitle
                      isMobile={isMobile ? 1 : 0}
                      id="nested-list-subheader"
                    >
                      {t("geofences.sections.disabled")}
                    </ListSubTitle>
                    {disabledFences.map((fence) => {
                      return (
                        <GeofenceListItem key={fence.name + "-fence-item"}>
                          <GeofenceName
                            id={fence.name + "-fence-item"}
                            isMobile={isMobile ? 1 : 0}
                            onClick={() => {
                              handleFenceClick(fence);
                            }}
                            primary={`${fence.name}`}
                          />
                          <>
                            <Tooltip
                              title={
                                fence.id
                                  ? "Edit geofence"
                                  : "option not available"
                              }
                            >
                              <EditButton
                                isMobile={isMobile ? 1 : 0}
                                enabled={fence.id !== undefined}
                                onClick={() => {
                                  editGeofence(fence);
                                }}
                              />
                            </Tooltip>
                            <Tooltip
                              title={
                                fence.id
                                  ? "Delete geofence"
                                  : "option not available"
                              }
                            >
                              <DeleteButton
                                isMobile={isMobile ? 1 : 0}
                                enabled={fence.id !== undefined}
                                onClick={() => {
                                  deleteGeofence(fence);
                                }}
                              />
                            </Tooltip>
                            {loggedUser?.isEnterprise &&
                            loggedUser.enterpriseData?.permissionList?.includes(
                              AccessList.GEOFENCES_UPDATE
                            ) ? (
                              <>
                                {loadingGeofenceUpdate ? (
                                  <CircularProgress
                                    style={{
                                      width: "1.5cqi",
                                      height: "1.5cqi",
                                      margin: "5px",
                                    }}
                                  />
                                ) : (
                                  <PbSwitch
                                    defaultChecked
                                    handleChange={(e, c) => {
                                      enbaleDisableGeofence(fence, c);
                                    }}
                                    name={fence.name}
                                    checked={fence.enabled}
                                    key={fence.name + "-fence-item-switch"}
                                  />
                                )}
                              </>
                            ) : null}
                          </>
                        </GeofenceListItem>
                      );
                    })}
                  </>
                ) : null}
              </List>
            ) : (
              <EmptyListContainer isMobile={isMobile ? 1 : 0}>
                {t("geofences.geofencesManager.noGeofences")}
              </EmptyListContainer>
            )}
          </>
        )}
      </ListContianer>
      <Modal
        open={showDeleteFenceModal}
        onClose={() => {
          dispatch(setShowDeleteFenceModal(false));
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "60%" : "20%",
            bgcolor: "#FFF",
            boxShadow: isMobile ? "10cqi" : "24",
            p: 4,
            borderRadius: isMobile ? "3cqi" : "0.8cqi",
          }}
        >
          <Typography
            textAlign={"center"}
            id="modal-modal-title"
            component="h2"
            style={{
              fontSize: isMobile ? "4cqi" : "1.3cqi",
            }}
          >
            {t("geofences.deleteModal.title")}
          </Typography>
          <Typography
            textAlign={"center"}
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              fontSize: isMobile ? "3cqi" : "0.9cqi",
            }}
          >
            {t("geofences.deleteModal.message1")} <b>{geofenceName}</b>
            {t("geofences.deleteModal.message2")}
          </Typography>
          <ButtonRow>
            <BackButton
              sx={{ flex: 0.3 }}
              style={{ margin: "4% 0", fontSize: isMobile ? "3cqi" : "0.9cqi" }}
              variant="contained"
              color="secondary"
              onClick={() => {
                dispatch(setShowDeleteFenceModal(false));
              }}
            >
              {t("geofences.deleteModal.back")}
            </BackButton>
            <Button
              sx={{ flex: 0.3 }}
              style={{ margin: "4%", fontSize: isMobile ? "3cqi" : "0.9cqi" }}
              variant="contained"
              color="error"
              onClick={() => {
                confirmDeleteGeofence();
              }}
            >
              {t("geofences.deleteModal.delete")}
            </Button>
          </ButtonRow>
        </Box>
      </Modal>
    </>
  );
};

export default GeofencesList;
