import React from "react";
import { Navigate, Outlet } from "react-router-dom";

interface PrivateRoutesProps {
    isAuthenticated: () => boolean,
    redirect: string
}

const PrivateRoutes = (props: PrivateRoutesProps) => {
  return props.isAuthenticated() ? <Outlet /> : <Navigate to={props.redirect} />;
};

export default PrivateRoutes
