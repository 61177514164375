import AsyncLock from "async-lock";

export interface GeocodingQueueItem {
  data: { lat: number; lng: number };
  dispatch: ({
    coordinates,
    status,
    address,
  }: {
    coordinates: { lat: number; lng: number };
    status: google.maps.GeocoderStatus | "pending";
    address: string;
  }) => void;
}

class QueueService {
  private geocodingQueue: GeocodingQueueItem[];
  private timeouts: NodeJS.Timeout[];
  private interval: number;

  private lock = new AsyncLock();

  constructor(interval = 750) {
    this.geocodingQueue = [];
    this.timeouts = [];
    this.interval = interval;
  }

  addToGeocodingQueue(
    data: { lat: number; lng: number },
    dispatch: ({
      coordinates,
      status,
      address,
    }: {
      coordinates: { lat: number; lng: number };
      status: google.maps.GeocoderStatus | "pending";
      address: string;
    }) => void
  ): void {
    this.geocodingQueue.push({ data, dispatch });
  }

  startGeocodingQueue(): void {
    const geocoder = new google.maps.Geocoder();
    this.timeouts.push(
      setTimeout(async () => {
        if (this.geocodingQueue.length === 0) {
          return;
        }

        const item = this.geocodingQueue.shift()!;
        const { data } = item;

        await geocoder.geocode(
          {
            location: data,
          },
          (result, status) => {
            switch (status) {
              case google.maps.GeocoderStatus.OK:
                const address =
                  result && Array.isArray(result) && result.length > 0
                    ? result[0].formatted_address
                    : "";
                item.dispatch({
                  coordinates: item.data,
                  status: status,
                  address: address,
                });
                break;
              case google.maps.GeocoderStatus.INVALID_REQUEST:
                break;
              case google.maps.GeocoderStatus.ERROR:
                break;
              case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
                this.addToGeocodingQueue(data, item.dispatch);
                break;
              case google.maps.GeocoderStatus.REQUEST_DENIED:
                break;
              case google.maps.GeocoderStatus.UNKNOWN_ERROR:
                break;
              case google.maps.GeocoderStatus.ZERO_RESULTS:
                break;
              default:
            }

            this.startGeocodingQueue();
          }
        );
      }, this.interval)
    );
  }

  cleanQueue() {
    this.geocodingQueue = [];
    this.timeouts.forEach((t) => {
      clearTimeout(t)
    })
  }
}

const queueService = new QueueService();
export default queueService;
