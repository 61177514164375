import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";
import {
  GroupingRow,
  SectionRow,
  SectionTitle,
  SubSectionRow,
  SubsectionTitle,
} from "./LayerSection";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import PbSwitch from "./PbSwitch";
import {
  setMapToRoadmap,
  setMapToSatellite,
  setShowClusters,
} from "../../../MainLayoutSlice";
import { setIsMetric } from "../../../../login/LoginSlice";

const MapSettings = () => {
  const dispatch = useAppDispatch();

  const isMobileScreen = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const mapTypeId = useAppSelector(
    (state: RootState) => state.mainLayout.mapTypeId
  );

  const showClusters = useAppSelector(
    (state: RootState) => state.mainLayout.showClusters
  );

  const loggedUser = useAppSelector((state: RootState) => state.login.loggedUser)

  const isMetric = useMemo(() => {
    return loggedUser && loggedUser.isMetric === 1;
  }, [loggedUser])

  const { t } = useTranslation();
  const [expand, setExpand] = useState<boolean>(true);

  const ExpandArrow = styled(KeyboardArrowUpIcon)({
    transform: expand ? "rotate(180deg)" : "rotate(0deg)",
    transition: "0.5s",
  });

  return (
    <>
      <SectionRow>
        <GroupingRow
          onClick={() => {
            setExpand(!expand);
          }}
        >
          <SectionTitle isMobile={isMobileScreen ? 1 : 0}>
            {t("layers.mapSettings.title")}
          </SectionTitle>
          <ExpandArrow />
        </GroupingRow>
      </SectionRow>
      <Collapse
        in={expand}
        timeout="auto"
        unmountOnExit
        style={{
          minHeight: "fit-content",
        }}
      >
        <SubSectionRow key={`metric-layer`}>
          <SubsectionTitle isMobile={isMobileScreen ? 1 : 0}>
            {t("layers.mapSettings.metric")}
          </SubsectionTitle>
          <PbSwitch
            name="test"
            handleChange={(e, c) => {
                dispatch(setIsMetric(c ? 1 : 0))
            }}
            defaultChecked={false}
            checked={isMetric}
          />
        </SubSectionRow>
        <SubSectionRow key={`satellite-layer`}>
          <SubsectionTitle isMobile={isMobileScreen ? 1 : 0}>
            {t("layers.mapSettings.satellite")}
          </SubsectionTitle>
          <PbSwitch
            name="test"
            handleChange={(e, c) => {
              if (c) {
                dispatch(setMapToSatellite());
              } else {
                dispatch(setMapToRoadmap());
              }
            }}
            defaultChecked={false}
            checked={mapTypeId === "satellite"}
          />
        </SubSectionRow>
        <SubSectionRow key={`clusters-layer`}>
          <SubsectionTitle isMobile={isMobileScreen ? 1 : 0}>
            {t("layers.mapSettings.clusters")}
          </SubsectionTitle>
          <PbSwitch
            name="test"
            handleChange={(e, c) => {
              dispatch(setShowClusters(c));
            }}
            defaultChecked={true}
            checked={showClusters}
          />
        </SubSectionRow>
      </Collapse>
    </>
  );
};

export default MapSettings;
