import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "../src/i18n/i18n";
import { createTheme, ThemeProvider } from "@mui/material";
import { fifthLevel } from "./app/levels";
import { PbDimYellow, PbFontBlack, PbFontGrayDark, PbWhite, PbYellow } from "./app/colors";
import { boxShadows } from "./app/boxShadows";

const container = document.getElementById("root")!;
const root = createRoot(container);

const theme = createTheme({
  /*palette: {
    primary: {
      main: PbYellow,
      light: PbDimYellow,
      dark: PbDimYellow,
      contrastText: PbFontBlack
    },
    secondary: {
      main: PbFontBlack,
      light: PbFontGrayDark,
      dark: PbFontBlack,
      contrastText: PbWhite
    }
  },*/
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: boxShadows.none,
          borderRadius: "999px",
          ":hover": {
            boxShadow: boxShadows.none,
          }
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip:{
          fontSize: "0.6cqi"
        },
        popper: {
          zIndex: fifthLevel + 100,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: PbYellow,
        root: {
          color: PbYellow
        }
      }
    }
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
