import { UserDevice } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface getUserDeviceResponse {
  message: string;
  device: UserDevice | undefined;
}

export async function get_UserDevice(mac: string) {
  try {
    if (mockService.getIsDemo()) {
      return {
        data: { device: await mockService.getMockedDevice(mac) },
        status: 200,
      };
    }
    const response = await httpClientCore.get<getUserDeviceResponse>(
      `/v2/devices/${mac}`
    );
    return response;
  } catch (err) {
    return null;
  }
}
