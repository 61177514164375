import styled from "@emotion/styled";
import { Chip, ClickAwayListener } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  PbFontGray,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../app/colors";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fifthLevel, thirdLevel } from "../../../../app/levels";
import { RootState } from "../../../../app/store";
import { applyableTrackingModes } from "../../../../Services/TrackingModesService";
import { setShowTrackingModesInfo } from "../../MainLayoutSlice";
import findingModeImg from "../../../../assets/imgs/trackingModes/finding-mode.svg";
import bleOnlyImg from "../../../../assets/imgs/trackingModes/bluetooth-only.svg";
import activeTrackingImg from "../../../../assets/imgs/trackingModes/active-tracking.svg";
import MotionTriggeredImg from "../../../../assets/imgs/trackingModes/motion-triggered.svg";
import standbyImg from "../../../../assets/imgs/trackingModes/standby.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import { UserDevice } from "pebblebee-sdk-frontend";

const TABLE_ROWS = [
  "image",
  "title",
  "description",
  "useCase",
  "howItWorks",
  "batteryLife",
];

const ROW_LABELS = ["useCase", "howItWorks", "batteryLife"];

const hideBorderTop = (row: number, col: number) =>
  row === 0 || row === 1 || row === 2 || col === -1;

const hideBorderRight = (row: number, col: number) =>
  col === Object.keys(applyableTrackingModes).length - 1 ||
  col === -1 ||
  row === 0 ||
  row === 1 ||
  row === TABLE_ROWS.length - 1;

const hideBorderBottom = (row: number, col: number) =>
  row === 0 || row === 1 || row === TABLE_ROWS.length - 1 || col === -1;

const hideBorderLeft = (row: number, col: number) =>
  col === 0 || col === -1 || row === 0 || row === 1;

const TrackingModesInfoBox = styled.div({
  position: "fixed",
  top: "5vh",
  left: "10vw",
  width: "80vw",
  height: "90vh",
  zIndex: fifthLevel + 1,
  background: "#fff",
  borderRadius: "2vw",
});

const BackgroundCover = styled.div({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100vw",
  height: "110vh",
  zIndex: fifthLevel,
  background: "#33333340",
});

const ModalTitle = styled.div({
  fontSize: "2cqi",
  fontWeight: 800,
  borderBottom: "solid 3px " + PbYellow,
  width: "30%",
  marginTop: "3%",
  marginLeft: "35%",
});

const ModalMessage = styled.div({
  fontSize: "1cqi",
  color: PbFontGray,
  margin: "2%",
});

const TrackingModesTable = styled.table({
  width: "85%",
  height: "70%",
  margin: "2% 5%",
  borderCollapse: "collapse",
  transform: "translate(-1%, 0)",
});

const TrackingTableCell = styled.td(
  (props: { row: number; column: number }) => ({
    border: "0.21cqi solid" + PbFontGrayWatermark + "60",
    padding: "0.2cqi",
    borderTop: hideBorderTop(props.row, props.column) ? "none" : undefined,
    borderLeft: hideBorderLeft(props.row, props.column) ? "none" : undefined,
    borderRight: hideBorderRight(props.row, props.column) ? "none" : undefined,
    borderBottom: hideBorderBottom(props.row, props.column)
      ? "none"
      : undefined,
  })
);

const CellContentContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  height: "100%",
});

const TrackingModeTitle = styled.div({
  fontSize: "1.1cqi",
  fontWeight: 700,
});

const TrackingModeText = styled.div({
  fontSize: "0.9cqi",
});

const TrackingModeImage = styled.img({
  width: "1.5cqi",
  height: "1.5cqi",
});

//row label
const RowLabel = styled.div({
  fontSize: "1cqi",
  wordBreak: "keep-all",
  whiteSpace: "nowrap",
  fontWeight: 600,
  transform: "rotate(-90deg)",
});

//current pill
const CurrentTrackingModeChip = styled(Chip)({
  fontSize: "0.9cqi",
  background: PbYellow,
  borderColor: PbYellow + "85",
  color: "#000",
  height: "min-content !important",
});

const PendingTrackingModeChip = styled(Chip)({
  fontSize: "0.9cqi",
  background: PbFontGrayWatermark + "85",
  color: "#000",
  height: "min-content !important",
});

const HiddenTrackingModeChip = styled(Chip)({
  fontSize: "0.9cqi",
  background: "#fff",
  color: "#fff",
  height: "min-content !important",
});

//close button
export const CloseButton = styled(CancelIcon)({
  position: "absolute",
  top: "1cqi",
  right: "1cqi",
  width: "1.4cqi",
  height: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: thirdLevel,
});

const TrackingModesInfoModal = (props: { device: UserDevice }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const showTrackingModesInfo = useAppSelector(
    (state: RootState) => state.mainLayout.showTrackingModesInfo
  );

  const getTrackingModeImage = (tracking: string) => {
    switch (tracking) {
      case "finding_mode":
        return findingModeImg;
      case "ble_only":
        return bleOnlyImg;
      case "standby":
        return standbyImg;
      case "area_tracking":
        return activeTrackingImg;
      case "motion_triggered":
        return MotionTriggeredImg;
      default:
        return standbyImg;
    }
  };

  const getCurrentTrackingMode = (tracking: string) => {
    if (
      props.device &&
      props.device.cellSettings &&
      props.device.cellSettings.tracking
    ) {
      //if the tracking mode matches de last tracking mode reported its the current one
      if (tracking === props.device.cellSettings.last_tracking) {
        return <CurrentTrackingModeChip label={t("trackingModes.current")} />;
      }

      //if the new tracking mode is not the same as the last reported is pending
      if (
        tracking === props.device.cellSettings.tracking &&
        props.device.cellSettings.tracking !==
          props.device.cellSettings.last_tracking
      ) {
        return <PendingTrackingModeChip label={t("trackingModes.pending")} />;
      }
    }

    return <HiddenTrackingModeChip label="-" />;
  };

  const getCellContent = (row: string, tracking: string) => {
    switch (row) {
      case "image":
        return (
          <CellContentContainer>
            <TrackingModeImage
              src={getTrackingModeImage(tracking)}
              alt={tracking}
            />
          </CellContentContainer>
        );
      case "title":
        return (
          <CellContentContainer>
            <TrackingModeTitle>
              {t(`trackingModes.modes.${tracking}.title`)}
            </TrackingModeTitle>
            {getCurrentTrackingMode(tracking)}
          </CellContentContainer>
        );
      default:
        return (
          <CellContentContainer>
            <TrackingModeText>
              {t(`trackingModes.modes.${tracking}.${row}`)}
            </TrackingModeText>
          </CellContentContainer>
        );
    }
  };

  return showTrackingModesInfo ? (
    <>
      <BackgroundCover />
      <ClickAwayListener
        onClickAway={() => {
          dispatch(setShowTrackingModesInfo(false));
        }}
      >
        <TrackingModesInfoBox>
          <CloseButton
            color="disabled"
            onClick={() => {
              dispatch(setShowTrackingModesInfo(false));
            }}
          ></CloseButton>
          <ModalTitle>{t("trackingModes.title")}</ModalTitle>
          <ModalMessage>{t("trackingModes.message")}</ModalMessage>
          <TrackingModesTable>
            {TABLE_ROWS.map((row, r) => {
              return (
                <tr>
                  {Object.keys(applyableTrackingModes).map((mode, c) => {
                    return (
                      <>
                        {c === 0 ? (
                          ROW_LABELS.includes(row) ? (
                            <TrackingTableCell row={r} column={c - 1}>
                              <RowLabel>
                                {t(`trackingModes.rows.${row}`)}
                              </RowLabel>
                            </TrackingTableCell>
                          ) : (
                            <TrackingTableCell row={r} column={c - 1} />
                          )
                        ) : null}
                        <TrackingTableCell row={r} column={c}>
                          {getCellContent(row, mode)}
                        </TrackingTableCell>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </TrackingModesTable>
        </TrackingModesInfoBox>
      </ClickAwayListener>
    </>
  ) : null;
};

export default TrackingModesInfoModal;
