import { Reading } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { plataformClient } from "../../api";

interface getDevicesReadingsResponse {
  status: string;
  devices: Reading[];
  error?: {
    message: string;
    code: string;
  };
}

export async function get_DevicesReadings() {
  try {
    if (mockService.getIsDemo()) {
      return { data: { status: "success", devices: await mockService.getMockedReadings() }, status: 200 };
    }

    const response = await plataformClient.get<getDevicesReadingsResponse>(
      `/devices/readings/latest?include=all`
    );
    return response;
  } catch (err) {
    return null;
  }
}
