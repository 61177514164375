import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PbFontGray,
  PbFontGrayLight,
  PbFontGrayWatermark,
} from "../../../../../app/colors";
import PbSwitch from "./PbSwitch";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";

export interface LayersSection {
  title: string;
  allHandleToggle: (checked: boolean) => void;
  sectionChecked?: () => boolean;
  dimmed?: boolean;
  subsections?: string[];
  subsectionHandleToggle?: (subsection: string, checked: boolean) => void;
  subsectionChecked?: (subsection: string) => boolean;
}

export const SectionRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
    background: "#F6F6F6",
  },
  padding: "0",
  margin: "0 4%",
});

export const SubSectionRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.1cqi 0",
  margin: "0.05cqi 4%",
});

export const GroupingRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

export const SectionTitle = styled.div((props: { isMobile: number }) => ({
  color: PbFontGray,
  fontSize: props.isMobile === 1 ? "3cqi" : "1cqi",
  margin: "0.2cqi 0",
}));

export const SubsectionTitle = styled.div((props: { isMobile: number }) => ({
  color: PbFontGrayLight,
  fontSize: props.isMobile === 1 ? "2.7cqi" : "0.8cqi",
  marginLeft: props.isMobile === 1 ? "2.5cqi" : "0.5cqi",
}));

export const InfoMessage = styled.div((props: { isMobile: number }) => ({
  color: PbFontGrayWatermark,
  fontSize: props.isMobile === 1 ? "2.6cqi" : "0.8cqi",
  marginRight: props.isMobile === 1 ? "0.9cqi" : "0.5cqi",
}));

const LayerSection = (props: LayersSection) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState<boolean>(true);

  const ExpandArrow = styled(KeyboardArrowUpIcon)({
    transform: expand ? "rotate(180deg)" : "rotate(0deg)",
    transition: "0.5s",
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const hasSubsections = useMemo(() => {
    return props.subsections && props.subsections.length > 0;
  }, [props.subsections]);

  return (
    <>
      <SectionRow
        style={{
          opacity: props.dimmed ? "50%" : "100%",
        }}
      >
        <GroupingRow
          onClick={() => {
            if (hasSubsections) {
              setExpand(!expand);
            }
          }}
        >
          <SectionTitle isMobile={isMobileScreen ? 1 : 0}>
            {props.title}
          </SectionTitle>
          {hasSubsections ? <ExpandArrow /> : null}
        </GroupingRow>
        <GroupingRow>
          {hasSubsections ? (
            <InfoMessage isMobile={isMobileScreen ? 1 : 0}>
              {t("layers.viewAll")}
            </InfoMessage>
          ) : null}
          <PbSwitch
            name="test"
            handleChange={(e, c) => {
              props.allHandleToggle(c);
            }}
            defaultChecked={true}
            checked={props.sectionChecked ? props.sectionChecked() : undefined}
          />
        </GroupingRow>
      </SectionRow>
      <Collapse
        in={expand}
        timeout="auto"
        unmountOnExit
        style={{
          minHeight: "fit-content",
        }}
      >
        {props.subsections &&
          props.subsections.map((subsection) => {
            return (
              <SubSectionRow key={`layer-${subsection}`}>
                <SubsectionTitle isMobile={isMobileScreen ? 1 : 0}>
                  {subsection}
                </SubsectionTitle>
                <PbSwitch
                  name="test"
                  handleChange={(e, c) => {
                    if (props.subsectionHandleToggle) {
                      props.subsectionHandleToggle(subsection, c);
                    }
                  }}
                  defaultChecked={true}
                  checked={
                    props.subsectionChecked
                      ? props.subsectionChecked(subsection)
                      : undefined
                  }
                />
              </SubSectionRow>
            );
          })}
      </Collapse>
    </>
  );
};

export default LayerSection;
