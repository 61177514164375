import ReadingsService, {
  HistoryPoint,
} from "../../../../Services/ReadingsService";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setSelectedHistoryPoint } from "../../historySlice";
import { getFormatedDayhour } from "pebblebee-sdk-frontend";
import {
  TimelineConnector,
  TimelineDot,
  TimelineSeparator,
} from "@mui/lab";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import styled from "@emotion/styled";
import {
  PbFontBlack,
  PbFontGrayLight,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../app/colors";
import AddressField from "../../../../components/AddressLabel/AddressLabel";
import { RootState } from "../../../../app/store";
import {
  CustomTimelineConnector,
  CustomTimelineContent,
  CustomTimelineItem,
  CustomTimelineOppositeContent,
  CustomTimelineSeparator,
  PassPointLine,
  PbPassPointTimeLineDot,
} from "./TimelineItemStyledComponents";



const PassPointLabel = styled.div((props: { inCluster: number }) => ({...{
  fontSize: "0.85cqi",
  color: props.inCluster === 1 ? PbFontGrayWatermark : PbFontBlack,
  ":hover": {
    cursor: "pointer",
    color: PbFontGrayLight,
  },
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
} as React.CSSProperties}));

//Mobile

const PassPointLineMobile = styled.div({
  position: "absolute",
  visibility: "visible",
  transform: "translateX(-50%)",
  borderTop: `solid 1cqi ${PbYellow}`,
  width: "2cqi",
  borderRadius: "1cqi",
});

const PassPointLabelMobile = styled.div((props: { inCluster: number }) => ({...{
  fontSize: "3cqi",
  color: props.inCluster === 1 ? PbFontGrayWatermark : PbFontBlack,
  ":hover": {
    cursor: "pointer",
    color: PbFontGrayLight,
  },
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
} as React.CSSProperties}));

const PbPassPointTimeLineDotMobile = styled(TimelineDot)({
  background: PbYellow,
  visibility: "hidden",
  height: "1cqi",
  width: "1cqi",
  padding: "1cqi",
  borderWidth: "1cqi",
});

const CustomTimelineOppositeContentMobile = styled(TimelineOppositeContent)({
  flex: 0.3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "3cqi",
  "::before": {
    display: "none",
  },
});

const PbTimelineConnectorMobile = styled(TimelineConnector)({
  background: PbYellow,
  width: "0.7cqi",
});

const PassPointTimelineItem = ({
  passPoint,
  index,
  inCluster,
}: {
  passPoint: HistoryPoint;
  index: number;
  inCluster: boolean;
}) => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const handleAddressClick = () => {
    const id: string = passPoint.deviceId + passPoint.timestamp;
    dispatch(setSelectedHistoryPoint(id));
    if (passPoint.coordinates) {
      ReadingsService.centerOnBounds([passPoint.coordinates]);
    }
  };

  const getFormatedDate = (date: string) => {
    try {
      const time = new Date(date).getTime();
      return getFormatedDayhour(time);
    } catch {
      return "";
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <CustomTimelineItem>
          <CustomTimelineOppositeContent color="text.secondary">
            {getFormatedDate(passPoint.timestamp)}{" "}
          </CustomTimelineOppositeContent>
          <CustomTimelineSeparator>
            <CustomTimelineConnector />
            <PbPassPointTimeLineDot>
              <PassPointLine />
            </PbPassPointTimeLineDot>
            <CustomTimelineConnector />
          </CustomTimelineSeparator>
          <CustomTimelineContent>
            <PassPointLabel
              inCluster={inCluster ? 1 : 0}
              onClick={handleAddressClick}
            >
              <AddressField coordinates={passPoint.coordinates} />
            </PassPointLabel>
          </CustomTimelineContent>
        </CustomTimelineItem>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <CustomTimelineItem>
          <CustomTimelineOppositeContentMobile color="text.secondary">
            {getFormatedDate(passPoint.timestamp)}{" "}
          </CustomTimelineOppositeContentMobile>
          <TimelineSeparator>
            <PbTimelineConnectorMobile />
            <PbPassPointTimeLineDotMobile>
              <PassPointLineMobile />
            </PbPassPointTimeLineDotMobile>
            <PbTimelineConnectorMobile />
          </TimelineSeparator>
          <CustomTimelineContent>
            <PassPointLabelMobile
              inCluster={inCluster ? 1 : 0}
              onClick={handleAddressClick}
            >
              <AddressField coordinates={passPoint.coordinates} />
            </PassPointLabelMobile>
          </CustomTimelineContent>
        </CustomTimelineItem>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default PassPointTimelineItem;
