import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getDevicesGroups,
  getUserDevices,
  mapGroupsToDevices,
} from "../../dataSlices/DevicesSlice";
import { getDevicesReadings } from "../../dataSlices/ReadingsSlice";
import {
  clearCheckout,
  clearSubscriptions,
} from "../../dataSlices/SubscriptionsSlice";
import { getUser, loadUserPreferences } from "../login/LoginSlice";
import FeaturesManager from "./components/menus/FeaturesManager/FeaturesManager";
import LayersManager from "./components/menus/LayersManager/LayersManager";
import TopMenu from "./components/menus/TopMenu/TopMenu";
import FloatingMenu from "./components/FloatingMenu/FloatingMenu";
import {
  clearAddresses,
  itemSelected,
  setInitializing,
  setTabSelected,
} from "./MainLayoutSlice";
import { getNotifications } from "../../dataSlices/NotificationsSlice";
import OverviewMap from "./components/OverviewMap";
import { setShowSearchInput } from "./components/menus/MenusSlice";
import { getGeofences } from "../../dataSlices/GeofencesSlice";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import ContactSalesWidget from "./components/menus/ContactSalesWidget/ContactSalesWidget";
import mockService from "../../Services/MockService";

const MainLayout = () => {
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const selectionTrigger = useSelector((state: RootState) => {
    return state.home.selectionTrigger;
  });

  const isMobileScreen = useAppSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const tabSelected = useAppSelector((state: RootState) => {
    return state.mainLayout.tabSelected;
  });

  const userDevice = useAppSelector(
    (state: RootState) => state.devices.userDevices
  );

  useEffect(() => {
    const populateData = async () => {
      dispatch(setInitializing(true));
      await dispatch(getUser());
      await dispatch(getUserDevices());
      await dispatch(getDevicesGroups());
      await dispatch(getDevicesReadings());
      await dispatch(getNotifications());
      await dispatch(getGeofences());
      await dispatch(loadUserPreferences());
      dispatch(mapGroupsToDevices());
      dispatch(setInitializing(false));
    };
    populateData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(itemSelected(selectedElementId));
  }, [dispatch, selectedElementId, selectionTrigger]);

  const refreshData = async () => {
    await dispatch(getUser());
    await dispatch(getUserDevices());
    await dispatch(getDevicesGroups());
    await dispatch(getDevicesReadings());
    await dispatch(getNotifications());
    await dispatch(getGeofences());
    //reset subscriptions, checkouts and address so they can be re pulled
    //add and call separtely
    dispatch(clearSubscriptions());
    dispatch(clearCheckout());
    dispatch(clearAddresses());
  };

  const closeSearchBar = () => {
    dispatch(setShowSearchInput(false));
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setTabSelected(newValue));
  };

  const mainLayoutTabs = ["Devices", "Layers", "Features"];

  return (
    <>
      <TopMenu />
      <OverviewMap />
      {!isMobileScreen ? (
        <div onClick={closeSearchBar}>
          {userDevice && userDevice.length > 0 ? (
            <>
              <LayersManager />
              <FeaturesManager />
              {mockService.getIsDemo() ? <ContactSalesWidget /> : null}
            </>
          ) : null}
          <FloatingMenu logoOnClick={refreshData} />
        </div>
      ) : (
        <Box sx={{ width: "100vw", overflow: "none" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: "0px" }}>
            <Tabs
              value={""}
              onChange={handleChange}
              aria-label="user dashboard tabs"
              variant="fullWidth"
            >
              {mainLayoutTabs.map((tab, index) => {
                return (
                  <Tab
                    label={tab}
                    id={index + ""}
                    aria-controls={`simple-tabpanel-${index}`}
                  />
                );
              })}
            </Tabs>
          </Box>
          <TabPanel value={tabSelected} index={0}>
            <FloatingMenu logoOnClick={refreshData} />
          </TabPanel>
          <TabPanel value={tabSelected} index={1}>
            <LayersManager />
          </TabPanel>
          <TabPanel value={tabSelected} index={2}>
            <FeaturesManager />
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export default MainLayout;
