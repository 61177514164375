import { UserDevice } from "pebblebee-sdk-frontend";
import React from "react";
import { useAppDispatch } from "../../../../../app/hooks";
import { fourthLevel } from "../../../../../app/levels";
import PbDeviceAutocomplete from "../../../../../components/PbDevicesAutocomplete/PbDevicesAutocomplete";
import { setSelectedElementId } from "../../../../home/HomeSlice";
import { setShowSearchInput } from "../MenusSlice";

const SearchBox = () => {
  const dispatch = useAppDispatch();

  return (
    <PbDeviceAutocomplete
      openOnFocus={false}
      itemOnClick={(device: UserDevice) => {
        dispatch(setShowSearchInput(false));
        dispatch(setSelectedElementId(device.guid || device.id));
      }}
      onClickAway={() => {
        dispatch(setShowSearchInput(false));
      }}
      styles={{
        desktop: {
          position: "fixed",
          top: "2%",
          right: "50%",
          transform: "translateX(50%)",
          width: "40vw",
          height: "3cqi",
          transition: "0.5s",
          zIndex: fourthLevel,
          background: "#FFFFFF",
          borderRadius: "1vw",
        },
        mobile: {
          position: "fixed",
          top: "1vh",
          right: "50%",
          transform: "translateX(15%)",
          width: "55vw",
          transition: "0.5s",
          zIndex: fourthLevel,
          background: "#FFFFFF",
          borderRadius: "1vw",
        },
      }}
      textFieldStyles={{
        desktop: {
          zIndex: fourthLevel,
          borderRadius: "1vw",
          fontSize: "2cqi",
          height: "100%",
        },
        mobile: { zIndex: fourthLevel, borderRadius: "1vw", width: "55vw" },
      }}
    />
  );
};

export default SearchBox;
