import styled from "@emotion/styled";
import { Avatar, Tooltip } from "@mui/material";
import { models } from "pebblebee-sdk-frontend";
import React from "react";
import { PbBlue, PbYellow } from "../../app/colors";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import modelsService from "../../Services/ModelsService";
import PbDeviceAvatarIconProps from "./PbDeviceAvatarIconProps";

const PbDeviceAvatarIcon = (props: PbDeviceAvatarIconProps) => {
  const desktopStyles = props.styles?.desktop;
  const mobileStyles = props.styles?.mobile;

  const deviceHasImage = () => {
    return (
      props.device.imageUrl !== undefined && props.device.imageUrl !== ""
    );
  };

  const AvatarIcon = styled(Avatar)(
    (props: { selected: boolean; shared: number }) => ({
      border: props.selected
        ? desktopStyles?.borderSelected || "0.22cqi solid"
        : desktopStyles?.border || "0.12cqi solid",
      margin: props.selected ? undefined : "0.08cqi",
      borderColor: props.shared ? PbBlue : PbYellow,
      width: desktopStyles?.width || "2.5cqi !important",
      height: desktopStyles?.height || "2.5cqi",
      aspectRatio: "1 / 1 !important",
      borderRadius: desktopStyles?.borderRadius || "1.2cqi",
      ":hover": {
        cursor: "pointer",
      },
      transition: "none",
      fontSize: desktopStyles?.fontSize || "1.2cqi",
      background: props.selected
        ? props.shared
          ? `${PbBlue}`
          : `${PbYellow}`
        : props.shared
        ? `${PbBlue}66`
        : `${PbYellow}88`,
      ".MuiListItemAvatar-root": {
        minWidth: undefined,
      },
      "& img": {
        height: deviceHasImage() ? undefined : "auto",
        width: deviceHasImage() ? undefined : "65%",
      },
    })
  );

  const AvatarIconMobile = styled(Avatar)(
    (props: { selected: boolean; shared: number }) => ({
      display: "flex",
      border: props.selected
        ? mobileStyles?.borderSelected || "0.4cqi solid"
        : mobileStyles?.border || "0.2cqi solid",
      borderColor: props.shared ? PbBlue : PbYellow,
      width: mobileStyles?.width || "10cqi !important",
      height: mobileStyles?.height || "10cqi",
      aspectRatio: "1 / 1 !important",
      borderRadius: mobileStyles?.borderRadius || "3cqi",
      ":hover": {
        cursor: "pointer",
      },
      transition: "none",
      fontSize: mobileStyles?.fontSize || "3cqi",
      background: PbYellow,
    })
  );

  const isMobileScreen = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const shared = props.device.sharedFrom ? 1 : 0;

  const deviceAvatarString = () => {
    if (props.device.name && props.device.name[0]) {
      return props.device.name[0];
    }
    return "";
  };

  const getDeviceModelIcon = () => {
    if (props.device && props.device.model) {
      const modelImg = modelsService.getDeviceModelImage(
        props.device.model
      );
      return modelImg;
    } else {
      const defaultData = models.getModelInfo(-1);
      if (defaultData && defaultData.img) {
        return defaultData.img;
      }
    }
    return "";
  };

  const getAvatarImage = () => {
    return props.showDeviceIcon || true
      ? props.device.imageUrl || getDeviceModelIcon()
      : props.device.imageUrl;
  };

  const getAvatarText = () => {
    return props.showDeviceIcon || false ? "" : deviceAvatarString();
  };

  return isMobileScreen ? (
    <AvatarIconMobile
      alt={`${props.device.name}`}
      src={getAvatarImage()}
      shared={shared}
      selected={props.selected || false}
    >
      {getAvatarText()}
    </AvatarIconMobile>
  ) : (
    <Tooltip
      title={props.device.name}
      disableHoverListener={props.hideTooltip}
      placement={props.tooltipPlacement}
    >
      <AvatarIcon
        alt={`${props.device.name}`}
        src={getAvatarImage()}
        shared={shared}
        selected={props.selected || false}
      >
        {getAvatarText()}
      </AvatarIcon>
    </Tooltip>
  );
};

export default PbDeviceAvatarIcon;
