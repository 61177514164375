import React, { Component } from "react";
import ErrorScreen from "../ErrorScreen/ErrorScreen";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

//https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    try {
      if (process.env.REACT_APP_ENV === "PROD") {
        const errorMessage =
          error && error.message ? error.message : "Unkwon error";
        const errorStack =
          error && error.stack ? JSON.stringify(error.stack) : "No stack";

        gtag("event", "user_dashboard_error", {
          errorMessage: errorMessage,
          errorStack: errorStack,
        });
      }
    } catch (error: any) {
      if (process.env.REACT_APP_ENV === "DEV") {
        console.error(error)
      }
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
