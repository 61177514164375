import styled from "@emotion/styled";
import { Avatar, Tooltip } from "@mui/material";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useAppDispatch } from "../../../../app/hooks";
import { setSelectedElementId } from "../../../home/HomeSlice";
import { setShowSearchInput } from "../../../mainLayout/components/menus/MenusSlice";
import { firstLevel } from "../../../../app/levels";
import PbDeviceAvatarIcon from "../../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";

interface DeviceMarkerProps {
  device: UserDevice;
  reading: Reading;
}

const OWNED_COLOR = "#F6EE81";
const SHARED_COLOR = "#3B89C7";

const AvatarIconMobile = styled(Avatar)(
  (props: { selected: boolean; shared: number }) => ({
    border: props.selected ? "1cqi solid" : "0.5cqi solid",
    borderColor: props.shared ? SHARED_COLOR : OWNED_COLOR,
    width: "9cqi",
    height: "9cqi",
    borderRadius: "4cqi",
    fontSize: "5cqi",
    zIndex: props.selected ? firstLevel : 1,
    ":hover": {
      cursor: "pointer",
    },
  })
);

const DeviceMarker = ({ device, reading }: DeviceMarkerProps) => {
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const handleOnClick = () => {
    dispatch(setShowSearchInput(false));
    dispatch(setSelectedElementId(device.guid || device.id));
  };

  const deviceAvatarString = () => {
    if (device.name && device.name[0]) {
      return device.name[0];
    }
    return "";
  };

  const renderDesktop = () => {
    return (
      <>
        <div id={`marker-${device.id}`} onClick={handleOnClick}>
          <Tooltip title={device.name}>
            <PbDeviceAvatarIcon device={device} selected/>
          </Tooltip>
        </div>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <div id={`marker-${device.id}`} onClick={handleOnClick}>
          <Tooltip title={device.name}>
            <AvatarIconMobile
              id={`marker-avatar-${device.id}`}
              alt={`${device.name}`}
              src={device.imageUrl}
              selected={selectedElementId === device.guid}
              shared={device?.sharedFrom ? 1 : 0}
            >
              {deviceAvatarString()}
            </AvatarIconMobile>
          </Tooltip>
        </div>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default DeviceMarker;
