import styled from "@emotion/styled";
import React, { useEffect, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, CircularProgress, Divider } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useAppDispatch } from "../../../../../app/hooks";
import UserDatabox from "./UserDatabox";
import SearchBox from "./SearchBox";
import NotificationsBox from "./NotificationsBox";
import {
  setShowAccountData,
  setShowNotificationsBox,
  setShowSearchInput,
} from "../MenusSlice";
import { PbFontGrayLight, PbYellow } from "../../../../../app/colors";
import { setShowDeviceDetails } from "../../../../mainLayout/MainLayoutSlice";
import { setGlobalError } from "../../../../../app/CommonSlice";
import { fourthLevel, secondLevel } from "../../../../../app/levels";
import NewNotificationsAlert from "./newNotificationsAlert";
import { boxShadows } from "../../../../../app/boxShadows";

const MenuContainer = styled.div({
  position: "fixed",
  top: "1vw",
  right: "1vw",
  width: "10vw",
  height: "2.5vw",
  borderRadius: "100px",
  background: "#FFF",
  zIndex: secondLevel,
  boxShadow: boxShadows.medium,
});

const MenuContainerMobile = styled.div({
  position: "fixed",
  top: "2vw",
  right: "2vw",
  width: "34vw",
  height: "10vw",
  borderRadius: "100px",
  background: "#FFF",
  zIndex: secondLevel,
});

const MenuContentRow = styled.div({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: "0 2%",
});

const MenuItemContainer = styled.div({
  height: "1.5vw",
  width: "1.5vw",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const MenuItemContainerMobile = styled.div({
  height: "1.5vw",
  width: "1.5vw",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const AvatarImg = styled(Avatar)({
  width: "1.5cqi",
  height: "1.5cqi",
  ":hover": {
    cursor: "pointer",
    background: "#EDEDED",
  },
});

const AvatarImgMobile = styled(Avatar)({
  width: "5cqi",
  height: "5cqi",
  ":hover": {
    cursor: "pointer",
    background: "#EDEDED",
  },
});

const NotificationsIconButton = styled(NotificationsIcon)(
  (props: { disabled: number }) => ({
    width: "1.5cqi",
    height: "1.5cqi",
    borderRadius: "999px",
    color: props.disabled === 1 ? PbFontGrayLight : undefined,
    ":hover": {
      cursor: "pointer",
      background: "#EDEDED",
    },
  })
);

const NotificationsIconButtonMobile = styled(NotificationsIcon)({
  width: "5cqi",
  height: "5cqi",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
    background: "#EDEDED",
  },
});

const SearchIconButton = styled(SearchIcon)((props: { disabled: number }) => ({
  width: "1.5cqi",
  height: "1.5cqi",
  borderRadius: "999px",
  color: props.disabled === 1 ? PbFontGrayLight : undefined,
  ":hover": {
    cursor: "pointer",
    background: "#EDEDED",
  },
}));

const SearchIconButtonMobile = styled(SearchIcon)({
  width: "5cqi",
  height: "5cqi",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
    background: "#EDEDED",
  },
});

const LoadingUserSpinner = styled(CircularProgress)({
  maxWidth: "28px",
  maxHeight: "28px",
  color: PbYellow,
});

const NotificationsDot = styled.div({
  width: "1cqi",
  height: "1cqi",
  background: "red",
  borderRadius: "999px",
  zIndex: fourthLevel,
  position: "absolute",
  top: "-0.1cqi",
  right: "-0.1cqi",
  fontSize: "0.9cqi",
  fontWeight: 700,
  color: "white",
});

const NotificationsDotMobile = styled.div({
  width: "12px",
  height: "12px",
  background: "red",
  borderRadius: "999px",
  zIndex: fourthLevel,
  position: "absolute",
  top: "0px",
  right: "0px",
  fontSize: "0.7rem",
  color: "white",
});

const TopMenu = () => {
  const dispatch = useAppDispatch();

  const loadingUserData = useSelector((state: RootState) => {
    return state.login.loadingUserData;
  });

  const user = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const showAccountData = useSelector((state: RootState) => {
    return state.menus.showAccountData;
  });

  const showNotificationsBox = useSelector((state: RootState) => {
    return state.menus.showNotificationsBox;
  });

  const showSearchInput = useSelector((state: RootState) => {
    return state.menus.showSearchInput;
  });

  const notifications = useSelector((state: RootState) => {
    return state.notifications.notifications;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const userDevices = useSelector(
    (state: RootState) => state.devices.userDevices
  );

  const disableSearch = useMemo(() => {
    return userDevices.length <= 0;
  }, [userDevices.length]);

  const disableNotifications = useMemo(() => {
    return userDevices.length <= 0;
  }, [userDevices.length]);

  const getInitials = () => {
    try {
      let initials = "";
      if (user) {
        const nameInitial =
          user.firstname && user.firstname.length > 0
            ? user.firstname[0].toUpperCase()
            : "";
        const lastNameInitial =
          user.lastname && user.lastname.length > 0
            ? user.lastname[0].toUpperCase()
            : "";

        initials = nameInitial + lastNameInitial;
        if (!initials && user.email && user.email.length > 0) {
          initials = user.email[0] ? user.email[0].toLocaleUpperCase() : "-";
        }
      }
      return initials;
    } catch (err) {
      //notify error
      dispatch(setGlobalError("Failed to load user data"));
      return "";
    }
  };

  const onSearchIconClick = () => {
    if (disableSearch) {
      return;
    }
    dispatch(setShowDeviceDetails(false));
    dispatch(setShowSearchInput(!showSearchInput));
    dispatch(setShowAccountData(false));
    dispatch(setShowNotificationsBox(false));
  };

  const onAccountIconClick = () => {
    dispatch(setShowAccountData(!showAccountData));
    dispatch(setShowSearchInput(false));
    dispatch(setShowNotificationsBox(false));
  };

  const onNotificationIconClick = () => {
    if (disableNotifications) {
      return;
    }
    dispatch(setShowNotificationsBox(!showNotificationsBox));
    dispatch(setShowAccountData(false));
    dispatch(setShowSearchInput(false));
  };

  const fetchNewNotifications = () => {
    //get new notifications
    //if there are new notifications show box
    //trigger hide new notification
  };

  useEffect(() => {
    setTimeout(() => {
      fetchNewNotifications();
    }, 180000);
  }, []);

  const renderDesktop = () => {
    return (
      <>
        <MenuContainer>
          <MenuContentRow>
            <MenuItemContainer>
              <SearchIconButton
                disabled={disableSearch ? 1 : 0}
                onClick={onSearchIconClick}
              />
            </MenuItemContainer>
            <Divider orientation="vertical" variant="middle" flexItem />
            <MenuItemContainer>
              <div style={{ position: "relative" }}>
                <NotificationsIconButton
                  disabled={disableNotifications ? 1 : 0}
                  onClick={onNotificationIconClick}
                ></NotificationsIconButton>
                {!disableNotifications && notifications.length > 0 ? (
                  <NotificationsDot>{notifications.length}</NotificationsDot>
                ) : null}
              </div>
            </MenuItemContainer>
            <Divider orientation="vertical" variant="middle" flexItem />
            <MenuItemContainer>
              {loadingUserData ? (
                <LoadingUserSpinner />
              ) : (
                <AvatarImg src={user?.imageUrl} onClick={onAccountIconClick}>
                  {getInitials()}
                </AvatarImg>
              )}
            </MenuItemContainer>
          </MenuContentRow>
        </MenuContainer>
        {showAccountData ? <UserDatabox /> : null}
        {showSearchInput ? <SearchBox /> : null}
        {showNotificationsBox ? <NotificationsBox /> : null}
        <NewNotificationsAlert />
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <MenuContainerMobile>
          <MenuContentRow>
            <MenuItemContainerMobile>
              <SearchIconButtonMobile onClick={onSearchIconClick} />
            </MenuItemContainerMobile>
            <Divider orientation="vertical" variant="middle" flexItem />
            <MenuItemContainerMobile>
              <div style={{ position: "relative" }}>
                <NotificationsIconButtonMobile
                  onClick={onNotificationIconClick}
                ></NotificationsIconButtonMobile>
                {notifications.length > 0 ? (
                  <NotificationsDotMobile>
                    {notifications.length}
                  </NotificationsDotMobile>
                ) : null}
              </div>
            </MenuItemContainerMobile>
            <Divider orientation="vertical" variant="middle" flexItem />
            <MenuItemContainerMobile>
              {loadingUserData ? (
                <LoadingUserSpinner />
              ) : (
                <AvatarImgMobile
                  src={user?.imageUrl}
                  onClick={onAccountIconClick}
                >
                  {getInitials()}
                </AvatarImgMobile>
              )}
            </MenuItemContainerMobile>
          </MenuContentRow>
        </MenuContainerMobile>
        {showAccountData ? <UserDatabox /> : null}
        {showSearchInput ? <SearchBox /> : null}
        {showNotificationsBox ? <NotificationsBox /> : null}
        <NewNotificationsAlert />
      </>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default TopMenu;
