import { UserDevice } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface getUserDevicesResponse {
    status: string;
    devices: UserDevice[];
}

export async function get_UserDevices() {
  try {
    if (mockService.getIsDemo()) {
      return {
        data: { devices: await mockService.getMockedDevices() },
        status: 200,
      };
    }
    const response = await httpClientCore.get<getUserDevicesResponse>(
      `/v2/devices`
    );
    return response;
  } catch (err) {
    return null;
  }
}
