import { CircularProgress, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { PbFontGrayLight } from "../../app/colors";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  createNewEnterpriseUser,
  editEnterpriseUser,
  getDevicesGroups,
  getEnterpriseUsers,
  setNewUserAccess,
  setNewUserDeviceGroupsIds,
  setNewUserEmail,
  setNewUserEmailError,
  setShowUserForm,
} from "./UserManagementSlice";
import {
  MY_DEVICES_UUID,
  SHARED_DEVICES_UUID,
} from "../../models/enterprise/DeviceGroup";
import { emailRegex } from "../../Services/ValidationsService";
import { PayloadAction } from "@reduxjs/toolkit";
import SearchIcon from "@mui/icons-material/Search";

const StyledInfoIcon = styled(InfoOutlinedIcon)({
  height: "16px",
  width: "16px",
  color: PbFontGrayLight,
});

const StyledSearchIcon = styled(SearchIcon)({
  height: "20px",
  width: "20px",
  color: PbFontGrayLight
});

const NewEnterpriseUserForm = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const groups = useAppSelector(
    (state: RootState) => state.userManagement.groups
  );

  const loadingGroups = useAppSelector(
    (state: RootState) => state.userManagement.loadingGroups
  );

  const isEdit = useAppSelector(
    (state: RootState) => state.userManagement.newEnterpriseUserForm.isEdit
  );

  const childUserId = useAppSelector(
    (state: RootState) => state.userManagement.newEnterpriseUserForm.id
  );

  const userEmailError = useAppSelector(
    (state: RootState) => state.userManagement.newEnterpriseUserForm.emailError
  );

  const newUserEmail = useAppSelector(
    (state: RootState) => state.userManagement.newEnterpriseUserForm.email
  );
  const newUserAccess = useAppSelector(
    (state: RootState) => state.userManagement.newEnterpriseUserForm.accessLevel
  );

  const newUserGroups = useAppSelector(
    (state: RootState) =>
      state.userManagement.newEnterpriseUserForm.deviceGroupsIds
  );

  const proccessing = useAppSelector(
    (state: RootState) =>
      state.userManagement.newEnterpriseUserForm.processingUser
  );

  const fetchGroupsCallback = useCallback(async () => {
    dispatch(getDevicesGroups());
  }, [dispatch]);

  useEffect(() => {
    fetchGroupsCallback();
  }, [fetchGroupsCallback]);

  const [groupsFilter, setGroupsFilter] = useState("");

  const filteredGroups = useMemo(() => {
    return groups.filter(
      (g) =>
        (groupsFilter === "" ||
          g.name.includes(groupsFilter) ||
          groupsFilter.includes(g.name)) &&
        g.uuid !== MY_DEVICES_UUID &&
        g.uuid !== SHARED_DEVICES_UUID
    );
  }, [groups, groupsFilter]);

  const handleUserEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setNewUserEmailError(""));
    dispatch(setNewUserEmail(e.target.value));
  };

  const handleUserAccessChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value !== "ADMIN" && e.target.value !== "READ") return;
    dispatch(setNewUserAccess(e.target.value));
  };

  const submit = async () => {
    let actionResult: PayloadAction<any> = {
      payload: {},
      type: "",
    };

    if (isEdit) {
      if (!childUserId) {
        dispatch(setNewUserEmailError("Invalid user, please try again."));
        return;
      }
      actionResult = await dispatch(
        editEnterpriseUser({
          id: childUserId,
          accessLevel: newUserAccess,
          deviceGroupIds: newUserGroups,
        })
      );
    } else {
      if (!newUserEmail || !emailRegex.test(newUserEmail)) {
        dispatch(setNewUserEmailError("Invalid user email."));
        return;
      }
      actionResult = await dispatch(
        createNewEnterpriseUser({
          email: newUserEmail,
          accessLevel: newUserAccess,
          deviceGroupIds: newUserGroups,
        })
      );
    }

    //if success update users list
    if (actionResult.type.includes("fulfilled"))
      await dispatch(getEnterpriseUsers());
  };

  return (
    <div
      className={`z-3000 flex h-full p-5 w-1/4 rounded-lg bg-white flex-col transition-all duration-200 ease-in-out`}
    >
      <div className="text-3xl w-full text-left mt-3 h-10%">
        {t("userManagement.newUser.title")}
      </div>
      <div className="text-lg w-full text-left mt-1 h-15% text-base">
        {t("userManagement.newUser.description")}
      </div>
      <div className="w-full flex items-start flex-col h-10%">
        <label className="text-sm">
          {t("userManagement.newUser.labels.email")}
        </label>
        <input
          className={`p-1 ${
            userEmailError ? "border-red-500 text-red-500" : "border-black"
          } border rounded-md w-full`}
          disabled={isEdit}
          value={newUserEmail}
          placeholder="john@company.com"
          onChange={handleUserEmailChange}
        />
        <div id="email-input error" className="text-sm text-red-500">
          {userEmailError}
        </div>
      </div>
      <div id="separator" className="my-1" />
      <div className="w-full flex items-start flex-col">
        <label className="text-sm">
          {t("userManagement.newUser.labels.access")}{" "}
          <Tooltip title={<div style={{fontSize: '14px'}}>{t("userManagement.newUser.tooltips.accessLevel")}</div>}>
            <StyledInfoIcon />
          </Tooltip>
        </label>
        <select
          className="p-1 border-black border rounded-md w-full bg-white"
          name="accessLevelSelect"
          id="accessLevelSelect"
          onChange={handleUserAccessChange}
          value={newUserAccess}
        >
          <option value="READ">Read Only</option>
          <option value="ADMIN">Admin</option>
        </select>
      </div>
      <div id="separator" className="my-1" />
      <div className="h-40% max-h-30% my-2 ">
        <div className="w-full flex items-start flex-col h-10%">
          <label className="text-sm">
            {t("userManagement.newUser.labels.groups")}
          </label>
        </div>
        <div className="w-full h-90% border-black border rounded-md pb-2 overflow-hidden">
          <div
            className={`px-2 py-2 flex flex-row justify-between items-center border-black border-b`}
          >
            <StyledSearchIcon />
            <input
              className="w-full rounded-md mr-3 outline-none"
              placeholder="Search"
              onChange={(e) => {
                setGroupsFilter(e.target.value);
              }}
            ></input>
            <input
              type="checkbox"
              name={"all-group"}
              id={"all-grops"}
              checked={
                newUserGroups.toString() ===
                filteredGroups.map((g) => g.uuid).toString()
              }
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    setNewUserDeviceGroupsIds(filteredGroups.map((g) => g.uuid))
                  );
                } else {
                  dispatch(setNewUserDeviceGroupsIds([]));
                }
              }}
            />
          </div>
          <div className="h-90% relative overflow-auto pb-2">
            <div className="h-auto overflow-auto">
              {loadingGroups ? (
                <CircularProgress />
              ) : filteredGroups && filteredGroups.length > 0 ? (
                filteredGroups.map((g, i) => {
                  return (
                    <div
                      className={`px-2 py-1 border-black flex flex-row justify-between ${
                        i === filteredGroups.length - 1 ? "" : "border-b"
                      }`}
                    >
                      {g.name}
                      <input
                        type="checkbox"
                        name={g.name}
                        id={g.uuid}
                        checked={newUserGroups.includes(g.uuid)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            if (newUserGroups.indexOf(g.uuid) !== -1) return;
                            dispatch(
                              setNewUserDeviceGroupsIds([
                                ...newUserGroups,
                                g.uuid,
                              ])
                            );
                          } else {
                            dispatch(
                              setNewUserDeviceGroupsIds(
                                newUserGroups.filter((gid) => gid !== g.uuid)
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="text-gray-400 p-5">No selectable groups</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        id="button-row"
        className="flex flex-row w.full justify-between mt-auto"
      >
        {proccessing ? (
          <div className=" flex justify-center items-center w-full">
            <CircularProgress />
          </div>
        ) : (
          <>
            <button
              onClick={() => {
                dispatch(setShowUserForm(false));
              }}
              className="p-2 bg-pbGrey rounded-xl text-lg w-40%"
            >
              {t("userManagement.newUser.buttons.cancel")}
            </button>
            <button
              onClick={submit}
              className="p-2 bg-pbYellow rounded-xl text-lg w-40%"
            >
              {isEdit
                ? t("userManagement.newUser.buttons.edit")
                : t("userManagement.newUser.buttons.create")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default NewEnterpriseUserForm;
