import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { fifthLevel } from "../../../../app/levels";
import { RootState } from "../../../../app/store";

const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background: "#00000090",
  zIndex: fifthLevel,
  position: "fixed",
  top: 0,
  left: 0,
});

const Loader = styled(CircularProgress)({
  color: "#F6EE81",
  margin: "25px",
  width: "3.5cqi !important",
  height: "3.5cqi !important",
});

const Message = styled.div({
  fontSize: "1.8cqi",
  fontWeight: 700,
});

const LoaderMobile = styled(CircularProgress)({
  color: "#F6EE81",
  margin: "25px",
  width: "7cqi !important",
  height: "7cqi !important",
});

const MessageMobile = styled.div({
  fontSize: "4cqi",
  fontWeight: 700,
});

const LoadingScreen = (props: { message?: string }) => {
  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const renderDesktop = () => {
    return (
      <>
        <Container>
          <Loader />
          <Message>{props.message}</Message>
        </Container>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Container>
          <LoaderMobile />
          <MessageMobile>{props.message}</MessageMobile>
        </Container>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default LoadingScreen;
