import mockService from "../../../../Services/MockService";
import { httpClientCore } from "../../../api";

export async function delete_EnterpriseUser({
  childUserId,
}: {
  childUserId: number;
}) {
  try {
    if (mockService.getIsDemo()) {
      await mockService.deleteMockedEnterpriseUser(childUserId)
      return { status: 200 };
    }
    const response = await httpClientCore.delete(`/v2/enterprise/users/${childUserId}`);
    return response;
  } catch (err) {
    return null;
  }
}
