import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { isEmail, PbErrorMessage } from "pebblebee-sdk-frontend";

import {
  requestCode,
  setCode,
  setCodeError,
  setCodeRequested,
  setEmail,
  setEmailError,
  setStaySignedIn,
  verifyCode,
} from "./LoginSlice";

import BackgroundIma from "../../assets/imgs/Map.png";
import Logo from "../../assets/imgs/Logo.svg";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useAppDispatch } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import AuthService from "../../Services/AuthService";
import { firstLevel } from "../../app/levels";
import { boxShadows } from "../../app/boxShadows";
import mockService from "../../Services/MockService";
import messagesService from "../../Services/MessagesService";
import { healthCheck } from "../../app/CommonSlice";

const LoginContainer = styled.div({
  zIndex: firstLevel,
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const LoginBackground = styled.div((props: { imgUrl: string }) => ({...{
  zIndex: "-2",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(${props.imgUrl})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
} as React.CSSProperties}));

const LoginBox = styled.div({
  background: "#ffffff 0% 0% no-repeat padding-box",
  boxShadow: boxShadows.veryHard,
  borderRadius: "1vw",
  opacity: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: "25vw",
  aspectRatio: "1.2/1",
  padding: "1vw",
});

const LogoContainer = styled.div({
  aspectRatio: "1.2/1",
  width: "20%",
});

const LogoImage = styled.img({
  width: "3vw",
  height: "auto",
});

const LoginTilte = styled.div({
  font: "normal normal normal 2cqi Lato",
  letterSpacing: "0px",
  margin: "0px",
});

const Separator = styled.div({
  border: `1px solid #F2E153`,
  borderRadius: "999px",
  width: "3vw",
  margin: "1vw 0 1vw 0",
});

const InfoText = styled.div({
  textAlign: "center",
  font: "normal normal normal 1rem Lato",
  letterSpacing: "0px",
  width: "80%",
  marginBottom: "5%",
  fontSize: "1cqi",
});

const ButtonRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "80%",
});

const LoginButton = styled(Button)({
  width: "40%",
  background: "#000000 0% 0% no-repeat padding-box",
  borderRadius: "0.5vw",
  aspectRatio: "3/1",
  color: "#FFFFFF",
  font: "normal normal bold 1cqi Lato",
  marginTop: "0.5cqi",
  ":hover": {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    color: "#000000",
    border: "solid 1px black",
  },
});

const StayRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0.5vw 0",
  "& p": {
    fontSize: "0.9cqi",
  },
});

const BottomInfo = styled.div({
  width: "60%",
  fontSize: "0.7cqi",
  marginBottom: "5px",
});

const InfoLink = styled.span({
  fontWeight: "800",
  ":hover": {
    cursor: "pointer",
  },
  textDecoration: "underline",
  color: "#000 !important",
});

const InfoMessage = styled.span({
  fontWeight: "800",
  ":hover": {
    cursor: "pointer",
  },
  color: "#000 !important",
});

const Loader = styled(CircularProgress)({
  color: "#F6EE81",
  margin: "25px",
  width: "8vw",
  height: "8vw",
});

//Mobile

const LoginBoxMobile = styled.div({
  background: "#ffffff 0% 0% no-repeat padding-box",
  boxShadow: boxShadows.veryHard,
  borderRadius: "3cqi",
  opacity: "1",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  width: "80vw",
  padding: "1vw",
});

const LogoContainerMobile = styled.div({
  marginTop: "3cqi",
  aspectRatio: "1.2/1",
  width: "50%",
});

const LogoImageMobile = styled.img({
  width: "15cqi",
  height: "auto",
});

const LoginTilteMobile = styled.div({
  font: "normal normal normal 7cqi Lato",
  letterSpacing: "0px",
  margin: "0px",
});

const SeparatorMobile = styled.div({
  border: `0.5cqi solid #F2E153`,
  borderRadius: "999px",
  width: "10cqi",
  margin: "1vw 0 1vw 0",
});

const InfoTextMobile = styled.div({
  textAlign: "center",
  font: "normal normal normal 4cqi Lato",
  letterSpacing: "0px",
  width: "80%",
  marginBottom: "5%",
  fontSize: "3.5cqi",
});

const LoginButtonMobile = styled(Button)({
  width: "60%",
  background: "#000000 0% 0% no-repeat padding-box",
  borderRadius: "3cqi",
  aspectRatio: "3/1",
  color: "#FFFFFF",
  font: "normal normal bold 4cqi Lato",
  marginTop: "0.5cqi",
  ":hover": {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    color: "#000000",
    border: "solid 1px black",
  },
});

const StayRowMobile = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "1.5cqi 0",
  "& p": {
    fontSize: "3cqi",
  },
});

const BottomInfoMobile = styled.div({
  width: "80%",
  fontSize: "4cqi",
  marginBottom: "5px",
});

const LoaderMobile = styled(CircularProgress)({
  color: "#F6EE81",
  margin: "3cqi",
  width: "15cqi",
  height: "15cqi",
});

const DotsButtonContainer = styled(IconButton)({
  position: "absolute",
  top: "0.5cqi",
  right: "0.5cqi",
});

const DotsButton = styled(MoreHorizIcon)({
  height: "1.4cqi",
  width: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
});

interface LoginProps {
  token?: string;
}

const Login = ({ token }: LoginProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dotsMenusRef = useRef(null);

  useEffect(() => {
    messagesService.subscribeToErrors(errorCallback);
    if (AuthService.isAuthenticated()) {
      navigate("/home");
    }
  }, [navigate]);

  const [callbackErrorMessage, setCallbackErrorMessage] = useState("");

  const errorCallback = (caller: string, message: string) => {
    setCallbackErrorMessage(message);
  };

  const hideErrorSnackBar = () => {
    setCallbackErrorMessage("");
  };

  const codeRequested = useSelector(
    (state: RootState) => state.login.codeRequested
  );

  const emailForm = useSelector((state: RootState) => state.login.emailForm);

  const codeForm = useSelector((state: RootState) => state.login.codeForm);

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const [showExtras, setShowExtras] = useState(false);

  const submitEmail = async () => {
    let isValid = true;
    let errors = { email: "" };

    if (!emailForm.email) {
      isValid = false;
      errors.email = t("login.emailForm.errors.required");
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailForm.email && !emailRegex.test(emailForm.email)) {
      isValid = false;
      errors.email = t("login.emailForm.errors.wrongFormat");
    }

    if (!isValid) {
      dispatch(setEmailError(errors));
      return;
    }

    const emailTrimed = emailForm.email.trim();

    await dispatch(healthCheck({ simulateFailure: false }));

    await dispatch(requestCode({ email: emailTrimed }));
  };

  const submitCode = async () => {
    let isValid = true;
    let errors = { code: "" };
    const reg = /^\d+$/;

    if (!codeForm.code) {
      isValid = false;
      errors.code = t("login.codeForm.errors.required");
    }

    if (codeForm.code.length && codeForm.code.length !== 6) {
      isValid = false;
      errors.code = t("login.codeForm.errors.wrongLength");
    }

    if (codeForm.code && !reg.test(codeForm.code)) {
      isValid = false;
      errors.code = t("login.codeForm.errors.wrongFormat");
    }

    if (!isValid) {
      dispatch(setCodeError(errors));
      return;
    }

    const emailTrimed = emailForm.email.trim();
    const codeTrimed = codeForm.code.trim();

    await dispatch(healthCheck({ simulateFailure: false }));

    const result = await dispatch(
      verifyCode({
        email: emailTrimed,
        code: codeTrimed,
        staySignedIn: emailForm.staySignedIn,
      })
    );

    if (result.type.includes("fulfilled")) {
      navigate("/home");
    }
  };

  const EmailForm = () => {
    return (
      <>
        <LoginTilte>{t("login.emailForm.title")}</LoginTilte>
        <Separator />
        <InfoText>{t("login.emailForm.info")}</InfoText>
        <TextField
          id="email-input"
          variant="standard"
          placeholder={t("login.emailForm.emailPlaceholder") as string}
          value={emailForm.email}
          style={{ width: "80%", height: "1.2cqi" }}
          sx={{
            "& input": {
              fontSize: "1cqi",
            },
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => {
            dispatch(setEmailError({ email: "" }));
            dispatch(setEmail(e.target.value));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              submitEmail();
            }
          }}
        />
        <PbErrorMessage message={emailForm.errors.email} bigText />
        <StayRow>
          <Checkbox
            style={{ color: "#000000", width: "1.5cqi", height: "1.5cqi" }}
            sx={{
              "& svg": {
                width: "1.2cqi",
                height: "1.2cqi",
              },
            }}
            value={emailForm.staySignedIn}
            checked={emailForm.staySignedIn}
            onChange={(e) => {
              dispatch(setStaySignedIn(e.target.checked));
            }}
          ></Checkbox>
          <Typography>{t("login.emailForm.staySignedIn")}</Typography>
        </StayRow>

        <ButtonRow>
          {emailForm.loading ? (
            <Loader />
          ) : (
            <LoginButton id="login-button" onClick={submitEmail}>
              {t("login.emailForm.submit")}
            </LoginButton>
          )}
        </ButtonRow>
        <PbErrorMessage message={emailForm.errorMessage} bigText />
      </>
    );
  };

  const EmailFormMobile = () => {
    return (
      <>
        <LoginTilteMobile>{t("login.emailForm.title")}</LoginTilteMobile>
        <SeparatorMobile />
        <InfoTextMobile>{t("login.emailForm.info")}</InfoTextMobile>
        <TextField
          id="email-input"
          variant="standard"
          placeholder={t("login.emailForm.emailPlaceholder") as string}
          value={emailForm.email}
          style={{ width: "80%", height: "5cqi" }}
          sx={{
            "& input": {
              fontSize: "4cqi",
            },
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => {
            dispatch(setEmailError({ email: "" }));
            dispatch(setEmail(e.target.value));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              submitEmail();
            }
          }}
        />
        <PbErrorMessage message={emailForm.errors.email} bigText />
        <StayRowMobile>
          <Checkbox
            style={{ color: "#000000", width: "5cqi", height: "5cqi" }}
            sx={{
              "& svg": {
                width: "4cqi",
                height: "4cqi",
              },
            }}
            value={emailForm.staySignedIn}
            checked={emailForm.staySignedIn}
            onChange={(e) => {
              dispatch(setStaySignedIn(e.target.checked));
            }}
          ></Checkbox>
          <Typography>{t("login.emailForm.staySignedIn")}</Typography>
        </StayRowMobile>

        <ButtonRow>
          {emailForm.loading ? (
            <LoaderMobile />
          ) : (
            <LoginButtonMobile id="login-button" onClick={submitEmail}>
              {t("login.emailForm.submit")}
            </LoginButtonMobile>
          )}
        </ButtonRow>
        <PbErrorMessage message={emailForm.errorMessage} bigText />
      </>
    );
  };

  const CodeForm = () => {
    return (
      <>
        <LoginTilte>{t("login.codeForm.title")}</LoginTilte>
        <Separator />
        <InfoText>
          {t("login.codeForm.info")}
          {emailForm.email}
        </InfoText>
        <TextField
          id="code-input"
          variant="standard"
          placeholder={t("login.codeForm.codePlaceholder") as string}
          value={codeForm.code}
          style={{ width: "80%", height: "1.2cqi" }}
          sx={{
            "& input": {
              fontSize: "1cqi",
            },
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => {
            const trimed = e.target.value.replace(/\D/g, "").trim();
            const numRgx = /^[0-9]*$/;
            if (!numRgx.test(trimed)) {
              return;
            }
            if (trimed.length > 6) {
              dispatch(setCode(trimed.substring(0, 6)));
              return;
            }

            dispatch(setCodeError({ code: "" }));
            dispatch(setCode(trimed));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              submitCode();
            }
          }}
        />
        <PbErrorMessage message={codeForm.errors.code} bigText />
        <ButtonRow>
          {codeForm.loading ? (
            <Loader />
          ) : (
            <LoginButton id="login-button" onClick={submitCode}>
              {t("login.codeForm.submit")}
            </LoginButton>
          )}
        </ButtonRow>
        <br />
        <BottomInfo>
          <InfoMessage
            onClick={() => {
              dispatch(setCodeRequested(false));
            }}
          >
            {t("login.codeForm.ChangeEmail")}
          </InfoMessage>
        </BottomInfo>
        {codeForm.errorMessage ? (
          <PbErrorMessage message={codeForm.errorMessage} bigText />
        ) : null}
      </>
    );
  };

  const CodeFormMobile = () => {
    return (
      <>
        <LoginTilteMobile>{t("login.codeForm.title")}</LoginTilteMobile>
        <SeparatorMobile />
        <InfoTextMobile>
          {t("login.codeForm.info")}
          {emailForm.email}
        </InfoTextMobile>
        <TextField
          id="code-input"
          variant="standard"
          placeholder={t("login.codeForm.codePlaceholder") as string}
          value={codeForm.code}
          style={{ width: "80%", height: "5cqi" }}
          sx={{
            "& input": {
              fontSize: "4cqi",
            },
          }}
          inputProps={{ style: { textAlign: "center" } }}
          onChange={(e) => {
            const trimed = e.target.value.replace(/\D/g, "").trim();
            const numRgx = /^[0-9]*$/;
            if (!numRgx.test(trimed)) {
              return;
            }
            if (trimed.length > 6) {
              dispatch(setCode(trimed.substring(0, 6)));
              return;
            }

            dispatch(setCodeError({ code: "" }));
            dispatch(setCode(trimed));
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              submitCode();
            }
          }}
        />
        <PbErrorMessage message={codeForm.errors.code} bigText />
        <ButtonRow style={{ marginTop: "3cqi" }}>
          {codeForm.loading ? (
            <LoaderMobile />
          ) : (
            <LoginButtonMobile id="login-button" onClick={submitCode}>
              {t("login.codeForm.submit")}
            </LoginButtonMobile>
          )}
        </ButtonRow>
        <br />
        <BottomInfoMobile>
          <InfoMessage
            onClick={() => {
              dispatch(setCodeRequested(false));
            }}
          >
            {t("login.codeForm.ChangeEmail")}
          </InfoMessage>
        </BottomInfoMobile>
        {codeForm.errorMessage ? (
          <PbErrorMessage message={codeForm.errorMessage} bigText />
        ) : null}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        <LoginContainer>
          <LoginBackground imgUrl={BackgroundIma} />
          <LoginBox>
            <LogoContainer className="flex justify-center items-center">
              <LogoImage src={Logo} />
            </LogoContainer>
            {codeRequested ? CodeForm() : EmailForm()}
            <BottomInfo>
              <span>{t("login.FooterFixed1")}</span>
              <InfoLink
                onClick={() => {
                  //navigate("/termsofuse");
                }}
              >
                <a
                  style={{ color: "#000" }}
                  href="https://pebblebee.com/pages/terms-of-use"
                >
                  {t("login.TermsOfUse")}
                </a>
              </InfoLink>
              <span> {t("login.FooterFixed2")} </span>
              <InfoLink
                onClick={() => {
                  //navigate("/privacypolicy");
                }}
              >
                <a
                  style={{ color: "#000" }}
                  href="https://pebblebee.com/pages/privacy-policy"
                >
                  {t("login.PrivacyPolicy")}
                </a>
              </InfoLink>
              <br />
            </BottomInfo>
            <DotsButtonContainer
              aria-label="more"
              id="long-button"
              aria-controls={showExtras ? "long-menu" : undefined}
              aria-expanded={showExtras ? "true" : undefined}
              aria-haspopup="true"
              onClick={() => {
                setShowExtras(!showExtras);
              }}
              ref={dotsMenusRef}
            >
              <DotsButton />
            </DotsButtonContainer>
            <Menu
              anchorEl={dotsMenusRef.current}
              open={showExtras}
              onClose={() => {
                setShowExtras(false);
              }}
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              style={{
                marginTop: "20px",
              }}
            >
              <MenuItem
                key={"trydemo"}
                onClick={() => {
                  //Setup demo flag
                  mockService.setIsDemo(true);
                  console.log(mockService.getIsDemo());
                  //Set user
                  navigate("/home");
                }}
              >
                {t("login.tryDemo")}
              </MenuItem>
            </Menu>
          </LoginBox>
          <Snackbar
            open={callbackErrorMessage !== ""}
            autoHideDuration={30000}
            onClose={hideErrorSnackBar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <div>
              <Alert severity="error">{callbackErrorMessage}</Alert>
            </div>
          </Snackbar>
        </LoginContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <LoginContainer>
          <LoginBackground imgUrl={BackgroundIma} />
          <LoginBoxMobile>
            <LogoContainerMobile>
              <LogoImageMobile src={Logo} />
            </LogoContainerMobile>
            {codeRequested ? CodeFormMobile() : EmailFormMobile()}
            <BottomInfoMobile>
              <span>{t("login.FooterFixed1")}</span>
              <InfoLink
                onClick={() => {
                  //navigate("/termsofuse");
                }}
              >
                <a
                  style={{ color: "#000" }}
                  href="https://pebblebee.com/pages/terms-of-use"
                >
                  {t("login.TermsOfUse")}
                </a>
              </InfoLink>
              <span> {t("login.FooterFixed2")} </span>
              <InfoLink
                onClick={() => {
                  //navigate("/privacypolicy");
                }}
              >
                <a
                  style={{ color: "#000" }}
                  href="https://pebblebee.com/pages/privacy-policy"
                >
                  {t("login.PrivacyPolicy")}
                </a>
              </InfoLink>
            </BottomInfoMobile>
          </LoginBoxMobile>
        </LoginContainer>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default Login;
