import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import EnterpriseUser, {
  EnterpriseAccessLevel,
  EnterpriseUserRow,
} from "../../models/enterprise/EnterpriseUser";
import EnterpriseLog from "../../models/enterprise/EnterpriseLogs";
import DeviceGroup from "../../models/enterprise/DeviceGroup";
import { get_EnterpriseUsers } from "../../app/apiRequests/core/enterprise/getEnterpriseUsers";
import messagesService from "../../Services/MessagesService";
import { get_DevicesGroups } from "../../app/apiRequests/core/enterprise/getDeviceGroups";
import { post_EnterpriseUser } from "../../app/apiRequests/core/enterprise/postEnterpriseUsers";
import { get_EnterpriseLogs } from "../../app/apiRequests/core/enterprise/getEnterpriseLogs";
import { put_EnterpriseUser } from "../../app/apiRequests/core/enterprise/putEnterpriseUsers";
import { delete_EnterpriseUser } from "../../app/apiRequests/core/enterprise/deleteEnterpriseUsers";

export interface UserManagementState {
  showUserForm: boolean;
  showActivity: boolean;
  loadingUsers: boolean;
  loadingGroups: boolean;
  loadingLogs: boolean;
  users: EnterpriseUser[];
  groups: DeviceGroup[];
  logs: EnterpriseLog[];
  newEnterpriseUserForm: {
    isEdit: boolean;
    id?: number;
    email: string;
    emailError: string;
    accessLevel: EnterpriseAccessLevel;
    deviceGroupsIds: string[];
    processingUser: boolean;
  };
  deleteUserModal: {
    show: boolean;
    user?: EnterpriseUserRow;
    processingDelete: boolean;
  };
}

const initialState: UserManagementState = {
  showUserForm: false,
  showActivity: false,
  loadingUsers: false,
  loadingGroups: false,
  loadingLogs: false,
  users: [],
  groups: [],
  logs: [],
  newEnterpriseUserForm: {
    isEdit: false,
    email: "",
    emailError: "",
    accessLevel: "READ",
    deviceGroupsIds: [],
    processingUser: false,
  },
  deleteUserModal: {
    show: false,
    processingDelete: false,
  },
};

export const getEnterpriseLogs = createAsyncThunk(
  "userManagement/getEnterpriseLogs",
  async (_, thunkApi) => {
    try {
      const response = await get_EnterpriseLogs();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      //save token and user data
      if (!response.data || !response.data.logs) {
        return thunkApi.rejectWithValue("An error occured");
      }

      return response.data.logs;
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const getEnterpriseUsers = createAsyncThunk(
  "userManagement/getEnterpriseUsers",
  async (_, thunkApi) => {
    try {
      const response = await get_EnterpriseUsers();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      //save token and user data
      if (!response.data || !response.data.users) {
        return thunkApi.rejectWithValue("An error occured");
      }

      return response.data.users;
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const getDevicesGroups = createAsyncThunk(
  "userManagement/getDevicesGroups",
  async (_, thunkApi) => {
    try {
      const response = await get_DevicesGroups();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      //save token and user data
      if (!response.data || !response.data.groups) {
        return thunkApi.rejectWithValue("An error occured");
      }

      return response.data.groups;
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const createNewEnterpriseUser = createAsyncThunk(
  "userManagement/createNewEnterpriseUser",
  async (
    {
      email,
      accessLevel,
      deviceGroupIds,
    }: {
      email: string;
      accessLevel: EnterpriseAccessLevel;
      deviceGroupIds: string[];
    },
    thunkApi
  ) => {
    try {
      const response = await post_EnterpriseUser({
        email,
        accessLevel,
        deviceGroupIds,
      });

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (response.status !== 200 && response.status !== 201) {
        return thunkApi.rejectWithValue( "An error occured");
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const editEnterpriseUser = createAsyncThunk(
  "userManagement/editEnterpriseUser",
  async (
    {
      id,
      accessLevel,
      deviceGroupIds,
    }: {
      id: number;
      accessLevel: EnterpriseAccessLevel;
      deviceGroupIds: string[];
    },
    thunkApi
  ) => {
    try {
      const response = await put_EnterpriseUser({
        id,
        accessLevel,
        groupsIds: deviceGroupIds,
      });

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (response.status !== 200 && response.status !== 201) {
        return thunkApi.rejectWithValue("An error occured");
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const deleteEnterpriseUser = createAsyncThunk(
  "userManagement/deleteEnterpriseUser",

  async ({ id }: { id: number }, thunkApi) => {
    try {
      const response = await delete_EnterpriseUser({
        childUserId: id,
      });

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const UserManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setShowUserForm: (state, action: PayloadAction<boolean>) => {
      state.showUserForm = action.payload;
    },
    setShowActivity: (state, action: PayloadAction<boolean>) => {
      state.showActivity = action.payload;
    },
    setNewUserEmail: (state, action: PayloadAction<string>) => {
      state.newEnterpriseUserForm.email = action.payload;
    },
    setNewUserEmailError: (state, action: PayloadAction<string>) => {
      state.newEnterpriseUserForm.emailError = action.payload;
    },
    setNewUserAccess: (state, action: PayloadAction<EnterpriseAccessLevel>) => {
      state.newEnterpriseUserForm.accessLevel = action.payload;
    },
    setNewUserDeviceGroupsIds: (state, action: PayloadAction<string[]>) => {
      state.newEnterpriseUserForm.deviceGroupsIds = action.payload;
    },
    addNewUser: (state) => {
      state.showUserForm = true;
      state.newEnterpriseUserForm = initialState.newEnterpriseUserForm;
    },
    setEditUser: (state, action: PayloadAction<EnterpriseUserRow>) => {
      console.log(action.payload);
      state.newEnterpriseUserForm.id = action.payload.childUserId;
      state.newEnterpriseUserForm.email = action.payload.childEmail;
      state.newEnterpriseUserForm.accessLevel = action.payload.permission;
      state.newEnterpriseUserForm.deviceGroupsIds =
        action.payload.deviceGroupsUUIDs;
      state.showUserForm = true;
      state.newEnterpriseUserForm.isEdit = true;
    },
    showDeleteModal: (state, action: PayloadAction<EnterpriseUserRow>) => {
      state.deleteUserModal.user = action.payload;
      state.deleteUserModal.show = true;
    },
    hideDeleteModal: (state) => {
      state.deleteUserModal.user = undefined;
      state.deleteUserModal.show = false;
    },
    resetUserManagementSlice: (state) => {
      state.users = [];
      state.logs = [];
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      //GET logs
      .addCase(getEnterpriseLogs.pending, (state) => {
        state.loadingLogs = true;
      })
      .addCase(getEnterpriseLogs.fulfilled, (state, action) => {
        state.logs = action.payload;
        state.loadingLogs = false;
      })
      .addCase(getEnterpriseLogs.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to fetch users");
        state.loadingLogs = false;
      })
      //GET device groups
      .addCase(getDevicesGroups.pending, (state) => {
        state.loadingGroups = true;
      })
      .addCase(getDevicesGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.loadingGroups = false;
      })
      .addCase(getDevicesGroups.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to fetch users");
        state.loadingGroups = false;
      })
      //GET users
      .addCase(getEnterpriseUsers.pending, (state) => {
        state.loadingUsers = true;
      })
      .addCase(getEnterpriseUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loadingUsers = false;
      })
      .addCase(getEnterpriseUsers.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to fetch users");
        state.loadingUsers = false;
      })
      //POST user
      .addCase(createNewEnterpriseUser.pending, (state) => {
        state.newEnterpriseUserForm.processingUser = true;
      })
      .addCase(createNewEnterpriseUser.fulfilled, (state, action) => {
        state.newEnterpriseUserForm.processingUser = false;
        state.showUserForm = false;
      })
      .addCase(createNewEnterpriseUser.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to create user");
        state.newEnterpriseUserForm.processingUser = false;
      })
      //PUT user
      .addCase(editEnterpriseUser.pending, (state) => {
        state.newEnterpriseUserForm.processingUser = true;
      })
      .addCase(editEnterpriseUser.fulfilled, (state, action) => {
        state.newEnterpriseUserForm.processingUser = false;
        state.showUserForm = false;
      })
      .addCase(editEnterpriseUser.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to edit user");
        state.newEnterpriseUserForm.processingUser = false;
      })
      //DELETE user
      .addCase(deleteEnterpriseUser.pending, (state) => {
        state.deleteUserModal.processingDelete = true;
      })
      .addCase(deleteEnterpriseUser.fulfilled, (state, action) => {
        state.deleteUserModal.processingDelete = false;
      })
      .addCase(deleteEnterpriseUser.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to delete user");
        state.deleteUserModal.processingDelete = false;
      });
  },
});

export const {
  setShowUserForm,
  setShowActivity,
  resetUserManagementSlice,
  setNewUserAccess,
  setNewUserDeviceGroupsIds,
  setNewUserEmail,
  setNewUserEmailError,
  addNewUser,
  setEditUser,
  showDeleteModal,
  hideDeleteModal,
} = UserManagementSlice.actions;

export default UserManagementSlice.reducer;
