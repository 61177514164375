import styled from "@emotion/styled";
import { Avatar, ListItemButton, Tooltip } from "@mui/material";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PbBlue, PbYellow } from "../../../../app/colors";
import { useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { setSelectedElementId } from "../../../home/HomeSlice";
import { closeCluster } from "../../../mainLayout/MainLayoutSlice";
import CancelIcon from "@mui/icons-material/Cancel";
import { firstLevel, fourthLevel, secondLevel } from "../../../../app/levels";
import { boxShadows, boxShadowsMobile } from "../../../../app/boxShadows";
import PbDeviceAvatarIcon from "../../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";

interface ClusterMarkerProps {
  clusterId: string;
  shared: boolean;
  mapReadings: {
    reading: Reading;
    device: UserDevice;
  }[];
}

const ClusterInfoWindow = (props: ClusterMarkerProps) => {
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const selectedClusterId = useSelector((state: RootState) => {
    return state.mainLayout.selectedClusterId;
  });

  const showItems = useMemo(() => {
    return selectedClusterId === props.clusterId;
  }, [props.clusterId, selectedClusterId]);

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const ExpandContainer = styled.div({
    position: "absolute",
    transform: "translate3d(0, -100%, 0) translateY(-0.6cqi)",
    zIndex: secondLevel,
  });

  const ListContainer = styled.div({
    background: "#FFFFFF",
    boxShadow: boxShadows.veryHard,
    width: "12vw",
    transform: "translateX(-50%)",
    borderRadius: "0.6cqi",
    maxHeight: "30cqi",
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  });

  const DownArrow = styled.div({
    width: "0",
    height: "0.5cqi",
    borderLeft: "0.7cqi solid transparent",
    borderRight: "0.7cqi solid transparent",
    borderTop: "0.6cqi solid #FFFFFF",
    transform: "translateX(-50%)",
  });

  const AvatarIcon = styled(Avatar)(
    (props: { selected: boolean; shared: number }) => ({
      border: props.selected ? "0.175cqi solid" : "0.125cqi solid",
      borderColor: props.shared ? PbBlue : PbYellow,
      width: "1.9cqi",
      height: "1.9cqi",
      borderRadius: "0.9cqi",
      zIndex: props.selected ? firstLevel : 1,
    })
  );

  const RowContainer = styled(ListItemButton)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5cqi 1cqi",
    ":hover": {
      cursor: "pointer",
    },
  });

  const NameLabel = styled.div({
    width: "7cqi",
    textAlign: "left",
    fontSize: "0.8cqi",
    fontWeight: 700,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  const CloseButton = styled(CancelIcon)({
    position: "absolute",
    top: "-0.5cqi",
    right: "5.5cqi",
    width: "1cqi",
    height: "1cqi",
    ":hover": {
      cursor: "pointer",
    },
    zIndex: fourthLevel,
  });

  //Mobile

  const ExpandContainerMobile = styled.div({
    position: "absolute",
    transform: "translate3d(0, -100%, 0) translateY(-4cqi)",
    zIndex: secondLevel,
  });

  const ListContainerMobile = styled.div({
    background: "#FFFFFF",
    boxShadow: boxShadowsMobile.veryHard,
    width: "40vw",
    transform: "translateX(-50%)",
    borderRadius: "1.2cqi",
    maxHeight: "30cqi",
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  });

  const DownArrowMobile = styled.div({
    width: "0",
    height: "1cqi",
    borderLeft: "2cqi solid transparent",
    borderRight: "2cqi solid transparent",
    borderTop: "2cqi solid #FFFFFF",
    transform: "translateX(-50%)",
  });

  const AvatarIconMobile = styled(Avatar)(
    (props: { selected: boolean; shared: number }) => ({
      border: props.selected ? "0.175cqi solid" : "0.125cqi solid",
      borderColor: props.shared ? PbBlue : PbYellow,
      width: "6cqi",
      height: "6cqi",
      borderRadius: "1.5cqi",
      zIndex: props.selected ? firstLevel : 1,
    })
  );

  const RowContainerMobile = styled(ListItemButton)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1cqi 2cqi",
    margin: "1cqi",
    ":hover": {
      cursor: "pointer",
    },
  });

  const NameLabelMobile = styled.div({
    width: "25cqi",
    textAlign: "left",
    fontSize: "4cqi",
    fontWeight: 700,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  const CloseButtonMobile = styled(CancelIcon)({
    position: "absolute",
    top: "-1.5cqi",
    right: "18.5cqi",
    width: "3cqi",
    height: "3cqi",
    ":hover": {
      cursor: "pointer",
    },
    zIndex: fourthLevel,
  });

  const clusterListMouseEnter = () => {
    if (window.gMap) {
      window.gMap.setOptions({ scrollwheel: false });
    }
  };

  const clusterListMouseLeave = () => {
    if (window.gMap) {
      window.gMap.setOptions({ scrollwheel: true });
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <ExpandContainer>
          <CloseButton
            onClick={() => {
              dispatch(closeCluster(""));
            }}
          />
          <ListContainer
            onMouseEnter={clusterListMouseEnter}
            onMouseLeave={clusterListMouseLeave}
          >
            {props.mapReadings.map((mapReading) => {
              return (
                <RowContainer
                  key={`cluster-data-row-${mapReading.device.id}`}
                  onClick={() => {
                    dispatch(
                      setSelectedElementId(
                        mapReading.device.guid || mapReading.device.id
                      )
                    );
                  }}
                >
                  <PbDeviceAvatarIcon 
                    device={mapReading.device}
                    selected={
                      selectedElementId === mapReading.device.guid ||
                      selectedElementId === mapReading.device.id
                    }
                  />
                  <Tooltip title={mapReading.device.name}>
                    <NameLabel>{mapReading.device.name}</NameLabel>
                  </Tooltip>
                </RowContainer>
              );
            })}
          </ListContainer>
          <DownArrow />
        </ExpandContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <ExpandContainerMobile>
          <CloseButtonMobile
            onClick={() => {
              dispatch(closeCluster(""));
            }}
          />
          <ListContainerMobile
            onMouseEnter={clusterListMouseEnter}
            onMouseLeave={clusterListMouseLeave}
          >
            {props.mapReadings.map((mapReading) => {
              return (
                <RowContainerMobile
                  key={`cluster-data-row-${mapReading.device.id}`}
                  onClick={() => {
                    dispatch(
                      setSelectedElementId(
                        mapReading.device.guid || mapReading.device.id
                      )
                    );
                  }}
                >
                  <AvatarIconMobile
                    id={`marker-avatar-${mapReading.device.id}`}
                    alt={`${mapReading.device.name}`}
                    src={mapReading.device.imageUrl}
                    selected={
                      selectedElementId === mapReading.device.guid ||
                      selectedElementId === mapReading.device.id
                    }
                    shared={mapReading.device.sharedFrom ? 1 : 0}
                  />
                  <Tooltip title={mapReading.device.name}>
                    <NameLabelMobile>{mapReading.device.name}</NameLabelMobile>
                  </Tooltip>
                </RowContainerMobile>
              );
            })}
          </ListContainerMobile>
          <DownArrowMobile />
        </ExpandContainerMobile>
      </>
    );
  };

  return showItems ? (
    isMobile ? renderMobile(): renderDesktop()
  ) : null;
};

export default ClusterInfoWindow;
