import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled";
import NotificationItem from "./NotificationItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import { useAppDispatch } from "../../../../../app/hooks";
import { clearNewNotification } from "../../../../../dataSlices/NotificationsSlice";
import { fourthLevel } from "../../../../../app/levels";

const NewNotificationContainer = styled.div({
  position: "fixed",
  top: "4vw",
  right: "1vw",
  zIndex: fourthLevel,
  width: "25vw",
  height: "auto",
  background: "#FFFFFF",
  borderRadius: "15px",
});

const NewNotificationsAlert = () => {

  const newNotification = useSelector((state: RootState) => {
    return state.notifications.newNotification;
  });

  const showNotificationsBox = useSelector((state: RootState) => {
    return state.menus.showNotificationsBox;
  });

  const showNotification = useMemo(() => {
    return newNotification !== undefined && !showNotificationsBox;
  }, [newNotification, showNotificationsBox]);

  return (
    <>
      {showNotification ? (
        <NewNotificationContainer>
          <NewNotification />
        </NewNotificationContainer>
      ) : null}
    </>
  );
};

const NewNotification = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch();

  const [notificationTimeout, setNotificationTimeout] =
    useState<NodeJS.Timeout>();

  const newNotification = useSelector((state: RootState) => {
    return state.notifications.newNotification;
  });

  const clearNotificationCallback = () => {
    if (notificationTimeout) {
      clearTimeout(notificationTimeout);
    }
    const tout = setTimeout(() => {
      dispatch(clearNewNotification());
    }, 60000);
    setNotificationTimeout(tout);
  };

  useEffect(() => {
    clearNotificationCallback();
    return () => {
      if (notificationTimeout) {
        clearTimeout(notificationTimeout);
      }
    };
  }, []);

  return newNotification ? <NotificationItem {...newNotification} /> : null;
};

export default NewNotificationsAlert;
