import React, { useCallback, useEffect } from "react";
import Login from "./features/login/Login";
import Home from "./features/home/Home";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AuthService from "./Services/AuthService";
import { useMediaQuery, useTheme } from "@mui/material";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ErrorScreen from "./components/ErrorScreen/ErrorScreen";
import appInfo from "../package.json";
import styled from "@emotion/styled";
import PrivateRoutes from "./components/PrivateRoute/PrivateRoute";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  healthCheck,
  OperatingSystems,
  setIsMobileScreen,
  setOs,
} from "./app/CommonSlice";
import GetTheAppScreen from "./components/GetTheAppScreen/GetTheAppScreen";
import { RootState } from "./app/store";
import MaintenanceScreen from "./components/MaintenanceScreen/MaintenanceScreen";

function App() {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.up("md"));

  const makingHealthCheck = useAppSelector(
    (state: RootState) => state.common.makingHealthCheck
  );
  const isOnMaintenance = useAppSelector(
    (state: RootState) => state.common.isOnMaintenance
  );

  const makeHealthCheck = useCallback(async () => {
    //check if maintainance mode is app
    await dispatch(healthCheck({simulateFailure: false}));
  }, [dispatch]);

  const VersionMark = styled.div({
    position: "fixed",
    top: "1px",
    right: "25px",
    color: "#DCDCDC70",
    fontSize: "12px",
  });

  useEffect(() => {
    dispatch(setIsMobileScreen(!mdScreen));
  }, [dispatch, mdScreen]);

  useEffect(() => {
    makeHealthCheck();

    const ua = navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
    const iosPlatforms = ["iPhone", "iPad", "iPod"];
    //const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];

    if (
      /iPad|iPhone|iPod/.test(ua) ||
      iosPlatforms.indexOf(platform) !== -1 ||
      macosPlatforms.indexOf(platform) !== -1 ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
    ) {
      dispatch(setOs(OperatingSystems.iOS));
    } else {
      dispatch(setOs(OperatingSystems.other));
    }
  }, [dispatch]);

  return (
    <div className="App">
      <ErrorBoundary>
        {makingHealthCheck ? <></> : null}
        {!mdScreen ? (
          <GetTheAppScreen />
        ) : isOnMaintenance ? (
          <MaintenanceScreen />
        ) : (
          <Router>
            <Routes>
              <Route path="error" element={<ErrorScreen />} />
              <Route path="login" element={<Login />} />
              <Route path="maintenance" element={<MaintenanceScreen />} />
              <Route
                element={
                  <PrivateRoutes
                    isAuthenticated={AuthService.isAuthenticated}
                    redirect="/login"
                  />
                }
              >
                <Route path="/" element={<Home />} />
                <Route path="home" element={<Home />} />
                <Route path="/*" element={<Navigate to={"/home"} replace />} />
              </Route>
            </Routes>
          </Router>
        )}
        <VersionMark>V-{appInfo.version}</VersionMark>
      </ErrorBoundary>
    </div>
  );
}

export default App;
