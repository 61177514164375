import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { CircularProgress, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import LogoImg from "../../../../assets/imgs/Logo.svg";
import PbLogoImg from "../../../../assets/imgs/pebblebe_name_logo.svg";
import shopIcon from "../../../../assets/imgs/bag-shopping.svg";
import expandIcon from "../../../../assets/imgs/expand.svg";
import DeviceDetails from "../DeviceData/DeviceDetails";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useTranslation } from "react-i18next";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import MenuContent from "../MenuContent/MenuContent";
import ReadingsService from "../../../../Services/ReadingsService";
import { firstLevel, secondLevel } from "../../../../app/levels";
import { boxShadows } from "../../../../app/boxShadows";
import GetStartedBox from "./GetStartedBox";
import { useAppDispatch } from "../../../../app/hooks";
import { getDevicesGroups } from "../../../../dataSlices/DevicesSlice";

interface FloatingMenuProps {
  logoOnClick?: () => any;
}

const fadeIn = keyframes`
  from{ opacity: 0; left: 4.5% }
  70%: { opacity: 0.5; left: 5.25%  }
  to: { opacity: 1; left: 5.5% }
`;

const fadeInExpanded = keyframes`
  from{ opacity: 0; left: 20.5% }
  70%: { opacity: 0.5; left: 21%  }
  to: { opacity: 1; left: 21.5% }
`;

const MenuContainer = styled.div(
  (props: { expanded: boolean; isMobile: number }) => ({
    ...({
      position: "fixed",
      top: "2%",
      bottom: "2%",
      left: "1.5%",
      width: props.expanded ? "20%" : "4%",
      transition: "0.5s",
      boxShadow: boxShadows.veryHard,
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: secondLevel,
      background: "#FFFFFF",
    } as React.CSSProperties),
  })
);

const DetailContainer = styled.div(
  (props: { expanded: boolean; show: boolean }) => ({
    ...({
      visibility: props.show ? "visible" : "hidden",
      position: "fixed",
      top: "2%",
      left: props.expanded ? "22.5%" : "6.5%",
      width: "24%",
      maxHeight: "90%",
      minHeight: "20%",
      overflowY: "auto",
      zIndex: secondLevel,
      background: "#FFFFFF",
      borderRadius: "0.5cqi",
      animation: props.expanded
        ? `${fadeInExpanded} 1s ease forwards`
        : `${fadeIn} 1s ease forwards`,
      transition: "0.5s",
      boxShadow: boxShadows.hard,
    } as React.CSSProperties),
  })
);

const LogoContainer = styled.div((props: { expanded: boolean }) => ({
  ...({
    height: "10%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: props.expanded ? "2%" : "0px",
    ":hover": {
      cursor: "pointer",
    },
    transition: "0.5s",
  } as React.CSSProperties),
}));

const ContentConainer = styled.div((props: { expanded: boolean }) => ({
  ...({
    width: "90%",
    height: props.expanded ? "85%" : "81%",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    "::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  } as React.CSSProperties),
}));

const ActionsConainer = styled.div((props: { expanded: boolean }) => ({
  ...({
    display: "flex",
    flexDirection: props.expanded ? "row" : "column",
    alignItems: props.expanded ? "flex-end" : "center",
    justifyContent: "flex-end",
    height: props.expanded ? "5%" : "9%",
    width: props.expanded ? "100%" : "60%",
    transition: "0.6s",
  } as React.CSSProperties),
}));

const IconContainer = styled.div((props: { expanded: boolean }) => ({
  height: "2cqi",
  width: "2cqi",
  marginBottom: "0.2cqi",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: props.expanded ? "0.4cqi" : "0px",
  ":hover": {
    cursor: "pointer",
  },
}));

const ActionImage = styled.img((props: { expanded: boolean }) => ({
  width: "1.3cqi",
  ":hover": {
    WebkitFilter: "brightness(70%)",
    WebkitTransition: "all 1s ease",
    MozTransition: "all 1s ease",
    OTransition: "all 1s ease",
    msTransition: "all 1s ease",
    filter: "brightness(70%)",
    transition: "all 0.5s ease",
  },
}));

const ExpandImage = styled.img((props: { expanded: boolean }) => ({
  width: "1.3cqi",
  ":hover": {
    WebkitFilter: "brightness(70%)",
    WebkitTransition: "all 1s ease",
    MozTransition: "all 1s ease",
    OTransition: "all 1s ease",
    msTransition: "all 1s ease",
    filter: "brightness(70%)",
    transition: "all 0.5s ease",
  },
  transition: "all 1s ease",
  transform: props.expanded ? "rotate(180deg)" : "rotate(0deg)",
}));

const MenuMobileContainer = styled.div({
  height: "35vh",
  width: "100vw",
  overflow: "auto",
});

const Loader = styled(CircularProgress)({
  color: "#F6EE81",
  margin: "25px",
  width: "2.5cqi !important",
  height: "2.5cqi !important",
});

const CoverScreen = styled.div({
  zIndex: firstLevel,
  background: "#00000035",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
});

const FloatingMenu = (props: FloatingMenuProps) => {
  const [expanded, setExpanded] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(false);

  const dispatch = useAppDispatch();

  const loggedUser = useSelector((state: RootState) => state.login.loggedUser);

  const loadingDevices = useSelector((state: RootState) => {
    return state.devices.loadingDevices;
  });

  const showDeviceDetails = useSelector((state: RootState) => {
    return state.mainLayout.showDeviceDetails;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const groups = useSelector((state: RootState) => {
    return state.devices.groups;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const location = useSelector((state: RootState) => {
    return state.common.location;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const hideShared = useSelector((state: RootState) => {
    return state.mainLayout.hideShared;
  });

  const hiddenGroups = useSelector((state: RootState) => {
    return state.mainLayout.hiddenGroups;
  });

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const { t } = useTranslation();

  const hasDevices = useMemo(() => {
    return userDevices.length > 0;
  }, [userDevices.length]);

  const showGetStartedBox = useMemo(() => {
    return userDevices.length <= 0 && !loadingDevices && showGetStarted;
  }, [loadingDevices, showGetStarted, userDevices.length]);

  const showFloatingMenu = useMemo(() => {
    return loadingDevices || userDevices.length > 0;
  }, [loadingDevices, userDevices.length]);

  useEffect(() => {
    //This avoids showing the box before the devices request is initialized
    if (loadingDevices) {
      setShowGetStarted(true);
    }
  }, [loadingDevices]);

  const fetchGroupsCallback = useCallback(async () => {
    dispatch(getDevicesGroups());
  }, [dispatch]);

  useEffect(() => {
    fetchGroupsCallback();
  }, [fetchGroupsCallback]);

  const menuSections = useMemo(() => {
    let sections: {
      title: string;
      devices: { data: UserDevice; reading: Reading }[];
    }[] = [];
    userDevices.forEach((userDevice, index) => {
        const reading = readings.find((r) => r.id === userDevice.guid);
        const show = ReadingsService.showReading(
          userDevice,
          hideShared,
          hiddenGroups
        );
        if (show) {
          const device = {
            data: userDevice,
            reading: reading,
          };

          //if the device group is not null create the group section
          if (userDevice.group) {
            const group = groups.find((g) => {
              return g.uuid === userDevice.group;
            });
            if (group) {
              addToGroup(sections, group.name, device);
            } else {
              if (!device.data.sharedFrom) {
                addToGroup(sections, "My Devices", device);
              } else {
                addToGroup(sections, "Shared", device);
              }
            }
          } else {
            //if there is no device group name, if its owned add to my devices. Else add to Shared
            if (!device.data.sharedFrom) {
              addToGroup(sections, "My Devices", device);
            } else {
              addToGroup(sections, "Shared", device);
            }
          }
        }
      });
    return sections;
  }, [hiddenGroups, hideShared, readings, userDevices]);

  function addToGroup(
    sections: {
      title: string;
      devices: { data: UserDevice; reading?: Reading }[];
    }[],
    name: string,
    device: { data: UserDevice; reading?: Reading }
  ) {
    let index = sections.findIndex((s) => s.title === name);
    if (index !== -1) {
      sections[index].devices.push(device);
    } else {
      sections.push({
        title: name,
        devices: [device],
      });
    }
  }

  const renderDesktop = () => {
    return (
      <>
        {showGetStartedBox && (
          <>
            <CoverScreen />
            <GetStartedBox />
          </>
        )}
        {showFloatingMenu && (
          <MenuContainer expanded={expanded} isMobile={isMobileScreen ? 1 : 0}>
            <LogoContainer expanded={expanded} onClick={props.logoOnClick}>
              <img
                style={{
                  width: expanded ? "50%" : "3vw",
                  maxHeight: expanded ? "100%" : "6vh",
                }}
                src={expanded ? PbLogoImg : LogoImg}
                alt="logo"
              />
            </LogoContainer>
            <ContentConainer
              expanded={expanded}
              className="hide-scroll-content"
            >
              {loadingDevices ? (
                <Loader />
              ) : (
                <MenuContent
                  expanded={expanded}
                  sections={menuSections}
                  userLocation={location}
                  key={"pb-menu-content"}
                />
              )}
            </ContentConainer>
            <ActionsConainer expanded={expanded}>
              <IconContainer expanded={expanded}>
                <a
                  href="https://pebblebee.com/collections/all-products"
                  target="_blank"
                  rel="noreferrer"
                  style={{ width: "100%" }}
                  className="flex justify-center items-center"
                >
                  <Tooltip
                    title={t("floatingMenu.tooltips.shop")}
                    placement={expanded ? "top" : "right"}
                  >
                    <ActionImage
                      expanded={expanded}
                      src={shopIcon}
                      alt="shop"
                    />
                  </Tooltip>
                </a>
              </IconContainer>
              <IconContainer
                expanded={expanded}
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                <Tooltip
                  title={
                    expanded
                      ? t("floatingMenu.tooltips.collapse")
                      : t("floatingMenu.tooltips.expand")
                  }
                  placement={expanded ? "top" : "right"}
                  arrow
                >
                  <ExpandImage
                    expanded={expanded}
                    src={expandIcon}
                    alt="Expand"
                  />
                </Tooltip>
              </IconContainer>
            </ActionsConainer>
          </MenuContainer>
        )}

        {showDeviceDetails && selectedElementId ? (
          <DetailContainer
            expanded={expanded}
            show={showDeviceDetails && selectedElementId !== ""}
          >
            <DeviceDetails />
          </DetailContainer>
        ) : null}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <MenuMobileContainer>
        {loadingDevices ? (
          <CircularProgress />
        ) : showDeviceDetails && selectedElementId !== "" ? (
          <>
            <DeviceDetails />
          </>
        ) : (
          <MenuContent
            expanded={true}
            sections={menuSections}
            userLocation={location}
            key={"pb-menu-content"}
          />
        )}
      </MenuMobileContainer>
    );
  };

  return <>{isMobileScreen ? <>{renderMobile()}</> : <>{renderDesktop()}</>}</>;
};

export default FloatingMenu;
