import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  deleteEnterpriseUser,
  getEnterpriseUsers,
  hideDeleteModal,
} from "./UserManagementSlice";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

const DeleteUserModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(
    (state: RootState) => state.userManagement.deleteUserModal.user
  );

  const processingDelete = useAppSelector(
    (state: RootState) => state.userManagement.deleteUserModal.processingDelete
  );

  const confirm = async () => {
    if (!user) return;
    const actionResult = await dispatch(
      deleteEnterpriseUser({ id: user?.childUserId })
    );
    //if success update users list
    if (actionResult.type.includes("fulfilled"))
      await dispatch(getEnterpriseUsers());
    dispatch(hideDeleteModal());
  };

  return (
    <div
      id="container"
      className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-3000"
    >
      <div
        id="bg-cover"
        className="w-full h-full bg-gray-600 opacity-60 fixed top-0 left-0 z-3000"
        onClick={() => {
          dispatch(hideDeleteModal());
        }}
      />
      <div
        id="delete-enterprise-user-modal-container"
        className="bg-white rounded-lg flex flex-col justify-center items-center z-4000 p-5"
      >
        <div className="my-3 text-xl">
          {t("userManagement.deleteUser.title")}
        </div>
        <div className="max-w-96 mb-4">
          {t("userManagement.deleteUser.description1")}
          <b>{user?.childEmail}</b>
          {t("userManagement.deleteUser.description2")}
        </div>
        <div className="flex flex-row justify-between w-full mt-3">
          {processingDelete ? (
            <div className=" flex justify-center items-center w-full">
              <CircularProgress />
            </div>
          ) : (
            <>
              <button
                className="p-2 bg-gray-300 rounded-xl text-lg min-w-32 mx-3"
                onClick={() => {
                  dispatch(hideDeleteModal());
                }}
              >
                {t("userManagement.deleteUser.buttons.cancel")}
              </button>
              <button
                onClick={confirm}
                className="p-2 bg-red-500 text-white rounded-xl text-lg min-w-32 mx-3"
              >
                {t("userManagement.deleteUser.buttons.delete")}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;
