import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PbDimYellow, PbYellow } from "../../../app/colors";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import ReadingsService from "../../../Services/ReadingsService";
import {
  setMapCenter,
  setMapCircles,
  setMapEntries,
  setMapLines,
  setShowZoomControls,
} from "../../UserMap/MapSlice";
import UserMap from "../../UserMap/UserMap";
import geofencesService from "../../../Services/GeofencesService";

//set all the history information for the map to consume
const GeofencesMap = () => {
  const dispatch = useAppDispatch();

  const geofences = useSelector((state: RootState) => {
    return state.geofences.geofences;
  });

  const showCreationHandler = useSelector((state: RootState) => {
    return state.geofencesLayout.showCreationHandler;
  });

  const hiddenGeofences = useSelector((state: RootState) => {
    return state.geofencesLayout.hiddenGeofences;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const drawCircles = () => {
    const circles: {
      center: { lat: number; lng: number };
      radius: number;
      color: string;
      strokeColor: string;
    }[] = [];

    if (!showCreationHandler) {
      geofencesService
        .getUserGeofences(
          geofences,
          loggedUser,
          {
            hiddenFences: hiddenGeofences,
            visible: true,
          }
        )
        .forEach((geofence) => {
          circles.push({
            center: geofence.geometry.coordinates,
            radius: geofence.geometry.radius,
            color: geofence.enabled ? PbDimYellow : "#D8D8D8",
            strokeColor: geofence.enabled ? PbYellow : "#CFCFCF",
          });
        });
    }
    dispatch(setMapCircles(circles));
  };

  const centerBounds = () => {
    ReadingsService.centerOnBounds(readings.map((r) => r.coordinates?.value || {lat: 0, lng: 0}));
    drawCircles();
  };

  useEffect(() => {
    dispatch(setShowZoomControls(true));
    return () => {
      dispatch(setShowZoomControls(false));
    };
  }, [dispatch]);

  useEffect(() => {
    let center: { lat: number; lng: number } | undefined = undefined;
    dispatch(setMapCenter(center));
  }, [dispatch]);

  useEffect(() => {
    const mapData = ReadingsService.generateMapEntries(
      readings,
      userDevices,
      false,
      [],
      undefined
    );
    const mapEntries = mapData.map((md) => {
      return {
        position: md.center,
        compProps: md,
      };
    });
    dispatch(setMapEntries(mapEntries));
  }, [dispatch, readings, userDevices]);

  useEffect(() => {
    const lines: { lat: number; lng: number }[][] = [];

    dispatch(setMapLines(lines));
  }, [dispatch]);

  useEffect(() => {
    const circles: {
      center: { lat: number; lng: number };
      radius: number;
      color: string;
      strokeColor: string;
    }[] = [];

    if (!showCreationHandler) {
      geofencesService
        .getUserGeofences(
          geofences,
          loggedUser,
          {
            hiddenFences: hiddenGeofences,
            visible: true,
          }
        )
        .forEach((geofence) => {
          circles.push({
            center: geofence.geometry.coordinates,
            radius: geofence.geometry.radius,
            color: geofence.enabled ? PbDimYellow : "#D8D8D8",
            strokeColor: geofence.enabled ? PbYellow : "#CFCFCF",
          });
        });
    }
    dispatch(setMapCircles(circles));
  }, [dispatch, geofences, hiddenGeofences, loggedUser, showCreationHandler]);

  return <UserMap onMapLoaded={centerBounds} />;
};

export default GeofencesMap;
