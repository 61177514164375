import { PbNotification } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface getNotificationsResponse {
  status: string;
  notifications: PbNotification[];
}

export async function get_Notifications() {
  try {
    if (mockService.getIsDemo()) {
      const mockedNotifications = await mockService.getMockedNotifications()
      return { data: { notifications: mockedNotifications, status: "Success"},status: 200 };
    }
    const response = await httpClientCore.get<getNotificationsResponse>(
      `/v2/user/notifications`
    );
    return response;
  } catch (err) {
    return null;
  }
}
