import styled from "@emotion/styled";
import React, { useMemo } from "react";
import AppsIcon from "@mui/icons-material/Apps";
import { useAppDispatch } from "../../../../../app/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  PbFontGray,
  PbFontGrayDark,
  PbFontGrayLight,
  PbFontGrayWatermark,
} from "../../../../../app/colors";
import { Grid } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { useTranslation } from "react-i18next";
import {
  setHistoryLayout,
  setSelectedElementId,
  setUserManagementLayout,
  setVirtualFenceLayout,
} from "../../../../home/HomeSlice";
import { thirdLevel } from "../../../../../app/levels";
import AdjustIcon from "@mui/icons-material/Adjust";
import { boxShadows } from "../../../../../app/boxShadows";
import { MenuBox, MenuCloseButton, MenuTitle } from "../MenusStyledComponents";
import { setBottomRightMenu } from "../MenusSlice";
import { healthCheck } from "../../../../../app/CommonSlice";
import ErrorIcon from "@mui/icons-material/Error";
import PersonIcon from "@mui/icons-material/Person";
import { AccessList } from "../../../../../Services/AuthService";

const BoxContainer = styled.div(() => ({
  ...({
    height: "4vw",
    width: "4vw",
    position: "fixed",
    right: "1vw",
    bottom: "1vw",
    zIndex: thirdLevel,
    background: "#FFF",
    transition: "0.5s",
    borderRadius: "999px",
  } as React.CSSProperties),
}));

const AppsIconContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
    background: "#E5E5E5",
  },
  boxShadow: boxShadows.veryHard,
});

const AppsIconButton = styled(AppsIcon)({
  height: "65%",
  width: "65%",
});

const FeaturesContainer = styled.div({
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "5px",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "20px",
  },
});

const HistoryIconButton = styled(HistoryIcon)({
  width: "25%",
  height: "auto",
});

const FencesIconButton = styled(AdjustIcon)({
  width: "25%",
  height: "auto",
});

const MaintenanceIconButton = styled(ErrorIcon)({
  width: "25%",
  height: "auto",
});

const UsersIconButton = styled(PersonIcon)({
  width: "25%",
  height: "auto",
});

const FeatureColumn = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0.5cqi 0",
  color: PbFontGrayLight,
  ":hover": {
    cursor: "pointer",
    color: PbFontGrayDark,
  },
  fontSize: "0.8cqi",
});

const MobileTextSize = styled.div({
  fontSize: "3cqi",
});

const FeaturesManager = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const bottomRightMenu = useSelector(
    (state: RootState) => state.menus.bottomRightMenu
  );

  const showFeaturesBox = useMemo(() => {
    return bottomRightMenu === "features";
  }, [bottomRightMenu]);

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const handleClose = () => {
    dispatch(setBottomRightMenu(""));
  };

  const renderDesktop = () => {
    return (
      <>
        <BoxContainer>
          <AppsIconContainer
            onClick={() => {
              dispatch(setBottomRightMenu(showFeaturesBox ? "" : "features"));
            }}
          >
            <AppsIconButton />
          </AppsIconContainer>
        </BoxContainer>
        {showFeaturesBox ? (
          <MenuBox>
            <MenuCloseButton onClick={handleClose} />
            <MenuTitle>{t("features.title")}</MenuTitle>
            <FeaturesContainer>
              <Grid
                style={{ width: "100%", height: "100%", margin: "0" }}
                container
                spacing={2}
              >
                {!loggedUser?.isEnterprise ||
                loggedUser.enterpriseData?.permissionList?.includes(
                  AccessList.HISTORY_READ
                ) ? (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <FeatureColumn
                      onClick={() => {
                        dispatch(setSelectedElementId(""));
                        dispatch(setHistoryLayout());
                      }}
                    >
                      <HistoryIconButton></HistoryIconButton>
                      {t("featuresLabels.history")}
                    </FeatureColumn>
                  </Grid>
                ) : null}
                {loggedUser &&
                loggedUser.isEnterprise &&
                loggedUser.enterpriseData?.permissionList?.includes(
                  AccessList.GEOFENCES_READ
                ) ? (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <FeatureColumn
                      onClick={() => {
                        dispatch(setSelectedElementId(""));
                        dispatch(setVirtualFenceLayout());
                      }}
                    >
                      <FencesIconButton></FencesIconButton>
                      {t("featuresLabels.fences")}
                    </FeatureColumn>
                  </Grid>
                ) : null}
                {loggedUser &&
                loggedUser.isEnterprise &&
                loggedUser.enterpriseData?.isParent  ? (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <FeatureColumn
                      onClick={() => {
                        dispatch(setUserManagementLayout());
                      }}
                    >
                      <UsersIconButton></UsersIconButton>
                      {t("featuresLabels.users")}
                    </FeatureColumn>
                  </Grid>
                ) : null}
                {process.env.REACT_APP_ENV === "DEV" ? (
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <FeatureColumn
                      onClick={() => {
                        dispatch(healthCheck({ simulateFailure: true }));
                      }}
                    >
                      <MaintenanceIconButton></MaintenanceIconButton>
                      {t("featuresLabels.simulateMaintenance")}
                    </FeatureColumn>
                  </Grid>
                ) : null}
              </Grid>
            </FeaturesContainer>
          </MenuBox>
        ) : null}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Grid
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "0" }}
          container
          spacing={2}
        >
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <FeatureColumn
              onClick={() => {
                dispatch(setSelectedElementId(""));
                dispatch(setHistoryLayout());
              }}
            >
              <HistoryIconButton></HistoryIconButton>
              <MobileTextSize>{t("featuresLabels.history")}</MobileTextSize>
            </FeatureColumn>
          </Grid>
          {loggedUser && loggedUser.isEnterprise ? (
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <FeatureColumn
                onClick={() => {
                  dispatch(setSelectedElementId(""));
                  dispatch(setVirtualFenceLayout());
                }}
              >
                <FencesIconButton></FencesIconButton>
                <MobileTextSize>{t("featuresLabels.fences")}</MobileTextSize>
              </FeatureColumn>
            </Grid>
          ) : null}
        </Grid>
      </>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default FeaturesManager;
