import { googleMapsApiClient } from "../../api";

export async function get_Geocoding(lat: number, lng: number) {
  try {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode(
      {
        location: { lat: lat, lng: lng },
      },
      (r, s) => {
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
