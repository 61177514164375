import styled from "@emotion/styled";
import { List } from "@mui/material";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React from "react";
import MenuItem from "./MenuItem";
import Divider from "@mui/material/Divider";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

interface MenuContentProps {
  sections: {
    title: string;
    devices: {
      data: UserDevice;
      reading: Reading;
    }[];
  }[];
  expanded: boolean;
  userLocation?: {
    lat: number;
    lng: number;
  };
}

const MenuContainer = styled.div({
  paddingLeft: "5px",
  paddingRight: "5px",
});

const SeparatorContainer = styled.div({
  height: "auto",
});

const SectionTitle = styled.div((props: { isMobile: number }) => ({...{
  textAlign: "start",
  color: "#797777",
  fontSize: props.isMobile === 1 ? "3cqi" : "1.1cqi",
  borderBottom: "solid 1px #D5D5D5",
  fontWeight: 800,
} as React.CSSProperties}));

const MenuContent = (props: MenuContentProps) => {
  const isMobileScreen = useAppSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  //FOR VIRTUALIZED-----------------------
  /*const groupsLength = useMemo(() => {
    return props.sections.map((section) => {
      return section.devices.length;
    });
  }, [props.sections]);

  const groups = useMemo(() => {
    return props.sections.map((section) => {
      return section.title;
    });
  }, [props.sections]);

  const allDevicesList = useMemo(() => {
    let allDevices: {
      data: UserDevice;
      reading: Reading;
    }[] = [];
    props.sections.forEach((section) => {
      allDevices = [...allDevices, ...section.devices];
    });
    return allDevices;
  }, [props.sections]);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <PbVirtualGroupedList
        groups={groups}
        groupComponent={(group: any) => {
          return (
            <>
              {props.expanded ? (
                <SeparatorContainer>
                  <SectionTitle isMobile={isMobileScreen ? 1 : 0}>
                    {group}
                  </SectionTitle>
                </SeparatorContainer>
              ) : (
                <Divider />
              )}
            </>
          );
        }}
        groupsLength={groupsLength}
        records={allDevicesList}
        rowComponent={(d: { data: UserDevice; reading: Reading }) => {
          const deviceName = d.data.device_name;
          return (
            <MenuItem
              key={`menu-item-${deviceName}-key`}
              expanded={props.expanded}
              device={d.data}
              reading={d.reading}
            />
          );
        }}
        sortingFn={() => {
          return 1;
        }}
        listStyle={{
          desktop: {
            maxHeight: "100%",
            height: "100%",
          },
        }}
      />
    </div>
  );*/
  //----------------------------------------

  //generate section of menu items for floating menu
  return (
    <div style={{ height: "100%", width: "100%" }}>
      {props.sections.map((section, i) => {
        return (
          <MenuContainer key={`menu-container-${i}`}>
            {props.expanded ? (
              <SeparatorContainer>
                <SectionTitle isMobile={isMobileScreen ? 1 : 0}>
                  {section.title}
                </SectionTitle>
              </SeparatorContainer>
            ) : i !== 0 ? (
              <Divider />
            ) : null}
              <List>
                {section.devices.map((d) => {
                  const deviceName = d.data.name;
                  return (
                    <MenuItem
                      key={`menu-item-${deviceName}-key`}
                      expanded={props.expanded}
                      device={d.data}
                      reading={d.reading}
                    />
                  );
                })}
              </List>         
          </MenuContainer>
        );
      })}
    </div>
  );
};

export default MenuContent;
