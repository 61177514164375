import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Subscription, SubscriptionCheckout } from "pebblebee-sdk-frontend";
import { post_SubscriptionCheckout } from "../app/apiRequests/subscriptions/getSubscriptionCheckout";
import { get_DeviceSubscriptions } from "../app/apiRequests/subscriptions/getSubscriptions";
import i18n from "../i18n/i18n";

export interface SubscriptionsState {
  loadingSubscription: boolean;
  faliedToLoadSubscription: boolean;
  devicesSubscription: Subscription[];
  loadingCheckout: boolean;
  devicesCheckouts: {
    deviceId: string;
    checkout?: SubscriptionCheckout;
  }[];
  loadingDevices: boolean;
  subscriptionsError: string;
}

const initialState: SubscriptionsState = {
  loadingSubscription: false,
  faliedToLoadSubscription: false,
  devicesSubscription: [],
  loadingCheckout: false,
  devicesCheckouts: [],
  loadingDevices: false,
  subscriptionsError: "",
};

export const getDeviceSubscriptions = createAsyncThunk(
  "subscriptions/subscriptions",
  async ({ mac }: { mac: string }, thunkApi) => {
    try {
      const response = await get_DeviceSubscriptions(mac);
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceSubscriotion.default")
        );
      }
      if (response.data.status !== "success") {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceSubscriotion.default")
        );
      }

      const sub = response.data.subscriptions?.find(
        (s) => s.deviceId.toLocaleLowerCase() === mac.toLocaleLowerCase()
      );
      return sub;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getDeviceSubscriotion.default")
      );
    }
  }
);

export const postSubscriptionCheckout = createAsyncThunk(
  "subscriptions/subscriptions-checkout",
  async ({ mac, plan }: { mac: string; plan: string }, thunkApi) => {
    try {
      const response = await post_SubscriptionCheckout(mac, plan);
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceSubscriotion.default")
        );
      }
      if (response.data.status !== "success") {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceSubscriotion.default")
        );
      }
      const checkout = {
        deviceId: mac,
        checkout: response.data,
      };
      return checkout;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getDeviceSubscriotion.default")
      );
    }
  }
);

export const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    clearSubscriptions: (state) => {
      state.devicesSubscription = [];
    },
    clearCheckout: (state) => {
      state.devicesCheckouts = [];
    },
    setError: (state, action: PayloadAction<string>) => {
      state.subscriptionsError = action.payload;
    },
    resetSubscriptionsState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      //get device subscription
      .addCase(getDeviceSubscriptions.pending, (state) => {
        state.loadingSubscription = true;
      })
      .addCase(getDeviceSubscriptions.fulfilled, (state, action) => {
        state.loadingSubscription = false;
        if (action.payload) {
          const exists = state.devicesSubscription.find((s) => {
            return s.deviceId === action.payload?.deviceId;
          });
          if (!exists) {
            state.devicesSubscription.push(action.payload);
          } else {
            const index = state.devicesSubscription.indexOf(exists);
            if (index !== -1) {
              state.devicesSubscription[index] = action.payload;
            }
          }
        }
      })
      .addCase(getDeviceSubscriptions.rejected, (state, action) => {
        state.loadingSubscription = false;
        state.faliedToLoadSubscription = true;
        state.subscriptionsError = action.payload as string;
      })
      //get device checkout
      .addCase(postSubscriptionCheckout.pending, (state) => {
        state.loadingCheckout = true;
      })
      .addCase(postSubscriptionCheckout.fulfilled, (state, action) => {
        state.loadingCheckout = false;
        if (action.payload) {
          const exists = state.devicesCheckouts.find((s) => {
            return s.deviceId === action.payload?.deviceId;
          });
          if (!exists) {
            state.devicesCheckouts.push(action.payload);
          } else {
            const index = state.devicesCheckouts.indexOf(exists);
            if (index !== -1) {
              state.devicesCheckouts[index] = action.payload;
            }
          }
        }
      })
      .addCase(postSubscriptionCheckout.rejected, (state, action) => {
        state.loadingCheckout = false;
        state.subscriptionsError = action.payload as string;
      });
  },
});

export const { clearCheckout, clearSubscriptions, setError, resetSubscriptionsState } =
  SubscriptionsSlice.actions;

export default SubscriptionsSlice.reducer;
