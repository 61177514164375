import styled from "@emotion/styled";
import React, { useCallback, useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
  addTimeout,
  clearTimeouts,
  setRefreshData,
} from "../HomeSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { getUser } from "../../login/LoginSlice";
import { CircularProgress } from "@mui/material";
import { getGeofences } from "../../../dataSlices/GeofencesSlice";
import { getNotifications } from "../../../dataSlices/NotificationsSlice";
import { getDevicesGroups, getUserDevices, mapGroupsToDevices } from "../../../dataSlices/DevicesSlice";
import { getDevicesReadings } from "../../../dataSlices/ReadingsSlice";
import { healthCheck } from "../../../app/CommonSlice";
const LoaderContainer = styled.div({
  position: "fixed",
  bottom: "25px",
  right: "30%",
  width: "40%",
});

const RefreshAlert = () => {
  const dispatch = useAppDispatch();

  const refreshMap = useSelector((state: RootState) => {
    return state.home.refreshData;
  });

  const selecetedFeature = useSelector((state: RootState) => {
    return state.home.selecetedFeature;
  });

  const refreshData = useCallback(async () => {
    await dispatch(healthCheck({simulateFailure: false}))
    if (selecetedFeature === "main") {
      await dispatch(getUserDevices());
      await dispatch(getDevicesGroups());
      await dispatch(getDevicesReadings());
      await dispatch(getNotifications());
      await dispatch(getGeofences());

      dispatch(mapGroupsToDevices())
    } else if (selecetedFeature === "virtualFences") {
      dispatch(getGeofences());
    }
  }, [dispatch, selecetedFeature])

  useEffect(() => {
    dispatch(clearTimeouts());
    if (refreshMap) {
      dispatch(getUser());
      refreshData();
      dispatch(setRefreshData(false));
    } else {
      const t = setTimeout(() => {
        dispatch(setRefreshData(true));
      }, 180000);
      dispatch(addTimeout(t));
    }
  }, [dispatch, refreshData, refreshMap]);

  return (
    <>
      {refreshMap ? (
        <LoaderContainer>
          <CircularProgress color="warning" />
        </LoaderContainer>
      ) : null}
    </>
  );
};

export default RefreshAlert;
