import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { scrollIntoView } from "seamless-scroll-polyfill";

export type Features = "history" | "fences" | "";

export interface MainLayoutState {
  selectedClusterId: string;
  showDeviceDetails: boolean;
  showSubData: boolean;
  loadingAddressInfo: boolean;
  devicesAddressInfo: {
    deviceId: string;
    addressInfo: string;
  }[];
  mapTypeId: string;
  hideShared: boolean;
  hiddenGroups: string[];
  hiddenFences: string[];
  feature: Features;
  initializing: boolean;
  showClusters: boolean;
  tabSelected: number;
  //move to device data slice?
  showTrackingModesBox: boolean;
  showTrackingModesInfo: boolean;
}

const initialState: MainLayoutState = {
  selectedClusterId: "",
  showDeviceDetails: false,
  showSubData: false,
  feature: "",
  devicesAddressInfo: [],
  hiddenFences: [],
  hiddenGroups: [],
  hideShared: false,
  loadingAddressInfo: false,
  mapTypeId: "roadmap",
  initializing: false,
  showClusters: false,
  tabSelected: 0,
  showTrackingModesBox: false,
  showTrackingModesInfo: false
};

export const MainLayoutSlice = createSlice({
  name: "mainLayout",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    itemSelected: (state, action: PayloadAction<string>) => {
      state.showSubData = false;
      state.showDeviceDetails = true;
      if (document && document.getElementById(`menu-item-${action.payload}`)) {
        scrollIntoView(
          document.getElementById(`menu-item-${action.payload}`) as HTMLElement,
          {
            behavior: "smooth",
            block: "center",
            inline: "center",
          }
        );
      }
    },
    setMapToRoadmap: (state) => {
      state.mapTypeId = "roadmap";
    },
    setMapToSatellite: (state) => {
      state.mapTypeId = "satellite";
    },
    setHideShared: (state, action: PayloadAction<boolean>) => {
      state.hideShared = action.payload;
    },
    setHiddenGroups: (state, action: PayloadAction<string[]>) => {
      state.hiddenGroups = action.payload;
    },
    setHiddenFences: (state, action: PayloadAction<string[]>) => {
      state.hiddenFences = action.payload;
    },
    setSelectedClusterId: (state, action: PayloadAction<string>) => {
      state.selectedClusterId = action.payload;
      state.showSubData = false;
    },
    closeCluster: (state, action: PayloadAction<string>) => {
      state.selectedClusterId = "";
    },
    setShowDeviceDetails: (state, action: PayloadAction<boolean>) => {
      state.showDeviceDetails = action.payload;
    },
    setShowSubData: (state, action: PayloadAction<boolean>) => {
      state.showSubData = action.payload;
    },
    setFeature: (state, action: PayloadAction<Features>) => {
      state.feature = action.payload;
    },
    clearAddresses: (state) => {
      state.devicesAddressInfo = [];
    },
    setInitializing: (state, action: PayloadAction<boolean>) => {
      state.initializing = action.payload;
    },
    setShowClusters: (state, action: PayloadAction<boolean>) => {
      state.showClusters = action.payload;
    },
    setTabSelected: (state, action: PayloadAction<number>) => {
      state.tabSelected = action.payload;
    },
    setShowTrackingModesBox: (state, action: PayloadAction<boolean>) => {
      state.showTrackingModesBox = action.payload;
    },
    setShowTrackingModesInfo: (state, action: PayloadAction<boolean>) => {
      state.showTrackingModesInfo = action.payload;
    },
    resetSlice: (state, _) => {
      state = initialState
    }
  },
  extraReducers: (builder) => {},
});

export const {
  itemSelected,
  setHiddenFences,
  setHiddenGroups,
  setHideShared,
  setMapToRoadmap,
  setMapToSatellite,
  setFeature,
  clearAddresses,
  closeCluster,
  setSelectedClusterId,
  setShowDeviceDetails,
  setShowSubData,
  setInitializing,
  setShowClusters,
  setTabSelected,
  setShowTrackingModesBox,
  setShowTrackingModesInfo,
  resetSlice
} = MainLayoutSlice.actions;

export default MainLayoutSlice.reducer;
