import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { useTranslation } from "react-i18next";
import {
  addNewUser,
  resetUserManagementSlice,
  setShowActivity,
  setShowUserForm,
} from "./UserManagementSlice";
import { setMainLayout } from "../home/HomeSlice";
import NewEnterpriseUserForm from "./NewEnterpriseUserForm";
import EnterpriseUserTable from "./EnterpriseUsersTable";
import DeleteUserModal from "./DeleteUserModal";
import ActiviyTable from "./ActivityTable";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const UserManagement = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showUserForm = useAppSelector(
    (state: RootState) => state.userManagement.showUserForm
  );

  const showActivity = useAppSelector(
    (state: RootState) => state.userManagement.showActivity
  );

  const showDeleteModal = useAppSelector(
    (state: RootState) => state.userManagement.deleteUserModal.show
  );

  const [selectedTable, setSelectedTable] = useState<"users" | "activity">(
    "users"
  );

  useEffect(() => {
    dispatch(resetUserManagementSlice());

    return (() => {
      dispatch(resetUserManagementSlice());
    })
  }, [dispatch]);

  const handleTableChange = (
    event: React.MouseEvent<HTMLElement>,
    selection: string | null
  ) => {
    if (selection !== "users" && selection !== "activity") return;

    if (selection === "users") {
      dispatch(setShowActivity(false));
    } else {
      dispatch(setShowActivity(true));
    }
    setSelectedTable(selection);
  };

  return (
    <div
      id="user-management-container"
      className="fixed top-0 left-0 w-full h-full z-1000 flex justify-center items-center"
    >
      <div
        id="user-management-bg"
        className="absolute top-0 left-0 w-screen h-screen bg-gray-800 opacity-55"
      />
      <div className={`absolute z-3000 flex justify-between w-90% h-5/6`}>
        <div
          className={`z-3000 flex h-full p-5 ${
            showUserForm ? "w-70%" : "w-full"
          } rounded-lg bg-white flex-col transition-all duration-200 ease-in-out`}
        >
          <div className="text-3xl w-full text-left px-5 mt-3">
            {t("userManagement.title")}
          </div>
          <div className="text-xl w-full text-left px-5 mt-3">
            {t("userManagement.description")}
          </div>
          <div
            id="button-row"
            className="flex w- justify-end items-center px-5 my-4"
          >
            <ToggleButtonGroup
              value={selectedTable}
              exclusive
              onChange={handleTableChange}
              aria-label="text alignment"
            >
              <ToggleButton value="users" aria-label="left aligned">
                {t("userManagement.usersTable.buttons.viewUser")}
              </ToggleButton>
              <ToggleButton value="activity" aria-label="centered">
                {t("userManagement.usersTable.buttons.viewActivity")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          {showActivity ? <ActiviyTable /> : <EnterpriseUserTable />}
          <div
            id="button-row"
            className="flex justify-end items-center px-5 mt-2"
          >
            <button
              onClick={() => {
                dispatch(setShowUserForm(false));
                dispatch(setMainLayout());
              }}
              className="p-2 bg-pbGrey rounded-xl text-lg min-w-32 mx-3"
            >
              {t("userManagement.usersTable.buttons.back")}
            </button>
            <button
              onClick={() => {
                dispatch(addNewUser());
              }}
              className="p-2 bg-pbYellow rounded-xl text-lg min-w-32"
            >
              {t("userManagement.usersTable.buttons.newUser")}
            </button>
          </div>
        </div>
        {showUserForm ? <NewEnterpriseUserForm /> : null}
      </div>
      {showDeleteModal ? <DeleteUserModal /> : null}
    </div>
  );
};

export default UserManagement;
