import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import styled from "@emotion/styled";
import { setShowSearchInput } from "../../../mainLayout/components/menus/MenusSlice";
import { setSelectedElementId } from "../../../home/HomeSlice";
import { RootState } from "../../../../app/store";

const LocationIcon = styled.div({
  width: "0.8cqi",
  height: "0.8cqi",
  background: "#2b75ed",
  borderRadius: "999px",
  border: "#FFFFFF solid 2px",
});

const LocationIconMobile = styled.div({
  width: "4cqi",
  height: "4cqi",
  background: "#2b75ed",
  borderRadius: "999px",
  border: "#FFFFFF solid 2px",
});

const UserLocationMarker = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const location = useAppSelector((state: RootState) => {
    return state.common.location
  })

  const setLocationAsSelected = () => {
    dispatch(setShowSearchInput(false));
    if(window.gMap && location) {
      const userLocation = new google.maps.LatLngBounds(location);
      window.gMap.fitBounds(userLocation)
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <Tooltip title="My location">
          <IconButton style={{ zIndex: 2 }} onClick={setLocationAsSelected}>
            <LocationIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Tooltip title="My location">
          <IconButton style={{ zIndex: 2 }} onClick={setLocationAsSelected}>
            <LocationIconMobile />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default UserLocationMarker;
