import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get_UserDevices } from "../../app/apiRequests/core/getDevices";
import { post_inquiry } from "../../app/apiRequests/core/inquiry";
import { get_DevicesReadings } from "../../app/apiRequests/platform/getReadings";

import i18n from "../../i18n/i18n";
import messagesService from "../../Services/MessagesService";
import { InquiryData } from "../mainLayout/components/menus/ContactSalesWidget/ContactSalesWidget";

export type UserDashboardFeatures = "main" | "history" | "virtualFences" | "userManagement";

export interface HomeState {
  selectedElementId: string;
  refreshData: boolean;
  timeouts: NodeJS.Timeout[];
  selectionTrigger: number;
  selecetedFeature: UserDashboardFeatures;
  userInteracted: boolean;
  sendingInquiry: boolean;
  inquirySent: boolean;
}

const initialState: HomeState = {
  selectedElementId: "",
  refreshData: false,
  timeouts: [],
  selectionTrigger: 0,
  selecetedFeature: "main",
  userInteracted: false,
  sendingInquiry: false,
  inquirySent: false,
};

export const getBackgroundRefreshData = createAsyncThunk(
  "home/getBackgroundRefreshData",
  async (_, thunkApi) => {
    try {
      const userDevicesResponse = await get_UserDevices();
      if (!userDevicesResponse || userDevicesResponse.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getUserDevices.default")
        );
      }
      const readingsResponse = await get_DevicesReadings();
      if (!readingsResponse || readingsResponse.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceReadings.default")
        );
      }
      return {
        devices: userDevicesResponse.data.devices,
        readings: readingsResponse.data.devices,
      };
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getDeviceReadings.default")
      );
    }
  }
);

export const salesInquiry = createAsyncThunk(
  "home/salesInquiry",
  async (data: InquiryData, thunkApi) => {
    try {
      const response = await post_inquiry(data);

      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(i18n.t("apiErrors.inquiry.default"));
      }
      return;
    } catch (err) {
      return thunkApi.rejectWithValue(i18n.t("apiErrors.inquiry.default"));
    }
  }
);

export const HomeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectedElementId: (state, action: PayloadAction<string>) => {
      state.userInteracted = true;
      state.selectedElementId = action.payload;
      state.selectionTrigger = Math.random();
    },
    setRefreshData: (state, action: PayloadAction<boolean>) => {
      state.refreshData = action.payload;
    },
    addTimeout: (state, action: PayloadAction<NodeJS.Timeout>) => {
      state.timeouts.push(action.payload);
    },
    clearTimeouts: (state) => {
      state.timeouts.forEach((t) => {
        clearTimeout(t);
      });
      state.timeouts = [];
    },
    setTrigger: (state, action: PayloadAction<number>) => {
      state.selectionTrigger = action.payload;
    },
    setMainLayout: (state) => {
      state.selecetedFeature = "main";
    },
    setHistoryLayout: (state) => {
      state.selecetedFeature = "history";
    },
    setVirtualFenceLayout: (state) => {
      state.selecetedFeature = "virtualFences";
    },
    setUserManagementLayout: (state) => {
      state.selecetedFeature = "userManagement";
    },
    resetHomeSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //send inquiry
      .addCase(salesInquiry.pending, (state) => {
        state.sendingInquiry = true;
      })
      .addCase(salesInquiry.fulfilled, (state, action) => {
        state.sendingInquiry = false;
        state.inquirySent = true;
      })
      .addCase(salesInquiry.rejected, (state, action) => {
        state.sendingInquiry = false;
        messagesService.notififyError(action.type, action.payload as string);
      });
  },
});

export const {
  setSelectedElementId,
  setRefreshData,
  addTimeout,
  clearTimeouts,
  setTrigger,
  setHistoryLayout,
  setMainLayout,
  setVirtualFenceLayout,
  setUserManagementLayout,
  resetHomeSlice,
} = HomeSlice.actions;

export default HomeSlice.reducer;
