import mockService from "../../../../Services/MockService";
import EnterpriseLog from "../../../../models/enterprise/EnterpriseLogs";
import { httpClientCore } from "../../../api";

interface GetEnterpriseLogsResponse {
  logs: EnterpriseLog[]
}

export async function get_EnterpriseLogs() {
  try {
    if (mockService.getIsDemo()) {
      return {
        data: { logs: mockService.getMockedEnterpriseLogs() },
        status: 200,
      };
    }
    const response = await httpClientCore.get<GetEnterpriseLogsResponse>(
      `/v2/enterprise/logs`
    );
    return response;
  } catch (err) {
    return null;
  }
}
