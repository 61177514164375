import React, { useEffect, useRef } from "react";
import { Autocomplete, createTheme, Divider, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import PbDeviceAutocompleteItem from "../items/PbDevicesAutocompleteItem";
import PbDevicesAutocompleteViewProps from "./PbDevicesAutocompleteViewProps";
import { boxShadows } from "../../../app/boxShadows";
import styled from "@emotion/styled";
import { PbFontGray } from "../../../app/colors";
import { ClickAwayListener } from "@mui/base";

let theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::placeholder": {
            fontSize: "0.7cqi",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          boxShadow: boxShadows.medium,
          borderRadius: "999px !important",
          ".MuiAutocomplete-root": {
            borderRadius: "999px",
          },
          ".MuiAutocomplete-inputRoot": {
            height: "100%",
            padding: "0 !important",
            hover: {
              boder: "solid 1px",
            },
            borderRadius: "999px",
          },
          ".MuiAutocomplete-inputRoot::before": {
            border: "none !important",
          },
          ".MuiAutocomplete-inputRoot::after": {
            border: "none !important",
          },
          ".MuiAutocomplete-endAdornment": {
            top: "0.75cqi",
            right: "1cqi",
            zIndex: 999999999,
            "& button": {
              "& svg": {
                width: "1.5cqi",
                height: "1.5cqi",
              },
              ":hover": {
                cursor: "pointer",
              },
            },
          },

          "& input": {
            height: "100%",
            padding: "0 1cqi !important",
            zIndex: 999999999,
            fontSize: "0.9cqi",
            borderRadius: "999px",
            ":hover": {
              border: "solid 1px #00000015",
            },
            ":focus": {
              border: "solid 1px #00000015",
            },
            "&::placeholder": {
              fontSize: "1.1cqi !important",
            },
          },
          "& fieldset": {
            border: "none",
            borderRadius: "999px",
          },
        },
        popper: {
          ".MuiAutocomplete-paper": {
            borderRadius: "1.5vw",
            marginTop: "1cqi",
            boxShadow: boxShadows.hard,
          },
        },
        groupLabel: {
          fontSize: "0.9cqi",
          padding: "0.4cqi 0.8cqi",
          margin: "0 auto",
          height: "fit-content",
        },
        listbox: {
          borderRadius: "20px",
          background: "#FFF",
          "::-webkit-scrollbar": {
            width: "0px",
          },
        },
      },
    },
  },
});

const PbGroupLabel = styled.div({
  padding: "0.2cqi 0.6cqi",
  color: PbFontGray,
  fontSize: "1.2cqi",
  fontWeight: 500,
});

const PbDevicesAutocompleteDesktopView = (
  props: PbDevicesAutocompleteViewProps
) => {
  const itemOnClick = props.itemOnClick;
  const inputRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (props.open && props.open === true) {
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <ClickAwayListener
          onClickAway={() => {
            if (props.onClickAway) {
              props.onClickAway();
            }
          }}
        >
          <Autocomplete
            id="devices-search"
            openOnFocus={props.openOnFocus}
            open={props.open}
            options={props.options}
            groupBy={(op) => op.group}
            getOptionLabel={(reading) => reading?.device?.name || ""}
            popupIcon={props.hideAdorment ? null : undefined}
            renderOption={(props, option, { selected }) => (
              <PbDeviceAutocompleteItem
                device={option.device}
                reading={option.reading}
                itemOnClick={itemOnClick}
              />
            )}
            onClose={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
            filterOptions={props.filterOptions}
            fullWidth={true}
            style={props.styles}
            renderInput={(params) => (
              <TextField
                inputRef={inputRef}
                style={props.textFieldStyle}
                variant="standard"
                {...params}
                placeholder={`Searching out of ${props.options.length} devices`}
              />
            )}
            renderGroup={(params) => {
              return (
                <li>
                  <PbGroupLabel>{params.group}</PbGroupLabel>
                  <Divider variant="middle" />
                  <>{params.children}</>
                </li>
              );
            }}
          />
        </ClickAwayListener>
      </ThemeProvider>
    </>
  );
};

export default PbDevicesAutocompleteDesktopView;
