import { Geofence } from "pebblebee-sdk-frontend";
import mockService from "../../../../Services/MockService";
import { plataformClient } from "../../../api";

interface postGeofenceResponse extends Geofence {
  status: string;
  error?: {
    message: string;
    code: string;
  };
}

export async function post_Geofence_Webhook(
  name: string,
  center: {
    lat: number;
    lng: number;
  },
  radius: number,
  devices: string[]
) {
  try {
    if (mockService.getIsDemo()) {
      await mockService.addMockedGeofence({ name, center, radius, devices });
      return { data: { status: "success", error: undefined }, status: 200 };
    }
    const webhookBody = {
      url: `${process.env.REACT_APP_CORE_API_HOST}/v1/notifications`,
      name: `${name}-geofence-Webhook`,
      description: `geofence Webhook for ${name} geofence`,
      geofence: {
        name: name,
        shape: "circle",
        geometry: {
          coordinates: center,
          radius: radius,
        },
        type: "deviceLevel",
        deviceId: devices,
        enabled: true,
      },
      events: ["geofence.entered", "geofence.exited"],
    };

    const response = await plataformClient.post<postGeofenceResponse>(
      `/webhooks`,
      webhookBody
    );
    return response;
  } catch (err) {
    return null;
  }
}
