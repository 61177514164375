import { InquiryData } from "../../../features/mainLayout/components/menus/ContactSalesWidget/ContactSalesWidget";
import { httpClientCore } from "../../api";

interface getNotificationsResponse {
  status: string;
}

export async function post_inquiry(contactData: InquiryData) {
  try {
    const response = await httpClientCore.post<getNotificationsResponse>(
      `/v2/sales/inquiry`,
      {
        ...contactData
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
