export const trackingModes: { [key: string]: string } = {
  finding_mode: "Finding Mode",
  area_tracking: "Adaptive tracking",
  standby: "Standby",
  emergency: "Emergency",
  dynamic: "Dynamic",
  low_power: "Low power",
  ble_only: "Bluetooth only",
  motion_triggered: "Motion triggered",
  lte_only: "LTE only",
  enhanced_mode: "Enhanced mode",
};

export const applyableTrackingModes: { [key: string]: string } = {
  finding_mode: "Finding Mode",
  area_tracking: "Adaptive tracking",
  motion_triggered: "Motion triggered",
  standby: "Standby",
  ble_only: "Bluetooth only",
};

class TrackingModesService {
  getTrackingMode(modeName: string): string {
    return trackingModes[modeName] || "-";
  }
}

const trackingModesService = new TrackingModesService();

export default trackingModesService;
