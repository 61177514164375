import { Geofence } from "pebblebee-sdk-frontend";
import { LoggedUser } from "./AuthService";

class GeofencesServices {
  userValidation(geofence: Geofence, user?: LoggedUser) {
    return (
      user?.isEnterprise &&
      (user.enterpriseData?.isChild || geofence.userId === user?.id)
    );
  }

  enabledValidation(geofence: Geofence, enabled: boolean) {
    return geofence.enabled === enabled;
  }

  hiddenValidation(
    geofence: Geofence,
    hiddenFences: string[],
    visible: boolean
  ) {
    const isHidden = hiddenFences.some((f) => f === geofence.name);
    return visible ? !isHidden : isHidden;
  }

  getUserGeofences(
    geofences: Geofence[],
    user?: LoggedUser,
    hiddenSearch?: { hiddenFences: string[]; visible: boolean },
    enabled?: boolean
  ) {
    return geofences.filter((g) => {
      const userValidation = this.userValidation(g, user);
      const hiddenValidation =
        hiddenSearch !== undefined
          ? this.hiddenValidation(
              g,
              hiddenSearch.hiddenFences,
              hiddenSearch.visible
            )
          : true;
      const enabledValidation =
        enabled !== undefined ? this.enabledValidation(g, enabled) : true;

      return userValidation && hiddenValidation && enabledValidation;
    });
  }
}
const geofencesService = new GeofencesServices();
export default geofencesService;
