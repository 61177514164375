import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

export async function get_healthCheck({simulateFailure }: {simulateFailure: boolean}) {
  try {
    if (mockService.getIsDemo()) {
      return { data: { user: mockService.getMockedUser() }, status: 200 };
    }
    const response = await httpClientCore.get(
      `/v2/health${simulateFailure ? "?simulateFailure=true" : ""}`
    );
    return response;
  } catch (err) {
    return null;
  }
}
