import styled from "@emotion/styled";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import FenceIcon from "@mui/icons-material/Fence";
import { getFormatedDayhour } from "pebblebee-sdk-frontend";
import {
  PbFontGray,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../../app/colors";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppDispatch } from "../../../../../app/hooks";
import { setNotificationsVolume, setShowNotificationsBox } from "../MenusSlice";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { fourthLevel } from "../../../../../app/levels";
import {
  deleteAllNotification,
  getNotifications,
} from "../../../../../dataSlices/NotificationsSlice";
import NotificationItem from "./NotificationItem";
import { boxShadows } from "../../../../../app/boxShadows";

const NotificationsBoxContainer = styled.div({
  position: "fixed",
  top: "4vw",
  right: "1vw",
  zIndex: fourthLevel,
  width: "25vw",
  height: "25vw",
  background: "#FFFFFF",
  borderRadius: "1vw",
  boxShadow: boxShadows.hard,
});

const NotificationsTitle = styled.div({
  color: PbFontGray,
  textAlign: "left",
  fontSize: "1.3cqi",
  marginTop: "0.75vw",
  marginLeft: "0.75vw",
  height: "2vw",
});

const NotificationsList = styled(List)({
  minHeight: "16vw",
  maxHeight: "16vw",
  overflow: "auto",
  padding: "0px",
  boxShadow: "1px 1px 1px 1px #EEE inset",
  "::-webkit-scrollbar": {
    width: "0.3vw",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "1vw",
  },
});

const CloseButton = styled(CancelIcon)({
  position: "absolute",
  top: "0.5vw",
  right: "0.5vw",
  width: "1.25vw",
  height: "1.25vw",
  color: PbFontGray,
  ":hover": {
    cursor: "pointer",
  },
});

const NoNotificationsContainer = styled.div({
  height: "15vw",
  minHeight: "15vw",
  maxHeight: "15vw",
  overflow: "auto",
  padding: "0px",
  boxShadow: "inset 1px 1px 3px #EEE",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: PbFontGrayWatermark,
  fontSize: "1.4cqi",
});

const TimeLable = styled.div({
  fontSize: "0.7cqi",
  color: "#787878",
  position: "absolute",
  right: "0.75vw",
  top: "0.5vw",
});

const Footer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: PbFontGrayWatermark,
  width: "100%",
  height: "3vw",
  textAlign: "center",
});

const SlideContainer = styled(Stack)({
  mb: 1,
  height: "2vw",
  maxHeight: "2vw",
  width: "90%",
  margin: "0 auto 1vw auto",
});

const PbSlider = styled(Slider)({
  color: PbYellow,
  "& .MuiSlider-thumb": {
    height: "1vw",
    width: "1vw",
    backgroundColor: PbYellow,
  },
  "& .MuiSlider-rail": {
    height: "0.25vw",
  },
  "& .MuiSlider-track": {
    height: "0.25vw",
  },
});

const ClearNotificationsButton = styled.span({
  fontSize: "1cqi",
  ":hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const PbLoader = styled(CircularProgress)({
  color: PbYellow,
});

const VolumeUpIcon = styled(VolumeUp)({
  height: "1.5cqi",
  width: "1.5cqi",
});

const VolumeDownIcon = styled(VolumeDown)({
  height: "1.5cqi",
  width: "1.5cqi",
});

//Mobile

const NotificationsBoxContainerMobile = styled.div({
  position: "fixed",
  top: "14vw",
  right: "2vw",
  zIndex: fourthLevel,
  width: "60vw",
  background: "#FFFFFF",
  borderRadius: "1vw",
});

const NotificationsTitleMobile = styled.div({
  color: PbFontGray,
  textAlign: "left",
  fontSize: "4cqi",
  marginTop: "0.75vw",
  marginLeft: "0.75vw",
});

const NotificationsListMobile = styled(List)({
  minHeight: "16vw",
  maxHeight: "40vw",
  overflow: "auto",
  padding: "0px",
  boxShadow: "1px 1px 1px 1px #EEE inset",
  "::-webkit-scrollbar": {
    width: "0.3vw",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "1vw",
  },
});

const CloseButtonMobile = styled(CancelIcon)({
  position: "absolute",
  top: "1cqi",
  right: "1cqi",
  width: "4cqi",
  height: "4cqi",
  color: PbFontGray,
  ":hover": {
    cursor: "pointer",
  },
});

const NoNotificationsContainerMobile = styled.div({
  height: "15vw",
  minHeight: "15vw",
  maxHeight: "15vw",
  overflow: "auto",
  padding: "0px",
  boxShadow: "inset 1px 1px 3px #EEE",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: PbFontGrayWatermark,
  fontSize: "3cqi",
});

const FooterMobile = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: PbFontGrayWatermark,
  width: "100%",
  textAlign: "center",
  margin: "1cqi 0",
});

const SlideContainerMobile = styled(Stack)({
  mb: 1,
  height: "3cqi",
  maxHeight: "3cqi",
  width: "90%",
  margin: "1.5cqi auto 1.5cqi auto",
});

const PbSliderMobile = styled(Slider)({
  color: PbYellow,
  "& .MuiSlider-thumb": {
    height: "1.5cqi",
    width: "1.5cqi",
    backgroundColor: PbYellow,
  },
  "& .MuiSlider-rail": {
    height: "0.5cqi",
  },
  "& .MuiSlider-track": {
    height: "0.5cqi",
  },
});

const ClearNotificationsButtonMobile = styled.span({
  fontSize: "3cqi",
  margin: "1cqi 0",
  ":hover": {
    textDecoration: "underline",
    cursor: "pointer",
  },
});

const VolumeUpIconMobile = styled(VolumeUp)({
  height: "3cqi",
  width: "3cqi",
});

const VolumeDownIconMobile = styled(VolumeDown)({
  height: "3cqi",
  width: "3cqi",
});

const NotificationsBox = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const notifications = useSelector((state: RootState) => {
    return state.notifications.notifications;
  });

  const notificationsVolume = useSelector((state: RootState) => {
    return state.menus.notificationsVolume;
  });

  const loadingNotifications = useSelector((state: RootState) => {
    return state.notifications.loadingNotifications;
  });

  const isMobile = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const formatedTimeLabel = (createdAt?: string) => {
    if (!createdAt) return;
    const time = new Date(createdAt).getTime();
    return getFormatedDayhour(time);
  };

  const handleClose = () => {
    dispatch(setShowNotificationsBox(false));
  };

  const clearAllNotifications = async () => {
    await dispatch(deleteAllNotification());
  };

  const changeNotificationsVolume = (
    event: Event,
    newValue: number | number[]
  ) => {
    dispatch(setNotificationsVolume(newValue as number));
  };

  const renderDesktop = () => {
    return (
      <>
        <NotificationsBoxContainer>
          <NotificationsTitle>{t("notifications.title")}</NotificationsTitle>
          <CloseButton onClick={handleClose} />
          <SlideContainer spacing={2} direction="row" alignItems="center">
            <VolumeDownIcon />
            <PbSlider
              aria-label="Volume"
              value={notificationsVolume}
              onChange={changeNotificationsVolume}
            />
            <VolumeUpIcon />
          </SlideContainer>
          {loadingNotifications ? (
            <PbLoader />
          ) : notifications.length > 0 ? (
            <>
              <NotificationsList>
                {notifications.map((n, index) => {
                  return (
                    <NotificationItem
                      {...n}
                      key={`notification-item-${index}`}
                    />
                  );
                })}
              </NotificationsList>
              <Footer>
                <ClearNotificationsButton onClick={clearAllNotifications}>
                  {t("notifications.footerButton")}
                </ClearNotificationsButton>
              </Footer>
            </>
          ) : (
            <NoNotificationsContainer>
              {t("notifications.noNotifications")}
            </NoNotificationsContainer>
          )}
        </NotificationsBoxContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <NotificationsBoxContainerMobile>
          <NotificationsTitleMobile>
            {t("notifications.title")}
          </NotificationsTitleMobile>
          <CloseButtonMobile onClick={handleClose} />
          <SlideContainerMobile spacing={2} direction="row" alignItems="center">
            <VolumeDownIconMobile />
            <PbSliderMobile
              aria-label="Volume"
              value={notificationsVolume}
              onChange={changeNotificationsVolume}
            />
            <VolumeUpIconMobile />
          </SlideContainerMobile>
          {loadingNotifications ? (
            <PbLoader />
          ) : notifications.length > 0 ? (
            <NotificationsListMobile>
              {notifications.map((n, index) => {
                return (
                  <NotificationItem {...n} key={`notification-item-${index}`} />
                );
              })}
            </NotificationsListMobile>
          ) : (
            <NoNotificationsContainerMobile>
              {t("notifications.noNotifications")}
            </NoNotificationsContainerMobile>
          )}
          <FooterMobile>
            <ClearNotificationsButtonMobile onClick={clearAllNotifications}>
              {t("notifications.footerButton")}
            </ClearNotificationsButtonMobile>
          </FooterMobile>
        </NotificationsBoxContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default NotificationsBox;
