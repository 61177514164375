import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import readingsService from "../../../Services/ReadingsService";
import ReadingsService, { MapReading } from "../../../Services/ReadingsService";
import {
  setLoadingMapData,
  setMapCenter,
  setMapCircles,
  setMapEntries,
} from "../../UserMap/MapSlice";
import UserMap from "../../UserMap/UserMap";
import geofencesService from "../../../Services/GeofencesService";

//set all the history information for the map to consume
const OverviewMap = () => {
  const dispatch = useAppDispatch();

  const initializing = useSelector((state: RootState) => {
    return state.mainLayout.initializing;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const geofences = useSelector((state: RootState) => {
    return state.geofences.geofences;
  });

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const hideShared = useSelector((state: RootState) => {
    return state.mainLayout.hideShared;
  });

  const hiddenGroups = useSelector((state: RootState) => {
    return state.mainLayout.hiddenGroups;
  });

  const hiddenFences = useSelector((state: RootState) => {
    return state.mainLayout.hiddenFences;
  });

  const showClusters = useSelector((state: RootState) => {
    return state.mainLayout.showClusters;
  });

  const trigger = useSelector((state: RootState) => {
    return state.home.selectionTrigger;
  });

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const homeUserInteracted = useSelector((state: RootState) => {
    return state.home.userInteracted;
  });

  const menusUserInteracted = useSelector((state: RootState) => {
    return state.menus.useInteracted;
  });

  useEffect(() => {
    dispatch(setLoadingMapData(initializing));
  }, [dispatch, initializing]);

  useEffect(() => {
    dispatch(setMapCenter(undefined));
  }, [dispatch]);

  useEffect(() => {
    const mapData = ReadingsService.generateMapEntries(
      readings,
      userDevices,
      hideShared,
      hiddenGroups,
      undefined,
      !showClusters
    );
    const mapEntries = mapData.map((md) => {
      return {
        position: md.center,
        compProps: md,
        isFocus: md.id === `cl_${selectedElementId}`,
      };
    });

    dispatch(setMapEntries(mapEntries));

    //Only set bounds if the user has not interacted with the app
    if (
      window.gMap &&
      mapEntries.length > 0 &&
      !homeUserInteracted &&
      !menusUserInteracted
    ) {
      ReadingsService.centerOnBounds(mapEntries.map((me) => me.position));
    }
  }, [
    dispatch,
    hiddenGroups,
    hideShared,
    readings,
    selectedElementId,
    showClusters,
    userDevices,
    homeUserInteracted,
    menusUserInteracted,
  ]);

  useEffect(() => {
    if (selectedElementId) {
      const mapData = ReadingsService.generateMapEntries(
        readings,
        userDevices,
        hideShared,
        hiddenGroups,
        undefined,
        !showClusters
      );
      const cluster = mapData.find((c) => {
        return (
          c.id === `cl_${selectedElementId}` ||
          (c.data as MapReading).mapReadings.find(
            (mr) => mr.device.guid === selectedElementId
          ) !== undefined
        );
      });
      if (cluster) {
        readingsService.centerOnBounds([cluster.center]);
      }
    }
  }, [dispatch, selectedElementId, trigger, showClusters]);

  useEffect(() => {
    const circles: { center: { lat: number; lng: number }; radius: number }[] =
      [];

    geofencesService
      .getUserGeofences(
        geofences,
        loggedUser,
        {
          hiddenFences: hiddenFences,
          visible: true,
        },
        true
      )
      .forEach((geofence) => {
        circles.push({
          center: geofence.geometry.coordinates,
          radius: geofence.geometry.radius,
        });
      });

    dispatch(setMapCircles(circles));
  }, [dispatch, geofences, hiddenFences, loggedUser]);

  const centerBounds = () => {
    ReadingsService.centerOnBounds(
      readings
        .filter((reading) => reading.coordinates !== undefined)
        .map((r) => r.coordinates?.value || { lat: 0, lng: 0 })
    );
  };

  return <UserMap onMapLoaded={centerBounds} />;
};

export default OverviewMap;
