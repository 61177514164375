import mockService from "../../../Services/MockService";
import { HistoryPoint } from "../../../Services/ReadingsService";
import { plataformClient } from "../../api";

interface getDevicesReadingsResponse {
  status: string;
  readings: HistoryPoint[];
  error?: {
    message: string;
    code: string;
  };
}

export async function get_DeviceHistory(
  deviceGuid: string,
  from: string,
  to: string
) {
  try {
    const fromParam = from ? `&from=${from}` : "";
    const toParam = to ? `&to=${to}` : "";
    const include = "coordinates,confidence,gpsCoordinates";

    if (mockService.getIsDemo()) {
      const hps = await mockService.getMockedHistory(deviceGuid, from)
      return { data: { status: "success", readings:  hps}, status: 200 };
    }

    const response = await plataformClient.get<getDevicesReadingsResponse>(
      `/devices/${deviceGuid}/readings?include=${include}&historyPoints=Everything${fromParam}${toParam}`
    );
    return response;
  } catch (err) {
    return null;
  }
}
