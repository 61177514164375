import styled from "@emotion/styled";
import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  PbDimYellow,
  PbFontBlack,
  PbFontGray,
  PbFontGrayDark,
  PbYellow,
} from "../../../app/colors";
import { useAppDispatch } from "../../../app/hooks";
import { fifthLevel, fourthLevel } from "../../../app/levels";
import { RootState } from "../../../app/store";
import { setGeofenceName, setStep } from "../GeofenceHandlerSlice";

const FormContainer = styled.div({
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: fifthLevel,
  background: "#fff",
  borderRadius: "1vw",
  padding: " 2vh 1vw  2vh 1vw",
  width: "fit-content",
  height: "fit-content",
});

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  whiteSpace: "nowrap",
  padding: "0 1vw",
});

const CoverScreen = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background: "#00000090",
  zIndex: fourthLevel,
  position: "fixed",
  top: 0,
  left: 0,
});

const TitleLabel = styled.div({
  flex: 0.6,
  textAlign: "center",
  fontSize: "1.2cqi",
  borderBottom: "0.1cqi solid " + PbYellow,
  marginBottom: "1cqi",
  minWidth: "10cqi",
  padding: "0.3cqi",
});

const FenceNameField = styled(TextField)({
  flex: 0.8,
  marginBottom: "2vh",
  whiteSpace: "nowrap",
  minWidth: "14cqi",
  height: "fit-content",
  "& label": {
    fontSize: "0.9cqi",
    transform: "translate(0.5cqi, 0.5cqi)",
    visibility: "hidden",
  },
  "& input": {
    fontSize: "0.9cqi",
    padding: "0.5cqi",
  },
  "& fieldset": {
    height: "100%",
    "& legend": {
      display: "none",
    },
  },
});

const ButtonRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  marginTop: "3vh",
});

const ContinueButton = styled(Button)({
  flex: "0.3",
  color: "#000",
  background: PbYellow,
  borderRadius: "999px",
  ":hover": {
    background: PbDimYellow,
  },
  fontSize: "0.7cqi",
  padding: "0.5cqi",
  minWidth: "6cqi",
});

const BackButton = styled(Button)({
  flex: "0.3",
  color: "#FFF",
  background: PbFontBlack,
  borderRadius: "999px",
  ":hover": {
    background: PbFontGrayDark,
  },
  fontSize: "0.7cqi",
  padding: "0.5cqi",
  minWidth: "6cqi",
});

const FormSubtitle = styled.div({
  fontSize: "0.9cqi",
  color: PbFontGray,
  minWidth: "12cqi",
  textAlign: "center",
  marginBottom: "1cqi",
});

//MOBILE

const FormContainerMobile = styled.div({
  zIndex: fifthLevel,
  background: "#fff",
  borderRadius: "1vw",
  padding: " 2vh 1vw  2vh 1vw",
  width: "100%",
  height: "fit-content",
  minHeight: "40vh",
});

const TitleLabelMobile = styled.div({
  flex: 0.6,
  textAlign: "center",
  fontSize: "4qi",
  borderBottom: "0.2cqi solid " + PbYellow,
  marginBottom: "2cqi",
  minWidth: "30cqi",
  padding: "0.9cqi",
});

const FenceNameFieldMobile = styled(TextField)({
  flex: 0.8,
  marginBottom: "3cqi",
  whiteSpace: "nowrap",
  minWidth: "12cqi",
  height: "fit-content",
  "& label": {
    fontSize: "3cqi",
    transform: "translate(0.5cqi, 0.5cqi)",
    visibility: "hidden",
  },
  "& input": {
    fontSize: "3cqi",
    padding: "1cqi",
  },
  "& fieldset": {
    height: "100%",
    "& legend": {
      display: "none",
    },
  },
});

const FormSubtitleMobile = styled.div({
  fontSize: "3cqi",
  color: PbFontGray,
  minWidth: "12cqi",
  textAlign: "center",
  marginBottom: "3cqi",
});

const ContinueButtonMobile = styled(Button)({
  flex: "0.3",
  color: "#000",
  background: PbYellow,
  borderRadius: "999px",
  ":hover": {
    background: PbDimYellow,
  },
  fontSize: "3.5cqi",
  padding: "0.5cqi",
  minWidth: "6cqi",
});

const BackButtonMobile = styled(Button)({
  flex: "0.3",
  color: "#FFF",
  background: PbFontBlack,
  borderRadius: "999px",
  ":hover": {
    background: PbFontGrayDark,
  },
  fontSize: "3.5cqi",
  padding: "0.5cqi",
  minWidth: "6cqi",
});

const NamingStep = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const geofenceName = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceName;
  });

  const geofenceId = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceId;
  });

  const geofences = useSelector((state: RootState) => {
    return state.geofences.geofences;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const backStep = () => {
    dispatch(setStep("Placement"));
  };

  const nameExists = () => {
    const exists = geofences.some(
      (g) => g.name === geofenceName && (!geofenceId || geofenceId !== g.id)
    );
    return exists;
  };

  const nextStep = () => {
    if (!geofenceName || geofenceName === "") {
      setFormError(t("geofences.errors.nameRequired") as string);
      return;
    }

    if (nameExists()) {
      setFormError(t("geofences.errors.nameExists") as string);
      return;
    }

    dispatch(setStep("Devices"));
  };

  const renderDesktop = () => {
    return (
      <>
        <CoverScreen />
        <FormContainer>
          <Row>
            <TitleLabel>{t(`geofences.name.title`)}</TitleLabel>
          </Row>
          <Row>
            <FormSubtitle>{t(`geofences.steps.Customization`)}</FormSubtitle>
          </Row>
          <Row>
            <FenceNameField
              label={t("geofences.name.filedLabel") as string}
              error={formError !== undefined}
              helperText={formError !== undefined ? formError : undefined}
              value={geofenceName}
              onChange={(e) => {
                if (e.target.value.length < 100) {
                  setFormError(undefined);
                  dispatch(setGeofenceName(e.target.value));
                } else {
                  setFormError(t("geofences.name.maxCharsError") as string);
                }
              }}
              style={{ margin: "0px" }}
            ></FenceNameField>
          </Row>
          <ButtonRow>
            <BackButton variant="contained" onClick={backStep}>
              {t(`geofences.name.back`)}
            </BackButton>
            <ContinueButton
              variant="contained"
              disabled={geofenceName === ""}
              onClick={nextStep}
            >
              {t(`geofences.name.continue`)}
            </ContinueButton>
          </ButtonRow>
        </FormContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <FormContainerMobile>
          <Row>
            <TitleLabelMobile>{t(`geofences.name.title`)}</TitleLabelMobile>
          </Row>
          <Row>
            <FormSubtitleMobile>
              {t(`geofences.steps.Customization`)}
            </FormSubtitleMobile>
          </Row>
          <Row>
            <FenceNameFieldMobile
              label={t("geofences.name.filedLabel") as string}
              error={formError !== undefined}
              helperText={formError !== undefined ? formError : undefined}
              value={geofenceName}
              onChange={(e) => {
                if (e.target.value.length < 100) {
                  setFormError(undefined);
                  dispatch(setGeofenceName(e.target.value));
                } else {
                  setFormError(t("geofences.name.maxCharsError") as string);
                }
              }}
              style={{ margin: "0px" }}
            ></FenceNameFieldMobile>
          </Row>
          <ButtonRow>
            <BackButtonMobile variant="contained" onClick={backStep}>
              {t(`geofences.name.back`)}
            </BackButtonMobile>
            <ContinueButtonMobile
              variant="contained"
              disabled={geofenceName === ""}
              onClick={nextStep}
            >
              {t(`geofences.name.continue`)}
            </ContinueButtonMobile>
          </ButtonRow>
        </FormContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default NamingStep;
