import styled from "@emotion/styled";
import { getFormatedDayhour } from "pebblebee-sdk-frontend";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PbFontBlack, PbFontGray } from "../../../../app/colors";
import { useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { HistoryPoint } from "../../../../Services/ReadingsService";
import AddressField from "../../../../components/AddressLabel/AddressLabel";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import StraightenIcon from "@mui/icons-material/Straighten";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CancelIcon from "@mui/icons-material/Cancel";
import { setSelectedHistoryPoint } from "../../../historyLayout/historySlice";
import { secondLevel, thirdLevel } from "../../../../app/levels";
import { boxShadows } from "../../../../app/boxShadows";

const HistoryInfoWindow = (props: { historyPoint: HistoryPoint }) => {
  const dispatch = useAppDispatch();

  const selectedHistoryPoint = useSelector((state: RootState) => {
    return state.history.selectedHistoryPoint;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const loggedUser = useSelector((state: RootState) => state.login.loggedUser);

  const isMetric = useMemo(() => {
    return loggedUser !== undefined && loggedUser.isMetric === 1;
  }, [loggedUser])

  const showItems = useMemo(() => {
    return (
      selectedHistoryPoint ===
      props.historyPoint.deviceId + props.historyPoint.timestamp
    );
  }, [props, selectedHistoryPoint]);

  const getFormatedDate = (date: string) => {
    try {
      const time = new Date(date).getTime();
      return getFormatedDayhour(time);
    } catch {
      return "";
    }
  };

  const getDistance = () => {
    if(isMetric) {
      return `${props.historyPoint.confidence}mts`;
    } else {
      return `${Math.ceil(props.historyPoint.confidence*3.28)}fts`;
    }
  }

  const ExpandContainer = styled.div({
    position: "absolute",
    transform: "translate3d(-50%, -100%, 0) translateY(-1.3vw)",
    width: "16vw",
    zIndex: secondLevel,
    background: "#FFFFFF",
    borderRadius: "0.7vw",
    boxShadow: boxShadows.veryHard,
  });

  const DownArrow = styled.div({
    width: "0",
    height: "0.5cqi",
    borderLeft: "0.5cqi solid transparent",
    borderRight: "0.5cqi solid transparent",
    borderTop: "0.5cqi solid #FFFFFF",
    transform: "translateX(15px)",
  });

  const CloseButton = styled(CancelIcon)({
    position: "absolute",
    top: "0.5cqi",
    right: "1cqi",
    width: "1cqi",
    height: "1cqi",
    ":hover": {
      cursor: "pointer",
    },
    zIndex: thirdLevel,
    transform: "translateX(50%)",
  });

  const Column = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "0px 1.2cqi 1cqi 1.2cqi",
  });

  const DataRow = styled.div({
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0.2cqi 0px",
  });

  const AddressLabel = styled.div({
    fontSize: "1.1cqi",
    fontWeight: 700,
    color: PbFontBlack,
    width: "100%",
    textAlign: "left",
  });

  const TimeLabel = styled.div({
    fontSize: "0.9cqi",
    color: PbFontBlack,
    width: "100%",
    textAlign: "left",
  });

  const PointDataLabel = styled.div({
    fontSize: "0.7cqi",
    color: PbFontGray,
    marginLeft: "10px",
  });

  const GpsReadingIcon = styled(SatelliteAltIcon)({
    color: PbFontGray,
  });

  const CellphoneReadingIcon = styled(SmartphoneIcon)({
    color: PbFontGray,
  });

  const ConfidenceIcon = styled(StraightenIcon)({
    color: PbFontGray,
  });

  const NumberOfPointsIcon = styled(LocationOnIcon)({
    color: PbFontGray,
  });

  // MOBILE

  const ExpandContainerMobile = styled.div({
    position: "absolute",
    transform: "translate3d(-50%, -100%, 0) translateY(-1.3vw)",
    width: "70vw",
    zIndex: secondLevel,
    background: "#FFFFFF",
    borderRadius: "2vw",
    boxShadow: boxShadows.veryHard,
  });

  const DownArrowMobile = styled.div({
    width: "0",
    height: "4cqi",
    borderLeft: "4cqi solid transparent",
    borderRight: "4cqi solid transparent",
    borderTop: "4cqi solid #FFFFFF",
    transform: "translateX(15px)",
  });

  const CloseButtonMobile = styled(CancelIcon)({
    position: "absolute",
    top: "-3cqi",
    right: "0px",
    width: "6cqi",
    height: "6cqi",
    ":hover": {
      cursor: "pointer",
    },
    zIndex: thirdLevel,
    transform: "translateX(50%)",
  });

  const ColumnMobile = styled.div({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    margin: "0px 3cqi 2cqi 3cqi",
  });

  const DataRowMobile = styled.div({
    width: "100%",
    height: "fit-content",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1cqi 0px",
  });

  const AddressLabelMobile = styled.div({
    fontSize: "4cqi",
    fontWeight: 700,
    color: PbFontBlack,
    width: "100%",
    textAlign: "left",
  });

  const TimeLabelMobile = styled.div({
    fontSize: "3cqi",
    color: PbFontBlack,
    width: "100%",
    textAlign: "left",
  });

  const PointDataLabelMobile = styled.div({
    fontSize: "3cqi",
    color: PbFontGray,
    marginLeft: "10px",
  });

  const getReadingType = () => {
    switch (props.historyPoint.origin) {
      case "GPS":
        return "GPS reading";
      case "BLUETOOTH":
        return "Phone GPS reading";
      default:
        return "GPS reading";
    }
  };

  const getReadingIcon = () => {
    switch (props.historyPoint.origin) {
      case "GPS":
        return <GpsReadingIcon />;
      case "BLUETOOTH":
        return <CellphoneReadingIcon />;
      default:
        return <GpsReadingIcon />;
    }
  };

  const renderMobile = () => {
    return (
      <>
        {" "}
        {showItems ? (
          <ExpandContainerMobile>
            <CloseButtonMobile
              onClick={() => {
                dispatch(setSelectedHistoryPoint(""));
              }}
            />
            <DownArrowMobile />
            <ColumnMobile>
              <DataRowMobile>
                <AddressLabelMobile>
                  <AddressField coordinates={props.historyPoint.coordinates} />
                </AddressLabelMobile>
              </DataRowMobile>
              <DataRowMobile>
                <TimeLabelMobile>
                  {getFormatedDate(props.historyPoint.timestamp)}
                </TimeLabelMobile>
              </DataRowMobile>
              <br />
              <DataRowMobile>
                {getReadingIcon()}
                <PointDataLabelMobile>{getReadingType() + "s"}</PointDataLabelMobile>
              </DataRowMobile>
              <DataRowMobile>
                <ConfidenceIcon />
                <PointDataLabelMobile>
                  Approximate to {getDistance()}.
                </PointDataLabelMobile>
              </DataRowMobile>
              {props.historyPoint.type === "PreciseCluster" ? (
                <DataRowMobile>
                  <NumberOfPointsIcon />
                  <PointDataLabelMobile>
                    {props.historyPoint.clusterTotalReadings} readings in this
                    location
                  </PointDataLabelMobile>
                </DataRowMobile>
              ) : null}
            </ColumnMobile>
          </ExpandContainerMobile>
        ) : null}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {" "}
        {showItems ? (
          <ExpandContainer>
            <CloseButton
              onClick={() => {
                dispatch(setSelectedHistoryPoint(""));
              }}
            />
            <DownArrow />
            <Column>
              <DataRow>
                <AddressLabel>
                  <AddressField coordinates={props.historyPoint.coordinates} />
                </AddressLabel>
              </DataRow>
              <DataRow>
                <TimeLabel>
                  {getFormatedDate(props.historyPoint.timestamp)}
                </TimeLabel>
              </DataRow>
              <br />
              <DataRow>
                {getReadingIcon()}
                <PointDataLabel>{getReadingType() + "s"}</PointDataLabel>
              </DataRow>
              <DataRow>
                <ConfidenceIcon />
                <PointDataLabel>
                  Approximate to {getDistance()}.
                </PointDataLabel>
              </DataRow>
              {props.historyPoint.type === "PreciseCluster" ? (
                <DataRow>
                  <NumberOfPointsIcon />
                  <PointDataLabel>
                    {props.historyPoint.clusterTotalReadings} readings in this
                    location
                  </PointDataLabel>
                </DataRow>
              ) : null}
            </Column>
          </ExpandContainer>
        ) : null}
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop()
};

export default HistoryInfoWindow;
