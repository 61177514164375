import Mp3NotificationSound from "../assets/sounds/notifications/A-Tone-His_Self-1266414414.mp3";

class MediaService {
  playNotificationSounds(volume: number) {
    let notificationSound = new Audio(Mp3NotificationSound);
    notificationSound.volume = volume / 100;
    notificationSound.play();
  }
}

const mediaService = new MediaService();

export default mediaService;