export enum UserPreferences {
  isMetric = "isMetric"
}

class LocalStorageService {
  saveData(key: string, data: any) {
    window.localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string) {
    const data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
