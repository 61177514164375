import { styled, Switch } from "@mui/material";
import React from "react";
import {
  PbFontGray,
  PbFontGrayLight,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../../app/colors";
import { useAppSelector } from "../../../../../app/hooks";
import { RootState } from "../../../../../app/store";

export const CustomPbSwitch = styled(Switch)({
  width: "2.2cqi",
  height: "1.3cqi",
  padding: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: "0.1cqi",
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(0.9cqi)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: PbYellow,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '0.2cqi solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: PbFontGrayLight,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: PbFontGrayWatermark,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: "1.1cqi",
    height: "1.1cqi",
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: PbFontGrayWatermark,
    opacity: 1,
  },
});

export const CustomPbSwitchMobile = styled(Switch)({
  width: "6cqi",
  height: "4.8cqi",
  borderRadius: "2cqi",
  padding: "1.2cqi 0",
  transitionDuration: "200ms",
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: "0.8cqi 0",
    color: "#ccc",
    "&.Mui-checked": {
      transform: "translateX(2.8cqi)",
      color: PbYellow,
      "& + .MuiSwitch-track": {
        backgroundColor: `${PbYellow}BB`,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "3.4cqi",
    height: "3.4cqi",
  },
  "& .MuiSwitch-track": {
    backgroundColor: PbFontGray,
    opacity: 1,
  },
});

interface PbSwitchProps {
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  name: string;
  defaultChecked: boolean;
  checked?: boolean;
}

const PbSwitch = (props: PbSwitchProps) => {
  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, check: boolean) => {
    props.handleChange(e, check);
  };

  return isMobile ? (
    <CustomPbSwitchMobile
      defaultChecked={props.defaultChecked}
      onChange={onChange}
      name={props.name}
      checked={props.checked}
    />
  ) : (
    <CustomPbSwitch
      defaultChecked={props.defaultChecked}
      onChange={onChange}
      name={props.name}
      checked={props.checked}
    />
  );
};

export default PbSwitch;
