import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getGeofences,
  setGeofenceError,
} from "../../dataSlices/GeofencesSlice";
import GeofencesCreateEdit from "../GeofenceCreateEdit/GeofenceCreateEdit";
import GeofencesList from "./components/GeofencesList";
import GeofencesManager from "./components/GeofencesManager";
import GeofencesMap from "./components/GeofencesMap";
import { setShowCreationHandler } from "./geofencesLayoutSlice";

const GeofencesLayout = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const showCreationHandler = useSelector((state: RootState) => {
    return state.geofencesLayout.showCreationHandler;
  });

  const geofencesError = useSelector((state: RootState) => {
    return state.geofences.geofencesError;
  });

  const showSnackbar = useMemo(() => {
    return (
      geofencesError !== undefined &&
      geofencesError !== null &&
      geofencesError !== ""
    );
  }, [geofencesError]);

  const closeSnackBar = () => {
    dispatch(setGeofenceError(""));
  };

  useEffect(() => {
    dispatch(setShowCreationHandler(false));
  }, []);

  useEffect(() => {
    const fetchGeofences = async () => {
      await dispatch(getGeofences());
    };
    fetchGeofences();
  }, [dispatch]);

  const renderDesktop = () => {
    return (
      <>
        <div>
          <GeofencesManager />
          {showCreationHandler ? (
            <GeofencesCreateEdit />
          ) : (
            <>
              <GeofencesList />
            </>
          )}
          <GeofencesMap />
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={closeSnackBar}
          >
            <Alert
              onClose={closeSnackBar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {geofencesError}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <div>
        <GeofencesMap />
          <GeofencesManager />
          {showCreationHandler ? (
            <GeofencesCreateEdit />
          ) : (
            <>
              <GeofencesList />
            </>
          )}
          
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={closeSnackBar}
          >
            <Alert
              onClose={closeSnackBar}
              severity="error"
              sx={{ width: "100%" }}
            >
              {geofencesError}
            </Alert>
          </Snackbar>
        </div>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop()
};

export default GeofencesLayout;
