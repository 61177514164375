import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get_healthCheck } from "./apiRequests/core/healthCheck";

export enum OperatingSystems {
  iOS = "iOS",
  other = "other",
}

export interface CommonState {
  loadingLocation: boolean;
  location?: {
    lat: number;
    lng: number;
  };
  globalError: string;
  showGlobalError: boolean;
  isMobileScreen: boolean;
  os: OperatingSystems;
  makingHealthCheck: boolean;
  isOnMaintenance: boolean;
  refreshingToken: boolean;
}

const initialState: CommonState = {
  loadingLocation: true,
  globalError: "",
  showGlobalError: false,
  isMobileScreen: false,
  os: OperatingSystems.other,
  makingHealthCheck: false,
  isOnMaintenance: false,
  refreshingToken: true
};

export const healthCheck = createAsyncThunk(
  "common/healthCheck",
  async ({ simulateFailure }: { simulateFailure: boolean }, thunkApi) => {
    try {
      const response = await get_healthCheck({ simulateFailure });

      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoadingLocation: (state, action: PayloadAction<boolean>) => {
      state.loadingLocation = action.payload;
    },
    setLocation: (
      state,
      action: PayloadAction<{ lat: number; lng: number }>
    ) => {
      state.location = action.payload;
    },
    setGlobalError: (state, action: PayloadAction<string>) => {
      state.globalError = action.payload;
      state.showGlobalError = true;
    },
    hideGlobalError: (state) => {
      state.globalError = "";
      state.showGlobalError = false;
    },
    setIsMobileScreen: (state, action: PayloadAction<boolean>) => {
      state.isMobileScreen = action.payload;
    },
    setOs: (state, action: PayloadAction<OperatingSystems>) => {
      state.os = action.payload;
    },
    setRefreshingToken: (state, action: PayloadAction<boolean>) => {
      state.refreshingToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //request code
      .addCase(healthCheck.pending, (state) => {
        state.makingHealthCheck = true;
      })
      .addCase(healthCheck.fulfilled, (state, action) => {
        state.isOnMaintenance = false;
        state.makingHealthCheck = false;
      })
      .addCase(healthCheck.rejected, (state, action) => {
        state.isOnMaintenance = true;
        state.makingHealthCheck = false;
      });
  },
});

export const {
  setLocation,
  setLoadingLocation,
  hideGlobalError,
  setGlobalError,
  setIsMobileScreen,
  setOs,
  setRefreshingToken
} = CommonSlice.actions;

export default CommonSlice.reducer;
