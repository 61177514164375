import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { PbFontGray } from "../../app/colors";
import { RootState } from "../../app/store";

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  margin: "3px 0",
});

const LoadingAddresMarker = styled(CircularProgress)({
  maxWidth: "18px",
  maxHeight: "18px",
  color: PbFontGray,
  marginLeft: "10px",
});

const AddressField = (props: {
  coordinates?: {
    lat: number;
    lng: number;
  };
}) => {
  const coordinatesAddresses = useSelector((state: RootState) => {
    return state.map.coordinatesAddresses;
  });

  const address = useMemo(() => {
    if (props.coordinates) {
      const address = coordinatesAddresses.find(
        (add) =>
          add.coordinates.lat === props.coordinates?.lat &&
          add.coordinates.lng === props.coordinates?.lng
      );
      return address;
    }
    return null;
  }, [coordinatesAddresses, props]);

  return address ? (
    <>
      {address.status === "pending" ? (
        <Row>
          <div>Loading address</div>
          <LoadingAddresMarker />
        </Row>
      ) : (
        address.address
      )}
    </>
  ) : null;
};

export default AddressField;
