import styled from "@emotion/styled";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { PbYellow } from "../../../../app/colors";
import { thirdLevel } from "../../../../app/levels";

export const CustomTimelineItem = styled(TimelineItem)({
  width: "100%",
  zIndex: thirdLevel,
  "::before": {
    display: "none",
  },
});

export const CustomTimelineOppositeContent = styled(TimelineOppositeContent)({
  flex: 0.4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.85cqi",
});

export const CustomTimelineSeparator = styled(TimelineSeparator)({
  
})

export const CustomTimelineConnector = styled(TimelineConnector)({
  background: PbYellow,
  width: "0.15cqi",
});

export const CustomTimeLineDot = styled(TimelineDot)({
  borderColor: PbYellow,
  border: `solid 4px ${PbYellow}`,
  height: "0.2cqi",
  width: "0.2cqi",
  borderWidth: "0.3cqi",
});

export const PbPassPointTimeLineDot = styled(TimelineDot)({
  background: PbYellow,
  visibility: "hidden",
  border: `solid 4px ${PbYellow}`,
  height: "0.2cqi",
  width: "0.2cqi",
  borderWidth: "0.3cqi",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
});

export const PassPointLine = styled.div({
  position: "absolute",
  visibility: "visible",
  borderTop: `solid 0.3cqi ${PbYellow}`,
  width: "0.7cqi",
  borderRadius: "1cqi",
});

export const CustomTimelineContent = styled(TimelineContent)({
});

