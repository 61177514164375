import mockService from "../../../../Services/MockService";
import DeviceGroup from "../../../../models/enterprise/DeviceGroup";
import { httpClientCore } from "../../../api";

interface GetDeviceGroupsResponse {
  groups: DeviceGroup[]
}

export async function get_DevicesGroups() {
  try {
    if (mockService.getIsDemo()) {
      return {
        data: { groups: mockService.getMockedDeviceGroups() },
        status: 200,
      };
    }
    const response = await httpClientCore.get<GetDeviceGroupsResponse>(
      `/v2/devices/groups`
    );
    return response;
  } catch (err) {
    return null;
  }
}