import { v1Client } from "../../app/api";
import AuthService, { LoggedUser } from "../../Services/AuthService";
import mockService from "../../Services/MockService";

export async function postLogin(email: string) {
  try {
    const response = await v1Client.post(`/v1/users/verify/${email}`);
    return response;
  } catch (err) {
    return null;
  }
}

interface LoginResponse {
  data: {
    token: {
      access_token: string;
      expires_in: number;
      token_type: string;
      refresh_token: string;
    };
    user: LoggedUser;
  };
}

export async function postVerifyCode(email: string, code: string) {
  try {
    const response = await v1Client.post<LoginResponse>(
      `/v1/users/verify/${email}/${code}`
    );
    return response;
  } catch (err) {
    return null;
  }
}

export async function post_refreshToken() {
  try {
    if (mockService.getIsDemo()) {
      const mockedResponse: LoginResponse = {
        data: {
          token: {
            access_token: "",
            expires_in: 1,
            refresh_token: "",
            token_type: "",
          },
          user: {
            devices: [],
            email: "",
            id: 1,
            isEnterprise: false,
            isMetric: 1,
            isSubscribed: 0,
            isVerified: 1,
            join_date: "",
            updated_at: "",
            uuid: "",
          },
        },
      };
      return { status: 200, data: mockedResponse };
    }
    const response = await v1Client.post<LoginResponse>(`/oauth2/token`, {
      grant_type: "refresh_token",
      refresh_token: AuthService.getRefreshToken(),
    });
    return response;
  } catch (err) {
    return null;
  }
}

interface UserResponseResponse {
  user: LoggedUser;
}

export async function get_user(user_uuid: string) {
  try {
    const response = await v1Client.get<UserResponseResponse>(`/v1/users`, {
      headers: {
        user_uuid: user_uuid,
      },
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function post_logout() {
  try {
    if (mockService.getIsDemo()) {
      return { status: 200, data: {message: "ok"} };
    }
    const response = await v1Client.post<LoginResponse>(`/v2/user/logout`, {
      refreshToken: AuthService.getRefreshToken(),
    });
    return response;
  } catch (err) {
    return null;
  }
}