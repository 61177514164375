import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import ReadingsService from "../../../Services/ReadingsService";
import {
  setLoadingMapData,
  setMapCenter,
  setMapEntries,
  setMapLines,
} from "../../UserMap/MapSlice";
import UserMap from "../../UserMap/UserMap";

//set all the history information for the map to consume
const HistoryMap = () => {
  const dispatch = useAppDispatch();

  const historyPoints = useSelector((state: RootState) => {
    return state.history.historyPoints;
  });

  const selectedDate = useSelector((state: RootState) => {
    return state.history.selectedDate;
  });

  const loadingHistoryPoint = useSelector((state: RootState) => {
    return state.history.loadingHistoryPoint;
  });

  const location = useSelector((state: RootState) => state.common.location);

  const centerBounds = () => {
    ReadingsService.centerOnBounds([location ? location : { lat: 0, lng: 0 }]);
  };

  useEffect(() => {
    dispatch(setLoadingMapData(loadingHistoryPoint));
  }, [dispatch, loadingHistoryPoint]);

  useEffect(() => {
    let center: { lat: number; lng: number } | undefined = undefined;
    if (
      historyPoints &&
      Array.isArray(historyPoints) &&
      historyPoints.length > 0
    ) {
      let lat = 0;
      let lng = 0;
      var bounds = new google.maps.LatLngBounds();
      historyPoints.forEach((hp) => {
        if (hp.coordinates) {
          lat = lat + hp.coordinates.lat;
          lng = lng + hp.coordinates.lng;
          bounds.extend(hp.coordinates);
        }
      });
      center = {
        lat: lat / historyPoints.length,
        lng: lng / historyPoints.length,
      };
      window.gMap.fitBounds(bounds);
    }
    dispatch(setMapCenter(center));
  }, [dispatch, historyPoints, selectedDate]);

  useEffect(() => {
    const mapData = ReadingsService.generateHistoryEntries(
      historyPoints,
      undefined
    );
    const mapEntries = mapData.map((md) => {
      return {
        position: md.center,
        compProps: md,
      };
    });
    dispatch(setMapEntries(mapEntries));
  }, [dispatch, historyPoints]);

  useEffect(() => {
    const lines: { lat: number; lng: number }[][] = [];
    if (historyPoints && Array.isArray(historyPoints)) {
      let line: { lat: number; lng: number }[] = [];
      historyPoints.forEach((h) => {
        if (h.coordinates) {
          line.push(h.coordinates);
        }
      });
      lines.push(line);
    }
    dispatch(setMapLines(lines));
  }, [dispatch, historyPoints]);

  return <UserMap onMapLoaded={centerBounds}/>;
};

export default HistoryMap;
