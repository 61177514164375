import styled from "@emotion/styled";
import { ListItem, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import React from "react"
import { fifthLevel, fourthLevel } from "../../../../app/levels";
import PbDeviceAvatarIcon from "../../../PbDeviceAvatarIcon/PbDeviceAvatarIcon";
import PbDevicesAutocompleteItemViewProps from "./PbDeviceAutocompleteItemViewProps";

const PbDeviceAutocompleteItemMobileView = ({
    avatarText,
    device,
    readingText,
    shared,
    onItemClick,
  }: PbDevicesAutocompleteItemViewProps) => {
    const StyledListItem = styled(ListItem)({
      display: "flex",
      justifyContent: "center",
      padding: "0.1cqi 0",
      zIndex: fourthLevel,
      background: "#fff",
      width: "100%",
    });
  
    const AnimatedListItemButton = styled(ListItemButton)({
      minHeight: "6.5%",
      maxHeight: "6.5%",
      padding: "0px 1cqi",
    });
  
    const StyledListItemAvatar = styled(ListItemAvatar)({
      width: "10%",
    });
  
    const InfoContainer = styled.div({
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      paddingLeft: "2%",
    });
  
    const DeviceNameLabel = styled(ListItemText)({
      fontWeight: "700",
      textAlign: "start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    });
  
    const DeviceNameText = styled.span({
      fontSize: "3.3cqi",
      zIndex: fifthLevel
    });
  
    const DeviceInfoText = styled.span({
      textAlign: "start",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontSize: "2.2cqi",
    });
  
    return (
      <>
        <StyledListItem onClick={() => {onItemClick(device)}} id={`menu-item-${device.guid || device.id}`}>
          <AnimatedListItemButton>
            <StyledListItemAvatar>
              <PbDeviceAvatarIcon 
                device={device}
              />
            </StyledListItemAvatar>
            <InfoContainer>
              <DeviceNameLabel
                id={`Info ${device.name}`}
                primary={<DeviceNameText>{`${device.name}`}</DeviceNameText>}
                secondary={<DeviceInfoText>{readingText}</DeviceInfoText>}
              />
            </InfoContainer>
          </AnimatedListItemButton>
        </StyledListItem>
      </>
    );
  };

  export default PbDeviceAutocompleteItemMobileView;