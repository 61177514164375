import React, { useMemo } from "react";

import { Box, createTheme, ThemeProvider } from "@mui/material";
import {
  BaseSingleInputFieldProps,
  FieldSection,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "@emotion/styled";
import {
  PbBlue,
  PbDimBlue,
  PbFontGray,
  PbFontGrayWatermark,
} from "../../../app/colors";
import { useAppDispatch } from "../../../app/hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { setSelectedDate } from "../historySlice";
import dayjs, { Dayjs } from "dayjs";
import { thirdLevel } from "../../../app/levels";
import { boxShadows } from "../../../app/boxShadows";

const HistoryDatePickerContainer = styled.div({
  position: "fixed",
  top: "1vw",
  right: "1vw",
  zIndex: thirdLevel,
  background: "#fff",
  borderRadius: "1cqi",
});

const TodayLabel = styled.div(
  (props: { isToday: number; elementSelected: number }) => ({
    fontSize: "1.2cqi",
    color:
      props.elementSelected === 0
        ? PbFontGrayWatermark
        : props.isToday === 1
        ? PbBlue
        : PbFontGray,
    ":hover": {
      cursor: props.elementSelected === 1 ? "pointer" : undefined,
    },
    fontWeight:
      props.isToday === 1 && props.elementSelected === 1 ? 700 : undefined,
    padding: "0.2cqi 0.4cqi",
    backgroundColor:
      props.isToday === 1 && props.elementSelected === 1
        ? PbDimBlue
        : undefined,
    borderRadius: "1cqi",
  })
);

const ResponsiveDatepickerBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  height: "3vw",
  width: "9vw",
  padding: "0 1cqi",
  justifyContent: "space-evenly",
  borderRadius: "1cqi",
  boxShadow: boxShadows.veryHard,
});

//mobile

const HistoryDatePickerContainerMobile = styled.div({
  zIndex: thirdLevel,
  background: "#fff",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "3.5vw",
});

const TodayLabelMobile = styled.div(
  (props: { isToday: number; elementSelected: number }) => ({
    width: "30cqi",
    height: "8cqi",
    fontSize: "4cqi",
    color:
      props.elementSelected === 0
        ? PbFontGrayWatermark
        : props.isToday === 1
        ? PbBlue
        : PbFontGray,
    ":hover": {
      cursor: props.elementSelected === 1 ? "pointer" : undefined,
    },
    fontWeight:
      props.isToday === 1 && props.elementSelected === 1 ? 700 : undefined,
    padding: "0.7cqi 1.4cqi",
    backgroundColor:
      props.isToday === 1 && props.elementSelected === 1
        ? PbDimBlue
        : undefined,
    borderRadius: "2cqi",
    marginRight: "5cqi",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  })
);

const HistoryDatePicker = () => {
  const dispatch = useAppDispatch();

  const selectedDate = useSelector((state: RootState) => {
    return state.history.selectedDate;
  });

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const elementSelected = useMemo(() => {
    return (
      selectedElementId !== "" &&
      selectedElementId !== null &&
      selectedElementId !== undefined
    );
  }, [selectedElementId]);

  const isToday = (date: Dayjs) => {
    const today = dayjs();
    return (
      date.date() === today.date() &&
      date.month() === today.month() &&
      date.year() === today.year()
    );
  };

  const isFuture = (date: Dayjs) => {
    return date.isAfter(dayjs());
  };

  const isOlderThan9Months = (date: Dayjs) => {
    return date.isBefore(dayjs().add(-9, "month"));
  };

  const disableDate = (date: Dayjs) => {
    return isFuture(date) || isOlderThan9Months(date);
  };

  const goToToday = () => {
    if (elementSelected) {
      dispatch(setSelectedDate(new Date().toISOString()));
    }
  };

  const theme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeMedium: {
            color: isToday(dayjs(selectedDate)) ? undefined : PbBlue,
            background: isToday(dayjs(selectedDate)) ? undefined : PbDimBlue,
            flex: 0.3,
            "& svg": {
              width: "1.5cqi",
              height: "1.5cqi",
            },
          },
        },
      },
    },
  });

  interface CustomDatepickerFieldProps
    extends BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, any> {}

  const CustomDatepickerField = (props: CustomDatepickerFieldProps) => {
    return (
      <ResponsiveDatepickerBox ref={props.InputProps?.ref}>
        <TodayLabel
          elementSelected={elementSelected ? 1 : 0}
          isToday={isToday(dayjs(selectedDate)) ? 1 : 0}
          onClick={goToToday}
        >
          Today
        </TodayLabel>
        <span>
          <ThemeProvider theme={theme}>
            {props.InputProps?.endAdornment}
          </ThemeProvider>
        </span>
      </ResponsiveDatepickerBox>
    );
  };

  const renderDesktop = () => {
    return (
      <HistoryDatePickerContainer id="picker-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date desktop"
            format="MM/DD/YYYY"
            value={dayjs(selectedDate)}
            disabled={!elementSelected}
            onChange={(date) => {
              if (date && elementSelected) {
                try {
                  dispatch(setSelectedDate(date.toISOString()));
                } catch {}
              }
            }}
            shouldDisableDate={disableDate}
            slots={{
              field: CustomDatepickerField,
            }}
            slotProps={{
              popper: {
                "aria-labelledby": "picker-container",
                "aria-label": "picker-container",
                style: {
                  paddingTop: "1vw",
                },
              },
            }}
          />
        </LocalizationProvider>
      </HistoryDatePickerContainer>
    );
  };

  const renderMobile = () => {
    return (
      <HistoryDatePickerContainerMobile>
        <TodayLabelMobile
          elementSelected={elementSelected ? 1 : 0}
          isToday={isToday(dayjs(selectedDate)) ? 1 : 0}
          onClick={goToToday}
        >
          Today
        </TodayLabelMobile>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            format="MM/DD/YYYY"
            value={dayjs(selectedDate)}
            disabled={!elementSelected}
            onChange={(date: any) => {
              if (date && elementSelected) {
                try {
                  dispatch(setSelectedDate(date.toISOString()));
                } catch {}
              }
            }}
            slotProps={{
              popper: {
                style: {
                  zIndex: 999999999999999999999999999999999,
                },
              },
            }}
            sx={{
              flex: "1",
            }}
            shouldDisableDate={disableDate}
          />
        </LocalizationProvider>
      </HistoryDatePickerContainerMobile>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default HistoryDatePicker;
