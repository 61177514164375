import styled from "@emotion/styled";
import { Avatar, Divider, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../../../app/hooks";
import { useTranslation } from "react-i18next";
import {
  getUser,
  postLogout,
  setCodeRequested,
} from "../../../../login/LoginSlice";
import AuthService from "../../../../../Services/AuthService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import LogoutIcon from "@mui/icons-material/Logout";
import { Row } from "../../../../mainLayout/components/DeviceData/StyledComponents";
import { setGlobalError } from "../../../../../app/CommonSlice";
import { fourthLevel } from "../../../../../app/levels";
import { resetMenusSlice, setShowAccountData } from "../MenusSlice";
import { resetDevicesState } from "../../../../../dataSlices/DevicesSlice";
import { resetNotificationsState } from "../../../../../dataSlices/NotificationsSlice";
import { resetReadingsState } from "../../../../../dataSlices/ReadingsSlice";
import { resetSubscriptionsState } from "../../../../../dataSlices/SubscriptionsSlice";
import { resetGeofencesState } from "../../../../../dataSlices/GeofencesSlice";
import { boxShadows } from "../../../../../app/boxShadows";
import { PbFontGrayWatermark } from "../../../../../app/colors";
import mockService from "../../../../../Services/MockService";
import { resetHomeSlice } from "../../../../home/HomeSlice";
import messagesService from "../../../../../Services/MessagesService";

const UserBoxContainer = styled.div({
  position: "fixed",
  top: "4vw",
  right: "1vw",
  zIndex: fourthLevel,
  width: "22cqi",
  height: "7cqi",
  background: "#FFFFFF",
  borderRadius: "1cqi",
  boxShadow: boxShadows.hard,
});

const AvatarImgBig = styled(Avatar)({
  width: "3cqi",
  height: "3cqi",
  margin: "0.8cqi",
  fontSize: "2cqi",
});

const NameText = styled.div({
  fontSize: "1.2cqi",
  fontWeight: 700,
  maxWidth: "90%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const EmailText = styled.div({
  fontSize: "1cqi",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const LogOut = styled.div({
  margin: "0.1cqi",
  marginRight: "1.2cqi",
  marginTop: "0.5cqi",
  fontSize: "1cqi",
  fontWeight: 700,
  ":hover": {
    cursor: "pointer",
  },
});

const CloseButton = styled(CancelIcon)({
  position: "absolute",
  top: "0.7cqi",
  right: "0.7cqi",
  width: "1.2cqi",
  height: "1.2cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const UserBoxContainerMobile = styled.div({
  position: "fixed",
  top: "14vw",
  right: "2vw",
  zIndex: fourthLevel,
  width: "50cqi",
  height: "25cqi",
  background: "#FFFFFF",
  borderRadius: "4cqi",
});

const AvatarImgBigMobile = styled(Avatar)({
  width: "10cqi",
  height: "10cqi",
  margin: "2.5cqi",
  fontSize: "8cqi",
});

const NameTextMobile = styled.div({
  fontSize: "3.5cqi",
  fontWeight: 700,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const EmailTextMobile = styled.div({
  fontSize: "3cqi",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const LogOutMobile = styled.div({
  margin: "1cqi",
  marginRight: "3cqi",
  fontSize: "3cqi",
  fontWeight: 700,
  ":hover": {
    cursor: "pointer",
  },
});

const CloseButtonMobile = styled(CancelIcon)({
  position: "absolute",
  top: "2.5cqi",
  right: "2.5cqi",
  width: "3.5cqi",
  height: "3.5cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const CenterRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
});

const CenterCol = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
});

const EndRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
});

const UserDatabox = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMobile = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  useEffect(() => {
    if (!user) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  const logOut = async () => {
    dispatch(setCodeRequested(false));
    const loggedOutActionResult = await dispatch(postLogout());
    if (!loggedOutActionResult.type.includes("fulfilled")) {
      return;
    }

    //reset all data
    dispatch(resetDevicesState());
    dispatch(resetReadingsState());
    dispatch(resetNotificationsState());
    dispatch(resetSubscriptionsState());
    dispatch(resetGeofencesState());
    dispatch(resetHomeSlice());
    dispatch(resetMenusSlice());
    mockService.setIsDemo(false);
    navigate("/login");
  };

  const getInitials = () => {
    try {
      let initials = "";
      if (user) {
        const nameInitial =
          user.firstname && user.firstname.length > 0
            ? user.firstname[0].toUpperCase()
            : "";
        const lastNameInitial =
          user.lastname && user.lastname.length > 0
            ? user.lastname[0].toUpperCase()
            : "";

        initials = nameInitial + lastNameInitial;
        if (!initials && user.email && user.email.length > 0) {
          initials = user.email[0] ? user.email[0].toLocaleUpperCase() : "-";
        }
      }
      return initials;
    } catch (err) {
      //notify error
      dispatch(setGlobalError("Failed to load user data"));
      return "";
    }
  };

  const getUserName = () => {
    try {
      if (user?.firstname === undefined || user?.lastname === undefined) {
        return "";
      }
      return user && (user.firstname || user.lastname)
        ? `${user.firstname ? user.firstname : ""} ${
            user.lastname ? user.lastname : ""
          }`
        : "---";
    } catch (err) {
      //notify error
      dispatch(setGlobalError("Failed to load user data"));
      return "";
    }
  };

  const getUserEmail = () => {
    try {
      return user ? `${user.email}` : "---";
    } catch (err) {
      //notify error
      dispatch(setGlobalError("Failed to load user data"));
      return "";
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <UserBoxContainer>
          <CloseButton
            onClick={() => {
              dispatch(setShowAccountData(false));
            }}
          />
          <CenterRow>
            <AvatarImgBig src={user?.imageUrl}>{getInitials()}</AvatarImgBig>
            <CenterCol>
              <Tooltip title={getUserName()}>
                <NameText>{getUserName()}</NameText>
              </Tooltip>
              <Tooltip title={getUserEmail()}>
                <EmailText>{getUserEmail()}</EmailText>
              </Tooltip>
            </CenterCol>
          </CenterRow>
          <Divider variant="middle" style={{ color: PbFontGrayWatermark }} />
          <EndRow>
            <LogOut>
              <Row
                onClick={() => {
                  logOut();
                }}
              >
                <LogoutIcon style={{ width: "1.2cqi", height: "1.2cqi" }} />
                <div>
                  {mockService.getIsDemo()
                    ? t("accountBox.exitDemo")
                    : t("accountBox.logOut")}
                </div>
              </Row>
            </LogOut>
          </EndRow>
        </UserBoxContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <UserBoxContainerMobile>
          <CloseButtonMobile
            onClick={() => {
              dispatch(setShowAccountData(false));
            }}
          />
          <CenterRow>
            <AvatarImgBigMobile src={user?.imageUrl}>
              {getInitials()}
            </AvatarImgBigMobile>
            <CenterCol>
              <Tooltip title={getUserName()}>
                <NameTextMobile>{getUserName()}</NameTextMobile>
              </Tooltip>
              <Tooltip title={getUserEmail()}>
                <EmailTextMobile>{getUserEmail()}</EmailTextMobile>
              </Tooltip>
            </CenterCol>
          </CenterRow>
          <EndRow>
            <LogOutMobile>
              <Row
                onClick={() => {
                  logOut();
                }}
              >
                <LogoutIcon style={{ width: "4cqi" }} />
                <div>{t("accountBox.logOut")}</div>
              </Row>
            </LogOutMobile>
          </EndRow>
        </UserBoxContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default UserDatabox;
