import mockService from "../../../../Services/MockService";
import EnterpriseUser from "../../../../models/enterprise/EnterpriseUser";
import { httpClientCore } from "../../../api";

interface GetEnterpriseUserResponse {
  users: EnterpriseUser[];
}

export async function get_EnterpriseUsers() {
  try {
    if (mockService.getIsDemo()) {
      return { data: { users: mockService.getMockedEnterpriseUsers() }, status: 200 };
    }
    const response = await httpClientCore.get<GetEnterpriseUserResponse>(
      `/v2/enterprise/users`
    );
    return response;
  } catch (err) {
    return null;
  }
}
