import card_black from "../assets/DevicesIcons/card_black.svg"
import finder_black from "../assets/DevicesIcons/finder_black.svg"
import clip_black from "../assets/DevicesIcons/clip_black.svg"
import found_black from "../assets/DevicesIcons/found_black.svg"
import tag_black from "../assets/DevicesIcons/tag_black.svg"
import unknown from "../assets/DevicesIcons/unknown_128x128.png"

class ModelsService {
    
    getDeviceModelImage(deviceModel: number) {
        switch(deviceModel) {
            case 11:
            case 16:
            case 22:
                return card_black
            case 5:
            case 13:
                return finder_black
            case 17:
            case 23:
                return clip_black
            case 14:
                return found_black
            case 24:
                return tag_black
            default:
                return unknown
        }
    }
}

const modelsService = new ModelsService();

export default modelsService;