import styled from "@emotion/styled";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { firstLevel, thirdLevel } from "../../../../app/levels";
import { RootState } from "../../../../app/store";
import { setSelectedClusterId } from "../../../mainLayout/MainLayoutSlice";

interface ClusterMarkerProps {
  clusterId: string;
  shared: boolean;
  mapReadings: {
    reading: Reading;
    device: UserDevice;
  }[];
}
const OWNED_COLOR = "#F6EE81";
const SHARED_COLOR = "#3B89C7";

const ClusterMarker = (props: ClusterMarkerProps) => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const ClusterContainer = styled.div({
    position: "relative",
    zIndex: thirdLevel,
  });

  const ClusterIcon = styled.div(() => ({
    width: "2.4vw",
    height: "2.4vw",
    background: props.shared ? SHARED_COLOR : OWNED_COLOR,
    color: props.shared ? "#FFFFFF" : "#000000",
    borderRadius: "1vw",
    zIndex: firstLevel,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "1.5cqi",
    ":hover": {
      cursor: "pointer",
    },
  }));

  //Mobile

  const ClusterContainerMobile = styled.div({
    position: "relative",
    zIndex: thirdLevel,
  });

  const ClusterIconMobile = styled.div(() => ({
    width: "9vw",
    height: "9vw",
    background: props.shared ? SHARED_COLOR : OWNED_COLOR,
    color: props.shared ? "#FFFFFF" : "#000000",
    borderRadius: "5vw",
    zIndex: firstLevel,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: "4cqi",
    ":hover": {
      cursor: "pointer",
    },
  }));

  const renderDesktop = () => {
    return (
      <>
        <ClusterContainer>
          <ClusterIcon
            onClick={() => {
              dispatch(setSelectedClusterId(props.clusterId));
            }}
            color={OWNED_COLOR}
          >
            {props.mapReadings.length}
          </ClusterIcon>
        </ClusterContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <ClusterContainerMobile>
          <ClusterIconMobile
            onClick={() => {
              dispatch(setSelectedClusterId(props.clusterId));
            }}
            color={OWNED_COLOR}
          >
            {props.mapReadings.length}
          </ClusterIconMobile>
        </ClusterContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop()
};

export default ClusterMarker;
