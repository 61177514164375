import styled from "@emotion/styled";
import React, { useMemo } from "react";
import LayersIcon from "@mui/icons-material/Layers";
import { useTranslation } from "react-i18next";
import { PbFontGray, PbFontGrayWatermark } from "../../../../../app/colors";
import { useAppDispatch } from "../../../../../app/hooks";
import { setBottomRightMenu, setShowLayersBox } from "../MenusSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
import {
  setHiddenFences,
  setHiddenGroups,
  setHideShared,
  setMapToRoadmap,
  setMapToSatellite,
  setShowClusters,
} from "../../../../mainLayout/MainLayoutSlice";
import LayerSection from "./LayerSection";
import { thirdLevel } from "../../../../../app/levels";
import { boxShadows } from "../../../../../app/boxShadows";
import MapSettings from "./MapSettings";
import { MenuBox, MenuCloseButton, MenuTitle } from "../MenusStyledComponents";
import geofencesService from "../../../../../Services/GeofencesService";

const Icon = styled.div(() => ({
  ...({
    height: "4vw",
    width: "4vw",
    position: "fixed",
    right: "1vw",
    bottom: "6vw",
    zIndex: thirdLevel,
    background: "#FFF",
    transition: "0.5s",
    borderRadius: "999px",
  } as React.CSSProperties),
}));

const AppsIconContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
    background: "#E5E5E5",
  },
  boxShadow: boxShadows.veryHard,
});

const LayersIconButton = styled(LayersIcon)({
  height: "65%",
  width: "65%",
});

const SectionsContainer = styled.div({
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "5px",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "20px",
  },
});

const SectionsContainerMobile = styled.div({
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "5px",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "20px",
  },
  width: "100%",
});

const LayersManager = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const bottomRightMenu = useSelector(
    (state: RootState) => state.menus.bottomRightMenu
  );

  const showLayersBox = useMemo(() => {
    return bottomRightMenu === "layers";
  }, [bottomRightMenu]);

  const hiddenGroups = useSelector((state: RootState) => {
    return state.mainLayout.hiddenGroups;
  });

  const hiddenFences = useSelector((state: RootState) => {
    return state.mainLayout.hiddenFences;
  });

  const showClusters = useSelector((state: RootState) => {
    return state.mainLayout.showClusters;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const geofences = useSelector((state: RootState) => {
    return state.geofences.geofences;
  });

  const mapTypeId = useSelector((state: RootState) => {
    return state.mainLayout.mapTypeId;
  });

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const hasShared = useMemo(() => {
    return userDevices.some((d) => d.sharedFrom);
  }, [userDevices]);

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const groups = useMemo(() => {
    let userGroups: string[] = [];
    let showMyDevice = false;
    userDevices.forEach((device) => {
      if (device.groupName) {
        if (!userGroups.includes(device.groupName) && !device.sharedFrom) {
          userGroups.push(device.groupName);
        }
      } else {
        showMyDevice = true;
      }
    });
    if (showMyDevice) {
      userGroups = ["My Devices", ...userGroups];
    }
    return userGroups;
  }, [userDevices]);

  const fences = useMemo(() => {
    const userEnabledFences = geofencesService.getUserGeofences(
      geofences,
      loggedUser,
      undefined,
      true
    );

    return userEnabledFences.map((g) => g.name);
  }, [geofences, loggedUser]);

  const handleIconClick = () => {
    dispatch(setBottomRightMenu(showLayersBox ? "" : "layers"));
  };

  const handleClose = () => {
    dispatch(setBottomRightMenu(""));
  };

  const hideAllGroups = (checked: boolean) => {
    if (checked) {
      dispatch(setHiddenGroups([]));
    } else {
      dispatch(setHiddenGroups(groups));
    }
  };

  const hideAllFences = (checked: boolean) => {
    if (checked) {
      dispatch(setHiddenFences([]));
    } else {
      dispatch(setHiddenFences(fences));
    }
  };

  const handleGroupsToggle = (checked: boolean, group: string) => {
    const groups = [...hiddenGroups];
    if (checked) {
      const index = groups.indexOf(group);
      if (index === -1) {
        return;
      }
      groups.splice(index, 1);
      dispatch(setHiddenGroups(groups));
    } else {
      if (groups.includes(group)) {
        return;
      }
      dispatch(setHiddenGroups([...groups, group]));
    }
  };

  const handleFencesToggle = (checked: boolean, fence: string) => {
    const fences = [...hiddenFences];
    if (checked) {
      const index = fences.indexOf(fence);
      if (index === -1) {
        return;
      }
      fences.splice(index, 1);
      dispatch(setHiddenFences(fences));
    } else {
      if (fences.includes(fence)) {
        return;
      }
      dispatch(setHiddenFences([...fences, fence]));
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <Icon>
          <AppsIconContainer onClick={handleIconClick}>
            <LayersIconButton />
          </AppsIconContainer>
        </Icon>
        {showLayersBox ? (
          <MenuBox>
            <MenuCloseButton onClick={handleClose} />
            <MenuTitle>{t("layers.title")}</MenuTitle>
            <SectionsContainer>
              {groups.length > 0 ? (
                <LayerSection
                  title={t("layers.groups")}
                  allHandleToggle={hideAllGroups}
                  sectionChecked={() => {
                    return hiddenGroups.length !== groups.length;
                  }}
                  subsections={groups}
                  subsectionHandleToggle={(group: string, checked: boolean) => {
                    handleGroupsToggle(checked, group);
                  }}
                  subsectionChecked={(group) => {
                    return !hiddenGroups.includes(group);
                  }}
                  dimmed={
                    hiddenGroups.length > 0 &&
                    hiddenGroups.length < groups.length
                  }
                />
              ) : (
                <LayerSection
                  title={t("layers.myDevices")}
                  allHandleToggle={hideAllGroups}
                />
              )}
              {hasShared ? (
                <LayerSection
                  title={t("layers.shared")}
                  allHandleToggle={(c) => {
                    dispatch(setHideShared(!c));
                  }}
                />
              ) : null}
              {fences.length > 0 ? (
                <LayerSection
                  title={t("layers.virtualFences")}
                  allHandleToggle={hideAllFences}
                  sectionChecked={() => {
                    return hiddenFences.length !== fences.length;
                  }}
                  subsections={fences}
                  subsectionHandleToggle={(fence: string, checked: boolean) => {
                    handleFencesToggle(checked, fence);
                  }}
                  subsectionChecked={(fence) => {
                    return !hiddenFences.includes(fence);
                  }}
                  dimmed={
                    hiddenFences.length > 0 &&
                    hiddenFences.length < fences.length
                  }
                />
              ) : null}
              <MapSettings />
            </SectionsContainer>
          </MenuBox>
        ) : null}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <SectionsContainerMobile>
          {groups.length > 0 ? (
            <LayerSection
              title={t("layers.groups")}
              allHandleToggle={hideAllGroups}
              sectionChecked={() => {
                return hiddenGroups.length !== groups.length;
              }}
              subsections={groups}
              subsectionHandleToggle={(group: string, checked: boolean) => {
                handleGroupsToggle(checked, group);
              }}
              subsectionChecked={(group) => {
                return !hiddenGroups.includes(group);
              }}
              dimmed={
                hiddenGroups.length > 0 && hiddenGroups.length < groups.length
              }
            />
          ) : (
            <LayerSection
              title={t("layers.myDevices")}
              allHandleToggle={hideAllGroups}
            />
          )}
          {hasShared ? (
            <LayerSection
              title={t("layers.shared")}
              allHandleToggle={(c) => {
                dispatch(setHideShared(!c));
              }}
            />
          ) : null}
          {fences.length > 0 ? (
            <LayerSection
              title={t("layers.virtualFences")}
              allHandleToggle={hideAllFences}
              sectionChecked={() => {
                return hiddenFences.length !== fences.length;
              }}
              subsections={fences}
              subsectionHandleToggle={(fence: string, checked: boolean) => {
                handleFencesToggle(checked, fence);
              }}
              subsectionChecked={(fence) => {
                return !hiddenFences.includes(fence);
              }}
              dimmed={
                hiddenFences.length > 0 && hiddenFences.length < fences.length
              }
            />
          ) : null}
          <LayerSection
            title={t("layers.satellite")}
            allHandleToggle={(c) => {
              if (c) {
                dispatch(setMapToSatellite());
              } else {
                dispatch(setMapToRoadmap());
              }
            }}
            sectionChecked={() => {
              return mapTypeId === "satellite";
            }}
          />
          <LayerSection
            title={t("layers.clusters")}
            allHandleToggle={(c) => {
              dispatch(setShowClusters(c));
            }}
            sectionChecked={() => {
              return showClusters;
            }}
          />
        </SectionsContainerMobile>
      </>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default LayersManager;
