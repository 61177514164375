import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  not,
  NotificationType,
  PbNotification
} from "pebblebee-sdk-frontend";
import { delete_AllNotifications } from "../app/apiRequests/core/deleteAllNotification";
import { delete_Notifications } from "../app/apiRequests/core/deleteNotification";
import { get_Notifications } from "../app/apiRequests/core/getNotifications";

import i18n from "../i18n/i18n";

export interface DevicesState {
  notifications: PbNotification[];
  newNotification?: PbNotification;
  loadingNotifications: boolean;
  loadingDeleteNotification: boolean;
  notificationsError: string;
}

const initialState: DevicesState = {
  notifications: [],
  newNotification: undefined,
  loadingNotifications: false,
  loadingDeleteNotification: false,
  notificationsError: "",
};

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, thunkApi) => {
    try {
      const response = await get_Notifications();
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getNotifications.default")
        );
      }
      return response.data.notifications;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getNotifications.default")
      );
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/deleteNotification",
  async ({ mac, type, userId }: { mac: string; type: NotificationType, userId: number }, thunkApi) => {
    try {
      const response = await delete_Notifications({
        mac_address: mac,
        notificationType: type,
      });
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getNotifications.default")
        );
      }
      return { type: type, deviceId: mac };
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getNotifications.default")
      );
    }
  }
);

export const deleteAllNotification = createAsyncThunk(
  "notifications/deleteAllNotifications",
  async (_, thunkApi) => {
    try {
      const response = await delete_AllNotifications();
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getNotifications.default")
        );
      }
      return response.data.data;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getNotifications.default")
      );
    }
  }
);

//Send the ones without date to the bottom, order the ones with date desc
const sortNotificationsDesc = (notifications: PbNotification[]) : PbNotification[] => {
  return notifications.sort((a, b) => {
    if (!a.createdAt) {
      return -1; // Coloca las notificaciones sin fecha al principio
    }
    if (!b.createdAt) {
      return 1; // Coloca las notificaciones sin fecha al principio
    }
    
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
  
    return dateB - dateA; // Ordena de más reciente a más antiguo
  });
}

const addNotifications = (
  currentNotifications: PbNotification[],
  newNotification: PbNotification[]
) => {
  const notificationsMap = new Map<string, PbNotification>();
  currentNotifications.forEach((not) => {
    notificationsMap.set(not.deviceId + "-" + not.type + "-" + not.userId, not);
  });
  newNotification.forEach((not) => {
    notificationsMap.set(not.deviceId + "-" + not.type + "-" + not.userId, not);
  });

  return sortNotificationsDesc(Array.from(notificationsMap.values()));
};

const removeNotification = (
  notifications: PbNotification[],
  type: NotificationType,
  deviceId: string
) => {
  const removed = notifications.filter((n) => {
    return n.type === type && n.deviceId === deviceId;
  });
  return not<PbNotification>(notifications, removed);
};

export const NotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.notificationsError = action.payload;
    },
    addNewNotification: (state, action: PayloadAction<PbNotification>) => {
      state.notifications = addNotifications(state.notifications, [
        action.payload,
      ]);
      state.newNotification = action.payload;
    },
    clearNewNotification: (state) => {
      state.newNotification = undefined;
    },
    resetNotificationsState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      //get notifications
      .addCase(getNotifications.pending, (state) => {
        state.loadingNotifications = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loadingNotifications = false;
        state.notifications = addNotifications(
          state.notifications,
          action.payload
        );
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loadingNotifications = false;
        state.notificationsError = action.payload as string;
      })
      //delete a single notification
      .addCase(deleteNotification.pending, (state) => {
        state.loadingDeleteNotification = true;
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.loadingDeleteNotification = false;
        state.newNotification = undefined;
        state.notifications = removeNotification(
          state.notifications,
          action.payload.type,
          action.payload.deviceId
        );
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.loadingDeleteNotification = false;
        state.notificationsError = action.payload as string;
      })
      //delete all
      .addCase(deleteAllNotification.pending, (state) => {
        state.loadingNotifications = true;
      })
      .addCase(deleteAllNotification.fulfilled, (state, action) => {
        state.loadingNotifications = false;
        state.newNotification = undefined;
        state.notifications = [];
      })
      .addCase(deleteAllNotification.rejected, (state, action) => {
        state.loadingNotifications = false;
        state.notificationsError = action.payload as string;
      });
  },
});

export const { addNewNotification, clearNewNotification, setError, resetNotificationsState } =
  NotificationsSlice.actions;

export default NotificationsSlice.reducer;
