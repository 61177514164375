import styled from "@emotion/styled";
import { Reading } from "pebblebee-sdk-frontend";
import React from "react";
import { useAppDispatch } from "../../../app/hooks";
import { secondLevel } from "../../../app/levels";
import readingsService, {
  HistoryPoint,
  MapOverlayData,
  MapReading,
} from "../../../Services/ReadingsService";
import { setMarkerHover } from "../MapSlice";
import ClusterMarker from "./ClusterMarker/ClusterMarker";
import DeviceMarker from "./DeviceMarker/DeviceMarker";
import HistoryMarker from "./History/HistoryMarker";
import UserLocationMarker from "./UserLocationMarker/UserLocationMarket";

const MarkerContainer = styled.div({
  width: "fit-content",
  height: "fit-content",
  transform: "translate3d(-50%,-50%,0)",
});

const MapMarker = (props: MapOverlayData) => {
  const dispatch = useAppDispatch();

  const getMarker = (): JSX.Element => {
    if (props.readingsType === "location") {
      return <UserLocationMarker />;
    } else if (props.readingsType === "cluster") {
      const mapReading = props.data as MapReading;
      return mapReading.isCluster ? (
        <ClusterMarker
          clusterId={mapReading.id}
          shared={mapReading.isShared}
          mapReadings={mapReading.mapReadings}
        />
      ) : (
        <DeviceMarker
          device={mapReading.mapReadings[0].device}
          reading={mapReading.mapReadings[0].reading as Reading}
        />
      );
    } else if (props.readingsType === "history") {
      const historyPoint = props.data as HistoryPoint;
      return (
        <HistoryMarker
          id={props.id}
          historyPoint={historyPoint}
          index={props.index}
        />
      );
    }
    return <></>;
  };

  const onMouseEnter = () => {
    dispatch(setMarkerHover(true))
  };

  const onMapLeave = () => {
    dispatch(setMarkerHover(false))
  };

  return (
    <MarkerContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMapLeave}
      className="overlay-component"
      id={`${props.center.lat}-${props.center.lng}`}
    >
      <div
        onClick={() => {
          readingsService.centerOnBounds([props.center]);
        }}
        style={{ zIndex: secondLevel }}
      >
        {getMarker()}
      </div>
    </MarkerContainer>
  );
};

export default MapMarker;
