import styled from "@emotion/styled";
import { ClickAwayListener, List, ListItemButton } from "@mui/material";
import { UserDevice } from "pebblebee-sdk-frontend";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { boxShadows } from "../../../../app/boxShadows";
import { PbDimYellow, PbFontGrayWatermark } from "../../../../app/colors";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { fifthLevel } from "../../../../app/levels";
import { RootState } from "../../../../app/store";
import {
  getUserDevice,
  updateDeviceTrackingMode,
} from "../../../../dataSlices/DevicesSlice";
import { applyableTrackingModes } from "../../../../Services/TrackingModesService";
import { setShowTrackingModesBox } from "../../MainLayoutSlice";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BackgroundCover = styled.div({
  position: "fixed",
  top: "0",
  left: "0",
  width: "100vw",
  height: "110vh",
  zIndex: fifthLevel,
});

const TrackingModeStatusLabel = styled.div({
  color: PbFontGrayWatermark,
  marginLeft: "0.5cqi",
});

const SwitchInfoIcon = styled(InfoOutlinedIcon)({
  width: "1.1cqi",
  height: "1.1cqi",
});

const SwitchTrackingContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "90%",
  maxWidth: "20vw",
  margin: "2% 5%",
  padding: "0.3cqi",
  background: PbDimYellow + "80",
  borderRadius: "1cqi",
  fontSize: "0.8cqi",
});

const ChangeTrackingModeBox = (props: {
  device: UserDevice;
  trackingModeRef: HTMLDivElement | null;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const ActionBox = styled.div({
    position: "fixed",
    top:
      props.trackingModeRef !== null
        ? props.trackingModeRef.getBoundingClientRect().top
        : "2%",
    left:
      props.trackingModeRef !== null
        ? props.trackingModeRef.getBoundingClientRect().left
        : "30.5%",
    width: "auto",
    height: "auto",
    background: "#fff",
    zIndex: fifthLevel + 1,
    boxShadow: boxShadows.medium,
  });

  const showTrackingModesBox = useAppSelector(
    (state: RootState) => state.mainLayout.showTrackingModesBox
  );

  const userDevices = useAppSelector(
    (state: RootState) => state.devices.userDevices
  );

  const userDevice = useMemo(() => {
    const ud = userDevices.find(
      (d) => d.mac === props.device.mac
    );
    return ud ? ud : props.device;
  }, [props.device, userDevices]);

  const getSwitchingMessage = () => {
    return <>{t("trackingModes.switchingInfo")}</>;
  };

  const getStatus = (tracking: string) => {
    if (
      userDevice &&
      userDevice.cellSettings &&
      userDevice.cellSettings.tracking
    ) {
      //if the tracking mode matches de last tracking mode reported its the current one
      if (tracking === userDevice.cellSettings.last_tracking) {
        return (
          <TrackingModeStatusLabel>
            {`(${t("trackingModes.current")})`}
          </TrackingModeStatusLabel>
        );
      }

      //if the new tracking mode is not the same as the last reported is pending
      if (
        tracking === userDevice.cellSettings.tracking &&
        userDevice.cellSettings.tracking !==
          userDevice.cellSettings.last_tracking
      ) {
        return (
          <TrackingModeStatusLabel>
            {`(${t("trackingModes.awaitingConfirmation")})`}
          </TrackingModeStatusLabel>
        );
      }
    }
  };

  const changeDeviceTrackingMode = async (tracking: string) => {
    dispatch(setShowTrackingModesBox(false));
    await dispatch(
      updateDeviceTrackingMode({
        mac: userDevice.mac,
        tracking: tracking,
      })
    );
    await dispatch(getUserDevice({ mac: userDevice.mac }));
  };

  return showTrackingModesBox ? (
    <>
      <BackgroundCover />
      <ClickAwayListener
        onClickAway={() => {
          dispatch(setShowTrackingModesBox(false));
        }}
      >
        <ActionBox>
          <List>
            {Object.keys(applyableTrackingModes).map((key) => {
              return (
                <ListItemButton
                  disabled={
                    key === userDevice.cellSettings?.tracking ||
                    key === "ble_only"
                  }
                  onClick={() => {
                    changeDeviceTrackingMode(key);
                  }}
                  style={{fontSize: "1cqi"}}
                >
                  {applyableTrackingModes[key]}
                  {getStatus(key)}
                </ListItemButton>
              );
            })}
          </List>
          <SwitchTrackingContainer>
            <SwitchInfoIcon />
            {getSwitchingMessage()}
          </SwitchTrackingContainer>
        </ActionBox>
      </ClickAwayListener>
    </>
  ) : null;
};

export default ChangeTrackingModeBox;
