import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get_User_v2 } from "../../app/apiRequests/core/getUserV2";
import AuthService, { LoggedUser } from "../../Services/AuthService";
import localStorageService, {
  UserPreferences,
} from "../../Services/LocalStorageService";
import messagesService from "../../Services/MessagesService";
import {
  postLogin,
  postVerifyCode,
  post_logout,
  post_refreshToken,
} from "./LoginApi";

export interface LoginState {
  emailForm: {
    email: string;
    staySignedIn: boolean;
    errors: {
      email: string;
    };
    errorMessage: string;
    loading: boolean;
  };
  codeForm: {
    code: string;
    errors: {
      code: string;
    };
    errorMessage: string;
    loading: boolean;
  };
  token?: string;
  codeRequested: boolean;
  loadingUserData: boolean;
  loggedUser?: LoggedUser;
}

const initialState: LoginState = {
  token: AuthService.getToken() || "",
  emailForm: {
    email: "",
    staySignedIn: false,
    errorMessage: "",
    errors: { email: "" },
    loading: false,
  },
  codeForm: {
    code: "",
    errorMessage: "",
    errors: { code: "" },
    loading: false,
  },
  codeRequested: false,
  loadingUserData: false,
};

export const requestCode = createAsyncThunk(
  "login/codeRequest",
  async ({ email }: { email: string }, thunkApi) => {
    try {
      const response = await postLogin(email);
      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (response.status !== 200 && response.status !== 201) {
        return thunkApi.rejectWithValue(response.data.message);
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const verifyCode = createAsyncThunk(
  "login/verifyCode",
  async (
    {
      email,
      code,
      staySignedIn,
    }: { email: string; code: string; staySignedIn: boolean },
    thunkApi
  ) => {
    try {
      const response = await postVerifyCode(email, code);

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (
        !response.data.data ||
        !response.data.data.token.access_token ||
        !response.data.data.token.refresh_token
      ) {
        return thunkApi.rejectWithValue("An error occured");
      }
      //save token and user data
      AuthService.handleLoginSuccess(
        response.data.data.token.access_token,
        response.data.data.token.refresh_token
      );
      AuthService.setSession(staySignedIn);
      AuthService.saveEnterpriseData({
        isEnterprise: response.data.data.user.isEnterprise,
        enterpriseData: response.data.data.user.enterpriseData,
      });

      return { token: response.data.data.token.access_token };
    } catch (err) {
      console.log(err);
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const refresToken = createAsyncThunk(
  "login/refresToken",
  async (_, thunkApi) => {
    try {
      const response = await post_refreshToken();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      //save token and user data
      if (
        !response.data.data ||
        !response.data.data.token.access_token ||
        !response.data.data.token.refresh_token
      ) {
        return thunkApi.rejectWithValue("An error occured");
      }

      AuthService.handleLoginSuccess(
        response.data.data.token.access_token,
        response.data.data.token.refresh_token
      );

      return { token: response.data.data.token.access_token };
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const getUser = createAsyncThunk(
  "login/getUserData",
  async (_, thunkApi) => {
    try {
      const response = await get_User_v2();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      AuthService.saveEnterpriseData({
        isEnterprise: response.data.user.isEnterprise,
        enterpriseData: response.data.user.enterpriseData,
      });

      return response.data.user;
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const postLogout = createAsyncThunk(
  "login/postLogout",
  async (_, thunkApi) => {
    try {
      const response = await post_logout();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const loadUserPreferences = createAsyncThunk(
  "login/loadUserPreferences",
  async (_, thunkApi) => {
    try {
      const isMetric = localStorageService.getData(UserPreferences.isMetric);

      if (isMetric == null) {
        localStorageService.saveData(UserPreferences.isMetric, false);
      }

      return { isMetric: isMetric };
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCodeRequested: (state, action: PayloadAction<boolean>) => {
      state.codeForm.errorMessage = "";
      state.codeRequested = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.emailForm.errorMessage = "";
      state.emailForm.email = action.payload;
    },
    setStaySignedIn: (state, action: PayloadAction<boolean>) => {
      state.emailForm.staySignedIn = action.payload;
    },
    setEmailError: (state, action: PayloadAction<{ email: string }>) => {
      state.emailForm.errors = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.codeForm.errorMessage = "";
      state.codeForm.code = action.payload;
    },
    setCodeError: (state, action: PayloadAction<{ code: string }>) => {
      state.codeForm.errors = action.payload;
    },
    setIsMetric: (state, action: PayloadAction<0 | 1>) => {
      if (state.loggedUser) {
        state.loggedUser.isMetric = action.payload;
        localStorageService.saveData(UserPreferences.isMetric, action.payload);
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //request code
      .addCase(requestCode.pending, (state) => {
        state.emailForm.loading = true;
      })
      .addCase(requestCode.fulfilled, (state, action) => {
        state.codeRequested = true;
        state.emailForm.loading = false;
      })
      .addCase(requestCode.rejected, (state, action) => {
        state.emailForm.errorMessage = action.payload as string;
        state.emailForm.loading = false;
      })
      //verify code
      .addCase(verifyCode.pending, (state) => {
        state.codeForm.loading = true;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.emailForm.email = "";
        state.codeForm.code = "";
        state.codeForm.loading = false;
        state.token = action.payload.token;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.codeForm.errorMessage = action.payload as string;
        state.codeForm.loading = false;
      })
      //
      .addCase(getUser.pending, (state) => {
        state.loadingUserData = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loggedUser = action.payload;
        state.loadingUserData = false;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.codeForm.errorMessage = action.payload as string;
        state.loadingUserData = false;
        AuthService.handleLogOut();
        window.location.reload();
      })
      //load pref
      .addCase(loadUserPreferences.pending, (state) => {})
      .addCase(loadUserPreferences.fulfilled, (state, action) => {
        if (state.loggedUser) {
          state.loggedUser.isMetric = action.payload.isMetric;
        }
      })
      .addCase(loadUserPreferences.rejected, (state, action) => {})
      //refresh token
      .addCase(refresToken.pending, (state) => {})
      .addCase(refresToken.fulfilled, (state, action) => {
        state.token = action.payload.token;
      })
      .addCase(refresToken.rejected, (state, action) => {})
      //logut
      .addCase(postLogout.pending, (state) => {})
      .addCase(postLogout.fulfilled, (state, action) => {
        AuthService.handleLogOut();
      })
      .addCase(postLogout.rejected, (state, action) => {
        messagesService.notififyError(
          action.type,
          "Failed to close session, please try again"
        );
      });
  },
});

export const {
  setEmail,
  setCode,
  setCodeError,
  setCodeRequested,
  setEmailError,
  setStaySignedIn,
  setIsMetric,
} = loginSlice.actions;

export default loginSlice.reducer;
