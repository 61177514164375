import dayjs from "dayjs";
import {
  models,
  PbNotification,
  Reading,
  Subscription,
  SubscriptionCheckout,
  UserDevice,
  Geofence,
} from "pebblebee-sdk-frontend";
import { LoggedUser } from "./AuthService";
import { HistoryPoint } from "./ReadingsService";
import { applyableTrackingModes } from "./TrackingModesService";
import EnterpriseUser, {
  EnterpriseAccessLevel,
} from "../models/enterprise/EnterpriseUser";
import DeviceGroup from "../models/enterprise/DeviceGroup";
import EnterpriseLog from "../models/enterprise/EnterpriseLogs";

interface MockedLocation {
  center: { lat: number; lng: number };
  name: string;
  dispersion: { lat: number; lng: number };
  deviceModels: number[];
  deviceAmount: number;
}

const standardDispersion = {
  lat: 0.002,
  lng: 0.002,
};

const mockedModels: number[] = [14, 22, 23, 24];

class MockService {
  private isDemo: boolean;

  private mockedEnterpriseUsers: EnterpriseUser[] = [
    {
      childEmail: "test@test.com",
      childUserId: 1,
      childUserUuid: "1",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test2@test.com",
      childUserId: 2,
      childUserUuid: "2",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
    {
      childEmail: "test3@test.com",
      childUserId: 3,
      childUserUuid: "3",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test4@test.com",
      childUserId: 4,
      childUserUuid: "4",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
    {
      childEmail: "test5@test.com",
      childUserId: 5,
      childUserUuid: "5",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test6@test.com",
      childUserId: 6,
      childUserUuid: "6",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
    {
      childEmail: "test7@test.com",
      childUserId: 7,
      childUserUuid: "7",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test8@test.com",
      childUserId: 8,
      childUserUuid: "8",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
    {
      childEmail: "test9@test.com",
      childUserId: 9,
      childUserUuid: "9",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test10@test.com",
      childUserId: 10,
      childUserUuid: "10",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
    {
      childEmail: "test11@test.com",
      childUserId: 11,
      childUserUuid: "11",
      deviceGroups: [{ uuid: "1", name: "Group 1" }],
      permission: { description: "ADMIN", id: 1 },
    },
    {
      childEmail: "test12@test.com",
      childUserId: 12,
      childUserUuid: "12",
      deviceGroups: [{ uuid: "2", name: "Group 2" }],
      permission: { description: "READ", id: 2 },
    },
  ];
  private mockedDeviceGroups: DeviceGroup[] = [
    { uuid: "1", name: "Group 1", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "2", name: "Group 2", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "3", name: "Group 3", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "4", name: "Group 4", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "5", name: "Group 5", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "6", name: "Group 6", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "7", name: "Group 7", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "8", name: "Group 8", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "9", name: "Group 9", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "10", name: "Group 10", devices: [], readOnly: true, userUuid: "1" },
    { uuid: "11", name: "Group 11", devices: [], readOnly: true, userUuid: "1" }, 
    { uuid: "12", name: "Group 12", devices: [], readOnly: true, userUuid: "1" },
  ];
  private mockedEnterpriseLogs: EnterpriseLog[] = [
    {
      childUserId: 1,
      message: "Username Changed",
      parentUserId: 1,
      timestamp: new Date('2024-01-01').toISOString(),
      type: "admin",
    },
    {
      childUserId: 2,
      message: "Device name change",
      parentUserId: 2,
      timestamp: new Date('2024-01-02').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 2",
      parentUserId: 3,
      timestamp: new Date('2024-01-03').toISOString(),
      type: "read",
    },
    {
      childUserId: 4,
      message: "Geofence created",
      parentUserId: 4,
      timestamp: new Date('2024-01-04').toISOString(),
      type: "read",
    },
    {
      childUserId: 5,
      message: "Device name change 3",
      parentUserId: 5,
      timestamp: new Date('2024-01-05').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 4",
      parentUserId: 3,
      timestamp: new Date('2024-01-06').toISOString(),
      type: "read",
    }, {
      childUserId: 2,
      message: "Device name change 5",
      parentUserId: 2,
      timestamp: new Date('2024-01-07').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 6",
      parentUserId: 3,
      timestamp: new Date('2024-01-08').toISOString(),
      type: "read",
    }, {
      childUserId: 2,
      message: "Device name change 7",
      parentUserId: 2,
      timestamp: new Date('2024-01-09').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 8",
      parentUserId: 3,
      timestamp: new Date('2024-01-10').toISOString(),
      type: "read",
    }, {
      childUserId: 2,
      message: "Device name change 9",
      parentUserId: 2,
      timestamp: new Date('2024-01-11').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 10",
      parentUserId: 3,
      timestamp: new Date('2024-01-12').toISOString(),
      type: "read",
    }, {
      childUserId: 2,
      message: "Device name change 11",
      parentUserId: 2,
      timestamp: new Date('2024-01-13').toISOString(),
      type: "admin",
    },
    {
      childUserId: 3,
      message: "Device name change 12",
      parentUserId: 3,
      timestamp: new Date('2024-01-14').toISOString(),
      type: "read",
    },
  ];

  private mockedDevices: UserDevice[] = [];
  private mockedReadings: Reading[] = [];
  private mockedGeofences: Geofence[] = [
    {
      name: "Example fence",
      deviceId: ["example-vf-1", "example-vf-2"],
      enabled: true,
      geometry: {
        coordinates: { lat: 32.882946, lng: -97.036715 },
        radius: 500,
      },
      shape: "circle",
      type: "deviceLevel",
      id: "ex0",
      userId: 1,
    },
  ];

  private exampleGeofenceDevices: UserDevice[] = [
    {
      name: `example-vf-1`,
      id: `example-vf-1`,
      guid: `example-vf-1`,
      mac: `100000000000`,
      activityTracking: "",
      group: `example-fence`,
      groupName: `Example virtual fence`,
      model: 22,
    },
    {
      name: `example-vf-2`,
      id: `example-vf-2`,
      guid: `example-vf-2`,
      mac: `200000000000`,
      activityTracking: "",
      group: `example-fence`,
      groupName: `Example virtual fence`,
      model: 23,
    },
  ];
  private exampleGeofenceReadings: Reading[] = [
    {
      coordinates: {
        timestamp: new Date().toISOString(),
        value: {
          lat: 32.882946,
          lng: -97.036715,
        },
      },
      id: `example-vf-1`,
    },
    {
      coordinates: {
        timestamp: new Date().toISOString(),
        value: {
          lat: 32.882451,
          lng: -96.998466,
        },
      },
      id: `example-vf-2`,
    },
  ];

  private locations: MockedLocation[] = [
    {
      center: { lat: 36.568381, lng: -121.942199 },
      name: "Pebble Beach golf course",
      dispersion: standardDispersion,
      deviceModels: mockedModels,
      deviceAmount: 20,
    },
    {
      center: { lat: 33.500234, lng: -82.02361 },
      name: "Augusta National golf course",
      dispersion: standardDispersion,
      deviceModels: [14],
      deviceAmount: 20,
    },
    {
      center: { lat: 32.897656, lng: -97.04046 },
      name: "Dallas/Fort Worth International Airport",
      dispersion: { lat: 0.024, lng: 0.012 },
      deviceModels: mockedModels,
      deviceAmount: 100,
    },
    {
      center: { lat: 40.777587, lng: -73.969201 },
      name: "Central Park",
      dispersion: { lat: 0.004, lng: 0.004 },
      deviceModels: mockedModels,
      deviceAmount: 40,
    },
  ];

  constructor() {
    this.isDemo = false;
  }

  setIsDemo(isDemo: boolean) {
    this.isDemo = isDemo;
  }

  getIsDemo() {
    return this.isDemo;
  }

  getMockedUser(): LoggedUser {
    const mockedUser: LoggedUser = {
      devices: [],
      email: "demo@user.con",
      firstname: "John",
      lastname: "Doe",
      id: 1,
      isEnterprise: true,
      isMetric: 0,
      isSubscribed: 0,
      isVerified: 1,
      join_date: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      uuid: "1",
      enterpriseData: {
        isParent: true,
        isChild: false
      }
    };

    return mockedUser;
  }

  async updateMockedDevice(mac: string, tracking: string): Promise<any> {
    await this.mockServiceDelay();

    try {
      const device = this.mockedDevices.find((d) => d.mac === mac);

      if (device && device.cellSettings) {
        const updatedDevice = {
          ...device,
          cellSettings: {
            ...device.cellSettings,
            tracking: tracking,
          },
        };
        let updatedList = [...this.mockedDevices];
        const index = this.mockedDevices.indexOf(device);
        if (index !== -1) {
          updatedList[index] = updatedDevice;
          this.mockedDevices = updatedList;
        }
      }
    } catch (err) {
      console.log(err);
    }
    return { status: 200, data: { status: 200 } };
  }

  async getMockedDevice(mac: string): Promise<UserDevice | undefined> {
    await this.mockServiceDelay();

    if (this.mockedDevices.length > 0) {
      return this.mockedDevices.find((d) => d.mac === mac);
    }
  }

  async getMockedDevices(): Promise<UserDevice[]> {
    await this.mockServiceDelay();

    if (this.mockedDevices.length > 0) {
      return this.mockedDevices;
    }

    this.locations.forEach((location, index) => {
      for (let i = 0; i < location.deviceAmount; i++) {
        const mockedModelNumber =
          location.deviceModels[
            Math.floor(Math.random() * location.deviceModels.length)
          ];

        const mockedTracking = Object.keys(applyableTrackingModes)[
          Math.floor(Math.random() * Object.keys(applyableTrackingModes).length)
        ];

        const mockedLastTracking = Object.keys(applyableTrackingModes)[
          Math.floor(Math.random() * Object.keys(applyableTrackingModes).length)
        ];

        const mockedCellSettings = models.isCellular(mockedModelNumber)
          ? {
              tracking: mockedTracking,
              last_tracking: mockedLastTracking,
            }
          : undefined;
        let mockedDevice: UserDevice = {
          name: `${location.name}-${i}`,
          id: `${index}-${i}`,
          guid: `${index}-${i}`,
          mac: `0000000000${index}${i}`,
          activityTracking: "",
          group: `g-${index}`,
          groupName: location.name,
          model: mockedModelNumber,
          cellSettings: mockedCellSettings,
        };
        this.mockedDevices.push(mockedDevice);
      }
    });

    this.exampleGeofenceDevices.forEach((d) => {
      this.mockedDevices.push(d);
    });

    return this.mockedDevices;
  }

  async getMockedReadings(): Promise<Reading[]> {
    await this.mockServiceDelay();

    if (this.mockedReadings.length > 0) {
      const refreshedMockedReadings = this.mockedReadings.map((r) => {
        const refreshedReading: Reading = {
          ...r,
          coordinates: {
            value: r.coordinates?.value || {lat: 0, lng: 0},
            timestamp: this.mockNow(),
          },
          batteryPercentage: {
            value: 100 - Math.floor(Math.random() * 40),
            timestamp: this.mockNow(),
            trend: 100,
          },
        };
        return refreshedReading;
      });
      this.mockedReadings = refreshedMockedReadings;
      return this.mockedReadings;
    }
    this.locations.forEach((location, index) => {
      for (let i = 0; i < location.deviceAmount; i++) {
        let mockedReading: Reading = {
          coordinates: {
            timestamp: this.mockNow(),
            value: {
              lat:
                location.center.lat + Math.random() * location.dispersion.lat,
              lng:
                location.center.lng + Math.random() * location.dispersion.lng,
            },
          },
          id: `${index}-${i}`,
          batteryPercentage: {
            value: 100 - Math.floor(Math.random() * 40),
            timestamp: this.mockNow(),
            trend: 100,
          },
        };
        this.mockedReadings.push(mockedReading);
      }
    });

    this.exampleGeofenceReadings.forEach((r) => {
      this.mockedReadings.push(r);
    });

    return this.mockedReadings;
  }

  getMockedSubscription(mac: string): Subscription {
    return {
      customerId: "1",
      deviceId: mac,
      id: "1",
      planId: "",
      userId: 1,
      status: "active",
      startDate: dayjs().add(-3, "months").toISOString(),
      planEnd: dayjs().add(33, "months").toISOString(),
      plan: {
        id: "",
        name: "",
        price: 144,
        description: "",
        duration: {
          interval: "year",
          length: "3",
        },
      },
    };
  }

  getMockedSubscriptionCheckout(): SubscriptionCheckout {
    return {
      discounts: [{ amount: 1, name: "", percentage: 1 }],
      nextPayment: { monthlyCost: 1, planId: "", total: 1 },
      planId: "",
      subtotal: 1,
      taxes: [{ amount: 1, name: "", percentage: 1 }],
      total: 144,
    };
  }

  async getMockedHistory(
    deviceGuid: string,
    date: string
  ): Promise<HistoryPoint[]> {
    await this.mockServiceDelay();

    let historyPoints: HistoryPoint[] = [];
    const distance = 0.0007;

    let timeInstance = 0;

    let clusters = [
      {
        step: {
          lat: 0.7,
          lng: 0.2,
        },
      },
      {
        step: {
          lat: 0,
          lng: 1,
        },
      },
      {
        step: {
          lat: -1,
          lng: 0,
        },
      },
    ];

    let device = this.mockedDevices.find((d) => d.guid === deviceGuid);
    let deviceReading = this.mockedReadings.find((r) => r.id === deviceGuid);

    if (!device || !deviceReading) {
      return [];
    }

    //Get the device location and set as initial coordinates
    let nextCoordinate = deviceReading.coordinates?.value;

    clusters.forEach((cluster, index) => {
      //Add cluster
      let newCluster: HistoryPoint = {
        batteryPercentage: 100,
        clusterTotalReadings: Math.floor(Math.random() * 10),
        confidence: Math.floor(Math.random() * 100),
        deviceId: deviceGuid,
        origin: device?.model === 14 ? "GPS" : "BLUETOOTH",
        timestamp: dayjs()
          .add(-5 * timeInstance, "minutes")
          .toISOString(),
        type: "PreciseCluster",
        coordinates: {
          lat: nextCoordinate?.lat || 0,
          lng: nextCoordinate?.lng || 0,
        },
      };

      historyPoints.push(newCluster);
      nextCoordinate = this.getNewCoordinates(
        nextCoordinate || {lat: 0, lng: 0},
        cluster.step,
        distance
      );
      timeInstance++;

      const passPointsNumber = Math.floor(Math.random() * 5);

      //add passpoints
      for (let i = 0; i < passPointsNumber; i++) {
        let newPasspoint: HistoryPoint = {
          batteryPercentage: 100,
          clusterTotalReadings: 1,
          confidence: Math.floor(Math.random() * 400),
          deviceId: deviceGuid,
          origin: device?.model === 14 ? "GPS" : "BLUETOOTH",
          timestamp: dayjs()
            .add(-5 * timeInstance, "minutes")
            .toISOString(),
          type: "PrecisePassthroughPoint",
          coordinates: {
            lat: nextCoordinate.lat,
            lng: nextCoordinate.lng,
          },
        };

        historyPoints.push(newPasspoint);
        nextCoordinate = this.getNewCoordinates(
          nextCoordinate,
          cluster.step,
          distance
        );
        timeInstance++;
      }
    });

    //invert history points in correct order
    return historyPoints;
  }

  getNewCoordinates(
    coordinate: { lat: number; lng: number },
    step: { lat: number; lng: number },
    distance: number
  ) {
    let newCoordinate = {
      lat:
        coordinate.lat +
        step.lat * distance * (Math.random() + 0.5) +
        Math.random() * 0.0001,
      lng:
        coordinate.lng +
        step.lng * distance * (Math.random() + 0.5) +
        Math.random() * 0.0001,
    };

    return newCoordinate;
  }

  //mock notifications
  async getMockedNotifications(): Promise<PbNotification[]> {
    await this.mockServiceDelay();
    const mockedNotifications: PbNotification[] = [];

    const mockedNotification: PbNotification = {
      id: 1,
      userId: 1,
      deviceId: "1",
      message: "Click here to contact our team.",
      type: "CONTACT_SALES",
      active: true,
      createdAt: new Date().toISOString(),
    };

    const geofenceExitNotification: PbNotification = {
      id: 3,
      userId: 1,
      deviceId: "200000000000",
      message: "Your device example-vf-2 has exited Example fence",
      type: "GEOFENCE_EXITED",
      active: true,
      createdAt: new Date().toISOString(),
      metadata: {
        deviceId: "example-vf-2",
        geofenceId: "ex0",
        geofenceName: "Example fence",
        timestamp: new Date().toISOString(),
        deviceLocation: {
          lat: 32.882946,
          lng: -97.036715,
        },
      },
    };

    const geofenceEnteredNotification: PbNotification = {
      id: 2,
      userId: 1,
      deviceId: "100000000000",
      message: "Your device example-vf-1 has entered Example fence",
      type: "GEOFENCE_ENTERED",
      active: true,
      createdAt: new Date().toISOString(),
      metadata: {
        deviceId: "example-vf-1",
        geofenceId: "ex0",
        geofenceName: "Example fence",
        timestamp: new Date().toISOString(),
        deviceLocation: {
          lat: 32.882451,
          lng: -96.998466,
        },
      },
    };

    mockedNotifications.push(mockedNotification);
    mockedNotifications.push(geofenceEnteredNotification);
    mockedNotifications.push(geofenceExitNotification);

    return mockedNotifications;
  }

  //mock geofences
  async getMockedGeofences(): Promise<Geofence[]> {
    await this.mockServiceDelay();
    return this.mockedGeofences;
  }

  async addMockedGeofence({
    name,
    center,
    radius,
    devices,
  }: {
    name: string;
    center: {
      lat: number;
      lng: number;
    };
    radius: number;
    devices: string[];
  }) {
    const newMockedGeofence: Geofence = {
      id: "g" + this.mockedGeofences.length + 1,
      deviceId: devices,
      enabled: true,
      geometry: {
        coordinates: center,
        radius: radius,
      },
      name: name,
      shape: "circle",
      type: "deviceLevel",
      userId: 1,
    };
    const newMockedGeofences = [...this.mockedGeofences, newMockedGeofence];
    this.mockedGeofences = newMockedGeofences;
    await this.mockServiceDelay();
  }

  async editMockedGeofence({
    id,
    name,
    center,
    radius,
    devices,
    enabled,
  }: {
    id: string;
    name: string;
    center: {
      lat: number;
      lng: number;
    };
    radius: number;
    devices: string[];
    enabled: boolean;
  }) {
    const gf = this.mockedGeofences.find((g) => g.id === id);
    if (gf) {
      const index = this.mockedGeofences.indexOf(gf);
      if (index !== -1) {
        const editedGeofence: Geofence = {
          ...gf,
          name,
          enabled,
          deviceId: devices,
          geometry: { coordinates: center, radius },
        };
        const newMockedGeofences = [...this.mockedGeofences];
        newMockedGeofences[index] = editedGeofence;
        this.mockedGeofences = newMockedGeofences;
        return editedGeofence;
      }
    }
    await this.mockServiceDelay();
  }

  async deleteMockedGeofence(id: string) {
    this.mockedGeofences = this.mockedGeofences.filter((g) => g.id !== id);
    await this.mockServiceDelay();
  }

  getMockedEnterpriseUsers() {
    return this.mockedEnterpriseUsers;
  }

  async addMockedEnterpriseUser(
    email: string,
    access: EnterpriseAccessLevel,
    groups: string[]
  ) {
    const newUser: EnterpriseUser = {
      childUserId: this.mockedEnterpriseUsers.length+1,
      childUserUuid: "uuid" + (this.mockedEnterpriseUsers.length+1),
      childEmail: email,
      deviceGroups: groups.map((g) => {
        const group = this.mockedDeviceGroups.find((mg) => mg.uuid === g);
        return group as DeviceGroup;
      }),
      permission: {
        description: access,
        id: Math.random(),
      },
    };
    const newMockedEnterpriseUsers = [...this.mockedEnterpriseUsers, newUser];
    this.mockedEnterpriseUsers = newMockedEnterpriseUsers;
    await this.mockServiceDelay();
  }

  async editMockedEnterpriseUser(
    id: number,
    access: EnterpriseAccessLevel,
    groups: string[]
  ) {
    const eu = this.mockedEnterpriseUsers.find((u) => u.childUserId === id);
    if (eu) {
      const index = this.mockedEnterpriseUsers.indexOf(eu);
      if (index !== -1) {
        const editedUser: EnterpriseUser = {
          ...eu,
          deviceGroups: groups.map((g) => {
            const group = this.mockedDeviceGroups.find((mg) => mg.uuid === g);
            return group as DeviceGroup;
          }),
          permission: {
            description: access,
            id: Math.random(),
          },
        };
        const newMockedEnterpriseUsers = [...this.mockedEnterpriseUsers];
        newMockedEnterpriseUsers[index] = editedUser;
        this.mockedEnterpriseUsers = newMockedEnterpriseUsers;
      }
    }
    await this.mockServiceDelay();
  }

  async deleteMockedEnterpriseUser(id: number) {
    this.mockedEnterpriseUsers = this.mockedEnterpriseUsers.filter(
      (u) => u.childUserId !== id
    );
    await this.mockServiceDelay();
  }

  getMockedDeviceGroups() {
    return this.mockedDeviceGroups;
  }

  getMockedEnterpriseLogs() {
    return this.mockedEnterpriseLogs;
  }

  mockNow() {
    return dayjs()
      .add(-(Math.random() * 5), "minutes")
      .toISOString();
  }

  async mockServiceDelay() {
    const p = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
    await p;
  }
}

const mockService = new MockService();

export default mockService;
