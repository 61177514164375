import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PbYellow } from "../../app/colors";

export interface geofenceHandlerrState {
  geofenceId?: string;
  geofence?: {
    center: {
      lat: number;
      lng: number;
    };
    radius: number;
  };
  geofenceName: string;
  geofenceColor: string;
  geofenceDevicesIds: string[];
  createStep: "Placement" | "Customization" | "Devices";
  fenceError?: string;
}

const initialState: geofenceHandlerrState = {
  geofenceColor: PbYellow,
  geofenceDevicesIds: [],
  geofenceName: "",
  createStep: "Placement",
  fenceError: undefined
};

export const geofenceHandlerSlice = createSlice({
  name: "geofenceHandlerr",
  initialState,
  reducers: {
    setGeofenceId: (state, action: PayloadAction<string | undefined>) => {
      state.geofenceId = action.payload;
    },
    setGeofence: (
      state,
      action: PayloadAction<
        | {
            center: {
              lat: number;
              lng: number;
            };
            radius: number;
          }
        | undefined
      >
    ) => {
      state.geofence = action.payload;
    },
    setGeofenceColor: (state, action: PayloadAction<string>) => {
      state.geofenceColor = action.payload;
    },
    setGeofenceName: (state, action: PayloadAction<string>) => {
      state.geofenceName = action.payload;
    },
    setGeofenceDevices: (state, action: PayloadAction<string[]>) => {
      state.geofenceDevicesIds = action.payload;
    },
    setStep: (
      state,
      action: PayloadAction<"Placement" | "Customization" | "Devices">
    ) => {
      state.createStep = action.payload;
    },
    clearGeofenceData: (state) => {
      state.createStep = "Placement";
      state.geofenceId = undefined;
      state.geofence = undefined;
      state.geofenceName = "";
      state.geofenceColor = PbYellow;
      state.geofenceDevicesIds = [];
    },
    setFenceError: (state, action: PayloadAction<string | undefined>) => {
      state.fenceError = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setGeofence,
  setGeofenceColor,
  setGeofenceDevices,
  setGeofenceName,
  setStep,
  setGeofenceId,
  clearGeofenceData,
  setFenceError
} = geofenceHandlerSlice.actions;

export default geofenceHandlerSlice.reducer;
