import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Card, CircularProgress, ListItemButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RefreshIcon from "@mui/icons-material/Refresh";
import { thirdLevel } from "../../../../app/levels";
import { boxShadows } from "../../../../app/boxShadows";

export const fadeIn = keyframes`
  from{ opacity: 0; }
  70%: { opacity: 0.5;  }
  to: { opacity: 1; }
`;

export const DataContainer = styled.div((props: { animate: number }) => ({
  padding: "0.5cqi 1.25cqi 1.5cqi 1.25cqi",
  animation: props.animate ? `${fadeIn} 0.5s ease forwards` : undefined,
  boxShadow: boxShadows.hard
}));

export const SubContainer = styled.div((props: { animateSub: number }) => ({
  animation: props.animateSub ? `${fadeIn} 0.5s ease forwards` : undefined,
}));

export const GroupContainer = styled.div({
  fontSize: "0.7cqi",
  textAlign: "left",
  marginBottom: "0.2cqi 0",
});

export const NameContainer = styled.div({
  fontSize: "1.2cqi",
  fontWeight: "700",
  textAlign: "left",
  margin: "0.5vh 0",
});

export const TopIconsContainer = styled.div({
  width: "fit-content",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
})

export const InfoCard = styled(Card)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: "1.5vh",
  marginBottom: "2.5vh",
  padding: "1vh",
  width: "auto",
  boxShadow: boxShadows.medium,
  borderRadius: "0.4cqi"
});

export const CardInfo = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
  marginLeft: "0.2cqi"
});

export const MetaText = styled.div({
  height: "1cqi",
  fontSize: "0.7cqi",
  textAlign: "left",
});

export const AddressText = styled.div({
  fontSize: "0.9cqi",
  textAlign: "left",
  width: "80%",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const SectionTitle = styled.div({
  textAlign: "left",
  margin: "0.6cqi 0px",
  fontSize: "1.05cqi",
  fontWeight: "700",
});

export const FieldTitle = styled.div({
  fontSize: "1cqi",
});
export const FieldValue = styled.div({
  color: "#B7B7B7",
  fontSize: "1cqi",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  position: "relative"
});

export const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const SubscriptionsTopRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "0.6cqi"
});

export const ActionsRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "2vh",
  height: "fit-content",
});
export const LightButton = styled.div({
  height: "3cqi",
  width: "8.5cqi",
  color: " #171717",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: boxShadows.medium,
  borderRadius: "0.4cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1cqi",
  fontWeight: 700,
  ":hover": {
    cursor: "pointer",
  },
});
export const BlueButton = styled.div({
  height: "3cqi",
  width: "8.5cqi",
  color: " #FFFFFF",
  background: "#2C7FF4 0% 0% no-repeat padding-box",
  boxShadow: boxShadows.medium,
  borderRadius: "0.4cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1cqi",
  fontWeight: 700,
});

export const SplitRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "0.4vh",
  borderBottom: "solid 1px #DBDBDB",
  height: "fit-content",
  padding: "0.2cqi",
  position: "relative"
});

export const FooterRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1vh",
  marginBottom: "0.5vh",
  height: "fit-content",
  padding: "5px",
});

export const FooterText = styled.div({
  fontSize: "0.8cqi",
  color: "#C5C5C5",
});

export const RowButton = styled(ListItemButton)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "0.5vh",
  borderBottom: "solid 1px #B7B7B7",
  height: "fit-content",
  padding: "0.2cqi",
  ":hover": {
    cursor: "pointer",
  },
});

export const SubscriptionRowButton = styled(ListItemButton)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "0.5vh",
  marginBottom: "0.5vh",
  borderBottom: "solid 1px #B7B7B7",
  height: "fit-content",
  padding: "0.2cqi",
  ":hover": {
    cursor: "pointer",
  },
});

export const CloseButton = styled(CancelIcon)({
  width: "1.4cqi",
  height: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: thirdLevel,
});

export const BackButton = styled(ArrowBackIosIcon)({
  width: "1.4cqi",
  height: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: thirdLevel,
});

export const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const RefreshButton = styled(RefreshIcon)({
  ":hover": {
    color: "#565656",
  },
});

export const LoaderContainer = styled.span({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0.2cqi 0"
})

export const SubscriptionLoader = styled(CircularProgress)({
  width: "1.8cqi !important",
  height: "1.8cqi !important",
})

export const AddressLoader = styled(CircularProgress)({
  width: "0.6cqi !important",
  height: "0.6cqi !important",
})