import React, { useCallback, useEffect, useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getEnterpriseLogs
} from "./UserManagementSlice";
import { fifthLevel } from "../../app/levels";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import EnterpriseLog from "../../models/enterprise/EnterpriseLogs";
import dayjs from "dayjs";

const ActivityTable = () => {
  const dispatch = useAppDispatch();

  const logs = useAppSelector(
    (state: RootState) => state.userManagement.logs
  );

  const loadingLogs = useAppSelector(
    (state: RootState) => state.userManagement.loadingLogs
  );

  const fetchLogsCallback = useCallback(async () => {
    await dispatch(getEnterpriseLogs());
  }, [dispatch]);

  useEffect(() => {
    fetchLogsCallback();
    const refreshInterval = setInterval(() => {
      fetchLogsCallback();
    }, 180000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [dispatch, fetchLogsCallback]);

  const gridCols: GridColDef<EnterpriseLog>[] = [
    {
      field: "message",
      headerName: "Description",
      flex: 1,
      minWidth: 100,
      sortingOrder: ["desc", "asc"],
      filterable: true,
      disableColumnMenu: true
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      renderCell: (params: { row: EnterpriseLog }) => {
        return <div>{params.row.type}</div>;
      },
      sortingOrder: ["desc", "asc"],
      filterable: true,
      disableColumnMenu: true,
    },
    {
      field: "timestamp",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      renderCell: (params: { row: EnterpriseLog }) => {
        return (
          <div>
            {dayjs(params.row.timestamp).format("MM-DD-YYYY HH:mm (UTC Z)")}
          </div>
        );
      },
      sortingOrder: ["desc", "asc"],
      filterable: true,
      disableColumnMenu: true,
    },
  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <div style={{ flex: 1 }}></div>
        <GridToolbarColumnsButton />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const logsRows = useMemo(()  => {
    return logs.map((l, i) => {return {...l, id: 'log_id_'+i }})
  }, [logs])

  return (
    <DataGrid
      className="mx-5"
      getRowId={(u) => u.id || ''}
      rows={logsRows}
      columns={gridCols}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [{ field: "timestamp", sort: "desc" }],
        },
      }}
      loading={loadingLogs}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      slots={{
        toolbar: CustomToolbar,
        columnSortedAscendingIcon: ArrowUpwardIcon,
        columnSortedDescendingIcon: ArrowDownwardIcon,
        columnUnsortedIcon: ArrowForwardIcon,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
        panel: {
          style: {
            zIndex: fifthLevel + 1,
          },
        },
        basePopper: {
          style: {
            zIndex: fifthLevel + 1,
          },
        },
      }}
    />
  );
};

export default ActivityTable;