import { CircularProgress } from "@mui/material";
import {
  date_MMM_Do_YYYY_Format,
  Subscription,
  UserDevice,
} from "pebblebee-sdk-frontend";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import PbDeviceAvatarIcon from "../../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";
import { postSubscriptionCheckout } from "../../../../dataSlices/SubscriptionsSlice";
import {
  Column,
  FieldTitle,
  FieldValue,
  FooterRow,
  FooterText,
  NameContainer,
  SectionTitle,
  SplitRow,
  SubContainer,
} from "./StyledComponents";
import SubscriptionBadge from "./SubscriptionBadge";

const SubscriptionData = ({
  device,
  subscription,
}: {
  device: UserDevice;
  subscription?: Subscription;
}) => {
  const [animateSub, setAnimateSub] = useState(0);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const devicesCheckouts = useSelector((state: RootState) => {
    return state.subscriptions.devicesCheckouts;
  });

  const loadingCheckout = useSelector((state: RootState) => {
    return state.subscriptions.loadingCheckout;
  });

  const checkoutData = useMemo(() => {
    return devicesCheckouts.find((c) => {
      return c.deviceId === device.mac;
    });
  }, [device.mac, devicesCheckouts]);

  useEffect(() => {
    setAnimateSub(0);
    setTimeout(() => {
      setAnimateSub(1);
    }, 1);
  }, []);

  useEffect(() => {
    const getCheckout = async () => {
      if (subscription && !checkoutData) {
        await dispatch(
          postSubscriptionCheckout({
            mac: device.mac,
            plan: subscription.planId,
          })
        );
      }
    };
    getCheckout();
  }, [checkoutData, device.mac, dispatch, subscription]);

  const getBilledEveryX = () => {
    if (!subscription?.plan) {
      return "";
    } else {
      if (subscription.plan.id === "indiegogo") {
        return `${t("deviceData.subscriptionLabels.indiegogo") as string}`;
      }
      return `${t("deviceData.subscriptionLabels.billedEvery") as string} ${
        subscription.plan.duration.length
      } ${subscription.plan.duration.interval}`;
    }
  };

  const getSubscriptionPrice = () => {
    if (checkoutData?.checkout) {
      const total = checkoutData.checkout.total;
      return `$${total}`;
    }
    if (subscription && subscription.plan) {
      const price = subscription?.plan?.price;
      return `$${price}`;
    }
    return "";
  };

  return (
    <SubContainer animateSub={animateSub}>
      <Column>
        <PbDeviceAvatarIcon
          styles={{
            desktop: {
              height: "4.5cqi",
              width: "4.5cqi",
              borderRadius: "999px",
            },
          }}
          selected
          device={device}
        />
        <NameContainer>{device.name}</NameContainer>
      </Column>
      {subscription ? (
        <>
          <SubscriptionBadge subscription={subscription} />
          <SectionTitle>
            {t("deviceData.subscriptionLabels.planDetails")}
          </SectionTitle>
          {subscription.freeTrial ? (
            <SplitRow>
              <FieldTitle>
                {t("deviceData.subscriptionLabels.freeTrialEnds")}
              </FieldTitle>
              <FieldValue>
                {date_MMM_Do_YYYY_Format(subscription.freeTrial.endsAt)}
              </FieldValue>
            </SplitRow>
          ) : null}
          <SplitRow>
            <FieldTitle>
              {t("deviceData.subscriptionLabels.startDate")}
            </FieldTitle>
            <FieldValue>
              {subscription.startDate
                ? date_MMM_Do_YYYY_Format(subscription.startDate)
                : subscription.freeTrial
                ? date_MMM_Do_YYYY_Format(subscription.freeTrial.claimedAt)
                : "-"}
            </FieldValue>
          </SplitRow>
          <SplitRow>
            <FieldTitle>
              {t("deviceData.subscriptionLabels.nextBilling")}
            </FieldTitle>
            <FieldValue>
              {subscription.planEnd
                ? date_MMM_Do_YYYY_Format(subscription.planEnd)
                : "-"}
            </FieldValue>
          </SplitRow>
          <SplitRow>
            <FieldTitle>{t("deviceData.subscriptionLabels.price")}</FieldTitle>
            <FieldValue>
              {loadingCheckout ? <CircularProgress /> : getSubscriptionPrice()}
            </FieldValue>
          </SplitRow>
          <FooterRow>
            <div></div>
            <FooterText>{getBilledEveryX()}</FooterText>
          </FooterRow>
        </>
      ) : (
        <Column>
          <FieldValue>{t("deviceData.subscriptionLabels.noSub")}</FieldValue>
        </Column>
      )}
    </SubContainer>
  );
};

export default SubscriptionData;
