import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/login/LoginSlice';
import homeReducer from "../features/home/HomeSlice";
import menusReducer from "../features/mainLayout/components/menus/MenusSlice"
import commonSlice from "./CommonSlice";
import mapSlice from '../features/UserMap/MapSlice';
import DevicesSlice from '../dataSlices/DevicesSlice';
import ReadingsSlice from '../dataSlices/ReadingsSlice';
import NotificationsSlice from '../dataSlices/NotificationsSlice';
import SubscriptionsSlice from '../dataSlices/SubscriptionsSlice';
import mainLayoutSlice from '../features/mainLayout/MainLayoutSlice';
import historySlice from '../features/historyLayout/historySlice';
import GeofencesSlice from '../dataSlices/GeofencesSlice';
import geofenceHandlerSlice from '../features/GeofenceCreateEdit/GeofenceHandlerSlice';
import geofencesLayoutSlice from '../features/geofencesLayout/geofencesLayoutSlice';
import userManagementSlice from '../features/UserManagement/UserManagementSlice';

export const store = configureStore({
  reducer: {
    common: commonSlice,
    login: loginReducer,
    home: homeReducer,
    menus: menusReducer,
    devices: DevicesSlice,
    readings: ReadingsSlice,
    subscriptions: SubscriptionsSlice,
    map: mapSlice,
    mainLayout: mainLayoutSlice,
    history: historySlice,
    notifications: NotificationsSlice,
    geofences: GeofencesSlice,
    geofenceHandler: geofenceHandlerSlice,
    geofencesLayout: geofencesLayoutSlice,
    userManagement: userManagementSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
