import React, { useMemo } from "react";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import ReadingsService from "../../../Services/ReadingsService";
import PbDevicesAutocompleteItemProps from "./PbDevicesAutocompleteItemProps";
import PbDeviceAutocompleteItemDesktopView from "./views/PbDeviceAutocompleteItemDesktopView";
import PbDeviceAutocompleteItemMobileView from "./views/PbDeviceAutocompleteItemMobileView";

const PbDeviceAutocompleteItem = ({
  device,
  reading,
  itemOnClick,
}: PbDevicesAutocompleteItemProps) => {
  const isMobileScreen = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const userLocation = useAppSelector((state: RootState) => {
    return state.common.location;
  });

  const loggedUser = useAppSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMetric = useMemo(() => {
    return loggedUser?.isMetric === 1;
  }, [loggedUser]);

  const shared = device.sharedFrom ? 1 : 0;

  const deviceAvatarString = () => {
    if (device.name && device.name[0]) {
      return device.name[0];
    }
    return "";
  };

  const getReadingText = () => {
    return reading
      ? ReadingsService.infoString(reading, userLocation, isMetric)
      : "No readings";
  };

  return isMobileScreen ? (
    <PbDeviceAutocompleteItemMobileView
      avatarText={deviceAvatarString()}
      device={device}
      readingText={getReadingText()}
      shared={shared}
      onItemClick={itemOnClick}
    />
  ) : (
    <PbDeviceAutocompleteItemDesktopView
      avatarText={deviceAvatarString()}
      device={device}
      readingText={getReadingText()}
      shared={shared}
      onItemClick={itemOnClick}
    />
  );
};

export default PbDeviceAutocompleteItem;
