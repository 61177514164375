import { CookieSetOptions } from "universal-cookie";
import CookiesService from "./CookiesService";
import mockService from "./MockService";

const timeInDay = 24 * 60 * 60 * 1000;

export const AccessList = {
  DEVICE_GROUPS_CREATE: "device_groups:create",
  DEVICE_GROUPS_READ: "device_groups:read",
  DEVICE_GROUPS_UPDATE: "device_groups:update",
  DEVICE_GROUPS_DELETE: "device_groups:delete",
  SHARED_DEVICE_CREATE: "share_device:create",
  SHARED_DEVICE_READ: "share_device:read",
  SHARED_DEVICE_UPDATE: "share_device:update",
  SHARED_DEVICE_DELETE: "share_device:delete",
  DEVICE_INFO_CREATE: "device_info:create",
  DEVICE_INFO_READ: "device_info:read",
  DEVICE_INFO_UPDATE: "device_info:update",
  DEVICE_INFO_DELETE: "device_info:delete",
  DEVICE_DELETE: "device:delete",
  TRACKING_MODE_UPDATE: "tracking_mode:update",
  HISTORY_READ: "device_history:read",
  SUBSCRIPTION_READ: "subscriptions:read",
  NOTIFICATION_READ: "notifications:read",
  HELP: "help",
  SHOP: "shop",
  SETTINGS: "settings",
  ADVANCE_SETTINGS: "advanced_settings",
  GEOFENCES_CREATE: "geofences:create",
  GEOFENCES_READ: "geofences:read",
  GEOFENCES_UPDATE: "geofences:update",
  GEOFENCES_DELETE: "geofences:delete",
  GEOFENCES_SINGLE_DEVICE_DELETE: "geofences_single_device:delete",
} as const;

export interface LoggedUser {
  id: number;
  uuid: string;
  email: string;
  firstname?: string;
  lastname?: string;
  imageUrl?: string;
  imageName?: string;
  postalAddress?: string;
  isMetric: number;
  isVerified: number;
  isSubscribed: number;
  isEnterprise: boolean;
  join_date: string;
  updated_at: string;
  devices: string[];
  sharedDevices?: string[];
  enterpriseData?: {
    isChild: boolean;
    isParent: boolean;
    permissionList?: string[];
  };
}

class AuthService {
  isAuthenticated() {
    if (mockService.getIsDemo()) {
      return true;
    }
    const logged = CookiesService.get("pb_session");
    return logged ? true : false;
  }

  getToken() {
    return CookiesService.get("access_token");
  }

  getRefreshToken() {
    return CookiesService.get("refresh_token");
  }

  setSession(staySignedIn: boolean) {
    const date = new Date();
    date.setTime(date.getTime() + timeInDay);
    if (staySignedIn) {
      date.setTime(date.getTime() + 90 * timeInDay);
    }
    const options = { path: "/", expires: date };
    CookiesService.set("pb_session", "logged", options);
  }

  getUserLoggedIn() {
    return CookiesService.get("pb_session");
  }

  handleLoginSuccess(token: string, refreshToken: string) {
    const date = new Date();
    const options: CookieSetOptions = {
      path: "/",
      maxAge: date.setTime(date.getTime() + timeInDay),
    };
    const refreshOptions: CookieSetOptions = {
      path: "/",
      maxAge: date.setTime(date.getTime() + 90 * timeInDay),
    };
    CookiesService.set("access_token", token, options);
    CookiesService.set("refresh_token", refreshToken, refreshOptions);
    return true;
  }

  async saveEnterpriseData({
    isEnterprise,
    enterpriseData,
  }: {
    isEnterprise: boolean;
    enterpriseData: any;
  }) {
    localStorage.setItem("isEnterprise", isEnterprise ? "true" : "false");
    localStorage.setItem("enterpriseData", enterpriseData);
  }

  async getIsEnterpriseFromStorage() {
    const isEnterprise = localStorage.getItem("isEnterprise");
    return isEnterprise !== null && isEnterprise === "true";
  }

  async handleLogOut() {
    CookiesService.remove("access_token");
    CookiesService.remove("refresh_token");
    CookiesService.remove("pb_session");

    return true;
  }
}

export default new AuthService();
