import mockService from "../../../../Services/MockService";
import { EnterpriseAccessLevel } from "../../../../models/enterprise/EnterpriseUser";
import { httpClientCore } from "../../../api";

export async function post_EnterpriseUser({
  email,
  accessLevel,
  deviceGroupIds,
}: {
  email: string;
  accessLevel: EnterpriseAccessLevel;
  deviceGroupIds: string[];
}) {
  try {
    if (mockService.getIsDemo()) {
      await mockService.addMockedEnterpriseUser(
        email,
        accessLevel,
        deviceGroupIds
      );
      return { status: 201 };
    }
    const response = await httpClientCore.post(`/v2/enterprise/users`, {
      deviceGroupIds,
      email,
      accessLevel,
    });
    return response;
  } catch (err) {
    return null;
  }
}
