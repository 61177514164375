import React, { useMemo } from "react";
import styled from "@emotion/styled";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/hooks";
import { setMainLayout } from "../../home/HomeSlice";
import { PbFontGray } from "../../../app/colors";
import { CircularProgress, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { date_MMM_Do_YYYY_Format } from "pebblebee-sdk-frontend";
import HistoryTimelineItem from "./HistoryTimelineItem/HistoryTimelineItem";
import { HistoryPoint } from "../../../Services/ReadingsService";
import { Timeline } from "@mui/lab";
import PassPointTimelineItem from "./HistoryTimelineItem/PassPointTimelineItem";
import { secondLevel } from "../../../app/levels";
import { boxShadows } from "../../../app/boxShadows";

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const ManagerContainer = styled.div({
  position: "fixed",
  top: "6vw",
  bottom: "1vw",
  left: "1vw",
  width: "22vw",
  transition: "0.5s",
  boxShadow: boxShadows.veryHard,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  zIndex: secondLevel,
  background: "#FFFFFF",
});

const TitleSection = styled.div({
  position: "fixed",
  top: "1vw",
  left: "1vw",
  height: "4vw",
  width: "22vw",
  transition: "0.5s",
  boxShadow: boxShadows.veryHard,
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  justifyContent: "center",
  zIndex: secondLevel,
  background: "#FFFFFF",
});

const BackButton = styled(ArrowBackIosIcon)({
  height: "1.5cqi",
  width: "1.5cqi",
  position: "absolute",
  left: "1cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const HistoryTitle = styled.div({
  height: "1.5cqi",
  fontSize: "1.5cqi",
  color: PbFontGray,
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const SelectedDateLabel = styled.div({
  fontSize: "1.3cqi",
  height: "10%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const ContentConainer = styled.div({
  width: "90%",
  height: "88%",
  margin: "1% 0",
  borderRadius: "1cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  overflow: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  position: "relative",
});

const InfoMessage = styled.div({
  fontSize: "1.3cqi",
  color: PbFontGray,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const CustomTimeline = styled(Timeline)({
  width: "100%",
  position: "absolute",
  top: "0%",
  margin: "0px"
});

//Mobile

const SelectedDateLabelMobile = styled.div({
  fontSize: "3.5cqi",
  height: "10%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

const ContentConainerMobile = styled.div({
  width: "100%",
  height: "90%",
  maxHeight: "90%",
  borderRadius: "1cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  overflow: "auto",
  "::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  position: "relative",
});

const InfoMessageMobile = styled.div({
  fontSize: "4.5cqi",
  color: PbFontGray,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const HistoryManager = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const historyPoints = useSelector((state: RootState) => {
    return state.history.historyPoints;
  });

  const selectedDate = useSelector((state: RootState) => {
    return state.history.selectedDate;
  });

  const loadingHistoryPoint = useSelector((state: RootState) => {
    return state.history.loadingHistoryPoint;
  });

  const isMobile = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const historyMenuItems = useMemo(() => {
    let clusterIndex = -1;
    const historyMenuItems: {
      historyPoint: HistoryPoint;
      passPoints: HistoryPoint[];
    }[] = [];

    if (
      loadingHistoryPoint ||
      !historyPoints ||
      !Array.isArray(historyPoints)
    ) {
      return [];
    }

    historyPoints.forEach((hp, i) => {
      if (hp.type === "PreciseCluster") {
        historyMenuItems.push({
          historyPoint: hp,
          passPoints: [],
        });
        clusterIndex = historyMenuItems.length - 1;
      } else {
        if (clusterIndex >= 0 && historyMenuItems[clusterIndex]) {
          historyMenuItems[clusterIndex].passPoints.push(hp);
        } else {
          //if there are no cluster, add the passPoint for display
          historyMenuItems.push({
            historyPoint: hp,
            passPoints: [],
          });
        }
      }
    });
    return historyMenuItems;
  }, [historyPoints, loadingHistoryPoint]);

  const getFormatedDate = () => {
    return date_MMM_Do_YYYY_Format(selectedDate);
  };

  const renderDesktop = () => {
    return (
      <>
        <TitleSection>
          <Row>
            <BackButton
              onClick={() => {
                dispatch(setMainLayout());
              }}
            ></BackButton>
            <HistoryTitle>{t("historyFeature.title")}</HistoryTitle>
          </Row>
        </TitleSection>
        <ManagerContainer>
          <SelectedDateLabel>{getFormatedDate()}</SelectedDateLabel>
          <Divider variant="middle" flexItem />
          <ContentConainer id="history-manager-content">
            {!selectedElementId ? (
              <InfoMessage>
                {t("historyFeature.historyManager.selectDevice")}
              </InfoMessage>
            ) : loadingHistoryPoint ? (
              <CircularProgress />
            ) : (
              historyPoints &&
              Array.isArray(historyPoints) &&
              (historyPoints.length > 0 ? (
                <CustomTimeline>
                  {historyMenuItems.map((menuItem, index) => {
                    return menuItem.historyPoint.type === "PreciseCluster" ? (
                      <HistoryTimelineItem
                        key={`history-list-item-${index}`}
                        cluster={menuItem.historyPoint}
                        passPoints={menuItem.passPoints}
                        index={index}
                        isFirst={index === 0 ? true : false}
                        isLast={
                          index + 1 === historyMenuItems.length ? true : false
                        }
                      />
                    ) : (
                      <PassPointTimelineItem
                        key={`history-list-item-${index}`}
                        inCluster={false}
                        index={index}
                        passPoint={menuItem.historyPoint}
                      />
                    );
                  })}
                </CustomTimeline>
              ) : (
                <InfoMessage>
                  {t("historyFeature.historyManager.noReadings")}
                </InfoMessage>
              ))
            )}
          </ContentConainer>
        </ManagerContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Divider variant="middle" flexItem />
        <ContentConainerMobile id="history-manager-content">
          {!selectedElementId ? (
            <InfoMessageMobile>
              {t("historyFeature.historyManager.selectDevice")}
            </InfoMessageMobile>
          ) : loadingHistoryPoint ? (
            <CircularProgress />
          ) : (
            historyPoints &&
            Array.isArray(historyPoints) &&
            (historyPoints.length > 0 ? (
              <CustomTimeline>
                {historyMenuItems.map((menuItem, index) => {
                  return menuItem.historyPoint.type === "PreciseCluster" ? (
                    <HistoryTimelineItem
                      key={`history-list-item-${index}`}
                      cluster={menuItem.historyPoint}
                      passPoints={menuItem.passPoints}
                      index={index}
                      isFirst={index === 0 ? true : false}
                      isLast={
                        index + 1 === historyMenuItems.length ? true : false
                      }
                    />
                  ) : (
                    <PassPointTimelineItem
                      key={`history-list-item-${index}`}
                      inCluster={false}
                      index={index}
                      passPoint={menuItem.historyPoint}
                    />
                  );
                })}
              </CustomTimeline>
            ) : (
              <InfoMessageMobile>
                {t("historyFeature.historyManager.noReadings")}
              </InfoMessageMobile>
            ))
          )}
        </ContentConainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default HistoryManager;
