import { Subscription } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { subscriptionsClient } from "../../api";

interface getDeviceSubscriptionsResponse {
  status: string;
  subscriptions?: Subscription[];
  error?: {
    message: string;
    code: string;
  };
}

export async function get_DeviceSubscriptions(mac: string) {
  try {
    if (mockService.getIsDemo()) {
      return { data: { status: "success", subscriptions: [mockService.getMockedSubscription(mac)] }, status: 200 };
    }
    const response =
      await subscriptionsClient.get<getDeviceSubscriptionsResponse>(
        `${process.env.REACT_APP_SUBSRIPTIONS_HOST}/subscriptions?deviceId=${mac}`
      );
    return response;
  } catch (err) {
    return null;
  }
}
