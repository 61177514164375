import { t } from "i18next";
import {
  mapLocationsHelper,
  Reading,
  UserDevice,
} from "pebblebee-sdk-frontend";

//Cluster formation distance in meters
const CLUSTER_GROUPING_DISTANCE_MTS = 100;

type ReadingsTypes = "location" | "cluster" | "history";

export interface HistoryPoint {
  deviceId: string;
  timestamp: string;
  coordinates?: {
    lat: number;
    lng: number;
  };
  batteryPercentage: number;
  confidence: number;
  origin: string;
  type: "PreciseCluster" | "PrecisePassthroughPoint" | "ReadingInsideCluster";
  clusterTotalReadings: number;
}

export interface MapReading {
  id: string;
  center: { lat: number; lng: number };
  isCluster: boolean;
  isLocation: boolean;
  isShared: boolean;
  mapReadings: {
    reading: Reading;
    device: UserDevice;
  }[];
}

//to draw anything on the map, MapMarker will have the logic to load the right components
//from home component, build the array necesary for each feature and send it to the map
export interface MapOverlayData {
  id: string;
  index: number;
  center: { lat: number; lng: number };
  readingsType: ReadingsTypes;
  data?: MapReading | HistoryPoint;
}

class ReadingsService {
  calculatedDistance(
    reading: Reading,
    userLocation?: { lat: number; lng: number },
    isMetric?: boolean
  ) {
    if (userLocation && reading.coordinates) {
      //distance in miles
      let distance = mapLocationsHelper.distanceBetweenCordinates(
        userLocation,
        reading.coordinates.value
      );

      if (distance > 0.25) {
        if (!isMetric) {
          return `${distance.toFixed(2)} ${t("deviceData.distanceLabels.milesAway")}`;
        } else {
          //convert to km, 1 mile === 1.6 Km
          return `${(distance * 1.6).toFixed(2)} ${t("deviceData.distanceLabels.kmAway")}`;
        }
      } else {
        if (!isMetric) {
          //convert to feet, 1 mile === 5280 feet
          const feetAway = distance * 5280;
          return `${feetAway.toFixed(0)} ${t("deviceData.distanceLabels.feetAway")}`;
        } else {
          //convert to meters, 1 mile === 1600 m
          const metersAway = distance * 1600;
          return `${metersAway.toFixed(0)} ${t("deviceData.distanceLabels.metersAway")}`;
        }
      }
    }
    return "";
  }

  getTimeSinceReading(reading: Reading) {
    if (reading.coordinates?.timestamp) {
      var startDate = new Date(reading.coordinates.timestamp);
      var endDate = new Date();
      var diff = endDate.getTime() - startDate.getTime();
      var minutes = Math.floor(diff / 1000 / 60);
      if (minutes < 60) {
        if(minutes === 0) {
          return t("deviceData.timeLabels.justNow");
        }
        return `${Math.floor(minutes)} ${t("deviceData.timeLabels.minsAgo")}`;
      } else if (minutes < 24 * 60) {
        const hours = minutes / 60;
        return `${Math.floor(hours)} ${t("deviceData.timeLabels.hsAgo")}`;
      } else if (minutes >= 24 * 60) {
        const days = minutes / (24 * 60);
        return `${Math.floor(days)} ${t("deviceData.timeLabels.daysAgo")}`;
      }
    }
    return "";
  }

  infoString(
    reading: Reading,
    userLocation?: { lat: number; lng: number },
    isMetric?: boolean
  ) {
    const distance = this.calculatedDistance(reading, userLocation, isMetric);
    const time = this.getTimeSinceReading(reading);

    if (!distance && !time) {
      return "-";
    }

    if (!distance) {
      return time;
    }

    if (!time) {
      return distance;
    }

    return distance + ", " + time;
  }

  getZoomBasedDistance = (zoom?: number) => {
    if (zoom) {
      if (zoom >= 5 && zoom <= 7) {
        return CLUSTER_GROUPING_DISTANCE_MTS * 512;
      } else if (zoom > 7 && zoom <= 10) {
        return CLUSTER_GROUPING_DISTANCE_MTS * 64;
      } else if (zoom > 10 && zoom <= 11) {
        return CLUSTER_GROUPING_DISTANCE_MTS * 16;
      } else if (zoom > 11 && zoom <= 19) {
        return CLUSTER_GROUPING_DISTANCE_MTS;
      } else {
        return CLUSTER_GROUPING_DISTANCE_MTS;
      }
    }
    return CLUSTER_GROUPING_DISTANCE_MTS;
  };

  generateMapEntries(
    readings: Reading[],
    devices: UserDevice[],
    hideShared: boolean,
    hiddenGroups: string[],
    zoom?: number,
    hideClusters?: boolean
  ) {
    const mapReadings: MapReading[] = [];
    devices.forEach((device) => {
      const reading = readings.find((r) => r.id === device.guid);
      const isShared = device.sharedFrom ? true : false;
      const show = this.showReading(device, hideShared, hiddenGroups);

      if (reading && reading.coordinates && show) {
        const coordinates = reading.coordinates.value;

        //if there are no center add it
        if (mapReadings.length === 0) {
          mapReadings.push({
            id: `cl_${device.guid || device.id}`,
            center: coordinates,
            isCluster: false,
            isLocation: false,
            isShared: isShared,
            mapReadings: [{ reading: reading, device: device }],
          });
        } else {
          //use to stop searching after a cluster is found
          let clustered = false;
          //control variable to iterate clusters
          let index = 0;

          //iterate clusters until end or until found
          while (!clustered && index < mapReadings.length) {
            const mapReading = mapReadings[index];

            const distance = mapLocationsHelper.distanceBetweenCordinates(
              coordinates,
              mapReading.center
            );
            const distanceInMeters = distance * 1600;

            const distanceToCluster = this.getZoomBasedDistance(zoom);

            //if reading belongs in cluster add it
            if (distanceInMeters <= distanceToCluster && !hideClusters) {
              mapReading.isCluster = true;
              mapReading.mapReadings.push({ reading: reading, device: device });
              //if the element its owned and the cluster is shared mark it as owned
              mapReadings[index].isShared = mapReadings[index].isShared
                ? isShared
                : mapReadings[index].isShared;
              clustered = true;
            }

            //go to next cluster
            index++;
          }

          //if the reading wasn't clustered, add it as a new center
          if (!clustered) {
            mapReadings.push({
              id: `cl_${device.guid || device.id}`,
              center: coordinates,
              isCluster: false,
              isLocation: false,
              isShared: isShared,
              mapReadings: [{ reading: reading, device: device }],
            });
          }
        }
      }
    });

    const mapData: MapOverlayData[] = [];

    mapReadings.forEach((mr, i) => {
      mapData.push({
        id: mr.id,
        index: i,
        center: mr.center,
        readingsType: "cluster",
        data: mr,
      });
    });

    return mapData;
  }

  generateHistoryEntries(historyPoints: HistoryPoint[], zoom?: number) {
    const mapData: MapOverlayData[] = [];
    let clusterNumber = 1;
    if (!historyPoints || !Array.isArray(historyPoints)) {
      return [];
    }
    historyPoints.forEach((point, index) => {
      if (point.coordinates) {
        let id = `hp-${index}`;
        if (point.type === "PreciseCluster") {
          id = clusterNumber + "";
          clusterNumber++;
        }
        const pointData: MapOverlayData = {
          center: point.coordinates,
          index: index,
          readingsType: "history",
          data: point,
          id: id,
        };
        mapData.push(pointData);
      }
    });
    return mapData;
  }

  showReading(device: UserDevice, hideShared: boolean, hiddenGroups: string[]) {
    const isShared = device.sharedFrom ? true : false;
    let groupName = device.groupName;

    const sharedCondition = isShared && hideShared;
    const groupCondition = groupName && hiddenGroups.includes(groupName);
    const MyDevicesCondition =
      !isShared && !groupName && hiddenGroups.includes("My Devices");

    const hide = sharedCondition || groupCondition || MyDevicesCondition;
    return !hide;
  }

  getReadingAddress(geocoding: google.maps.GeocoderResponse) {
    if (geocoding && geocoding.results && geocoding.results.length > 0) {
      const match = geocoding.results[0];
      if (match.address_components && match.address_components.length > 0) {
        const route = match.address_components.find((ac) =>
          ac.types.includes("route")
        );
        const number = match.address_components.find((ac) =>
          ac.types.includes("street_number")
        );
        if (route && number) {
          return `${route.long_name} ${number.long_name}`;
        }
        if (match.formatted_address) {
          return match.formatted_address;
        }
      }
    }
    return "";
  }

  getMiddle(prop: "lat" | "lng", readings: MapReading[]) {
    let values = readings.map((r) => r.center[prop]);
    let min = Math.min(...values);
    let max = Math.max(...values);
    if (prop === "lng" && max - min > 180) {
      values = values.map((val) => (val < max - 180 ? val + 360 : val));
      min = Math.min(...values);
      max = Math.max(...values);
    }
    let result = (min + max) / 2;
    if (prop === "lng" && result > 180) {
      result -= 360;
    }
    return result;
  }

  findCenter(readings: MapReading[]) {
    return {
      lat: this.getMiddle("lat", readings),
      lng: this.getMiddle("lng", readings),
    };
  }

  async getNavigatorGeolocation() {
    let response = {
      location: { lat: 0, lng: 0 },
      error: null,
    };
    if (!navigator.geolocation) {
      response.error = t("home.geolocation.browserNoSupport");
    } else {
      await navigator.geolocation.getCurrentPosition(
        (position) => {
          response.location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            response.error = t("home.geolocation.accessDenied");
          } else {
            response.error = t("home.geolocation.error");
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
        }
      );
    }
    return response;
  }

  centerOnBounds(bounds: { lat: number; lng: number }[]) {
    if (window.gMap) {
      const centerBounds = new google.maps.LatLngBounds();
      bounds.forEach((b) => {
        centerBounds.extend(b);
      });
      window.gMap.fitBounds(centerBounds);
    }
  }
}

const readingsService = new ReadingsService();
export default readingsService;
