import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reading } from "pebblebee-sdk-frontend";
import { get_DevicesReadings } from "../app/apiRequests/platform/getReadings";
import i18n from "../i18n/i18n";

export interface ReadingsState {
  loadingReadings: boolean;
  readings: Reading[];
  readingsError: string;
}

const initialState: ReadingsState = {
  loadingReadings: false,
  readings: [],
  readingsError: "",
};

export const getDevicesReadings = createAsyncThunk(
  "readings/getDevicesReadings",
  async (_, thunkApi) => {
    try {
      const response = await get_DevicesReadings();
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getDeviceReadings.default")
        );
      }
      return response.data.devices;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getDeviceReadings.default")
      );
    }
  }
);

export const ReadingsSlice = createSlice({
  name: "readings",
  initialState,
  reducers: {
    clearReadings: (state) => {
      state.readings = [];
    },
    setError: (state, action: PayloadAction<string>) => {
      state.readingsError = action.payload;
    },
    resetReadingsState: () => initialState
  },
  extraReducers: (builder) => {
    builder

      //get the readgins for user devices
      .addCase(getDevicesReadings.pending, (state) => {
        state.loadingReadings = true;
      })
      .addCase(getDevicesReadings.fulfilled, (state, action) => {
        state.loadingReadings = false;
        state.readings = action.payload;
      })
      .addCase(getDevicesReadings.rejected, (state, action) => {
        state.loadingReadings = false;
        state.readings = [];
        state.readingsError = action.payload as string;
      });
  },
});

export const { clearReadings, setError, resetReadingsState } = ReadingsSlice.actions;

export default ReadingsSlice.reducer;
