import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Avatar, Card, ListItemButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RefreshIcon from "@mui/icons-material/Refresh";
import { fifthLevel, thirdLevel } from "../../../../app/levels";
import { boxShadowsMobile } from "../../../../app/boxShadows";

export const fadeIn = keyframes`
  from{ opacity: 0; }
  70%: { opacity: 0.5;  }
  to: { opacity: 1; }
`;

export const DataContainerMobile = styled.div((props: { animate: number }) => ({
  padding: "0.25cqi 0.5cqi",
  animation: props.animate ? `${fadeIn} 0.5s ease forwards` : undefined,
}));

export const SubContainerMobile = styled.div((props: { animateSub: number }) => ({
  animation: props.animateSub ? `${fadeIn} 0.5s ease forwards` : undefined,
}));

export const GroupContainerMobile = styled.div({
  height: "auto",
  fontSize: "3cqi",
  textAlign: "left",
  marginBottom: "4px",
});

export const NameContainerMobile = styled.div({
  height: "auto",
  fontSize: "4.5cqi",
  textAlign: "left",
  marginBottom: "1vh",
});

export const InfoCardMobile = styled(Card)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  marginBottom: "1.5vh",
  padding: "1vh",
});

export const CardInfoMobile = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  width: "100%",
});

export const AvatarImgMobile = styled(Avatar)({
  height: "10vw",
  width: "10vw",
  borderRadius: "3vw",
  marginRight: "2cqi",
});

export const AvatarImgLargeMobile = styled(Avatar)({
  height: "15vw",
  width: "15vw",
  marginTop: "2cqi",
});

export const MetaTextMobile = styled.div({
  fontSize: "3.5cqi",
  textAlign: "left",
});

export const AddressTextMobile = styled.div({
  fontSize: "3cqi",
  textAlign: "left",
  width: "95%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const SectionTitleMobile = styled.div({
  textAlign: "left",
  margin: "3cqi 0px",
  fontSize: "5cqi",
  fontWeight: "700",
});

export const FieldTitleMobile = styled.div({
  fontSize: "4cqi",
});
export const FieldValueMobile = styled.div({
  color: "#B7B7B7",
  fontSize: "4cqi",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const RowMobile = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ActionsRowMobile = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "10px",
  height: "fit-content",
});
export const LightButtonMobile = styled.div({
  height: "6vh",
  width: "40vw",
  color: " #171717",
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: boxShadowsMobile.medium,
  borderRadius: "2cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "4cqi",
  ":hover": {
    cursor: "pointer",
  },
  marginLeft: "5vw"
});
export const BlueButtonMobile = styled.div({
  height: "6vh",
  width: "40vw",
  color: " #FFFFFF",
  background: "#2C7FF4 0% 0% no-repeat padding-box",
  boxShadow: boxShadowsMobile.medium,
  borderRadius: "2cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "4.5cqi",
  marginRight: "5vw"
});

export const SplitRowMobile = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "0.4vh",
  borderBottom: "solid 1px #B7B7B7",
  height: "fit-content",
  padding: "5px",
});

export const FooterRowMobile = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "1vh",
  marginBottom: "0.5vh",
  height: "fit-content",
  padding: "5px",
});

export const FooterTextMobile = styled.div({
  fontSize: "3cqi",
  color: "#C5C5C5",
});

export const RowButtonMobile = styled(ListItemButton)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "0.5vh",
  borderBottom: "solid 1px #B7B7B7",
  height: "fit-content",
  padding: "0.2cqi",
  ":hover": {
    cursor: "pointer",
  },
});

export const CloseButtonMobile = styled(ArrowBackIcon)({
  top: "0.5cqi",
  right: "0.5cqi",
  width: "5cqi",
  height: "5cqi",
  color: "#000",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: fifthLevel,
});

export const BackButtonMobile = styled(ArrowBackIosIcon)({
  position: "absolute",
  top: "0.5cqi",
  right: "0.5cqi",
  width: "1.4cqi",
  height: "1.4cqi",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: thirdLevel,
});

export const ColumnMobile = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const RefreshButtonMobile = styled(RefreshIcon)({
  ":hover": {
    color: "#565656",
  },
});
