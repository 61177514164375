export const boxShadows = {
    none: "none",
    soft: "0px 0.2cqi 0.4cqi #00000045",
    medium:"0px 0.4cqi 0.8cqi #00000045",
    hard: "0px 0.6cqi 1.2cqi #00000045",
    veryHard: "0px 0.8cqi 1.6cqi #00000045"
}

export const boxShadowsMobile = {
    none: "none",
    soft: "0px 0.2cqi 0.4cqi #00000045",
    medium:"0px 0.4cqi 0.8cqi #00000045",
    hard: "0px 0.6cqi 1.2cqi #00000045",
    veryHard: "0px 0.8cqi 1.6cqi #00000045"
}