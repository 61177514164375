import styled from "@emotion/styled";
import {
  Autocomplete,
  Avatar,
  createFilterOptions,
  createTheme,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { models, Reading, UserDevice } from "pebblebee-sdk-frontend";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import MenuItem from "../../mainLayout/components/MenuContent/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PbFontGray, PbFontGrayWatermark } from "../../../app/colors";
import { fourthLevel, secondLevel } from "../../../app/levels";
import { boxShadows } from "../../../app/boxShadows";
import PbDeviceAutocomplete from "../../../components/PbDevicesAutocomplete/PbDevicesAutocomplete";
import { setSelectedElementId } from "../../home/HomeSlice";
import { useAppDispatch } from "../../../app/hooks";
import PbDeviceAvatarIcon from "../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";

const CoverScreen = styled.div({
  zIndex: secondLevel,
  background: "#00000035",
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
});

const CollapsedBox = styled.div({
  position: "fixed",
  top: "1vw",
  left: "50%",
  transform: "translateX(-50%)",
  width: "15vw",
  maxWidth: "15vw",
  height: "3vw",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: secondLevel,
  background: "#FFFFFF",
  boxShadow: boxShadows.veryHard,
  borderRadius: "999px",
});

const AvatarImage = styled(Avatar)({
  height: "1.5cqi",
  width: "1.5cqi",
  marginLeft: "0.7cqi",
});

const DeviceImage = styled(Avatar)({
  height: "1.5cqi",
  width: "1.5cqi",
  marginLeft: "0.7cqi",
  borderRadius: "0px !important",
});

const DeviceName = styled.div({
  color: PbFontGray,
  textAlign: "center",
  fontSize: "0.9cqi",
  flex: 0.6,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const SelectDeviceLabel = styled.div({
  color: PbFontGrayWatermark,
  fontSize: "0.8cqi",
});

const ArrowIcon = styled(ArrowDropDownIcon)({
  height: "1.2cqi",
  width: "1.2cqi",
});

const theme = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        img: {
          height: "100%",
          width: "auto !important",
        },
      },
    },
  },
});

//MOBILE

const CollapsedBoxMobile = styled.div({
  position: "fixed",
  top: "3vw",
  left: "50%",
  transform: "translateX(-50%)",
  width: "50vw",
  height: "10vw",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  ":hover": {
    cursor: "pointer",
  },
  zIndex: secondLevel,
  background: "#FFFFFF",
  boxShadow: boxShadows.hard,
  borderRadius: "0.7cqi",
});

const DeviceNameMobile = styled.div({
  color: PbFontGray,
  textAlign: "center",
  fontSize: "4cqi",
  flex: 0.6,
});

const DeviceImageMobile = styled(Avatar)({
  height: "6cqi",
  width: "6cqi",
  marginLeft: "1.4cqi",
  borderRadius: "0px !important",
});

const ArrowIconMobile = styled(ArrowDropDownIcon)({
  height: "5cqi",
  width: "5cqi",
});

let AutoCompletethemeMobile = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::placeholder": {
            fontSize: "3cqi",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiAutocomplete-inputRoot": {
            height: "100%",
            padding: "0 !important",
          },
          "& input": {
            height: "auto",
            padding: "0 10px !important",
            zIndex: 999999999,
          },
        },
      },
    },
  },
});

const DeviceSelector = () => {
  const [showSelector, setShowSelector] = useState(false);

  const dispatch = useAppDispatch();

  const devices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const selectedDevice = useMemo(() => {
    setShowSelector(false);
    return devices.find((dev) => dev.guid === selectedElementId);
  }, [devices, selectedElementId]);

  const options = useMemo(() => {
    try {
      return devices.map((d) => {
        const reading = readings.find((r) => r.id === d.guid);
        const group =
          d.groupName && d.groupName
            ? d.groupName
            : d.sharedFrom
            ? "Shared"
            : "My devices";
        return {
          group: group,
          device: d,
          reading: reading,
        };
      });
    } catch {
      return [];
    }
  }, [devices, readings]);

  const filterOptions = createFilterOptions({
    limit: 15,
    stringify: (option: {
      group: string;
      device: UserDevice;
      reading?: Reading;
    }) => option.device?.name || "",
  });

  const onClickOutside = () => {
    setShowSelector(false);
  };

  const getModelImage = () => {
    if (selectedDevice && selectedDevice.model) {
      const modelData = models.getModelInfo(selectedDevice.model);
      return modelData.img;
    } else {
      const defaultData = models.getModelInfo(-1);
      if (defaultData && defaultData.img) {
        return defaultData.img;
      }
    }
    return "";
  };

  const renderDesktop = () => {
    return (
      <>
        {showSelector ? (
          <>
            <PbDeviceAutocomplete
              openOnFocus={true}
              open={true}
              itemOnClick={(device: UserDevice) => {
                dispatch(setSelectedElementId(device.guid || device.id));
                setShowSelector(false);
              }}
              onClickAway={() => {
                setShowSelector(false);
              }}
              onClose={() => {
                setShowSelector(false);
              }}
              hideAdorment={true}
              styles={{
                desktop: {
                  position: "fixed",
                  top: "2%",
                  right: "50%",
                  transform: "translateX(50%)",
                  width: "40vw",
                  height: "3cqi",
                  transition: "0.5s",
                  zIndex: fourthLevel,
                  background: "#FFFFFF",
                  borderRadius: "1vw",
                },
                mobile: {
                  position: "fixed",
                  top: "1vh",
                  right: "50%",
                  transform: "translateX(15%)",
                  width: "55vw",
                  transition: "0.5s",
                  zIndex: fourthLevel,
                  background: "#FFFFFF",
                  borderRadius: "1vw",
                },
              }}
              textFieldStyles={{
                desktop: {
                  zIndex: fourthLevel,
                  borderRadius: "1vw",
                  fontSize: "2cqi",
                  height: "100%",
                },
                mobile: {
                  zIndex: fourthLevel,
                  borderRadius: "1vw",
                  width: "55vw",
                },
              }}
            />
            <CoverScreen onClick={onClickOutside} />
          </>
        ) : (
          <>
            <CollapsedBox
              onClick={() => {
                setShowSelector(true);
              }}
            >
              {selectedDevice ? (
                <>
                  <div style={{ flex: 0.2, marginLeft: "0.7cqi" }}>
                    <PbDeviceAvatarIcon
                      device={selectedDevice}
                      styles={{
                        desktop: { width: "1.7cqi", height: "1.7cqi" },
                      }}
                    />
                  </div>
                  <DeviceName>{selectedDevice?.name || ""}</DeviceName>
                </>
              ) : (
                <>
                  <DeviceImage src={getModelImage()}>-</DeviceImage>
                  <SelectDeviceLabel>Select a device</SelectDeviceLabel>
                </>
              )}
              <ArrowIcon style={{ flex: 0.2 }} />
            </CollapsedBox>
          </>
        )}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        {" "}
        {showSelector ? (
          <>
            <ThemeProvider theme={AutoCompletethemeMobile}>
              <Autocomplete
                id="history-devices-search"
                options={options}
                openOnFocus
                groupBy={(op) => op.group}
                getOptionLabel={(reading) => reading.device?.name || ""}
                renderOption={(props, option, { selected }) => (
                  <li
                    {...props}
                    style={{
                      zIndex: fourthLevel,
                      background: "#FFF",
                      padding: "0px",
                    }}
                    onClick={() => {
                      setShowSelector(false);
                    }}
                  >
                    <MenuItem
                      device={option.device}
                      reading={option.reading}
                      expanded={true}
                    />
                  </li>
                )}
                filterOptions={filterOptions}
                style={{
                  position: "fixed",
                  top: "2%",
                  right: "50%",
                  transform: "translateX(50%)",
                  width: "90vw",
                  height: "fit-content",
                  transition: "0.5s",
                  zIndex: fourthLevel,
                  background: "#FFFFFF",
                  borderRadius: "1vw",
                }}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    style={{
                      zIndex: fourthLevel,
                      borderRadius: "1vw",
                      fontSize: "2cqi",
                    }}
                    {...params}
                    placeholder={`Searching out of ${options.length} devices`}
                  />
                )}
              />
              <CoverScreen onClick={onClickOutside} />
            </ThemeProvider>
          </>
        ) : (
          <>
            <CollapsedBoxMobile
              onClick={() => {
                setShowSelector(true);
              }}
            >
              {selectedDevice ? (
                <>
                  <div style={{ flex: 0.2 }}>
                    {selectedDevice?.imageUrl ? (
                      <AvatarImage
                        src={selectedDevice.imageUrl || getModelImage()}
                      >
                        -
                      </AvatarImage>
                    ) : (
                      <ThemeProvider theme={theme}>
                        <DeviceImageMobile src={getModelImage()}>
                          -
                        </DeviceImageMobile>
                      </ThemeProvider>
                    )}
                  </div>
                  <DeviceNameMobile>
                    {selectedDevice?.name || ""}
                  </DeviceNameMobile>
                </>
              ) : (
                <>
                  <DeviceImageMobile src={getModelImage()}>-</DeviceImageMobile>
                  <SelectDeviceLabel>Select a device</SelectDeviceLabel>
                </>
              )}
              <ArrowIconMobile style={{ flex: 0.2 }} />
            </CollapsedBoxMobile>
          </>
        )}
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default DeviceSelector;
