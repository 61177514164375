import styled from "@emotion/styled";
import { getFormatedDayhour } from "pebblebee-sdk-frontend";
import React, { useState } from "react";
import {
  PbFontBlack,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../app/colors";
import ReadingsService, {
  HistoryPoint,
} from "../../../../Services/ReadingsService";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setSelectedHistoryPoint } from "../../historySlice";
import AddressField from "../../../../components/AddressLabel/AddressLabel";
import PassPointTimelineItem from "./PassPointTimelineItem";
import { RootState } from "../../../../app/store";
import {
  CustomTimelineConnector,
  CustomTimelineContent,
  CustomTimeLineDot,
  CustomTimelineItem,
  CustomTimelineOppositeContent,
  CustomTimelineSeparator,
} from "./TimelineItemStyledComponents";

interface HistoryTimelineItemProps {
  index: number;
  cluster: HistoryPoint;
  passPoints: HistoryPoint[];
  isFirst: boolean;
  isLast: boolean;
}
export const PbTimelineConnector = styled(TimelineConnector)({
  background: PbYellow,
  width: "3px",
});

const Column = styled.div({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
});

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "3px 0",
});

const AddressLabel = styled.div({
  color: PbFontBlack,
  fontSize: "0.85cqi",
  ":hover": {
    cursor: "pointer",
    color: "#000",
  },
});

const PassPointContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  borderTop: `1px solid ${PbFontGrayWatermark}`,
  borderBottom: `1px solid ${PbFontGrayWatermark}`,
  ":hover": {
    cursor: "pointer",
  },
  margin: "3px 0",
});

const PassPointsLabel = styled.div({
  fontSize: "0.7cqi",
  color: PbFontGrayWatermark,
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
});

const DropArrow = styled(KeyboardArrowDownIcon)(
  (props: { expanded: number }) => ({
    color: PbFontGrayWatermark,
    transform: props.expanded === 1 ? "rotate(180deg)" : undefined,
    transition: "0.4s",
  })
);

//Mobile

const CustomTimelineOppositeContentMobile = styled(TimelineOppositeContent)({
  flex: 0.3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "3cqi",
});

const PbTimeLineDotMobile = styled(TimelineDot)({
  borderColor: PbYellow,
  border: `solid 4px ${PbYellow}`,
  height: "1cqi",
  width: "1cqi",
  padding: "1cqi",
  borderWidth: "1cqi",
});

const PbTimelineConnectorMobile = styled(TimelineConnector)({
  background: PbYellow,
  width: "0.7cqi",
});

const AddressLabelMobile = styled.div({
  color: PbFontBlack,
  fontSize: "4cqi",
  ":hover": {
    cursor: "pointer",
    color: "#000",
  },
});

const PassPointsLabelMobile = styled.div({
  fontSize: "3.5cqi",
  color: PbFontGrayWatermark,
});

const HistoryTimelineItem = (props: HistoryTimelineItemProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const getFormatedDate = (date: string) => {
    try {
      const time = new Date(date).getTime();
      return getFormatedDayhour(time);
    } catch {
      return "";
    }
  };

  const handlePassClick = () => {
    setExpand(!expand);
  };

  const handleAddressClick = () => {
    const id: string = props.cluster.deviceId + props.cluster.timestamp;
    dispatch(setSelectedHistoryPoint(id));
    if (props.cluster.coordinates) {
      ReadingsService.centerOnBounds([props.cluster.coordinates]);
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <CustomTimelineItem>
          <CustomTimelineOppositeContent color="text.secondary">
            {getFormatedDate(props.cluster.timestamp)}{" "}
          </CustomTimelineOppositeContent>
          <CustomTimelineSeparator>
            <CustomTimelineConnector />
            <CustomTimeLineDot variant="outlined" />
            <CustomTimelineConnector />
          </CustomTimelineSeparator>
          <CustomTimelineContent>
            <Column>
              <Row>
                <AddressLabel onClick={handleAddressClick}>
                  <AddressField coordinates={props.cluster.coordinates} />
                </AddressLabel>
              </Row>
              {props.passPoints &&
              Array.isArray(props.passPoints) &&
              props.passPoints.length > 0 ? (
                <PassPointContainer onClick={handlePassClick}>
                  <DropArrow expanded={expand ? 1 : 0} />
                  <PassPointsLabel>
                    {props.passPoints.length} passthrough points
                  </PassPointsLabel>
                </PassPointContainer>
              ) : null}
            </Column>
          </CustomTimelineContent>
        </CustomTimelineItem>
        {expand ? (
          <>
            {props.passPoints.map((ps, index) => {
              return (
                <PassPointTimelineItem
                  key={`cluster-${props.index}-passpoint-${index}`}
                  passPoint={ps}
                  index={index}
                  inCluster={true}
                />
              );
            })}
          </>
        ) : null}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <CustomTimelineItem>
          <CustomTimelineOppositeContentMobile color="text.secondary">
            {getFormatedDate(props.cluster.timestamp)}{" "}
          </CustomTimelineOppositeContentMobile>
          <TimelineSeparator>
            <PbTimelineConnectorMobile />
            <PbTimeLineDotMobile variant="outlined" />
            <PbTimelineConnectorMobile />
          </TimelineSeparator>
          <CustomTimelineContent>
            <Column>
              <Row>
                <AddressLabelMobile onClick={handleAddressClick}>
                  <AddressField coordinates={props.cluster.coordinates} />
                </AddressLabelMobile>
              </Row>
              {props.passPoints &&
              Array.isArray(props.passPoints) &&
              props.passPoints.length > 0 ? (
                <PassPointContainer onClick={handlePassClick}>
                  <DropArrow expanded={expand ? 1 : 0} />
                  <PassPointsLabelMobile>
                    {props.passPoints.length} passthrough points
                  </PassPointsLabelMobile>
                </PassPointContainer>
              ) : null}
            </Column>
          </CustomTimelineContent>
        </CustomTimelineItem>
        {expand ? (
          <>
            {props.passPoints.map((ps, index) => {
              return (
                <PassPointTimelineItem
                  key={`cluster-${props.index}-passpoint-${index}`}
                  passPoint={ps}
                  index={index}
                  inCluster={true}
                />
              );
            })}
          </>
        ) : null}
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default HistoryTimelineItem;
