import { models } from "pebblebee-sdk-frontend";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { DeviceData } from "./DeviceData";

const DeviceDetails = () => {
  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const selectedElement = useMemo(() => {
    const device = userDevices.find(d => d.guid === selectedElementId)
    const reading = readings.find(r => r.id === selectedElementId)

    if(!device) return null

    return {
      data: device,
      reading: reading
    }
  }, [readings, selectedElementId, userDevices])

  const isCellular = useMemo(() => {
    if (selectedElement) {
      return models.isCellular(
        (selectedElement.data.model as number) || ""
      );
    }
    return false;
  }, [selectedElement]);

  return (
    <>
      {selectedElement ? (
        <DeviceData
          device={selectedElement.data}
          reading={selectedElement.reading}
          isCellular={isCellular}
        />
      ) : null}
    </>
  );
};

export default DeviceDetails;
