import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import queueService from "../../Services/QueueService";
import { HistoryPoint } from "../../Services/ReadingsService";
import {
  setCoordinateAddress,
  setMapEntries,
  setMapLines,
} from "../UserMap/MapSlice";
import DeviceSelector from "./components/DeviceSelector";
import HistoryDatePicker from "./components/HistoryDatePicker";
import HistoryManager from "./components/HistoryManager";
import HistoryMap from "./components/HistoryMap";
import {
  clearHistoryPoints,
  getHistory,
  setSelectedDate,
  setSelectedHistoryPoint,
} from "./historySlice";

const MobileControlsContainer = styled.div({
  width: "100vw",
  height: "40vh",
});

const HistoryLayout = () => {
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const selectedDate = useSelector((state: RootState) => {
    return state.history.selectedDate;
  });

  const historyPoints = useSelector((state: RootState) => {
    return state.history.historyPoints;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  useEffect(() => {
    return () => {
      //clear all the history and map state
      queueService.cleanQueue();
      dispatch(setSelectedDate(new Date().toISOString()));
      dispatch(setSelectedHistoryPoint(undefined));
      dispatch(clearHistoryPoints());
      dispatch(setMapEntries([]));
      dispatch(setMapLines([]));
    };
  }, [dispatch]);

  useEffect(() => {
    queueService.cleanQueue();
    const fetchHistory = async () => {
      const fromDate = new Date(selectedDate);
      fromDate.setHours(0, 0, 0, 0);
      const toDate = new Date(selectedDate);
      toDate.setHours(23, 59, 59, 999);
      if (selectedElementId && selectedElementId !== "") {
        await dispatch(
          getHistory({
            deviceId: selectedElementId,
            fromDate: fromDate.toISOString().split(".")[0] + "Z",
            toDate: toDate.toISOString().split(".")[0] + "Z",
          })
        );
      }
    };
    fetchHistory();
  }, [dispatch, selectedDate, selectedElementId]);

  useEffect(() => {
    dispatch(setSelectedDate(new Date().toISOString()));
  }, [dispatch, selectedElementId]);

  useEffect(() => {
    const addAddressCallback = ({
      coordinates,
      status,
      address,
    }: {
      coordinates: { lat: number; lng: number };
      status: google.maps.GeocoderStatus | "pending";
      address: string;
    }) => {
      dispatch(
        setCoordinateAddress({
          coordinates,
          status,
          address,
        })
      );
    };
    const fetchAddress = async () => {
      if (historyPoints && Array.isArray(historyPoints)) {
        let clusters: HistoryPoint[] = [];
        let passPoints: HistoryPoint[] = [];

        historyPoints.forEach((hp) => {
          if (hp.type === "PreciseCluster") {
            clusters.push(hp);
          } else {
            passPoints.push(hp);
          }
        });

        const sortedHp = [...clusters, ...passPoints];

        for (const hp of sortedHp) {
          if (hp.coordinates) {
            //set the address status to pending
            addAddressCallback({
              coordinates: hp.coordinates,
              status: "pending",
              address: "",
            });
            //adds request to the queue to be processed
            queueService.addToGeocodingQueue(
              hp.coordinates,
              addAddressCallback
            );
          }
        }
        //starts processing the queue
        queueService.startGeocodingQueue();
      }
    };
    fetchAddress();
  }, [dispatch, historyPoints]);

  const renderDesktop = () => {
    return (
      <div>
        <HistoryManager />
        <DeviceSelector />
        <HistoryDatePicker />
        <HistoryMap />
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div>
        <DeviceSelector />
        <HistoryMap />
        <MobileControlsContainer>
          <HistoryDatePicker />
          <HistoryManager />
        </MobileControlsContainer>
      </div>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default HistoryLayout;
