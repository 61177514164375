import styled from "@emotion/styled";
import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { PbDarkYellow, PbYellow } from "../../app/colors";
import { healthCheck } from "../../app/CommonSlice";
import { useAppDispatch } from "../../app/hooks";
import Logo from "../../assets/imgs/Pebblebee_Logo_text-01.png";

const FullScreenContainer = styled(Box)({
  width: "100%",
  height: "100%",
  position: "fixed",
  top: "0px",
  left: "0px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
});

const Title = styled.h1`
  font-size: 3cqi;
  color: black;
`;

const Message = styled.p`
  font-size: 1.2cqi;
  color: black;
  width: 60%;
`;

const TryAgainButton = styled(Button)({
  background: PbYellow,
  color: "black",
  width: "300px",
  marginTop: "100px",
  ":hover": {
    background: PbDarkYellow,
  },
});

const PbLogo = styled.img({
  width: "200px",
});

const MaintenanceScreen = () => {
  const dispatch = useAppDispatch();

  const maleHealthCheck = useCallback(async () => {
    await dispatch(healthCheck({ simulateFailure: false }));
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      maleHealthCheck();
    }, 180000);
    return () => {
        clearInterval(interval);
    }
  }, [maleHealthCheck]);

  const handleClick = () => {
    window.location.reload();
  };
  return (
    <FullScreenContainer>
      <PbLogo src={Logo}></PbLogo>
      <Title>App Under Maintenance</Title>
      <Message>
        We're currently performing maintenance on the Pebblebee app. This
        process should be completed within a few minutes. Please bear with us
        and try again shortly.
      </Message>
      <TryAgainButton variant="contained" onClick={handleClick}>
        {" "}
        Try again
      </TryAgainButton>
    </FullScreenContainer>
  );
};

export default MaintenanceScreen;
