import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Geofence } from "pebblebee-sdk-frontend";

export interface GeofencesLayoutState {
  showCreationHandler: boolean;
  hiddenGeofences: string[];
  showDeleteFenceModal: boolean;
}

const initialState: GeofencesLayoutState = {
  showCreationHandler: false,
  hiddenGeofences: [],
  showDeleteFenceModal: false
};

export const GeofencesLayoutSlice = createSlice({
  name: "geofences",
  initialState,
  reducers: {
    setShowCreationHandler: (state, action: PayloadAction<boolean>) => {
      state.showCreationHandler = action.payload;
    },
    addHiddenGeofence: (state, action: PayloadAction<Geofence>) => {
      state.hiddenGeofences.push(action.payload.name);
    },
    setShowDeleteFenceModal: (state, action: PayloadAction<boolean>) => {
      state.showDeleteFenceModal = action.payload;
    },
    removeHiddenGeofence: (state, action: PayloadAction<Geofence>) => {
      const index = state.hiddenGeofences.indexOf(action.payload.name)
      if(index !== -1) {
        state.hiddenGeofences.splice(index, 1);
      }
      
    },
  },
  extraReducers: (builder) => {},
});

export const { setShowCreationHandler, addHiddenGeofence, removeHiddenGeofence, setShowDeleteFenceModal } = GeofencesLayoutSlice.actions;

export default GeofencesLayoutSlice.reducer;
