import styled from "@emotion/styled";
import React from "react";
import { fifthLevel, fourthLevel, secondLevel } from "../../../app/levels";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { PbFontGray } from "../../../app/colors";
import { useAppDispatch } from "../../../app/hooks";
import { setMainLayout } from "../../home/HomeSlice";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { setShowCreationHandler } from "../geofencesLayoutSlice";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { clearGeofenceData } from "../../GeofenceCreateEdit/GeofenceHandlerSlice";
import { boxShadows } from "../../../app/boxShadows";
import { AccessList } from "../../../Services/AuthService";

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const TitleSection = styled.div({
  position: "fixed",
  top: "1vw",
  left: "1vw",
  height: "4vw",
  width: "22vw",
  transition: "0.5s",
  boxShadow: boxShadows.veryHard,
  borderRadius: "0.7cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  justifyContent: "center",
  zIndex: secondLevel,
  background: "#FFFFFF",
});

const BackButton = styled(ArrowBackIosIcon)({
  height: "1.3cqi",
  width: "1.3cqi",
  position: "absolute",
  left: "1cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const AddButton = styled(AddIcon)({
  height: "1.3cqi",
  width: "1.3cqi",
  position: "absolute",
  right: "1cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const HistoryTitle = styled.div({
  height: "2cqi",
  fontSize: "1.2cqi",
  color: PbFontGray,
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

//MOBILE

const TitleSectionMobile = styled.div({
  width: "100%",
  height: "fit-content",
  transition: "0.5s",
  boxShadow: boxShadows.veryHard,
  borderRadius: "0.7cqi",
  display: "flex",
  flexDirection: "column",
  alignItems: "normal",
  justifyContent: "center",
  zIndex: fifthLevel,
  background: "#FFFFFF",
  marginTop: "5cqi",
});

const BackButtonMobile = styled(ArrowBackIosIcon)({
  height: "4.5cqi",
  width: "4.5cqi",
  margin: "0 4cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const AddButtonMobile = styled(AddIcon)({
  height: "6cqi",
  width: "6cqi",
  margin: "0 4cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const FencesTitleMobile = styled.div({
  fontSize: "5cqi",
  color: PbFontGray,
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const GeofencesManager = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const showCreationHandler = useSelector((state: RootState) => {
    return state.geofencesLayout.showCreationHandler;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const handleBackButtonClick = () => {
    if (showCreationHandler) {
      dispatch(setShowCreationHandler(false));
    } else {
      dispatch(setMainLayout());
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <TitleSection>
          <Row>
            <BackButton onClick={handleBackButtonClick}></BackButton>
            <HistoryTitle>{t("geofences.title")}</HistoryTitle>
            {loggedUser?.enterpriseData?.permissionList?.includes(
              AccessList.GEOFENCES_CREATE
            ) ? (
              showCreationHandler ? null : (
                <Tooltip title={t("geofences.geofencesManager.addNewTooltip")}>
                  <AddButton
                    onClick={() => {
                      dispatch(clearGeofenceData());
                      dispatch(setShowCreationHandler(true));
                    }}
                  ></AddButton>
                </Tooltip>
              )
            ) : null}
          </Row>
        </TitleSection>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <TitleSectionMobile>
          <Row>
            <BackButtonMobile
              onClick={handleBackButtonClick}
            ></BackButtonMobile>
            <FencesTitleMobile>{t("geofences.title")}</FencesTitleMobile>
            {showCreationHandler ? null : (
              <Tooltip title={t("geofences.geofencesManager.addNewTooltip")}>
                <AddButtonMobile
                  onClick={() => {
                    dispatch(clearGeofenceData());
                    dispatch(setShowCreationHandler(true));
                  }}
                ></AddButtonMobile>
              </Tooltip>
            )}
          </Row>
        </TitleSectionMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default GeofencesManager;
