import React, { useEffect, useState } from "react";
import PbMapsProps from "./PbMapProps.type";
import { GoogleMap, useJsApiLoader, OverlayView } from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";
import { PbErrorMessage } from "pebblebee-sdk-frontend";

const CenterContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  height: "100%",
});

export default function PbMap<T>(props: PbMapsProps<T>) {
  const mapRef = React.useRef<google.maps.Map>();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: props.ApiKey,
    ...props.loadOptions,
  });

  const [gMap, setGMap] = useState<google.maps.Map>();
  const [loadedMarkes, setLoadedMarkes] = useState(props.markers);

  useEffect(() => {
    if (props.getMapRef) {
      props.getMapRef(mapRef);
    }
  }, [mapRef, props]);

  const onLoad = React.useCallback(
    function callback(map: google.maps.Map) {
      setLoadedMarkes(props.markers);

      if (props.mapOptions) {
        map.setOptions(props.mapOptions);
      } else {
        const options: google.maps.MapOptions = {
          clickableIcons: false,
          streetViewControl: false,
          disableDefaultUI: true,
          fullscreenControl: false,
        };
        map.setOptions(options);
      }

      map.setZoom(15);
      if (props.getMapInstance) {
        props.getMapInstance(map);
      }
      setGMap(map);
    },
    [props]
  );

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setGMap(undefined);
  }, []);

  useEffect(() => {
    setLoadedMarkes(props.markers);
  }, [props.markers]);

  const [mapBounds, setMapBounds] = useState<
    google.maps.LatLngBounds | undefined
  >();

  useEffect(() => {
    if (window.gMap) {
      window.gMap.addListener("bounds_changed", function () {
        const bounds = window.gMap.getBounds();
        setMapBounds(bounds);
      });
    }
  }, [window.gMap]);

  return loadError ? (
    <CenterContainer>
      <PbErrorMessage message="Unable to load map" bigText />
    </CenterContainer>
  ) : isLoaded ? (
    <GoogleMap
      mapContainerStyle={props.containerStyle}
      center={props.center}
      zoom={props.zoom ? props.zoom : 15}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onResize={() => {}}
      onZoomChanged={() => {
        if (gMap) {
          //if zoom callback defined pass zoom to parent
          if (props.onZoomChange) {
            props.onZoomChange(gMap?.getZoom() || 0);
          }
        }
      }}
      options={{
        maxZoom: 19,
        minZoom: 3,
        mapTypeId: props.mapTypeId ? props.mapTypeId : "roadmap",
      }}
      onDragEnd={() => {
        if (props.onCenterChange && gMap) {
          const center = gMap.getCenter();
          const centarCoordinates = {
            lat: center?.lat() || 0,
            lng: center?.lng() || 0,
          };
          props.onCenterChange(centarCoordinates);
        }
      }}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        {loadedMarkes &&
          loadedMarkes.map((el, i) => {
            return (
              <PbOverlayView
                bounds={mapBounds}
                id={el.id}
                compProps={el.compProps}
                position={el.position}
                component={props.component}
                infoWindowComponent={props.infoWindowComponent}
                isFocus={el.isFocus}
              />
            );
          })}
      </>
    </GoogleMap>
  ) : (
    <CenterContainer>
      <CircularProgress />
    </CenterContainer>
  );
}

interface PbOverlayViewProps<T> {
  id?: string | undefined;
  position: {
    lat: number;
    lng: number;
  };
  compProps: T;
  component: (props: T) => JSX.Element;
  infoWindowComponent?: ((props: T) => JSX.Element) | undefined;
  bounds: google.maps.LatLngBounds | undefined;
  isFocus?: boolean;
}

interface PbOverlayViewState {
  isVisible: boolean;
}

//Convert to class component, only update if it should
class PbOverlayView<T> extends React.Component<
  PbOverlayViewProps<T>,
  PbOverlayViewState
> {
  constructor(props: PbOverlayViewProps<T>) {
    super(props);
    this.shouldShow = this.shouldShow.bind(this);
    this.setState({
      isVisible: false,
    });
  }

  shouldComponentUpdate(
    nextProps: Readonly<PbOverlayViewProps<T>>,
    nextState: Readonly<PbOverlayViewState>,
    nextContext: any
  ): boolean {
    const { position, bounds, isFocus } = this.props;

    const boundsLoaded = bounds === undefined && nextProps.bounds !== undefined;

    const wasFocused =
      (isFocus === undefined || isFocus === false) &&
      nextProps.isFocus !== undefined &&
      nextProps.isFocus === true;

    //if the bound just loaded update de component
    if (boundsLoaded) {
      return true;
    }

    if (bounds && nextProps.bounds) {
      const inBounds = bounds.contains(position);
      const nextInBounds = nextProps.bounds.contains(nextProps.position);

      //if there is no state update to generate one
      //if the object is visible but the previous state was not, update
      const updateVisible = !this?.state || (!this.state.isVisible && inBounds);

      return (
        inBounds !== nextInBounds ||
        updateVisible ||
        this.props.compProps !== nextProps.compProps ||
        wasFocused
      );
    }
    return false;
  }

  shouldShow() {
    const inBounds =
      this.props.bounds && this.props.bounds.contains(this.props.position);
    if (inBounds) {
      this.setState((state) => ({
        isVisible: true,
      }));
    } else {
      this.setState((state) => ({
        isVisible: false,
      }));
    }
    return inBounds;
  }

  render() {
    return this.shouldShow() ? (
      <span key={`ov-vw-${Math.random()}`}>
        {this.props.infoWindowComponent ? (
          <OverlayView
            key={`ov-vw-fp-${Math.random()}`}
            position={this.props.position}
            mapPaneName={OverlayView.FLOAT_PANE}
          >
            <div>{this.props.infoWindowComponent(this.props.compProps)}</div>
          </OverlayView>
        ) : null}
        <OverlayView
          key={`ov-vw-omt-${Math.random()}`}
          position={this.props.position}
          mapPaneName={
            this.props.isFocus
              ? OverlayView.FLOAT_PANE
              : OverlayView.OVERLAY_MOUSE_TARGET
          }
        >
          <div>{this.props.component(this.props.compProps)}</div>
        </OverlayView>
      </span>
    ) : null;
  }
}
