import mockService from "../../../Services/MockService";
import { v1Client } from "../../api";

interface putUserDeviceResponse {
  data: any;
}

export async function put_UserDevice({
  mac,
  newTracking,
}: {
  mac: string;
  newTracking: string;
}) {
  try {
    const payload : { [mac:string]: { tracking: string} } = {
      [mac]: {
        tracking: newTracking,
      }
    }
    if (mockService.getIsDemo()) {
      return {
        data: { data: { devices: await mockService.updateMockedDevice(mac, newTracking) } },
        status: 200,
      };
    }
   
    const response = await v1Client.put<putUserDeviceResponse>(
      `/v1/devices/${mac}`,
      {
        ...payload
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
