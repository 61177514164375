import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import styled from "@emotion/styled";
import {
  hideGlobalError,
  setGlobalError,
  setLoadingLocation,
  setLocation,
  setRefreshingToken,
} from "../../app/CommonSlice";
import { Alert, Snackbar } from "@mui/material";
import { useAppDispatch } from "../../app/hooks";
import { useTranslation } from "react-i18next";
import { getUser, refresToken } from "../login/LoginSlice";
import {
  setShowAccountData,
  setShowNotificationsBox,
  setShowSearchInput,
} from "../mainLayout/components/menus/MenusSlice";
import RefreshAlert from "./RefreshAlert/RefreshAlert";
import MainLayout from "../mainLayout/MainLayout";
import HistoryLayout from "../historyLayout/history";
import AuthService from "../../Services/AuthService";
import useWebSocket from "react-use-websocket";
import { isNotification, isWebhookNotification } from "pebblebee-sdk-frontend";
import { addNewNotification } from "../../dataSlices/NotificationsSlice";
import mediaService from "../../Services/MediaService";
import GeofencesLayout from "../geofencesLayout/GeofencesLayout";
import mockService from "../../Services/MockService";
import messagesService from "../../Services/MessagesService";
import LoadingScreen from "../UserMap/components/LoadingScreen/LoadingScreen";
import UserManagement from "../UserManagement/UserManagement";
import { fifthLevel } from "../../app/levels";

//Define window prop gMap to be able to store map instance
declare global {
  interface Window {
    gMap: google.maps.Map;
  }
}

const MainContent = styled.div({
  display: "flex",
  flexDirection: "row",
  "@media (max-width: 768px)": {
    flexDirection: "column",
  },
});

const Home = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const refreshToken = useCallback(async () => {
    if (AuthService.isAuthenticated()) {
      await dispatch(refresToken());
    }
    dispatch(setRefreshingToken(false));
  }, [dispatch]);

  const [errorNotification, setErrorNotification] = useState("");

  const selecetedFeature = useSelector((state: RootState) => {
    return state.home.selecetedFeature;
  });

  const showGlobalError = useSelector((state: RootState) => {
    return state.common.showGlobalError;
  });

  const globalError = useSelector((state: RootState) => {
    return state.common.globalError;
  });

  const token = useSelector((state: RootState) => state.login.token);

  const refreshingToken = useSelector(
    (state: RootState) => state.common.refreshingToken
  );

  //add use memo to merge errors into global error
  const errorCallback = (caller: string, message: string) => {
    setErrorNotification(message);
  };

  useEffect(() => {
    refreshToken();
    //update the token
    const refreshTimer = 50 * 60 * 1000;
    const refreshInterval = setInterval(() => {
      refreshToken();
    }, refreshTimer);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [refreshToken]);

  useEffect(() => {
    if (errorNotification !== "") {
      dispatch(setGlobalError(errorNotification));
      setErrorNotification("");
    }
  }, [dispatch, errorNotification]);

  useEffect(() => {
    messagesService.subscribeToErrors(errorCallback);
    const getLocation = () => {
      if (!navigator.geolocation) {
        dispatch(setGlobalError(t("home.geolocation.browserNoSupport")));
        dispatch(setLoadingLocation(false));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            dispatch(
              setLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            );
            dispatch(setLoadingLocation(false));
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              dispatch(setGlobalError(t("home.geolocation.accessDenied")));
            } else {
              dispatch(setGlobalError(t("home.geolocation.error")));
            }
            dispatch(setLoadingLocation(false));
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
          }
        );
      }
    };
    getLocation();
  }, [dispatch, t]);

  const hideToasterGlobal = () => {
    dispatch(hideGlobalError());
  };

  const clearMenuSelection = () => {
    dispatch(setShowAccountData(false));
    dispatch(setShowNotificationsBox(false));
    dispatch(setShowSearchInput(false));
  };

  const getFeatureLayout = () => {
    switch (selecetedFeature) {
      case "main":
      case "userManagement":
        return <MainLayout />;
      case "history":
        return <HistoryLayout />;
      case "virtualFences":
        return <GeofencesLayout />;
      default:
        return <MainLayout />;
    }
  };

  return (
    <>
      {/* do not initialize web socket if it's on demo flow */}
      {mockService.getIsDemo() ? null : <WebSocketHandler token={token} />}
      {refreshingToken ? <LoadingScreen /> : getFeatureLayout()}
      {selecetedFeature === "userManagement" ? <UserManagement /> : null}
      <MainContent onClick={clearMenuSelection}>
        <Snackbar
          open={showGlobalError}
          autoHideDuration={6000}
          onClose={hideToasterGlobal}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <div className="z-3000">
            <Alert severity="error">{globalError}</Alert>
          </div>
        </Snackbar>
        <RefreshAlert />
      </MainContent>
    </>
  );
};

const WebSocketHandler = ({ token }: { token?: string }) => {
  const dispatch = useAppDispatch();

  const notificationsVolume = useSelector((state: RootState) => {
    return state.menus.notificationsVolume;
  });

  //Socket connection
  const socketUrl = `${process.env.REACT_APP_SOCKET_URL}?authorization=${
    token ? token : AuthService.getToken()
  }`;

  useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      return true;
    },
    onMessage: (event: MessageEvent<string>) => {
      processNotification(event.data);
    },
  });

  const processNotification = (messageData: string) => {
    try {
      const webHookNotification = JSON.parse(messageData);
      if (
        !webHookNotification ||
        !isWebhookNotification(webHookNotification) ||
        !isNotification(webHookNotification.data)
      ) {
        return;
      }
      mediaService.playNotificationSounds(notificationsVolume);
      if (!webHookNotification.data.createdAt) {
        webHookNotification.data.createdAt = new Date().toISOString();
      }
      dispatch(addNewNotification(webHookNotification.data));
    } catch (e) {}
  };

  return <></>;
};

export default Home;
