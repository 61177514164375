export type EnterpriseAccessLevel = "ADMIN" | "READ";

type EnterpriseUser = {
  childUserId: number;
  childUserUuid: string;
  permission: {
    id: number;
    description: EnterpriseAccessLevel;
  };
  deviceGroups: {
    uuid: string;
    name: string;
  }[];
  childEmail: string;
};

export type EnterpriseUserRow = {
  childUserId: number;
  childUserUuid: string;
  permissionId: number;
  permission: EnterpriseAccessLevel;
  deviceGroupsUUIDs: string[];
  deviceGroups: string;
  childEmail: string;
};

export const convertEnterpriseUsersToTableData = (
  users: EnterpriseUser[]
): EnterpriseUserRow[] => {
  return users.map((u) => {
    return convertEnterpriseUserToTableRow(u)
  })
};

export const convertEnterpriseUserToTableRow = (
  user: EnterpriseUser
): EnterpriseUserRow => {
  let userRow : EnterpriseUserRow = {
    childUserId: user.childUserId,
    childEmail: user.childEmail,
    childUserUuid: user.childUserUuid,
    deviceGroups: user.deviceGroups.map((g) => g.name).join(', '),
    deviceGroupsUUIDs: user.deviceGroups.map((g) => g.uuid.trim()),
    permission: user.permission.description,
    permissionId: user.permission.id
  }

  return userRow
};

export default EnterpriseUser;
