import { Geofence } from "pebblebee-sdk-frontend";
import mockService from "../../../../Services/MockService";
import { plataformClient } from "../../../api";

interface patchGeofenceResponse extends Geofence {
  status: string;
  error?: {
    message: string;
    code: string;
  };
}

export async function patch_Geofence(
  id: string,
  name: string,
  center: {
    lat: number;
    lng: number;
  },
  radius: number,
  devices: string[],
  enabled: boolean
) {
  try {
    if (mockService.getIsDemo()) {
      const editedMockedGeofence = await mockService.editMockedGeofence({
        id,
        name,
        center,
        radius,
        devices,
        enabled,
      });
      if (!editedMockedGeofence) {
        throw new Error("Invalid id");
      }
      return {
        data: { status: "success", ...editedMockedGeofence },
        status: 200,
      };
    }
    const response = await plataformClient.patch<patchGeofenceResponse>(
      `/geofences/${id}`,
      {
        name: name,
        shape: "circle",
        geometry: {
          coordinates: center,
          radius: radius,
        },
        type: "deviceLevel",
        deviceId: devices,
        enabled: enabled,
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
