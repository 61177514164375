import styled from "@emotion/styled";
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { fifthLevel, fourthLevel } from "../../../../app/levels";
import PbDeviceAvatarIcon from "../../../PbDeviceAvatarIcon/PbDeviceAvatarIcon";
import PbDevicesAutocompleteItemViewProps from "./PbDeviceAutocompleteItemViewProps";

const PbDeviceAutocompleteItemDesktopView = ({
  avatarText,
  device,
  readingText,
  shared,
  onItemClick,
}: PbDevicesAutocompleteItemViewProps) => {
  const StyledListItem = styled(ListItem)({
    display: "flex",
    justifyContent: "center",
    padding: "0.1cqi 0",
    zIndex: fourthLevel,
    background: "#fff",
    width: "100%",
    margin: "0px",
  });

  const AnimatedListItemButton = styled(ListItemButton)({
    minHeight: "6.5%",
    maxHeight: "6.5%",
    padding: "0px 1cqi",
  });

  const StyledListItemAvatar = styled(ListItemAvatar)({
    width: "10%",
  });

  const InfoContainer = styled.div({
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    paddingLeft: "2%",
  });

  const DeviceNameLabel = styled(ListItemText)({
    fontWeight: "700",
    textAlign: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  });

  const DeviceNameText = styled.span({
    fontSize: "1.2cqi",
    zIndex: fifthLevel,
  });

  const DeviceInfoText = styled.span({
    textAlign: "start",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.9cqi",
  });

  return (
    <>
      <StyledListItem
        onClick={() => {
          onItemClick(device);
        }}
        id={`menu-item-${device.guid || device.id}`}
      >
        <AnimatedListItemButton>
          <StyledListItemAvatar>
            <PbDeviceAvatarIcon
              styles={{
                desktop: {
                  height: "3.2cqi",
                  width: "3.2cqi",
                },
              }}
              device={device}
            />
          </StyledListItemAvatar>
          <InfoContainer>
            <DeviceNameLabel
              id={`Info ${device.name}`}
              primary={
                <DeviceNameText>{`${device.name}`}</DeviceNameText>
              }
              secondary={<DeviceInfoText>{readingText}</DeviceInfoText>}
            />
          </InfoContainer>
        </AnimatedListItemButton>
      </StyledListItem>
    </>
  );
};

export default PbDeviceAutocompleteItemDesktopView;
