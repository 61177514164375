import styled from "@emotion/styled";
import { Alert } from "@mui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { boxShadows } from "../../../app/boxShadows";
import { useAppDispatch } from "../../../app/hooks";
import { thirdLevel } from "../../../app/levels";
import { RootState } from "../../../app/store";
import { setStep } from "../GeofenceHandlerSlice";

const StepContainer = styled.div({
  position: "fixed",
  bottom: "2vw",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: thirdLevel,
});

const ConfirmButton = styled.div((props: { enabled: boolean }) => ({...{
  fontSize: "1.2cqi",
  padding: "1vh 2vw",
  background: props.enabled ? "#FFF" : "#F0F0F0",
  borderRadius: "999px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: thirdLevel,
  boxShadow: boxShadows.medium,
  ":hover": {
    cursor: props.enabled ? "pointer" : undefined,
  },
} as React.CSSProperties}));

//Mobile
const StepContainerMobile = styled.div({
  position: "fixed",
  bottom: "6vw",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: thirdLevel,
});

const ConfirmButtonMobile = styled.div((props: { enabled: boolean }) => ({...{
  fontSize: "5cqi",
  padding: "1.5cqi 3cqi",
  background: props.enabled ? "#FFF" : "#F0F0F0",
  borderRadius: "999px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: thirdLevel,
  boxShadow: boxShadows.medium,
  ":hover": {
    cursor: props.enabled ? "pointer" : undefined,
  },
} as React.CSSProperties}));

const PlacingStep = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const geofence = useSelector((state: RootState) => {
    return state.geofenceHandler.geofence;
  });

  const fenceError = useSelector((state: RootState) => {
    return state.geofenceHandler.fenceError;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const goToNextStep = () => {
    if (
      geofence !== undefined &&
      geofence.center &&
      geofence.radius &&
      !fenceError
    ) {
      dispatch(setStep("Customization"));
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <StepContainer>
          {fenceError ? (
            <Alert style={{ marginBottom: "10px" }} severity="warning">
              {fenceError}
            </Alert>
          ) : null}
          {geofence !== undefined && geofence.center && geofence.radius ? (
            <ConfirmButton
              enabled={fenceError === undefined}
              onClick={goToNextStep}
            >
              {t("geofences.place.confirm")}
            </ConfirmButton>
          ) : null}
        </StepContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <StepContainerMobile>
          {fenceError ? (
            <Alert style={{ marginBottom: "10px" }} severity="warning">
              {fenceError}
            </Alert>
          ) : null}
          {geofence !== undefined && geofence.center && geofence.radius ? (
            <ConfirmButtonMobile
              enabled={fenceError === undefined}
              onClick={goToNextStep}
            >
              {t("geofences.place.confirm")}
            </ConfirmButtonMobile>
          ) : null}
        </StepContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default PlacingStep;
