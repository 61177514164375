import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get_DeviceHistory } from "../../app/apiRequests/platform/getReadingHistory";

import i18n from "../../i18n/i18n";
import { HistoryPoint } from "../../Services/ReadingsService";

export interface HistoryState {
  loadingHistoryPoint: boolean;
  loadingAddresses: boolean;
  historyPoints: HistoryPoint[];
  historyPointsError: string;
  selectedDate: string;
  selectedHistoryPoint?: string;
  addresses: (string | null)[];
}

const initialState: HistoryState = {
  loadingHistoryPoint: false,
  loadingAddresses: false,
  historyPoints: [],
  historyPointsError: "",
  selectedDate: new Date().toISOString(),
  addresses: [],
};

export const getHistory = createAsyncThunk(
  "history/getHistory",
  async (
    {
      deviceId,
      fromDate,
      toDate,
    }: { deviceId: string; fromDate: string; toDate: string },
    thunkApi
  ) => {
    try {
      const historyResponse = await get_DeviceHistory(
        deviceId,
        fromDate,
        toDate
      );
      if (!historyResponse || historyResponse.status !== 200) {
        return thunkApi.rejectWithValue(i18n.t("apiErrors.gethistory.default"));
      }
      const historyPoints = historyResponse.data.readings.sort((a, b) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });

      return historyPoints;
    } catch (err) {
      return thunkApi.rejectWithValue(i18n.t(""));
    }
  }
);

export const HistorySlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setSelectedDate: (state, action: PayloadAction<string>) => {
      state.selectedDate = action.payload;
    },
    setHistoryError: (state, action: PayloadAction<string>) => {
      state.historyPointsError = action.payload;
    },
    setSelectedHistoryPoint: (state, action: PayloadAction<string | undefined>) => {
      state.selectedHistoryPoint = action.payload;
    },
    clearHistoryPoints: (state) => {
      state.historyPoints = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistory.pending, (state) => {
        state.loadingHistoryPoint = true;
      })
      .addCase(getHistory.fulfilled, (state, action) => {
        state.historyPoints = action.payload;
        state.loadingHistoryPoint = false;
      })
      .addCase(getHistory.rejected, (state, action) => {
        state.loadingHistoryPoint = false;
      });
  },
});

export const { clearHistoryPoints, setSelectedDate, setHistoryError, setSelectedHistoryPoint } =
  HistorySlice.actions;

export default HistorySlice.reducer;
