import React, { useCallback, useEffect } from "react";
import {
  EnterpriseUserRow,
  convertEnterpriseUsersToTableData,
} from "../../models/enterprise/EnterpriseUser";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import {
  getEnterpriseUsers,
  setEditUser,
  showDeleteModal,
} from "./UserManagementSlice";
import { fifthLevel } from "../../app/levels";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const EnterpriseUserTable = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(
    (state: RootState) => state.userManagement.users
  );

  const loadingUsers = useAppSelector(
    (state: RootState) => state.userManagement.loadingUsers
  );

  const fetchUserCallback = useCallback(async () => {
    await dispatch(getEnterpriseUsers());
  }, [dispatch]);

  useEffect(() => {
    fetchUserCallback();
    const refreshInterval = setInterval(() => {
      fetchUserCallback();
    }, 180000);
    return () => {
      clearInterval(refreshInterval);
    };
  }, [dispatch, fetchUserCallback]);

  const gridCols: GridColDef<EnterpriseUserRow>[] = [
    {
      field: "childEmail",
      headerName: "Users",
      flex: 1,
      minWidth: 100,
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true
    },
    {
      field: "permission",
      headerName: "Type of Account",
      flex: 1,
      minWidth: 100,
      renderCell: (params: { row: EnterpriseUserRow }) => {
        return <div>{params.row.permission}</div>;
      },
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true
    },
    {
      field: "deviceGroups",
      headerName: "Access",
      flex: 1,
      minWidth: 100,
      renderCell: (params: { row: EnterpriseUserRow }) => {
        return <div>{params.row.deviceGroups}</div>;
      },
      disableReorder: true,
      sortingOrder: ["desc", "asc"],
      disableColumnMenu: true
    },
    {
      field: "Actions",
      disableExport: true,
      renderCell: (params: { row: EnterpriseUserRow }) => {
        return (
          <div className="flex flex-row justify-start items-center w-1/2">
            <IconButton
              onClick={() => {
                dispatch(setEditUser(params.row));
              }}
            >
              <EditIcon />
            </IconButton>
            <div className=" mx-2"></div>
            <IconButton
              onClick={() => {
                dispatch(showDeleteModal(params.row));
              }}
            >
              <DeleteIcon onClick={() => {}} />
            </IconButton>
          </div>
        );
      },
      flex: 1,
      minWidth: 100,
      hideSortIcons: true,
      disableColumnMenu: true
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <div style={{ flex: 1 }}></div>
        <GridToolbarColumnsButton />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: "Export data" },
            button: { variant: "outlined" },
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <DataGrid
      className="mx-5"
      getRowId={(u) => u.childUserId}
      rows={convertEnterpriseUsersToTableData(users)}
      columns={gridCols}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        sorting: {
          sortModel: [{ field: "childEmail", sort: "asc" }],
        },
      }}
      loading={loadingUsers}
      pageSizeOptions={[10]}
      disableRowSelectionOnClick
      slots={{
        toolbar: CustomToolbar,
        columnSortedAscendingIcon: ArrowUpwardIcon,
        columnSortedDescendingIcon: ArrowDownwardIcon,
        columnUnsortedIcon: ArrowForwardIcon,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          printOptions: { disableToolbarButton: true },
          csvOptions: { disableToolbarButton: true },
        },
        panel: {
          style: {
            zIndex: fifthLevel + 1,
          },
        },
        basePopper: {
          style: {
            zIndex: fifthLevel + 1,
          },
        },
      }}
    />
  );
};

export default EnterpriseUserTable;
