import { UserDevice } from "pebblebee-sdk-frontend";

export const MY_DEVICES_UUID = 'A62779E1-936B-4B91-B9BA-2E8E33E15974'
export const SHARED_DEVICES_UUID = 'BE812A01-5198-41EC-AE2E-D9E96D8BF49F'

type DeviceGroup = {
  userUuid: string;
  uuid: string;
  name: string;
  readOnly: boolean;
  devices: UserDevice[];
};

export default DeviceGroup;
