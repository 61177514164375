import styled from "@emotion/styled";
import { Link } from "@mui/material";
import React from "react";
import AppStoreImg from "../../assets/institutional/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";
import GooglePlay from "../../assets/institutional/google-play-badge.png";
import Logo from "../../assets/imgs/Pebblebee_Logo_text-01.png";
import { useTranslation } from "react-i18next";
const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background:
    "linear-gradient(172deg, rgba(255,255,255,1) 0%, rgba(224,224,237,0.6222864145658263) 80%, rgba(246,238,129,1) 100%)",
});

const Message = styled.div({
  fontSize: "22px",
  fontWeight: 700,
  maxWidth: "80%",
  marginBottom: "60px",
});

const GoogleImage = styled.img({
  width: "160px",
});

const AppleImage = styled.img({
  width: "130px",
});

const PbLogo = styled.img({
  width: "290px",
  position: "fixed",
  top: "30px",
});

const AppsBadgesRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  bottom: "15vh",
});

const GetTheAppScreen = (props: { message?: string }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <PbLogo src={Logo}></PbLogo>
      <Message>{t("getTheApp.message")}</Message>
      <AppsBadgesRow>
        <Link href="https://play.google.com/store/apps/details?id=com.pebblebee.app.hive3&hl=en&gl=US">
          <GoogleImage src={GooglePlay} alt="google play" />
        </Link>
        <Link href="https://apps.apple.com/us/app/pebblebee-app/id1052309602">
          <AppleImage src={AppStoreImg} alt="apple store" />
        </Link>
      </AppsBadgesRow>
    </Container>
  );
};

export default GetTheAppScreen;
