import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { ListItemAvatar } from "@mui/material";
import React, { useMemo } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { UserDevice } from "pebblebee-sdk-frontend";
import { Reading } from "pebblebee-sdk-frontend";
import { useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { useAppDispatch } from "../../../../app/hooks";
import ReadingsService from "../../../../Services/ReadingsService";
import { setSelectedElementId } from "../../../home/HomeSlice";
import { setShowSearchInput } from "../menus/MenusSlice";
import { fourthLevel } from "../../../../app/levels";
import PbDeviceAvatarIcon from "../../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";
import { PbFontGray } from "../../../../app/colors";

interface MenuItemProps {
  device: UserDevice;
  reading?: Reading;
  expanded: boolean;
}

const show = keyframes`
  from {
    clip-path: inset(0 100% 0 0);
    width: 0%;
  }
  to {
    clip-path: inset(0 0 0 0 );
    width: 100%;
  }
`;

const hide = keyframes`
  from {
    clip-path: inset(0 0 0 0 );
    width: 100%;
  }
  to {
    clip-path: inset(0 100% 0 0);
    width: 0%;
  }
`;

const AnimatedListItemButton = styled(ListItemButton)(
  (props: { expanded: number }) => ({
    padding: props.expanded === 1 ? "0px" : "0px 0.5cqi",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: props.expanded === 1 ? undefined : "1.2cqi",
    width: props.expanded === 1 ? undefined : "3vw",
    height: props.expanded === 1 ? undefined : "3vw",
    margin: props.expanded === 1 ? undefined : "0.5cqi 0",
  })
);

const InfoContainer = styled.div((props: { expanded: number }) => ({...{
  animation: `${props.expanded === 1 ? show : hide} 0.8s ease forwards`,
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  justifyContent: "center",
  paddingLeft: "2%",
  fontSize: "0.8rem",
  containerType: "inline-size",
  transform: "translateX(0%)",
} as React.CSSProperties}));

const DeviceNameLabel = styled(ListItemText)({
  fontWeight: "700",
  textAlign: "start",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
});

const DeviceNameText = styled.span({
  fontSize: "9cqi",
  fontWeight: 400,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "90%",
  lineHeight: "normal",
});

const DeviceInfoText = styled.span({
  textAlign: "start",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "7.5cqi",
  maxWidth: "100% !important",
  display: "block",
  color: PbFontGray,
  marginTop: "6px",
});

const DeviceNameTextMobile = styled.span({
  fontSize: "min(7.5cqi)",
});

const DeviceInfoTextMobile = styled.span({
  textAlign: "start",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontSize: "min(7.5cqi)",
});

const StyledListItem = styled(ListItem)({
  display: "flex",
  justifyContent: "center",
  padding: "0.1cqi 0",
  zIndex: fourthLevel,
  background: "#fff",
  width: "100%",
});

const StyledListItemAvatar = styled(ListItemAvatar)({
  padding: "0.2cqi",
  display: "flex !important",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "auto",
});

const StyledListItemMobile = styled(ListItem)({
  display: "flex",
  justifyContent: "center",
  paddingLeft: "0px",
  paddingRight: "0px",
  padding: "0px",
  zIndex: fourthLevel,
  background: "#fff",
  width: "100%",
});

const StyledListItemAvatarMobile = styled(ListItemAvatar)({
  width: "20%",
});

const MenuItem = ({ device, reading, expanded }: MenuItemProps) => {
  const dispatch = useAppDispatch();

  const selectedElementId = useSelector((state: RootState) => {
    return state.home.selectedElementId;
  });

  const userLocation = useSelector((state: RootState) => {
    return state.common.location;
  });

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMetric = useMemo(() => {
    return loggedUser?.isMetric === 1;
  }, [loggedUser]);

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const selected = useMemo(() => {
    return selectedElementId === device.guid || selectedElementId === device.id;
  }, [device, selectedElementId]);

  const itemOnClick = () => {
    dispatch(setShowSearchInput(false));
    dispatch(setSelectedElementId(device.guid || device.id));
  };

  const renderDesktop = () => {
    return (
      <StyledListItem
        onClick={itemOnClick}
        id={`menu-item-${device.guid || device.id}`}
      >
        <AnimatedListItemButton expanded={expanded ? 1 : 0}>
          <StyledListItemAvatar>
            <PbDeviceAvatarIcon
              styles={{ desktop: { width: "2.8vw", height: "2.8vw" } }}
              device={device}
              selected={selected}
              hideTooltip={expanded}
              tooltipPlacement="right"
            />
          </StyledListItemAvatar>

          <InfoContainer expanded={expanded ? 1 : 0}>
            <DeviceNameText>{`${device.name}`}</DeviceNameText>
            <DeviceInfoText>
              {reading
                ? ReadingsService.infoString(reading, userLocation, isMetric)
                : "No readings"}
            </DeviceInfoText>
          </InfoContainer>
        </AnimatedListItemButton>
      </StyledListItem>
    );
  };

  const renderMobile = () => {
    return (
      <StyledListItemMobile
        onClick={itemOnClick}
        id={`menu-item-${device.guid || device.id}`}
      >
        <AnimatedListItemButton expanded={1}>
          <StyledListItemAvatarMobile>
            <PbDeviceAvatarIcon device={device} selected={selected} />
          </StyledListItemAvatarMobile>

          <InfoContainer expanded={1}>
            <DeviceNameLabel
              id={`Info ${device.name}`}
              primary={
                <DeviceNameTextMobile>{`${device.name}`}</DeviceNameTextMobile>
              }
              secondary={
                reading ? (
                  <DeviceInfoTextMobile>
                    {ReadingsService.infoString(
                      reading,
                      userLocation,
                      isMetric
                    )}
                  </DeviceInfoTextMobile>
                ) : (
                  "No readings"
                )
              }
            />
          </InfoContainer>
        </AnimatedListItemButton>
      </StyledListItemMobile>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export default MenuItem;
