import React from "react";

import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import Battery2BarIcon from "@mui/icons-material/Battery2Bar";
import Battery3BarIcon from "@mui/icons-material/Battery3Bar";
import Battery4BarIcon from "@mui/icons-material/Battery4Bar";
import Battery5BarIcon from "@mui/icons-material/Battery5Bar";
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";
import BatteryFullIcon from "@mui/icons-material/BatteryFull";
import BatteryUnknownIcon from "@mui/icons-material/BatteryUnknown";
import styled from "@emotion/styled";
import { Reading } from "pebblebee-sdk-frontend";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

const BatteryContainer = styled.div({

  height: "1.4cqi",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});

const BatteryContainerMobile = styled.div({
  top: "0.5cqi",
  right: "2.5cqi",
  height: "6cqi",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "2cqi"
});

const BatteryLevel = ({
  reading,
  modelNumber,
}: {
  reading?: Reading;
  modelNumber?: number;
}) => {
  const isMobile = useAppSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });
  const getbatteryPercentage = () => {
    const desktopIconStyle = { width: "1.1cqi", height: "1.1cqi" }
    const mobileIconStyle = { width: "4cqi", height: "4cqi" }
    const iconStyle = isMobile ? mobileIconStyle : desktopIconStyle

    if (reading?.batteryPercentage) {
      if (reading.batteryPercentage.value < 10) {
        return (
          <Battery0BarIcon
            style={iconStyle}
            color="error"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 10 &&
        reading.batteryPercentage.value < 25
      ) {
        return (
          <Battery1BarIcon
            style={iconStyle}
            color="error"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 25 &&
        reading.batteryPercentage.value < 37.5
      ) {
        return (
          <Battery2BarIcon
            style={iconStyle}
            color="warning"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 37.5 &&
        reading.batteryPercentage.value < 50
      ) {
        return (
          <Battery3BarIcon
            style={iconStyle}
            color="warning"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 50 &&
        reading.batteryPercentage.value < 62.5
      ) {
        return (
          <Battery4BarIcon
            style={iconStyle}
            color="success"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 62.5 &&
        reading.batteryPercentage.value < 75
      ) {
        return (
          <Battery5BarIcon
            style={iconStyle}
            color="success"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 75 &&
        reading.batteryPercentage.value < 87.5
      ) {
        return (
          <Battery6BarIcon
            style={iconStyle}
            color="success"
          />
        );
      } else if (
        reading.batteryPercentage.value >= 87.5 &&
        reading.batteryPercentage.value <= 100
      ) {
        return (
          <BatteryFullIcon
            style={iconStyle}
            color="success"
          />
        );
      } else {
        return (
          <BatteryUnknownIcon
            style={iconStyle}
            color="disabled"
          />
        );
      }
    }
    return <BatteryUnknownIcon color="disabled" />;
  };

  const PreciseBatteryModels = [13, 14, 16, 17, 22, 23, 24];

  const renderDesktop = () => {
    return (
      <>
        <BatteryContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              fontSize: "0.7cqi",
            }}
          >
            {modelNumber && PreciseBatteryModels.includes(modelNumber)
              ? reading?.batteryPercentage
                ? `${reading?.batteryPercentage.value}%`
                : null
              : null}
          </div>
          {getbatteryPercentage()}
        </BatteryContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <BatteryContainerMobile>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              fontSize: "3cqi",
            }}
          >
            {modelNumber && PreciseBatteryModels.includes(modelNumber)
              ? reading?.batteryPercentage
                ? `${reading?.batteryPercentage.value}%`
                : null
              : null}
          </div>
          {getbatteryPercentage()}
        </BatteryContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default BatteryLevel;
