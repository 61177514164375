import React, {
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MapMarker from "./components/MapMarker";
import { useAppDispatch } from "../../app/hooks";
import { RootState } from "../../app/store";
import { MapOverlayData } from "../../Services/ReadingsService";
import { setMapCenter, setZoom } from "./MapSlice";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { PbDimYellow, PbYellow } from "../../app/colors";
import PbMap from "../../components/PbMap/PbMap";
import MapInfoWindow from "./components/MapInfoWindow";
import { firstLevel } from "../../app/levels";

interface UserMapProps {
  onMapLoaded?: () => void
}

const UserMap = (props: UserMapProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  //data loaders
  const loadingMapData = useSelector((state: RootState) => {
    return state.map.loadingMapData;
  });

  const loadingLocation = useSelector((state: RootState) => {
    return state.common.loadingLocation;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  //map data
  const location = useSelector((state: RootState) => {
    return state.common.location;
  });

  const mapsEntries = useSelector((state: RootState) => {
    return state.map.mapsEntries;
  });

  const mapLines = useSelector((state: RootState) => {
    return state.map.mapLines;
  });

  const mapCircles = useSelector((state: RootState) => {
    return state.map.mapCircles;
  });

  const selectedPosition = useSelector((state: RootState) => {
    return state.map.selectedPosition;
  });

  const mapCenter = useSelector((state: RootState) => {
    return state.map.mapCenter;
  });

  const zoom = useSelector((state: RootState) => {
    return state.map.zoom;
  });

  const showZoomControls = useSelector((state: RootState) => {
    return state.map.showZoomControls;
  });

  const mapTypeId = useSelector((state: RootState) => {
    return state.mainLayout.mapTypeId;
  });

  useEffect(() => {
    let polylines: google.maps.Polyline[] = [];

    mapLines.forEach((line) => {
      const polyline = new google.maps.Polyline({
        path: line,
        strokeColor: PbYellow,
        strokeOpacity: 80,
        strokeWeight: 6,
        clickable: false,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: firstLevel,
      });
      if (window.gMap) {
        polyline.setMap(window.gMap);
        polylines.push(polyline);
      }
    });
    return () => {
      // Clean up
      polylines.forEach((pl) => {
        pl.setMap(null);
      });
    };
  }, [mapLines]);

  useEffect(() => {
    let circles: google.maps.Circle[] = [];
    try {
      if (window.gMap) {
        const newCircles = mapCircles.map((circle) => {
          const newCircle = new google.maps.Circle({
            strokeColor: circle.strokeColor ? circle.strokeColor : PbYellow,
            strokeOpacity: 1,
            strokeWeight: 4,
            fillColor: circle.color ? circle.color : PbDimYellow,
            fillOpacity: 0.4,
            center: circle.center,
            radius: circle.radius,
            map: window.gMap,
          });
          return newCircle;
        });
        circles = newCircles;
      }
    } catch {}
    return () => {
      // Clean up
      circles.forEach((circle) => {
        circle.setMap(null);
      });
    };
  }, [mapCircles]);

  const getCenter = useCallback(() => {
    let firstDeviceCenter = undefined;
    if (mapsEntries && mapsEntries.length > 0) {
      firstDeviceCenter = mapsEntries[0].position;
    }
    return mapCenter || location || firstDeviceCenter || { lat: 0, lng: 0 };
  }, [location, mapCenter, mapsEntries]);

  const mapsWithLocationEntries = useMemo(() => {
    let entries = [...mapsEntries];

    if (entries && location) {
      entries.push({
        position: location,
        compProps: {
          center: location,
          index: 0,
          id: "user-location",
          readingsType: "location",
        },
      });
    }
    return entries;
  }, [mapsEntries, location]);

  const setViewMapCenter = (center: { lat: number; lng: number }) => {
    dispatch(setMapCenter(center));
  };

  const setMapZoom = (newZoom: number) => {
    dispatch(setZoom(newZoom));
  };

  return (
    <>
      {loadingMapData || loadingLocation ? (
        <LoadingScreen message={t("home.loading") as string} />
      ) : null}
      <PbMap<MapOverlayData>
        ApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
        center={undefined}
        containerStyle={{
          height: isMobileScreen ? "60vh" : "100vh",
          width: "100vw",
        }}
        loadOptions={{}}
        markers={mapsWithLocationEntries}
        selectedMarker={selectedPosition}
        zoom={zoom}
        getMapInstance={(map) => {
          window.gMap = map;
          if(props.onMapLoaded) {
            props.onMapLoaded()
          }
        }}
        component={(p: MapOverlayData) => {
          return <MapMarker {...p} />;
        }}
        infoWindowComponent={(p: MapOverlayData) => {
          return <MapInfoWindow {...p} />;
        }}
        onCenterChange={setViewMapCenter}
        onZoomChange={setMapZoom}
        mapTypeId={mapTypeId}
        polylines={mapLines}
        lineOptions={{
          strokeColor: PbYellow,
          strokeWeight: 5,
        }}
        mapOptions={{
          clickableIcons: false,
          streetViewControl: false,
          disableDefaultUI: true,
          fullscreenControl: false,
          zoomControl: showZoomControls ? true : false,
        }}
      />
    </>
  );
};

export default UserMap;
