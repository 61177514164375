import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface deleteAllNotificationsResponse {
  data: {
    status: string;
  };
}

export async function delete_AllNotifications() {
  try {
    if (mockService.getIsDemo()) {
      return { data: { data: { status: "Success" } }, status: 200 };
    }
    const response =
      await httpClientCore.delete<deleteAllNotificationsResponse>(
        `/v2/user/notifications`
      );
    return response;
  } catch (err) {
    return null;
  }
}
