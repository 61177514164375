import { LoggedUser } from "../../../Services/AuthService";
import mockService from "../../../Services/MockService";
import { httpClientCore } from "../../api";

interface getUserResponse {
  status: string;
  error?: any;
  user: LoggedUser;
}

export async function get_User_v2() {
  try {
    if (mockService.getIsDemo()) {
      return { data: { user: mockService.getMockedUser() }, status: 200 };
    }
    const response = await httpClientCore.get<getUserResponse>(`/v2/user`);
    return response;
  } catch (err) {
    return null;
  }
}
