import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  dateToTimeFormat,
  date_MMM_Do_YYYY_Format,
  getFormatedDayhour,
  isGeofenceMetadata,
  PbNotification,
  Reading,
  UserDevice,
} from "pebblebee-sdk-frontend";
import React from "react";
//import FenceIcon from "@mui/icons-material/Fence";
import AdjustIcon from "@mui/icons-material/Adjust";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../../../../app/hooks";
import { deleteNotification } from "../../../../../dataSlices/NotificationsSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../app/store";
//import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from '@mui/icons-material/Clear';
import { PbFontGrayDark, PbFontGrayLight, PbWhite } from "../../../../../app/colors";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { setBottomRightMenu, setShowContactSalesBox, setShowNotificationsBox, setTopMenus } from "../MenusSlice";

const ResponsiveListItem = styled(ListItem)({
  padding: "0.5cqi 3.5cqi 0.5cqi 1cqi",
});

const ContentRow = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
});

const TimeLable = styled.div({
  fontSize: "0.6cqi",
  color: "#787878",
  flex: 0.3,
  textAlign: "right",
});

const PrimaryContentRow = styled.span({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const TypeContainer = styled.span({
  fontSize: "0.9cqi",
  flex: 0.6,
});

const MessageContainer = styled.span({
  padding: "0.4cqi 0",
  fontSize: "0.8cqi",
});

const ClearIconContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const ClearButton = styled(ClearIcon)({
  width: "1.2cqi",
  height: "1.2cqi",
  marginRight: "0.3cqi",
  color: PbFontGrayLight,
  ":hover": {
    cursor: "pointer",
    color: PbFontGrayDark
  },
});

const ResponsiveFenceIcon = styled(AdjustIcon)({
  width: "1.6cqi",
  height: "1.6cqi",
});

const ResponsiveQuestionAnswerIcon = styled(QuestionAnswerIcon)({
  width: "1.6cqi",
  height: "1.6cqi",
});

//MOBILE
const ResponsiveListItemMobile = styled(ListItem)({
  padding: "1.5cqi 9cqi 1.5cqi 3cqi",
});

const TimeLableMobile = styled.div({
  fontSize: "2.5cqi",
  color: "#787878",
  flex: 0.3,
  textAlign: "right",
});

const TypeContainerMobile = styled.span({
  fontSize: "3.5cqi",
  flex: 0.7,
});

const MessageContainerMobile = styled.span({
  fontSize: "3cqi",
});

const ClearButtonMobile = styled(ClearIcon)({
  width: "3cqi",
  height: "3cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const ResponsiveFenceIconMobile = styled(AdjustIcon)({
  width: "4cqi",
  height: "4cqi",
  marginRight: "1cqi",
});

const NotificationItem = (props: PbNotification) => {
  const dispatch = useAppDispatch();

  const loadingDelteNotification = useSelector((state: RootState) => {
    return state.notifications.loadingDeleteNotification;
  });

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const readings = useSelector((state: RootState) => {
    return state.readings.readings;
  });

  const isMobile = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const formatedDateLabel = (createdAt?: string) => {
    if (!createdAt) {
      return "";
    }
    return date_MMM_Do_YYYY_Format(createdAt);
  };

  const formatedTimeLabel = (createdAt?: string) => {
    if (!createdAt) {
      return "";
    }
    return getFormatedDayhour(new Date(createdAt).getTime());
  };

  const dismissNotification = async () => {
    await dispatch(
      deleteNotification({
        mac: props.deviceId,
        type: props.type,
        userId: props.userId,
      })
    );
  };

  const getUserDevice = (
    mac: string
  ): { device?: UserDevice; reading?: Reading } => {
    const device = userDevices.find((d) => d.mac === mac);
    let result: { device?: UserDevice; reading?: Reading } = {
      device: undefined,
      reading: undefined,
    };
    if (device) {
      result.device = device;
      const reading = readings.find((r) => r.id === device.guid);
      if (reading) {
        result.reading = reading;
      }
    }
    return result;
  };

  const handleNotificationClick = () => {
    switch (props.type) {
      case "GEOFENCE_ENTERED":
      case "GEOFENCE_EXITED":
        //check if metadata is of the correct type
        if (
          props.metadata !== undefined &&
          isGeofenceMetadata(props.metadata)
        ) {
          //use metadata to handle click
          if (window.gMap && props.metadata.deviceLocation) {
            const { device, reading } = getUserDevice(props.deviceId);
            if (device && reading && reading.coordinates) {
              const bounds = new window.google.maps.LatLngBounds(
                reading.coordinates.value
              );
              window.gMap.fitBounds(bounds);
            }
          }
        }
        break;
      case "CONTACT_SALES":
        dispatch(setBottomRightMenu("contactSales"));
        dispatch(setShowNotificationsBox(false))
        break;
    }
    dismissNotification();
  };

  const parseTypeIntoMessage = (type: any) => {
    switch (type) {
      case "GEOFENCE_EXITED":
        return "Virtual fence";
      case "GEOFENCE_ENTERED":
        return "Virtual fence";
      case "CONTACT_SALES":
        return "Want to learn more?";
      default:
        return "Alert";
    }
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case "GEOFENCE_EXITED":
        return  <ResponsiveFenceIcon />;
      case "GEOFENCE_ENTERED":
        return  <ResponsiveFenceIcon />;
      case "CONTACT_SALES":
        return <ResponsiveQuestionAnswerIcon />;
      default:
        return <></>;
    }
  }

  const renderDesktop = () => {
    return (
      <>
        <ResponsiveListItem
          secondaryAction={
            <ClearIconContainer>
              <ClearButton onClick={dismissNotification} />
            </ClearIconContainer>
          }
          sx={{
            ":hover": {
              background: PbWhite,
              borderRadius: "1vw",
              cursor: loadingDelteNotification ? "progress" : undefined,
            },
          }}
        >
          <ContentRow onClick={handleNotificationClick}>
            <ListItemIcon style={{ minWidth: "2cqi", marginRight: "0.5cqi" }}>
              {getNotificationIcon(props.type)}
            </ListItemIcon>
            <ListItemText
              primary={
                <PrimaryContentRow>
                  <TypeContainer>
                    {parseTypeIntoMessage(props.type)}
                  </TypeContainer>
                  <TimeLable>
                    {formatedDateLabel(props.createdAt)}
                    <br /> {formatedTimeLabel(props.createdAt)}
                  </TimeLable>
                </PrimaryContentRow>
              }
              secondary={<MessageContainer>{props.message}</MessageContainer>}
            />
          </ContentRow>
        </ResponsiveListItem>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <ResponsiveListItemMobile
          secondaryAction={
            <ClearIconContainer>
              <ClearButtonMobile onClick={dismissNotification} />
            </ClearIconContainer>
          }
          sx={{
            ":hover": {
              background: PbWhite,
              borderRadius: "1vw",
              cursor: loadingDelteNotification ? "progress" : undefined,
            },
          }}
        >
          <ContentRow onClick={handleNotificationClick}>
            <ListItemIcon style={{ minWidth: "2cqi", marginRight: "0.5cqi" }}>
              <ResponsiveFenceIconMobile />
            </ListItemIcon>
            <ListItemText
              primary={
                <PrimaryContentRow>
                  <TypeContainerMobile>
                    {parseTypeIntoMessage(props.type)}
                  </TypeContainerMobile>
                  <TimeLableMobile>
                    {formatedTimeLabel(props.createdAt)}
                  </TimeLableMobile>
                </PrimaryContentRow>
              }
              secondary={
                <MessageContainerMobile>{props.message}</MessageContainerMobile>
              }
            />
          </ContentRow>
        </ResponsiveListItemMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default NotificationItem;
