import styled from "@emotion/styled";
import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PbFontBlack } from "../../../app/colors";
import { fifthLevel } from "../../../app/levels";
import { RootState } from "../../../app/store";

const StepperContainer = styled.div({
  position: "fixed",
  top: "1vw",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: fifthLevel,
  height: "3vw",
  borderRadius: "1cqi",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "fit-content",
});

const InstructionContainer = styled.div({
  position: "fixed",
  top: "4.5vw",
  left: "50%",
  transform: "translateX(-50%)",
  zIndex: fifthLevel,
  height: "1vw",
  borderRadius: "1cqi",
  background: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "20vw",
  fontSize: "0.9cqi",
  color: PbFontBlack,
  textAlign: "center",
  padding: "0 10px",
});

const CustomStepLabel = styled(StepLabel)({
  "& svg": {
    width: "1cqi",
    height: "1cqi",
  },
});

const StepLabelText = styled.span({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1cqi",
});

//MOBILE

const CustomStepLabelMobile = styled(StepLabel)({
  "& svg": {
    width: "5cqi",
    height: "5cqi",
  },
});

const StepLabelTextMobile = styled.span({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "3.5cqi",
});

const InstructionTextMobile = styled.span({
  fontSize: "3cqi",
});

const GeofenceStepper = () => {
  const { t } = useTranslation();

  const steps = ["Placement", "Customization", "Devices"];

  const currentStep = useSelector((state: RootState) => {
    return state.geofenceHandler.createStep;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const getStep = () => {
    return steps.indexOf(currentStep);
  };

  const renderDesktop = () => {
    return (
      <>
        <StepperContainer>
          <Stepper activeStep={getStep()}>
            {steps.map((label) => (
              <Step key={label}>
                <CustomStepLabel>
                  <StepLabelText>
                    {t(`geofences.stepsTitles.${label}`)}
                  </StepLabelText>
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
        </StepperContainer>
        {currentStep === "Placement" ? (
          <InstructionContainer>
            {t(`geofences.steps.${currentStep}`)}
          </InstructionContainer>
        ) : null}
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
      <br></br>
        <Stepper activeStep={getStep()}>
          {steps.map((label) => (
            <Step key={label}>
              <CustomStepLabelMobile>
                <StepLabelTextMobile>
                  {t(`geofences.stepsTitles.${label}`)}
                </StepLabelTextMobile>
              </CustomStepLabelMobile>
            </Step>
          ))}
        </Stepper>
        {currentStep === "Placement" ? (
          <InstructionTextMobile>
            <br></br>
            {t(`geofences.steps.${currentStep}`)}
          </InstructionTextMobile>
        ) : null}
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default GeofenceStepper;
