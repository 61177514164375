import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get_UserDevice } from "../app/apiRequests/core/getDevice";
import { get_UserDevices } from "../app/apiRequests/core/getDevices";
import { put_UserDevice } from "../app/apiRequests/core/updateDevice";

import i18n from "../i18n/i18n";
import messagesService from "../Services/MessagesService";
import { get_DevicesGroups } from "../app/apiRequests/core/enterprise/getDeviceGroups";
import DeviceGroup from "../models/enterprise/DeviceGroup";
import { UserDevice } from "pebblebee-sdk-frontend";
import AuthService, { LoggedUser } from "../Services/AuthService";

export interface DevicesState {
  loadingDevices: boolean;
  refreshingDevice: boolean;
  updatingTrackingMode: boolean;
  userDevices: UserDevice[];
  loadingGroups: boolean;
  groups: DeviceGroup[];
  devicesError: string;
}

const initialState: DevicesState = {
  loadingDevices: false,
  refreshingDevice: false,
  updatingTrackingMode: false,
  userDevices: [],
  loadingGroups: false,
  groups: [],
  devicesError: "",
};

export const getUserDevices = createAsyncThunk(
  "devices/getUserDevices",
  async (_, thunkApi) => {
    try {
      const response = await get_UserDevices();
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getUserDevices.default")
        );
      }
      
      const isEnterprise = await AuthService.getIsEnterpriseFromStorage()

      return response.data.devices.filter(
        (d) =>
          !isEnterprise ||
          (isEnterprise && !d.sharedFrom)
      );
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getUserDevices.default")
      );
    }
  }
);

export const getUserDevice = createAsyncThunk(
  "devices/getUserDevice",
  async ({ mac }: { mac: string }, thunkApi) => {
    try {
      const response = await get_UserDevice(mac);
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getUserDevice.default")
        );
      }
      return response.data.device;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.getUserDevice.default")
      );
    }
  }
);

export const getDevicesGroups = createAsyncThunk(
  "devices/getDevicesGroups",
  async (_, thunkApi) => {
    try {
      const response = await get_DevicesGroups();

      if (!response) {
        return thunkApi.rejectWithValue("An error occured");
      }

      if (!response.data || response.status !== 200) {
        return thunkApi.rejectWithValue("An error occured");
      }

      //save token and user data
      if (!response.data || !response.data.groups) {
        return thunkApi.rejectWithValue("An error occured");
      }

      return response.data.groups;
    } catch (err) {
      return thunkApi.rejectWithValue("An error occured");
    }
  }
);

export const updateDeviceTrackingMode = createAsyncThunk(
  "devices/updateDeviceTrackingMode",
  async ({ mac, tracking }: { mac: string; tracking: string }, thunkApi) => {
    try {
      const response = await put_UserDevice({
        mac: mac,
        newTracking: tracking,
      });
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.updateDeviceTrackingMode.default")
        );
      }
      return response.data.data.devices;
    } catch (err) {
      console.log(err);
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.updateDeviceTrackingMode.default")
      );
    }
  }
);

export const DevicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    clearDevices: (state) => {
      state.userDevices = [];
    },
    setError: (state, action: PayloadAction<string>) => {
      state.devicesError = action.payload;
    },
    mapGroupsToDevices: (state) => {
      state.userDevices.forEach((d) => {
        const group = state.groups.find((g) => g.uuid === d.group);
        if (group) {
          d.groupName = group.name;
        }
      });
    },
    resetDevicesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //get user devices list
      .addCase(getUserDevices.pending, (state) => {
        state.loadingDevices = true;
      })
      .addCase(getUserDevices.fulfilled, (state, action) => {
        state.loadingDevices = false;
        state.userDevices = action.payload;
      })
      .addCase(getUserDevices.rejected, (state, action) => {
        state.loadingDevices = false;
        state.userDevices = [];
        state.devicesError = action.payload as string;
      })
      //get user device (and update list)
      .addCase(getUserDevice.pending, (state) => {
        state.refreshingDevice = true;
      })
      .addCase(getUserDevice.fulfilled, (state, action) => {
        state.refreshingDevice = false;
        const newUserDevice = [...state.userDevices];
        if (action.payload) {
          const device = state.userDevices.find(
            (d) => d.mac === action.payload?.mac
          );
          if (device) {
            const index = state.userDevices.indexOf(device);
            if (index !== -1) {
              newUserDevice[index] = action.payload;
              state.userDevices = newUserDevice;
            }
          }
        }
      })
      .addCase(getUserDevice.rejected, (state, action) => {
        state.refreshingDevice = false;
        messagesService.notififyError(action.type, action.payload as string);
      })
      //update user device (tracking mode)
      .addCase(updateDeviceTrackingMode.pending, (state) => {
        state.updatingTrackingMode = true;
      })
      .addCase(updateDeviceTrackingMode.fulfilled, (state, action) => {
        state.updatingTrackingMode = false;
      })
      .addCase(updateDeviceTrackingMode.rejected, (state, action) => {
        state.updatingTrackingMode = false;
        messagesService.notififyError(action.type, action.payload as string);
      })
      //GET device groups
      .addCase(getDevicesGroups.pending, (state) => {
        state.loadingGroups = true;
      })
      .addCase(getDevicesGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
        state.loadingGroups = false;
      })
      .addCase(getDevicesGroups.rejected, (state, action) => {
        messagesService.notififyError(action.type, "Failed to fetch users");
        state.loadingGroups = false;
      });
  },
});

export const { clearDevices, setError, resetDevicesState, mapGroupsToDevices } =
  DevicesSlice.actions;

export default DevicesSlice.reducer;
