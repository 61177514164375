import { createFilterOptions } from "@mui/material";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React, { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import PbDevicesAutocompleteProps from "./PbDevicesAutocompleteProps";
import PbDevicesAutocompleteDesktopView from "./views/PbDevicesAutocompleteDesktopView";
import PbDevicesAutocompleteMobileView from "./views/PbDevicesAutocompleteMobileView";

const PbDeviceAutocomplete = (props: PbDevicesAutocompleteProps) => {
  const isMobileScreen = useAppSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const devices = useAppSelector(
    (state: RootState) => state.devices.userDevices
  );
  const readings = useAppSelector(
    (state: RootState) => state.readings.readings
  );

  const loggedUser = useAppSelector(
    (state: RootState) => state.login.loggedUser
  );

  const options = useMemo(() => {
    return devices.map((d) => {
        const reading = readings.find((r) => r.id === d.guid);
        const group =
          d.group && d.groupName
            ? d.groupName
            : d.sharedFrom
            ? "Shared"
            : "My devices";
        return {
          group: group,
          device: d,
          reading: reading,
        };
      });
  }, [devices, readings]);

  const filterOptions = createFilterOptions({
    limit: 15,
    stringify: (option: {
      group: string;
      device: UserDevice;
      reading?: Reading;
    }) => option?.device?.name || "",
  });

  return isMobileScreen ? (
    <PbDevicesAutocompleteMobileView
      styles={props.styles?.mobile}
      textFieldStyle={props.textFieldStyles?.mobile}
      filterOptions={filterOptions}
      options={options}
      itemOnClick={props.itemOnClick}
      openOnFocus={props.openOnFocus}
      open={props.open}
    />
  ) : (
    <PbDevicesAutocompleteDesktopView
      styles={props.styles?.desktop}
      textFieldStyle={props.textFieldStyles?.desktop}
      filterOptions={filterOptions}
      options={options}
      itemOnClick={props.itemOnClick}
      openOnFocus={props.openOnFocus}
      open={props.open}
      onClickAway={props.onClickAway}
      onClose={props.onClose}
      hideAdorment={props.hideAdorment}
    />
  );
};

export default PbDeviceAutocomplete;
