import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import { Subscription } from "pebblebee-sdk-frontend";
import React from "react";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";

const CHIP_COLORS = {
  noSub: "#EA2E2E",
  freeTrial: "#8D8D8D",
  indiegogo: "#D33374",
  regularSub: "#3A91CE",
};

const SubscriptionChip = styled(Chip)(
  (props: { background: string; isMobile: number }) => ({
    height: props.isMobile === 1 ? "5<cqi" : "1.5cqi",
    fontSize: props.isMobile === 1 ? "3cqi" :"0.7cqi",
    fontWeight: "800",
    margin: "5px 0px",
    color: "white",
    backgroundColor: props.background,
    ":hover": {
      cursor: "pointer",
    },
  })
);

//Components
const SubscriptionBadge = ({
  subscription,
}: {
  subscription?: Subscription;
}) => {
  const isMobile = useAppSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const isActiveSubscription = () => {
    return (
      subscription &&
      subscription.status &&
      (subscription.status === "active" || subscription.status === "trialing")
    );
  };

  const freeTrialDuration = () => {
    if (subscription?.freeTrial) {
      const claimedAt = dayjs(subscription.freeTrial.claimedAt);
      const endsAt = dayjs(subscription.freeTrial.endsAt);

      const days = endsAt.diff(claimedAt, "day");
      return `${days} FREE TRIAL`;
    }
    return `FREE TRIAL`;
  };

  const getChip = () => {
    if (subscription) {
      if (isActiveSubscription()) {
        if (subscription.planId.toLocaleLowerCase() === "indiegogo") {
          return (
            <SubscriptionChip
              label="INDIEGOGO"
              background={CHIP_COLORS.indiegogo}
              isMobile={isMobile ? 1 : 0}
            ></SubscriptionChip>
          );
        } else {
          if (subscription.status === "trialing") {
            return (
              <SubscriptionChip
                label={freeTrialDuration()}
                background={CHIP_COLORS.freeTrial}
                isMobile={isMobile ? 1 : 0}
              />
            );
          }
          if (subscription.plan) {
            const label = `${subscription.plan.duration.length} ${subscription.plan.duration.interval} PLAN`;
            return (
              <SubscriptionChip
                label={label.toLocaleUpperCase()}
                background={CHIP_COLORS.regularSub}
                isMobile={isMobile ? 1 : 0}
              ></SubscriptionChip>
            );
          } else {
            return (
              <SubscriptionChip
                label={subscription.planId}
                background={CHIP_COLORS.regularSub}
                isMobile={isMobile ? 1 : 0}
              ></SubscriptionChip>
            );
          }
        }
      }
    } else {
      return (
        <SubscriptionChip
          label="NO SUBSCRIPTION"
          background={CHIP_COLORS.noSub}
          isMobile={isMobile ? 1 : 0}
        ></SubscriptionChip>
      );
    }
  };

  return <>{getChip()}</>;
};

export default SubscriptionBadge;
