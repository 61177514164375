export const PbBlue = "#3B89C7";
export const PbDimBlue = "#F0F7FF";
export const PbYellow = "#F6EE81";
export const PbDimYellow = "#FFFCBD";
export const PbDarkYellow = "#d1ca66";
export const PbFontBlack = "#252829";
export const PbFontGray = "#717171";
export const PbFontGrayLight = "#878787";
export const PbFontGrayDark = "#555555";
export const PbFontGrayWatermark = "#A8A8A8";
export const PbWhite = "#F7F7F7";
