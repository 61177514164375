import { SubscriptionCheckout } from "pebblebee-sdk-frontend";
import mockService from "../../../Services/MockService";
import { subscriptionsClient } from "../../api";

interface PostDeviceCheckoutResponse extends SubscriptionCheckout {
  status: string;
  error?: {
    message: string;
    code: string;
  };
}
export async function post_SubscriptionCheckout(mac: string, plan: string) {
  try {
    if (mockService.getIsDemo()) {
      return { data: { status: "success", ...mockService.getMockedSubscriptionCheckout() }, status: 200 };
    }
    const response = await subscriptionsClient.post<PostDeviceCheckoutResponse>(
      `${process.env.REACT_APP_SUBSRIPTIONS_HOST}/subscriptions/checkout`,
      {
        deviceId: mac,
        plan: plan,
      }
    );
    return response;
  } catch (err) {
    return null;
  }
}
