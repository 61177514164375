import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { thirdLevel } from "../../../../../app/levels";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { boxShadows } from "../../../../../app/boxShadows";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../../../app/store";
import { setBottomRightMenu } from "../MenusSlice";
import {
  MenuCloseButton,
  MenuSubtitle,
  MenuTitle,
} from "../MenusStyledComponents";
import {
  PbDarkYellow,
  PbFontGray,
  PbFontGrayDark,
  PbFontGrayWatermark,
  PbYellow,
} from "../../../../../app/colors";
import {
  Button,
  TextField,
  TextareaAutosize,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { salesInquiry } from "../../../../home/HomeSlice";
import { PbErrorMessage } from "pebblebee-sdk-frontend";

const BoxContainer = styled.div(() => ({
  ...({
    height: "4vw",
    width: "4vw",
    position: "fixed",
    right: "1vw",
    bottom: "11vw",
    zIndex: thirdLevel,
    background: "#FFF",
    transition: "0.5s",
    borderRadius: "999px",
  } as React.CSSProperties),
}));

const AppsIconContainer = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  borderRadius: "999px",
  ":hover": {
    cursor: "pointer",
    background: "#E5E5E5",
  },
  boxShadow: boxShadows.veryHard,
});

const ContactContainer = styled.div({
  overflow: "auto",
  "::-webkit-scrollbar": {
    width: "5px",
    background: PbFontGrayWatermark,
  },
  "::-webkit-scrollbar-thumb": {
    background: PbFontGray,
    height: "20px",
  },
});

const QuestionAnswerIconButton = styled(QuestionAnswerIcon)({
  height: "65%",
  width: "65%",
});

const Row = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const CustomFormControl = styled(FormControl)({
  margin: "0px 4%",
  width: "100%",
});

export const ContactFormBox = styled.div({
  position: "fixed",
  right: "7vw",
  bottom: "1vw",
  display: "flex",
  flexDirection: "column",
  alignContent: "flex-start",
  width: "30%",
  height: "fit-content",
  borderRadius: "20px",
  background: "#FFF",
  zIndex: thirdLevel,
  paddingBottom: "1cqi",
  boxShadow: boxShadows.veryHard,
});

const SendMessageButton = styled(Button)({
  color: "#000",
  background: PbYellow,
  marginTop: "0.6cqi",
  width: "92%",
  fontSize: "1.2cqi",
  ":hover": {
    background: PbDarkYellow,
  },
});

const ContactTextField = styled(TextField)({
  width: "100%",
  fontSize: "0.8cqi",
  marginTop: "0.3cqi",
});

const ContactTextArea = styled(TextareaAutosize)({
  width: "99%",
  minWidth: "99%",
  maxWidth: "99%",
  height: "9vh",
  minHeight: "9vh",
  maxHeight: "9vh",
  fontSize: "1cqi",
});

const BlackLoader = styled(CircularProgress)({
  color: "#000",
  maxWidth: "1.2cqi",
  maxHeight: "1.2cqi",
  marginLeft: "2cqi",
});

const MessageSentContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "1.5cqi 1cqi",
});

const MessageSentText = styled.div({
  fontSize: "1cqi",
  color: PbFontGrayDark,
  margin: "0 0.3cqi",
});

export interface InquiryData {
  firstName: string;
  lastName: string;
  company: string;
  companyEmail: string;
  message: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  company?: string;
  companyEmail?: string;
  message?: string;
}

const ContactSalesWidget = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [formData, setFormData] = useState<InquiryData>({
    firstName: "",
    lastName: "",
    company: "",
    companyEmail: "",
    message: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const bottomRightMenu = useAppSelector(
    (state: RootState) => state.menus.bottomRightMenu
  );

  const sendingInquiry = useAppSelector(
    (state: RootState) => state.home.sendingInquiry
  );

  const inquirySent = useAppSelector(
    (state: RootState) => state.home.inquirySent
  );

  const showContactSalesBox = useMemo(() => {
    return bottomRightMenu === "contactSales";
  }, [bottomRightMenu]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    //clear error from modify field
    setErrors({
      ...errors,
      [name]: undefined,
    });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    dispatch(setBottomRightMenu(""));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = t(
        "contactSalesModal.errors.firstNameRequired"
      ) as string;
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = t(
        "contactSalesModal.errors.lastNameRequired"
      ) as string;
    }

    if (!formData.company.trim()) {
      newErrors.company = t(
        "contactSalesModal.errors.companyRequired"
      ) as string;
    }

    if (!formData.companyEmail.trim()) {
      newErrors.companyEmail = t(
        "contactSalesModal.errors.companyEmailRequired"
      ) as string;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      formData.companyEmail.trim() &&
      formData.companyEmail.trim() !== "" &&
      !formData.companyEmail.match(emailPattern)
    ) {
      newErrors.companyEmail = t(
        "contactSalesModal.errors.invalidEmail"
      ) as string;
    }

    if (!formData.message || formData.message === "") {
      newErrors.message = t(
        "contactSalesModal.errors.messageRequired"
      ) as string;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = () => {
    const isValid = validateForm();

    if (isValid) {
      dispatch(salesInquiry(formData));
    }
  };

  return (
    <>
      <BoxContainer>
        <AppsIconContainer
          onClick={() => {
            dispatch(
              setBottomRightMenu(showContactSalesBox ? "" : "contactSales")
            );
          }}
        >
          <QuestionAnswerIconButton />
        </AppsIconContainer>
      </BoxContainer>
      {showContactSalesBox ? (
        <ContactFormBox>
          <MenuCloseButton onClick={handleClose} />
          <MenuTitle>{t("contactSalesModal.title")}</MenuTitle>
          <MenuSubtitle>{t("contactSalesModal.subtitle")}</MenuSubtitle>
          <br></br>
          {inquirySent ? (
            <MessageSentContainer>
              <MessageSentText>
                {t("contactSalesModal.messageSent")}
              </MessageSentText>
            </MessageSentContainer>
          ) : (
            <ContactContainer>
              <Row>
                <CustomFormControl>
                  <ContactTextField
                    id="first-name-input"
                    name="firstName"
                    label={t("contactSalesModal.placeHolders.firstName")}
                    value={formData.firstName}
                    onChange={handleInputChange}
                    error={errors.firstName !== undefined}
                    helperText={errors.firstName}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <ContactTextField
                    id="last-name-input"
                    name="lastName"
                    label={t("contactSalesModal.placeHolders.lastName")}
                    value={formData.lastName}
                    onChange={handleInputChange}
                    error={errors.lastName !== undefined}
                    helperText={errors.lastName}
                  />
                </CustomFormControl>
              </Row>
              <br></br>
              <Row>
                <CustomFormControl>
                  <ContactTextField
                    id="company-input"
                    name="company"
                    label={t("contactSalesModal.placeHolders.company")}
                    value={formData.company}
                    onChange={handleInputChange}
                    error={errors.company !== undefined}
                    helperText={errors.company}
                  />
                </CustomFormControl>
                <CustomFormControl>
                  <ContactTextField
                    id="company-email-message"
                    name="companyEmail"
                    label={t("contactSalesModal.placeHolders.companyEmail")}
                    value={formData.companyEmail}
                    onChange={handleInputChange}
                    error={errors.companyEmail !== undefined}
                    helperText={errors.companyEmail}
                  />
                </CustomFormControl>
              </Row>
              <br></br>
              <CustomFormControl style={{ width: "92%" }}>
                <Row>
                  <ContactTextArea
                    id="user-message"
                    name="message"
                    minRows={5}
                    maxRows={5}
                    placeholder={
                      t("contactSalesModal.placeHolders.leaveMessage") as string
                    }
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </Row>
                <PbErrorMessage message={errors.message || ""}></PbErrorMessage>
              </CustomFormControl>
              <SendMessageButton
                onClick={submitForm}
                variant="contained"
                disabled={inquirySent}
              >
                {sendingInquiry ? (
                  <>
                    {t("contactSalesModal.sendingMessage")}
                    <BlackLoader />
                  </>
                ) : (
                  t("contactSalesModal.sendMessage")
                )}
              </SendMessageButton>
            </ContactContainer>
          )}
        </ContactFormBox>
      ) : null}
    </>
  );
};

export default ContactSalesWidget;
