import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Geofence } from "pebblebee-sdk-frontend";
import { delete_Geofence_Webhook } from "../app/apiRequests/platform/geofence/deleteGeofence";
import { get_Geofences } from "../app/apiRequests/platform/geofence/getGeofence";
import { patch_Geofence } from "../app/apiRequests/platform/geofence/patchGeofence";
import { post_Geofence_Webhook } from "../app/apiRequests/platform/geofence/postGeofence";
import i18n from "../i18n/i18n";

export interface GeofencesState {
  geofences: Geofence[];
  loadingGeofences: boolean;
  creatingGeofence: boolean;
  updatingGeofence: boolean;
  deletingGeofence: boolean;
  geofencesError: string;
}

const initialState: GeofencesState = {
  geofences: [],
  geofencesError: "",
  loadingGeofences: false,
  creatingGeofence: false,
  deletingGeofence: false,
  updatingGeofence: false,
};

export const getGeofences = createAsyncThunk(
  "geofences/getGeofences",
  async (_, thunkApi) => {
    try {
      const response = await get_Geofences();
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.getGeofences.default")
        );
      }
      return response.data.geofences;
    } catch (err) {
      return thunkApi.rejectWithValue(i18n.t("apiErrors.getGeofences.default"));
    }
  }
);

export const createGeofencesWebhook = createAsyncThunk(
  "geofences/createGeofence",
  async (
    {
      name,
      center,
      radius,
      devices,
    }: {
      name: string;
      center: {
        lat: number;
        lng: number;
      };
      radius: number;
      devices: string[];
    },
    thunkApi
  ) => {
    try {
      const response = await post_Geofence_Webhook(
        name,
        center,
        radius,
        devices
      );
      if (!response || (response.status !== 200 && response.status !== 201)) {
        const errorMessage =
          response?.data.error?.message ||
          i18n.t("apiErrors.createGeofences.default") as string;
        return thunkApi.rejectWithValue(errorMessage);
      }
      return i18n.t("geofences.created");
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.createGeofences.default")
      );
    }
  }
);

export const patchGeofences = createAsyncThunk(
  "geofences/patchGeofence",
  async (
    {
      id,
      name,
      center,
      radius,
      devices,
      enabled,
    }: {
      id: string;
      name: string;
      center: {
        lat: number;
        lng: number;
      };
      radius: number;
      devices: string[];
      enabled: boolean;
    },
    thunkApi
  ) => {
    try {
      const response = await patch_Geofence(
        id,
        name,
        center,
        radius,
        devices,
        enabled
      );
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.updateGeofences.default")
        );
      }
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.updateGeofences.default")
      );
    }
  }
);

export const deleteGeofencesWebhook = createAsyncThunk(
  "geofences/deleteGeofence",
  async (
    {
      id,
    }: {
      id: string;
    },
    thunkApi
  ) => {
    try {
      const response = await delete_Geofence_Webhook(id);
      if (!response || response.status !== 200) {
        return thunkApi.rejectWithValue(
          i18n.t("apiErrors.deleteGeofences.default")
        );
      }
      return i18n.t("geofence.created");
    } catch (err) {
      return thunkApi.rejectWithValue(
        i18n.t("apiErrors.deleteGeofences.default")
      );
    }
  }
);

export const GeofencesSlice = createSlice({
  name: "geofences",
  initialState,
  reducers: {
    setGeofence: (state, action: PayloadAction<Geofence>) => {
      const index = state.geofences.indexOf(action.payload);
      if (index !== -1) {
        state.geofences[index] = action.payload;
      }
    },
    setGeofenceError: (state, action: PayloadAction<string>) => {
      state.geofencesError = action.payload;
    },
    resetGeofencesState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      //get notifications
      .addCase(getGeofences.pending, (state) => {
        state.loadingGeofences = true;
      })
      .addCase(getGeofences.fulfilled, (state, action) => {
        state.loadingGeofences = false;
        state.geofences = action.payload;
      })
      .addCase(getGeofences.rejected, (state, action) => {
        state.loadingGeofences = false;
        state.geofencesError = action.payload as string;
      })
      //--- CREATE GEOFENCE --------------------------------
      .addCase(createGeofencesWebhook.pending, (state) => {
        state.creatingGeofence = true;
      })
      .addCase(createGeofencesWebhook.fulfilled, (state, action) => {
        state.creatingGeofence = false;
      })
      .addCase(createGeofencesWebhook.rejected, (state, action) => {
        state.creatingGeofence = false;
        state.geofencesError = action.payload as string;
      })
      //--- PATCH GEOFENCE --------------------------------
      .addCase(patchGeofences.pending, (state) => {
        state.updatingGeofence = true;
      })
      .addCase(patchGeofences.fulfilled, (state, action) => {
        if (action.payload.id) {
          const geofence = state.geofences.find(
            (g) => g.id === action.payload.id
          );
          if (geofence) {
            const index = state.geofences.indexOf(geofence);
            if (index !== -1) {
              state.geofences[index] = { ...action.payload };
            }
          }
        }
        state.updatingGeofence = false;
      })
      .addCase(patchGeofences.rejected, (state, action) => {
        state.geofencesError = action.payload as string;
        state.updatingGeofence = false;
      })
      //--- DELETE GEOFENCE --------------------------------
      .addCase(deleteGeofencesWebhook.pending, (state) => {
        state.deletingGeofence = true;
      })
      .addCase(deleteGeofencesWebhook.fulfilled, (state, action) => {
        state.deletingGeofence = false;
      })
      .addCase(deleteGeofencesWebhook.rejected, (state, action) => {
        state.geofencesError = action.payload as string;
        state.deletingGeofence = false;
      });
  },
});

export const { setGeofenceError, resetGeofencesState } = GeofencesSlice.actions;

export default GeofencesSlice.reducer;
