import * as React from "react";
import styled from "@emotion/styled";
import { fifthLevel, fourthLevel } from "../../../app/levels";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import CustomTransferList from "./CustomTransferList";
import { useAppDispatch } from "../../../app/hooks";
import {
  clearGeofenceData,
  setGeofenceDevices,
  setStep,
} from "../GeofenceHandlerSlice";
import {
  PbDimYellow,
  PbFontBlack,
  PbFontGray,
  PbFontGrayDark,
  PbYellow,
} from "../../../app/colors";
import {
  createGeofencesWebhook,
  getGeofences,
  patchGeofences,
} from "../../../dataSlices/GeofencesSlice";
import { setShowCreationHandler } from "../../geofencesLayout/geofencesLayoutSlice";
import {
  Button,
  CircularProgress,
  createTheme,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserDevice } from "pebblebee-sdk-frontend";
import DeviceGroup, { MY_DEVICES_UUID, SHARED_DEVICES_UUID } from "../../../models/enterprise/DeviceGroup";

const FormContainer = styled.div({
  position: "fixed",
  top: "55%",
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  zIndex: fifthLevel,
  background: "#fff",
  borderRadius: "20px",
  padding: " 4vh 4vw  4vh 4vw",
  height: "75vh",
  width: "50vw",
});

const TextFlexContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
});

const FormHeader = styled.div({
  height: "15%",
  overflow: "hidden",
});

const FormContent = styled.div({
  height: "70%",
  overflow: "hidden",
});

const FormTitle = styled.div({
  fontSize: "1.4cqi",
  fontWeight: 500,
  color: PbFontBlack,
  borderBottom: "0.2cqi solid " + PbYellow,
  textAlign: "center",
  marginBottom: "1.5vh",
});

const FormSubtitle = styled.div({
  fontSize: "1cqi",
  color: PbFontGray,
  minWidth: "10cqi",
  textAlign: "center",
  marginBottom: "2cqi",
});

const CoverScreen = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  background: "#00000090",
  zIndex: fourthLevel,
  position: "fixed",
  top: 0,
  left: 0,
});

const ButtonRow = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
  height: "15%",
  overflow: "hidden",
  marginTop: "2cqi",
});

const ButtonFlexContainer = styled.div({
  flex: "0.2",
});

const ContinueButton = styled(Button)({
  width: "100%",
  color: "#000",
  background: PbYellow,
  borderRadius: "999px",
  ":hover": {
    background: PbDimYellow,
  },
  fontSize: "0.9cqi",
  padding: "0.5cqi",
});

const BackButton = styled(Button)({
  width: "100%",
  color: "#FFF",
  background: PbFontBlack,
  borderRadius: "999px",
  ":hover": {
    background: PbFontGrayDark,
  },
  fontSize: "0.9cqi",
  padding: "0.5cqi",
});

const ErrorMessage = styled.div({
  color: "red",
  background: '#FFF',
  marginTop: '0.2cqi',
  borderRadius: '15px'
});

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: fifthLevel + 100,
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

const Loader = styled(CircularProgress)({
  width: "4cqi",
  height: "4cqi",
});

//Mobile

const FormContainerMobile = styled.div({
  zIndex: fifthLevel,
  background: "#fff",
  padding: " 2vh 3vw",
  minHeight: "40vh",
  width: "94vw",
});

const FormTitleMobile = styled.div({
  fontSize: "4qi",
  fontWeight: 500,
  color: PbFontBlack,
  borderBottom: "0.6cqi solid " + PbYellow,
  textAlign: "center",
  marginBottom: "3cqi",
});

const FormContentMobile = styled.div({
  width: "100%",
  overflow: "hidden",
});

const FormSubtitleMobile = styled.div({
  fontSize: "3cqi",
  color: PbFontGray,
  minWidth: "10cqi",
  textAlign: "center",
  marginBottom: "2cqi",
});

const LoaderMobile = styled(CircularProgress)({
  width: "10cqi",
  height: "10cqi",
});

const ContinueButtonMobile = styled(Button)({
  width: "100%",
  color: "#000",
  background: PbYellow,
  borderRadius: "999px",
  ":hover": {
    background: PbDimYellow,
  },
  fontSize: "3.5cqi",
  padding: "0.6cqi",
});

const BackButtonMobile = styled(Button)({
  width: "100%",
  color: "#FFF",
  background: PbFontBlack,
  borderRadius: "999px",
  ":hover": {
    background: PbFontGrayDark,
  },
  fontSize: "3.5cqi",
  padding: "0.6cqi",
});

export default function AddDevicesStep() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [submitError, setSubmitError] = React.useState<string | undefined>(
    undefined
  );

  const geofence = useSelector((state: RootState) => {
    return state.geofenceHandler.geofence;
  });

  const geofenceId = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceId;
  });

  const geofenceName = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceName;
  });

  const geofenceDevicesIds = useSelector((state: RootState) => {
    return state.geofenceHandler.geofenceDevicesIds;
  });

  const creatingGeofence = useSelector((state: RootState) => {
    return state.geofences.creatingGeofence;
  });

  const updatingGeofence = useSelector((state: RootState) => {
    return state.geofences.updatingGeofence;
  });

  const processingGeofence = React.useMemo(() => {
    return creatingGeofence || updatingGeofence;
  }, [creatingGeofence, updatingGeofence]);

  const devices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const devicesGroups = useSelector((state: RootState) => {
    return state.devices.groups;
  });

  const isMobile = useSelector(
    (state: RootState) => state.common.isMobileScreen
  );

  const itemKey = (item: UserDevice) => {
    return item.mac;
  };

  const itemLabel = (item: UserDevice) => {
    return item.name;
  };

  const onSearch = (items: UserDevice[], searchValue: string) => {
    if (searchValue && searchValue !== "") {
      const filtered = items.filter((d) => {
        return d.name
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());
      });
      return filtered;
    }
    return items;
  };

  const diferentGroupsValdation = (macs: string[]) => {
    let groups : DeviceGroup[] = [];

    macs.forEach((m) => {
      let d = devices.find((d) => d.mac === m);
      if (d) {
        let g = devicesGroups.find((g) => d?.group === g.uuid);
        if (g && groups.indexOf(g) === -1 && d.group !== MY_DEVICES_UUID && d.group !== SHARED_DEVICES_UUID) groups.push(g);
        if (d.group === MY_DEVICES_UUID) groups.push({
          userUuid: "",
          uuid: MY_DEVICES_UUID,
          name: "My Devices",
          readOnly: false,
          devices: []
        })
        if (d.group === SHARED_DEVICES_UUID) groups.push({
          userUuid: "",
          uuid: SHARED_DEVICES_UUID,
          name: "Shared",
          readOnly: false,
          devices: []
        })
      }
    });

    return groups.length > 1;
  };

  const onSelected = (selected: UserDevice[]) => {
    setSubmitError("");
    const macs = selected.map((d) => d.mac);
    if (diferentGroupsValdation(macs)) {
      setSubmitError(
        "Geofence devices can only belong to 1 group, please select devices from a single group"
      );
    }
    dispatch(setGeofenceDevices(macs));
  };

  const backStep = () => {
    dispatch(setStep("Customization"));
  };

  const submit = async () => {
    setSubmitError(undefined);
    if (!geofenceDevicesIds || geofenceDevicesIds.length === 0) {
      setSubmitError("At least one device must be added to the virtual fence");
      return;
    }

    if (diferentGroupsValdation(geofenceDevicesIds)) {
      setSubmitError(
        "Geofence devices can only belong to 1 group, please select devices from a single group"
      );
      return;
    }

    if (geofence) {
      if (geofenceId) {
        //patch virtual fence
        await dispatch(
          patchGeofences({
            id: geofenceId,
            center: geofence?.center,
            radius: geofence?.radius,
            name: geofenceName,
            devices: geofenceDevicesIds,
            enabled: true,
          })
        );
      } else {
        //create virtual fence
        await dispatch(
          createGeofencesWebhook({
            center: geofence?.center,
            radius: geofence?.radius,
            name: geofenceName,
            devices: geofenceDevicesIds,
          })
        );
      }
    }
    //clear state and go back to geofences main page
    dispatch(clearGeofenceData());
    dispatch(setShowCreationHandler(false));
    setTimeout(() => {
      dispatch(getGeofences());
    }, 1000);
  };

  const notSelected = React.useMemo(() => {
    const not = devices.filter((d) => {
      return (
        !geofenceDevicesIds.includes(d.guid || "") &&
        !geofenceDevicesIds.includes(d.mac) &&
        !d.sharedFrom
      );
    });
    return not;
  }, [devices, geofenceDevicesIds]);

  const selected = React.useMemo(() => {
    const s = devices.filter((d) => {
      return (
        (geofenceDevicesIds.includes(d.mac) ||
          geofenceDevicesIds.includes(d.guid || "")) &&
        !d.sharedFrom
      );
    });
    return s;
  }, [devices, geofenceDevicesIds]);

  const getGroups = (items: UserDevice[], filter: string) => {
    let userGroups: string[] = [];
    let showMyDevice = false;
    let filteredItems = onSearch(items, filter);
    filteredItems.forEach((device) => {
      let deviceGroup = devicesGroups.find((g) => {
        return g.uuid === device.group;
      });
      if (deviceGroup) {
        if (!userGroups.includes(deviceGroup.name) && !device.sharedFrom) {
          userGroups.push(deviceGroup.name);
        }
      } else {
        showMyDevice = true;
      }
    });
    if (showMyDevice) {
      userGroups = ["My Devices", ...userGroups];
    }

    const groups = userGroups.map((g) => {
      return {
        title: g,
        items: getGroupItems(items, g),
      };
    });
    return groups;
  };

  const getGroupItems = (items: UserDevice[], group: string) => {
    if (group === "My Devices") {
      const devices = items.filter(
        (d) => devicesGroups.some((g) => !d.group || d.group === MY_DEVICES_UUID)
      );
      return devices;
    } else {
      const devices = items.filter((d) =>
        devicesGroups.some((g) => g.uuid === d.group && g.name === group)
      );
      return devices;
    }
  };

  const renderDesktop = () => {
    return (
      <>
        <CoverScreen />
        <FormContainer>
          <FormHeader>
            <TextFlexContainer>
              <FormTitle>{t("geofences.stepsTitles.Devices")}</FormTitle>
            </TextFlexContainer>
            <TextFlexContainer>
              <FormSubtitle>{t("geofences.steps.Devices")}</FormSubtitle>
            </TextFlexContainer>
          </FormHeader>
          <FormContent>
            <CustomTransferList<UserDevice>
              initialItems={notSelected}
              initialSelectedItems={selected}
              itemKey={itemKey}
              itemLabel={itemLabel}
              onSearch={onSearch}
              onSelected={onSelected}
              getGroups={getGroups}
              leftTitle={"Unassigned"}
              rightTitle={"Assigned"}
              leftSearchPlaceHolder={"Search unassigned devices..."}
              rightSearchPlaceHolder={"Search assigned devices..."}
            />
          </FormContent>
          <ButtonRow>
            {processingGeofence ? (
              <Loader />
            ) : (
              <>
                <ButtonFlexContainer>
                  <BackButton onClick={backStep}>
                    {t("geofences.addDevices.back")}
                  </BackButton>
                </ButtonFlexContainer>
                <ButtonFlexContainer>
                  <ThemeProvider theme={theme}>
                    <Tooltip
                      title={
                        !geofenceDevicesIds || geofenceDevicesIds.length === 0
                          ? t("geofences.errors.deviceRequired")
                          : ""
                      }
                    >
                      <span>
                        <ContinueButton
                          disabled={
                            !geofenceDevicesIds ||
                            geofenceDevicesIds.length === 0
                          }
                          onClick={submit}
                          style={{ position: "relative" }}
                        >
                          {t("geofences.addDevices.save")}
                        </ContinueButton>
                      </span>
                    </Tooltip>
                  </ThemeProvider>
                </ButtonFlexContainer>
              </>
            )}
          </ButtonRow>
          <ErrorMessage>{submitError}</ErrorMessage>
        </FormContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <FormContainerMobile>
          <FormHeader>
            <TextFlexContainer>
              <FormTitleMobile>Device selection</FormTitleMobile>
            </TextFlexContainer>
            <TextFlexContainer>
              <FormSubtitleMobile>
                {t("geofences.steps.Devices")}
              </FormSubtitleMobile>
            </TextFlexContainer>
          </FormHeader>
          <FormContentMobile>
            <CustomTransferList<UserDevice>
              initialItems={notSelected}
              initialSelectedItems={selected}
              itemKey={itemKey}
              itemLabel={itemLabel}
              onSearch={onSearch}
              onSelected={onSelected}
              getGroups={getGroups}
              leftTitle={"Unassigned"}
              rightTitle={"Assigned"}
              leftSearchPlaceHolder={"Search unassigned devices..."}
              rightSearchPlaceHolder={"Search assigned devices..."}
            />
          </FormContentMobile>
          <ButtonRow>
            {processingGeofence ? (
              <LoaderMobile />
            ) : (
              <>
                <ButtonFlexContainer>
                  <BackButtonMobile onClick={backStep}>Back</BackButtonMobile>
                </ButtonFlexContainer>
                <ButtonFlexContainer>
                  <ThemeProvider theme={theme}>
                    <Tooltip
                      title={
                        !geofenceDevicesIds || geofenceDevicesIds.length === 0
                          ? t("geofences.errors.deviceRequired")
                          : ""
                      }
                    >
                      <span>
                        <ContinueButtonMobile
                          disabled={
                            !geofenceDevicesIds ||
                            geofenceDevicesIds.length === 0
                          }
                          onClick={submit}
                          style={{ position: "relative" }}
                        >
                          Save
                        </ContinueButtonMobile>
                      </span>
                    </Tooltip>
                  </ThemeProvider>
                </ButtonFlexContainer>
              </>
            )}
          </ButtonRow>
          <ErrorMessage>
            <span className="p2 rounded-md bg-white z-10000">
              {submitError}
            </span>
          </ErrorMessage>
        </FormContainerMobile>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
}
