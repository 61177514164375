import { CircularProgress, ListItemButton, Tooltip } from "@mui/material";
import { Reading, UserDevice } from "pebblebee-sdk-frontend";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import ReadingsService from "../../../../Services/ReadingsService";
import { useTranslation } from "react-i18next";
import SubscriptionBadge from "./SubscriptionBadge";
import BatteryLevel from "./BatteryLevel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ActionsRow,
  AddressLoader,
  AddressText,
  BackButton,
  BlueButton,
  CardInfo,
  CloseButton,
  DataContainer,
  FieldTitle,
  FieldValue,
  GroupContainer,
  InfoCard,
  LightButton,
  LoaderContainer,
  MetaText,
  NameContainer,
  RefreshButton,
  Row,
  SectionTitle,
  SplitRow,
  SubscriptionLoader,
  SubscriptionRowButton,
  SubscriptionsTopRow,
  TopIconsContainer,
} from "./StyledComponents";
import SubscriptionData from "./SubscriptionData";
import { getDeviceSubscriptions } from "../../../../dataSlices/SubscriptionsSlice";
import {
  setShowDeviceDetails,
  setShowSubData,
  setShowTrackingModesBox,
  setShowTrackingModesInfo,
} from "../../MainLayoutSlice";
import styled from "@emotion/styled";
import {
  setHistoryLayout,
  setSelectedElementId,
} from "../../../home/HomeSlice";
import { setCoordinateAddress } from "../../../UserMap/MapSlice";
import queueService from "../../../../Services/QueueService";
import { PbFontGray } from "../../../../app/colors";
import {
  ActionsRowMobile,
  AddressTextMobile,
  AvatarImgMobile,
  BackButtonMobile,
  BlueButtonMobile,
  CardInfoMobile,
  CloseButtonMobile,
  DataContainerMobile,
  FieldTitleMobile,
  FieldValueMobile,
  GroupContainerMobile,
  InfoCardMobile,
  LightButtonMobile,
  MetaTextMobile,
  NameContainerMobile,
  RowButtonMobile,
  SectionTitleMobile,
  SplitRowMobile,
} from "./StyledComponentsMobile";
import trackingModesService from "../../../../Services/TrackingModesService";
import PbDeviceAvatarIcon from "../../../../components/PbDeviceAvatarIcon/PbDeviceAvatarIcon";
import TrackingModesInfoModal from "./TrackingModesInfoModal";
import ChangeTrackingModeBox from "./ChangeTrackingModeBox";
import { getUserDevice } from "../../../../dataSlices/DevicesSlice";
import { AccessList } from "../../../../Services/AuthService";

const LoadingAddresMarker = styled(CircularProgress)({
  maxWidth: "1.2cqi",
  maxHeight: "1.2cqi",
  color: PbFontGray,
  marginLeft: "0.5cqi",
});

const InfoOutlinedIconButton = styled(InfoOutlinedIcon)({
  marginLeft: "0.3cqi",
  height: "1.2cqi",
  width: "1.2cqi",
  ":hover": {
    cursor: "pointer",
  },
});

const DeviceData = ({
  device,
  reading,
  isCellular,
}: {
  device: UserDevice;
  reading?: Reading;
  isCellular: Boolean;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [animate, setAnimate] = useState(0);

  const trackingModeRef = useRef<HTMLDivElement | null>(null);

  const userDevices = useSelector((state: RootState) => {
    return state.devices.userDevices;
  });

  const userDevice = useMemo(() => {
    const ud = userDevices.find((d) => d.mac === device.mac);
    return ud !== undefined ? ud : device;
  }, [device, userDevices]);

  const loggedUser = useSelector((state: RootState) => {
    return state.login.loggedUser;
  });

  const isMetric = useMemo(() => {
    return loggedUser?.isMetric === 1;
  }, [loggedUser]);

  const faliedToLoadSubscription = useSelector((state: RootState) => {
    return state.subscriptions.faliedToLoadSubscription;
  });

  const devicesSubscription = useSelector((state: RootState) => {
    return state.subscriptions.devicesSubscription;
  });

  const subscriptionData = useMemo(() => {
    return devicesSubscription.find((s) => {
      return s.deviceId === userDevice.mac;
    });
  }, [userDevice.mac, devicesSubscription]);

  const coordinatesAddresses = useSelector((state: RootState) => {
    return state.map.coordinatesAddresses;
  });

  const address = useMemo(() => {
    if (reading && reading.coordinates!==undefined) {
      let crds = reading.coordinates.value
      const address = coordinatesAddresses.find(
        (add) =>
          add.coordinates.lat === crds.lat &&
          add.coordinates.lng === crds.lng
      );
      if (address) {
        if (address.status === "pending") {
          return (
            <Row>
              <div>Loading address</div>
              <LoadingAddresMarker />
            </Row>
          );
        }
        return address.address;
      }
    }
    return "";
  }, [coordinatesAddresses, reading]);

  useEffect(() => {
    const addAddressCallback = ({
      coordinates,
      status,
      address,
    }: {
      coordinates: { lat: number; lng: number };
      status: google.maps.GeocoderStatus | "pending";
      address: string;
    }) => {
      dispatch(
        setCoordinateAddress({
          coordinates,
          status,
          address,
        })
      );
    };
    const fetchAddress = async () => {
      if (reading && reading.coordinates) {
        addAddressCallback({
          coordinates: reading.coordinates.value,
          status: "pending",
          address: "",
        });
        //adds request to the queue to be processed
        queueService.addToGeocodingQueue(
          reading.coordinates.value,
          addAddressCallback
        );
        queueService.startGeocodingQueue();
      }
    };
    fetchAddress();
  }, [dispatch, reading]);

  useEffect(() => {
    //restart animation
    setAnimate(0);
    setTimeout(() => {
      setAnimate(1);
    }, 1);
  }, []);

  useEffect(() => {
    const getSubData = async () => {
      await dispatch(getDeviceSubscriptions({ mac: userDevice.mac }));
    };

    if (isCellular && !subscriptionData) {
      getSubData();
    }
  }, [userDevice.mac, dispatch, isCellular, subscriptionData]);

  const userLocation = useSelector((state: RootState) => {
    return state.common.location;
  });

  const showSubData = useSelector((state: RootState) => {
    return state.mainLayout.showSubData;
  });

  const loadingAddressInfo = useSelector((state: RootState) => {
    return state.mainLayout.loadingAddressInfo;
  });

  const loadingSubscription = useSelector((state: RootState) => {
    return state.subscriptions.loadingSubscription;
  });

  const isMobileScreen = useSelector((state: RootState) => {
    return state.common.isMobileScreen;
  });

  const refreshingDevice = useSelector(
    (state: RootState) => state.devices.refreshingDevice
  );

  const updatingTrackingMode = useSelector(
    (state: RootState) => state.devices.updatingTrackingMode
  );

  const handleSubscriptionRefresh = () => {
    dispatch(getDeviceSubscriptions({ mac: userDevice.mac }));
  };

  const getTrackingMode = () => {
    if (userDevice.cellSettings?.tracking) {
      return trackingModesService.getTrackingMode(
        userDevice.cellSettings?.tracking
      );
    }
    if (userDevice.cellSettings?.last_tracking) {
      return trackingModesService.getTrackingMode(
        userDevice.cellSettings?.last_tracking
      );
    }
    return "-";
  };

  const isBluetoothOnly = useMemo(() => {
    return userDevice.cellSettings?.tracking === "ble_only";
  }, [userDevice.cellSettings]);

  const displayTrackingModesBox = async () => {
    //first update the device to get the latest info and tracking modes
    const result = await dispatch(
      getUserDevice({ mac: userDevice.mac })
    );
    if (result.type.includes("fulfilled")) {
      const device = result.payload as UserDevice;
      if (
        !device.cellSettings ||
        device.cellSettings?.tracking === "ble_only"
      ) {
        return;
      }
    } else {
      return;
    }
    dispatch(setShowTrackingModesBox(true));
  };

  const displayTrackingModesInfo = () => {
    dispatch(setShowTrackingModesInfo(true));
  };

  const renderDesktop = () => {
    return (
      <>
        <DataContainer animate={animate}>
          {!showSubData ? (
            <>
              <GroupContainer>
                {userDevice.groupName ? userDevice.groupName : ""}
              </GroupContainer>
              <SplitRow>
                <NameContainer>{userDevice.name}</NameContainer>
                <TopIconsContainer>
                  <BatteryLevel
                    reading={reading}
                    modelNumber={userDevice.model}
                  />
                  <CloseButton
                    color="disabled"
                    onClick={() => {
                      dispatch(setShowDeviceDetails(false));
                      dispatch(setSelectedElementId(""));
                    }}
                  ></CloseButton>
                </TopIconsContainer>
              </SplitRow>
              <InfoCard>
                <PbDeviceAvatarIcon device={userDevice} selected />
                <CardInfo>
                  <MetaText>
                    {reading
                      ? ReadingsService.infoString(
                          reading,
                          userLocation,
                          isMetric
                        )
                      : "No readings"}
                  </MetaText>
                  <Tooltip title={loadingAddressInfo ? "" : address}>
                    <AddressText>
                      {loadingAddressInfo ? (
                        <LoaderContainer>
                          <AddressLoader />
                        </LoaderContainer>
                      ) : (
                        <>{address}</>
                      )}
                    </AddressText>
                  </Tooltip>
                </CardInfo>
              </InfoCard>
              <ActionsRow>
                <LightButton
                  onClick={() => {
                    dispatch(setHistoryLayout());
                  }}
                >
                  {t("deviceData.labels.history")}
                </LightButton>
                <Tooltip title="Coming soon!">
                  <BlueButton>{t("deviceData.labels.directions")}</BlueButton>
                </Tooltip>
              </ActionsRow>
              {isCellular &&
              !userDevice.sharedFrom &&
              (!loggedUser?.isEnterprise ||
                loggedUser.enterpriseData?.permissionList?.includes(
                  AccessList.SUBSCRIPTION_READ
                )) ? (
                loadingSubscription ? (
                  <LoaderContainer>
                    <SubscriptionLoader />
                  </LoaderContainer>
                ) : (
                  <>
                    <SubscriptionRowButton
                      onClick={() => {
                        if (subscriptionData) dispatch(setShowSubData(true));
                      }}
                    >
                      <FieldTitle>
                        {t("deviceData.labels.subscription")}
                      </FieldTitle>
                      <FieldValue>
                        {faliedToLoadSubscription ? (
                          <Row onClick={handleSubscriptionRefresh}>
                            {t("deviceData.labels.failedSubscription")}{" "}
                            <RefreshButton />
                          </Row>
                        ) : (
                          <SubscriptionBadge subscription={subscriptionData} />
                        )}
                      </FieldValue>
                    </SubscriptionRowButton>
                    {subscriptionData ? (
                      <SplitRow>
                        {!loggedUser?.isEnterprise ||
                        loggedUser.enterpriseData?.permissionList?.includes(
                          AccessList.TRACKING_MODE_UPDATE
                        ) ? (
                          <>
                            <ChangeTrackingModeBox
                              device={userDevice}
                              trackingModeRef={trackingModeRef.current}
                            />
                            <TrackingModesInfoModal device={userDevice} />
                          </>
                        ) : null}
                        <FieldTitle>
                          <Row>
                            {t("deviceData.labels.trackingMode")}
                            <Tooltip title="View specs">
                              <InfoOutlinedIconButton
                                onClick={displayTrackingModesInfo}
                              />
                            </Tooltip>
                          </Row>
                        </FieldTitle>
                        <Tooltip
                          title={
                            isBluetoothOnly
                              ? (t("deviceData.bleOnlyWarning") as string)
                              : ""
                          }
                        >
                          <FieldValue onClick={displayTrackingModesBox}>
                            <ListItemButton
                              disabled={isBluetoothOnly}
                              style={{ padding: "0.2cqi 0px" }}
                            >
                              <div
                                ref={trackingModeRef}
                                id="tracking-mode-label"
                              >
                                {updatingTrackingMode || refreshingDevice ? (
                                  <CircularProgress />
                                ) : (
                                  getTrackingMode()
                                )}
                              </div>
                            </ListItemButton>
                          </FieldValue>
                        </Tooltip>
                      </SplitRow>
                    ) : null}
                  </>
                )
              ) : null}

              <SectionTitle>{t("deviceData.labels.deviceInfo")}</SectionTitle>
              {userDevice.sharedFrom ? (
                <SplitRow>
                  <FieldTitle>{t("deviceData.labels.sharedFrom")}</FieldTitle>
                  <Tooltip title={userDevice.sharedFrom}>
                    <FieldValue>{userDevice.sharedFrom}</FieldValue>
                  </Tooltip>
                </SplitRow>
              ) : null}
              <SplitRow>
                <FieldTitle>{t("deviceData.labels.mac")}</FieldTitle>
                <FieldValue>{userDevice.mac}</FieldValue>
              </SplitRow>
              {isCellular ? (
                <>
                  <SplitRow>
                    <FieldTitle>{t("deviceData.labels.imei")}</FieldTitle>
                    <FieldValue>{userDevice.imei}</FieldValue>
                  </SplitRow>
                  <SplitRow>
                    <FieldTitle>{t("deviceData.labels.iccid")}</FieldTitle>
                    <FieldValue>{userDevice.iccid}</FieldValue>
                  </SplitRow>
                </>
              ) : null}
            </>
          ) : (
            <>
              <SubscriptionsTopRow>
                <BackButton
                  color="disabled"
                  onClick={() => {
                    dispatch(setShowSubData(false));
                  }}
                ></BackButton>
                <CloseButton
                  color="disabled"
                  onClick={() => {
                    dispatch(setShowDeviceDetails(false));
                    dispatch(setShowSubData(false));
                  }}
                ></CloseButton>
              </SubscriptionsTopRow>
              <SubscriptionData
                device={userDevice}
                subscription={subscriptionData}
              />
            </>
          )}
        </DataContainer>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <DataContainerMobile animate={animate}>
          {!showSubData ? (
            <>
              <ActionsRowMobile>
                <CloseButtonMobile
                  color="disabled"
                  onClick={() => {
                    dispatch(setShowDeviceDetails(false));
                  }}
                ></CloseButtonMobile>
                <BatteryLevel
                  reading={reading}
                  modelNumber={userDevice.model}
                />
              </ActionsRowMobile>
              <GroupContainerMobile>
                {userDevice.groupName ? userDevice.groupName : ""}
              </GroupContainerMobile>
              <NameContainerMobile>
                {userDevice.name}
              </NameContainerMobile>
              <InfoCardMobile>
                <AvatarImgMobile
                  src={userDevice.imageUrl}
                  alt={userDevice.name}
                />
                <CardInfoMobile>
                  <MetaTextMobile>
                    {reading
                      ? ReadingsService.infoString(
                          reading,
                          userLocation,
                          isMetric
                        )
                      : "No readings"}
                  </MetaTextMobile>
                  <AddressTextMobile>
                    {loadingAddressInfo ? (
                      <CircularProgress />
                    ) : (
                      <Tooltip title={address}>
                        <>{address}</>
                      </Tooltip>
                    )}
                  </AddressTextMobile>
                </CardInfoMobile>
              </InfoCardMobile>
              <ActionsRowMobile>
                <LightButtonMobile
                  onClick={() => {
                    dispatch(setHistoryLayout());
                  }}
                >
                  {t("deviceData.labels.history")}
                </LightButtonMobile>
                <Tooltip title="Coming soon!">
                  <BlueButtonMobile>
                    {t("deviceData.labels.directions")}
                  </BlueButtonMobile>
                </Tooltip>
              </ActionsRowMobile>
              {isCellular && !userDevice.sharedFrom ? (
                loadingSubscription ? (
                  <CircularProgress />
                ) : (
                  <>
                    <RowButtonMobile
                      onClick={() => {
                        if (subscriptionData) dispatch(setShowSubData(true));
                      }}
                    >
                      <FieldTitleMobile>
                        {t("deviceData.labels.subscription")}
                      </FieldTitleMobile>
                      <FieldTitleMobile>
                        {faliedToLoadSubscription ? (
                          <Row>
                            {t("deviceData.labels.failedSubscription")}{" "}
                            <RefreshButton
                              onClick={handleSubscriptionRefresh}
                            />
                          </Row>
                        ) : (
                          <SubscriptionBadge subscription={subscriptionData} />
                        )}
                      </FieldTitleMobile>
                    </RowButtonMobile>
                    {subscriptionData ? (
                      <SplitRowMobile>
                        <FieldTitleMobile>
                          {t("deviceData.labels.trackingMode")}
                        </FieldTitleMobile>
                        <FieldTitleMobile>{getTrackingMode()}</FieldTitleMobile>
                      </SplitRowMobile>
                    ) : null}
                  </>
                )
              ) : null}

              <SectionTitleMobile>
                {t("deviceData.labels.deviceInfo")}
              </SectionTitleMobile>
              <SplitRowMobile>
                <FieldTitleMobile>
                  {t("deviceData.labels.sharedFrom")}
                </FieldTitleMobile>
                <Tooltip title={userDevice.sharedFrom}>
                  <FieldValueMobile>{userDevice.sharedFrom}</FieldValueMobile>
                </Tooltip>
              </SplitRowMobile>
              <SplitRowMobile>
                <FieldTitleMobile>
                  {t("deviceData.labels.mac")}
                </FieldTitleMobile>
                <FieldValueMobile>{userDevice.mac}</FieldValueMobile>
              </SplitRowMobile>
              {isCellular ? (
                <>
                  <SplitRowMobile>
                    <FieldTitleMobile>
                      {t("deviceData.labels.imei")}
                    </FieldTitleMobile>
                    <FieldValueMobile>{userDevice.imei}</FieldValueMobile>
                  </SplitRowMobile>
                  <SplitRowMobile>
                    <FieldTitleMobile>
                      {t("deviceData.labels.iccid")}
                    </FieldTitleMobile>
                    <FieldValueMobile>{userDevice.iccid}</FieldValueMobile>
                  </SplitRowMobile>
                </>
              ) : null}
            </>
          ) : (
            <>
              <CloseButtonMobile
                color="disabled"
                onClick={() => {
                  dispatch(setShowDeviceDetails(false));
                  dispatch(setShowSubData(false));
                }}
              ></CloseButtonMobile>
              <BackButtonMobile
                color="disabled"
                onClick={() => {
                  dispatch(setShowSubData(false));
                }}
              ></BackButtonMobile>
              <SubscriptionData
                device={userDevice}
                subscription={subscriptionData}
              />
            </>
          )}
        </DataContainerMobile>
      </>
    );
  };

  return isMobileScreen ? renderMobile() : renderDesktop();
};

export { DeviceData };
